import { Form, Input, Select } from "antd";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

export default function CompanyInfoStep() {
  const { data: constants } = useFetchBackendConstantsQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <>
      <Form.Item name="id" label="Company ID">
        <Input placeholder="Enter company id..." disabled />
      </Form.Item>
      <Form.Item
        name="name"
        label="Company Name"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Input placeholder="Enter company name..." />
      </Form.Item>
      <Form.Item
        name="einTaxId"
        label="EIN Tax ID"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Input placeholder="Enter tax id..." />
      </Form.Item>
      <Form.Item
        name={["address", "address1"]}
        label="Address"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Input placeholder="Enter address..." />
      </Form.Item>
      <Form.Item name={["address", "address2"]} label="Address Line 2">
        <Input placeholder="Enter address..." />
      </Form.Item>
      <Form.Item
        name={["address", "city"]}
        label="City"
        rules={[
          { pattern: /^[^\d]*$/, message: "Please enter a valid city" },
          { required: true, message: "This field is required." },
        ]}
      >
        <Input placeholder="Enter city..." />
      </Form.Item>
      <Form.Item
        name={["address", "state"]}
        label="State"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Select
          placeholder="Please select a state..."
          filterOption={filterOption}
          showSearch
          options={constants?.states.map((state) => ({
            label: state.display,
            value: state.value,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={["address", "zipCode"]}
        label="Zip Code"
        rules={[
          { pattern: /^\d{5}(-\d{4})?$/, message: "Must be a valid zip code" },
          { required: true, message: "This field is required." },
        ]}
      >
        <Input placeholder="Enter zip code..." maxLength={5} />
      </Form.Item>
      <Form.Item
        name="collectionManagerName"
        label="Collection Manager"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Input placeholder="Enter collection manager..." />
      </Form.Item>
      <Form.Item
        label="Collection Manager's Phone"
        name="collectionManagerPhone"
        rules={[
          {
            pattern:
              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
            message: "Business phone is required",
          },
          { required: true, message: "This field is required." },
        ]}
      >
        <Input placeholder="Enter collection manager's phone..." />
      </Form.Item>
    </>
  );
}
