import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input, Tooltip } from "antd";
import { toTitleCase } from "common/utils";
import styled from "styled-components";

const StyledValue = styled.span`
  flex: 1;
  overflow-wrap: anywhere;
`;

const StyledLabel = styled.span`
  text-transform: capitalize;
  color: grey;
  font-size: 12px;
  flex: 1;
`;

const StyledInfoTitle = styled.div`
  margin-left: 4px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

export default function DisplayEmail(props) {
  const { isEditing, label, slice, children } = props;
  if (isEditing) {
    return (
      children || (
        <Form.Item {...props}>
          <Input />
        </Form.Item>
      )
    );
  }
  return (
    <StyledContainer>
      <StyledLabel>
        {label} ({toTitleCase({ str: slice.type, delimiter: "_" })})
        {slice.notes ? (
          <Tooltip title={slice.notes} trigger="hover">
            <StyledInfoCircleOutlined />
          </Tooltip>
        ) : null}
      </StyledLabel>
      <StyledValue>{slice.address}</StyledValue>
    </StyledContainer>
  );
}
