export default [
  {
    key: "id",
    label: "Debtor ID",
    type: "number",
    validators: [{ validate: "require_without_all", fields: ["ssn", "external_id"] }],
  },
  {
    key: "external_id",
    label: "External Debtor ID",
    type: "string",
    validators: [{ validate: "require_without_all", fields: ["id", "ssn"] }],
  },
  {
    key: "ssn",
    label: "SSN",
    type: "string",
    validators: [{ validate: "require_without_all", fields: ["id", "external_id"] }],
  },
];
