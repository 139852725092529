import api, { transformRequest, transformResponse } from "services/api";

export const CreditorGroup = api
  .enhanceEndpoints({
    addTagTypes: ["CreditorGroup", "DuplicateDebtorMerge"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorGroup: build.query({
        query: () => "api/v1/core/debtor-merge-groups/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorGroup", id })),
                { type: "CreditorGroup", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorGroup', id: 'LIST' }` is invalidated
              [{ type: "CreditorGroup", id: "LIST" }],
      }),
      addCreditorGroup: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/debtor-merge-groups/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorGroup", id: "LIST" }],
      }),
      updateCreditorGroup: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-merge-groups/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "CreditorGroup", id: arg.id }],
      }),
      deleteCreditorGroup: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-merge-groups/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [{ type: "CreditorGroup", id: "LIST" }],
      }),
      fetchEligibleDebtorsForGroup: build.query({
        query: (payload) => ({
          url: `/api/v1/core/merge-eligible-debtors/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorGroup", id })),
                { type: "CreditorGroup", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorGroup', id: 'LIST' }` is invalidated
              [{ type: "CreditorGroup", id: "LIST" }],
      }),
      deleteEligibleDebtorsForGroup: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/merge-eligible-debtors/${payload.mergeEligibleDebtorsId}/delete/`,
          method: "POST",
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [
          { type: "CreditorGroup", id: "LIST" },
          { type: "DuplicateDebtorMerge", id: "LIST" },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchCreditorGroupQuery,
  useAddCreditorGroupMutation,
  useDeleteCreditorGroupMutation,
  useUpdateCreditorGroupMutation,
  useFetchEligibleDebtorsForGroupQuery,
  useDeleteEligibleDebtorsForGroupMutation,
} = CreditorGroup;
