import { PageHeader } from "components/pageHeader";
import WorkflowTabs from "features/workflows/components/workflowTabs";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function WorkflowListPage() {
  return (
    <PageLayout>
      <Content>
        <PageHeader>Automation Workflows</PageHeader>
        <WorkflowTabs />
      </Content>
    </PageLayout>
  );
}

export default WorkflowListPage;
