import api, { transformRequest, transformResponse } from "services/api";

export const creditReportingApi = api
  .enhanceEndpoints({ addTagTypes: ["AuthenticationMethods", "AuthenticationMethodSchema"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAuthenticationMethods: build.query({
        query: () => `/api/v1/core/authentication-methods/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "AuthenticationMethods", id })),
                { type: "AuthenticationMethods", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AuthenticationMethods', id: 'LIST' }` is invalidated
              [{ type: "AuthenticationMethods", id: "LIST" }],
      }),
      updateAuthenticationMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/authentication-methods/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all AuthenticationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "AuthenticationMethods", id: "LIST" }],
      }),
      deleteAuthenticationMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/authentication-methods/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all AuthenticationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "AuthenticationMethods", id: "LIST" }],
      }),
      createAuthenticationMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/authentication-methods/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all AuthenticationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "AuthenticationMethods", id: "LIST" }],
      }),
      fetchAuthenticationSchema: build.query({
        query: () => `/api/v1/core/authentication-methods/schema/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) =>
          result
            ? // successful query
              [{ type: "AuthenticationMethodSchema", id: "LIST" }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AuthenticationMethodSchema', id: 'LIST' }` is invalidated
              [{ type: "AuthenticationMethodSchema", id: "LIST" }],
      }),
    }),
  });

export const {
  useCreateAuthenticationMethodMutation,
  useFetchAuthenticationMethodsQuery,
  useUpdateAuthenticationMethodMutation,
  useDeleteAuthenticationMethodMutation,
  useFetchAuthenticationSchemaQuery,
} = creditReportingApi;
