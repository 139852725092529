import api, { transformResponse } from "services/api";

export const udfApi = api
  .enhanceEndpoints({ addTagTypes: ["DebtorUdfCustomField"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtorUdfCustomFields: build.query({
        query: () => `/api/v1/common/debtor-custom-fields/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorUdfCustomField", id })),
                { type: "DebtorUdfCustomField", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorUdfCustomField', id: 'LIST' }` is invalidated
              [{ type: "DebtorUdfCustomField", id: "LIST" }],
      }),
    }),
  });

export const { useFetchDebtorUdfCustomFieldsQuery } = udfApi;
