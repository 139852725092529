import { Button, Form, Modal, Select, Switch, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  useCreateAppearanceMutation,
  useFetchAppearancesQuery,
  useUpdateAppearanceMutation,
} from "features/appearance/appearanceAPI";
import AppearanceManager from "features/appearance/components/appearanceManager";
import { useFetchRolesQuery } from "features/userManagementTable/userManagementAPI";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
`;

const StyledSelect = styled(Select)`
  max-width: 400px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
    position: sticky;
    top: 0px;
    z-index: 1;
    margin-top: -16px;
    margin-bottom: 32px;
    padding: 16px 0;
  }

  .ant-modal-close {
    position: sticky;
    float: right;
  }

  .ant-modal-footer {
    position: sticky;
    bottom: 0px;
    background-color: #fff;
    padding: 16px 0;
    margin-bottom: -16px;
    border-top: 1px solid #f0f0f0;
  }

  .ant-modal-content {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

export function reconcileColumns(
  defaultColumns,
  { columns: appearanceItemColumns, defaultSortOrder } = {
    columns: [],
    defaultSortOrder: undefined,
  },
) {
  defaultColumns.forEach((column) => {
    // We need to track the original title, because we override the column's title later
    // so that the title can be used in the table header.
    column.originalTitle = column.title;

    // If this column has a sorter, make sure to save the sort field names so we can save
    // the sort order if this column is selected as the default sort column.
    if (column.sorter) {
      column.sorterFieldNames = column.sorter?.fields ?? [column.dataIndex];
    }
  });
  // If there's no appearance item, return the default columns
  if (!appearanceItemColumns) {
    return [...defaultColumns];
  }

  // Build a map of default column keys to default columns
  const defaultColumnKeysToColumn = defaultColumns.reduce((acc, column) => {
    acc[column.key] = { ...column };
    return acc;
  }, {});

  const appearanceColumnKeys = appearanceItemColumns?.map((column) => column.key);

  const columnsToRender = [];

  // Return columns in the order they appear in the appearance item along
  // with any new columns that are not in the appearance item added at the end.
  appearanceItemColumns.forEach((appearanceColumn) => {
    if (defaultColumnKeysToColumn[appearanceColumn.key]) {
      const column = defaultColumnKeysToColumn[appearanceColumn.key];
      // We need to track the original title so that the edit modal can display the original title
      column.originalTitle = column.title;
      column.visible = appearanceColumn.visible ?? true;
      column.newTitle = appearanceColumn.newTitle;
      // We override the column's title bc the actual table relies on the 'title' key
      // to set the table's headers.
      column.title = appearanceColumn.newTitle || column.title;
      if (appearanceColumn.sorterFieldNames && defaultSortOrder?.column === column.key) {
        column.defaultSortOrder = defaultSortOrder?.direction;
      }
      columnsToRender.push(column);
    }
  });

  defaultColumns.forEach((column) => {
    if (!appearanceColumnKeys.includes(column.key)) {
      columnsToRender.push(column);
    }
  });
  return columnsToRender;
}

/*
 * This component is used to manage the appearance of the table or the sider.
 * It is used in the TableAppearanceModal component.
 *  @param {boolean} open - The visibility of the modal
 *  @param {function} onOk - The function to call when the ok button is clicked
 *  @param {function} onCancel - The function to call when the cancel button is clicked
 *  @param {string} tableId - The id of the table
 *  @param {array} defaultColumns - The default columns of the table
 *  @param {"table"|"sider"} mode - The mode of the appearance manager
 */
function TableAppearanceModal({
  open,
  onOk,
  onCancel,
  tableId,
  defaultColumns,
  mode = "table",
  paginationSelector = true,
}) {
  const [form] = useForm();
  const [createAppearance, { isLoading: isCreateMethodLoading }] = useCreateAppearanceMutation();
  const [updateAppearance, { isLoading: isUpdateMethodLoading }] = useUpdateAppearanceMutation();
  const { data: roles, isLoading: isRolesLoading } = useFetchRolesQuery();
  const { data: appearances, isLoading, isFetching } = useFetchAppearancesQuery();

  const [dataSource, setDataSource] = useState([]);

  const watchedGroupID = Form.useWatch("groupId", form);
  useEffect(() => {
    if (watchedGroupID) {
      const currentRole = roles.find((r) => r.id === watchedGroupID);
      const appearance = appearances?.find((a) => a.id === currentRole.appearanceId);
      const perTableId = appearance?.items?.[tableId] ?? {};
      if (perTableId?.columns) {
        setDataSource(reconcileColumns(defaultColumns, perTableId));
      } else {
        setDataSource(defaultColumns);
      }
      if (perTableId?.defaultSortOrder) {
        form.setFieldsValue({
          sortColumn: perTableId.defaultSortOrder?.column,
          sortOrder: perTableId.defaultSortOrder?.direction,
        });
      } else {
        form.resetFields(["sortColumn", "sortOrder"]);
      }
      if (perTableId?.pageSize) {
        form.setFieldsValue({ pageSize: perTableId.pageSize });
      } else {
        form.resetFields(["pageSize"]);
      }
      if (perTableId?.hidePanel) {
        form.setFieldsValue({ hidePanel: perTableId.hidePanel });
      } else {
        form.resetFields(["hidePanel"]);
      }
    }
  }, [appearances, defaultColumns, form, roles, tableId, watchedGroupID]);

  const onSubmit = async () => {
    const columnsData = [];
    dataSource.forEach((source) => {
      const payload = {
        key: source.key,
        newTitle: source.newTitle,
        visible: source.visible,
        sorterFieldNames: source.sorterFieldNames,
      };
      columnsData.push(payload);
    });

    const { groupId, sortColumn, sortOrder, pageSize, hidePanel } = await form.validateFields();
    const currentRole = roles.find((r) => r.id === groupId);
    const isNew = !currentRole?.appearanceId;
    const action = isNew ? createAppearance : updateAppearance;
    const data = {
      items: {
        [tableId]: {
          columns: columnsData,
          defaultSortOrder: { column: sortColumn, direction: sortOrder },
          pageSize,
          hidePanel,
        },
      },
    };
    if (isNew) {
      data.name = `${currentRole?.name} appearance`;
      data.groupIds = [groupId];
    } else {
      data.id = currentRole?.appearanceId;
    }

    const result = await action(data);
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success("Appearance has been saved");
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <StyledModal
      maskClosable={false}
      title="Appearance Manager"
      open={open}
      onCancel={onCancel}
      okButtonProps={{ loading: isCreateMethodLoading || isUpdateMethodLoading }}
      width={700}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onSubmit}>
          OK
        </Button>,
      ]}
    >
      <StyledForm layout="vertical" form={form}>
        <Form.Item name="groupId" label="Role" rules={[{ required: true, message: "Select role" }]}>
          <StyledSelect
            disabled={isRolesLoading}
            loading={isRolesLoading}
            popupMatchSelectWidth={false}
            placeholder="Select one..."
            options={roles?.map((role) => ({ label: role.name, value: role.id }))}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.groupId !== curr.groupId}>
          {({ getFieldValue }) => {
            const groupId = getFieldValue("groupId");
            const currentRole = roles?.find((role) => role.id === groupId);
            if (!currentRole) {
              return null;
            }

            return (
              <>
                {mode === "sider" && (
                  <Form.Item name="hidePanel" label="Hide Filter Panel" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                )}
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.hidePanel !== curr.hidePanel}>
                  {() => {
                    const hidePanel = getFieldValue("hidePanel");
                    if (hidePanel) {
                      return null;
                    }
                    return (
                      <AppearanceManager
                        isLoading={isLoading || isFetching}
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                        mode={mode}
                        paginationSelector={paginationSelector}
                      />
                    );
                  }}
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </StyledForm>
    </StyledModal>
  );
}

export default TableAppearanceModal;
