import { Checkbox, Form, Input } from "antd";

export default function CreditBureauInfoStep({ title, type }) {
  return (
    <>
      <Form.Item name={[type, "id"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item valuePropName="checked" name={[type, "isEnabled"]}>
        <Checkbox>{`Report this company's data to ${title}`}</Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[type]?.isEnabled !== currentValues[type]?.isEnabled
        }
      >
        {({ getFieldValue }) => {
          const isEnabled = getFieldValue([type, "isEnabled"]);
          return (
            <>
              <Form.Item
                name={[type, "programId"]}
                label="Program Identifier"
                rules={[{ required: isEnabled, message: "Program Identifier is required" }]}
              >
                <Input placeholder="Enter program identifier..." />
              </Form.Item>
              <Form.Item
                name={[type, "identificationNumber"]}
                label="Identification Number"
                rules={[{ required: isEnabled, message: "Identification Number is required" }]}
              >
                <Input placeholder="Enter identification number..." />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
}
