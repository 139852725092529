import { Form, Input, Radio, Select } from "antd";

export default function BaseStep({ forwardingEntities }) {
  return (
    <>
      <Form.Item name="id" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item
        name={["name"]}
        label="Name"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>
      <Form.Item
        name={["code"]}
        label="Forwarding Entity Code"
        tooltip="A unique shortname used to identify this forwarding entity"
      >
        <Input placeholder="Enter code (e.g 'CANTE')" />
      </Form.Item>
      <Form.Item
        name={["status"]}
        label="Forwarding Entity Status"
        tooltip="Status of the current forwarding entity"
        rules={[{ required: true, message: "Please select a status" }]}
      >
        <Select
          placeholder="Select a forwarding entity"
          options={[
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
            {
              label: "Prospect",
              value: "prospect",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name={["isLawFirm"]}
        label="Is Law Firm?"
        tooltip={<p>When true, this forwarding entity is categorized as a law firm.</p>}
      >
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["parentId"]} label="Parent Company" tooltip="Parent Company">
        <Select
          placeholder="Select forwarding entity"
          options={forwardingEntities.map((forwardingEntity) => ({
            label: forwardingEntity.name,
            value: forwardingEntity.id,
          }))}
        />
      </Form.Item>
    </>
  );
}
