import SignUpForm from "features/auth/components/signupForm";
import PageLayout from "layouts/login/pageLayout";

function SignUpPage() {
  return (
    <PageLayout>
      <SignUpForm />
    </PageLayout>
  );
}

export default SignUpPage;
