import AccountsTable from "features/accountsTable/creditorPortal/accountsTable";
import Content from "layouts/creditorPortal/dashboard/content";
import PageLayout from "layouts/creditorPortal/dashboard/pageLayout";

function AccountsPage() {
  return (
    <PageLayout>
      <Content>
        <AccountsTable />
      </Content>
    </PageLayout>
  );
}

export default AccountsPage;
