import TaxPlanTable from "features/taxPlans/components/taxPlanTable";
import TaxRuleGroupTable from "features/taxPlans/components/taxPlanRuleGroupTable";

export default function TaxPlans() {
  return (
    <>
      <TaxPlanTable />
      <TaxRuleGroupTable />
    </>
  );
}

export { TaxPlans };
