import { Layout } from "antd";
import { CreditorProfile } from "features/creditorProfile";
import { Creditors } from "features/creditors";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";
import { Route, Routes } from "react-router-dom";

function CreditorProfilePage() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout>
            <Layout>
              <Creditors />
            </Layout>
          </PageLayout>
        }
      />
      <Route
        path="/:creditorId"
        element={
          <PageLayout>
            <Layout>
              <CreditorProfile />
            </Layout>
          </PageLayout>
        }
      />
    </Routes>
  );
}

export default CreditorProfilePage;
