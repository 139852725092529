import { Form, Modal, Select } from "antd";
import {
  collectorsWithRolesSelector,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";

export default function PaymentAllocationCollectorModal({ open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const onSubmit = async () => {
    const data = await form.validateFields();
    onOk(data);
  };

  return (
    <Modal
      maskClosable={false}
      title="Update Credited Collector"
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={{ creditedCollectorId: null }}
      >
        <Form.Item name="creditedCollectorId">
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select Collector"
            allowClear
            options={collectors.map((d) => ({
              label: d.name,
              value: d.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
