import { UserManagementTable } from "features/userManagementTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function UserManagementPage() {
  return (
    <PageLayout>
      <Content>
        <UserManagementTable />
      </Content>
    </PageLayout>
  );
}

export default UserManagementPage;
