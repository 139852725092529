import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Row, Table } from "antd";
import { useState } from "react";
import styled from "styled-components";

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 28px;
  left: 0;
  background-color: #f9f3f3;
`;

const StyledCard = styled(Card)`
  flex: 1;
  .ant-card-body {
    padding: 12px 16px 16px;
  }
`;
const StyledHeader = styled.h4`
  margin: 0;
  line-height: 2.25;
  text-transform: uppercase;
`;

const StyledRow = styled(Row)`
  align-items: baseline;
`;

const StyledButtonsDivider = styled(Divider)`
  margin: 0;
  background-color: #f9f3f3;
`;

const StyledEditOutlined = styled(EditOutlined)`
  color: gray;
  cursor: pointer;
`;

const StyledForm = styled(Form)`
  max-width: 400px;
  padding-top: 32px;

  .ant-form-item-label {
    overflow: visible;
    white-space: pre;
  }
`;

const StyledTable = styled(Table)`
  padding-top: 32px;

  .ant-table-tbody > tr > td {
    border: none;
    padding: 2px;
  }
`;

/*
  Get value from object by path (where path could be an array of keys e.g ["creditReportingConfig", "isEnabled"])
 */
const getValueByPath = (obj, path) => {
  if (!Array.isArray(path)) {
    return obj[path];
  }

  return path.reduce((value, key) => {
    if (value[key] !== undefined) {
      return value[key];
    }
    return undefined;
  }, obj);
};

export default function EditableCardComponent({
  title,
  rows,
  record,
  onSave,
  hasEditPermission = true,
}) {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  const handleOnSave = async () => {
    const values = await form.validateFields();
    onSave(values);
    setIsEditing(false);
  };

  const handleOnCancel = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const columns = [
    {
      dataIndex: "label",
      key: "label",
    },
    {
      dataIndex: "value",
      key: "value",
      render: (_, row) => {
        const value = getValueByPath(record, row.name);
        return row.render ? row.render(value, record) : value;
      },
    },
  ];

  return (
    <StyledCard>
      <StyledRow justify="space-between">
        <StyledHeader>{title}</StyledHeader>
        {isEditing ? (
          <Row align="middle">
            <Button type="link" onClick={handleOnSave}>
              Save
            </Button>
            <StyledButtonsDivider type="vertical" />
            <Button type="link" onClick={handleOnCancel}>
              Cancel
            </Button>
          </Row>
        ) : (
          hasEditPermission && (
            <StyledEditOutlined key="edit" onClick={() => setIsEditing(!isEditing)} />
          )
        )}
      </StyledRow>
      <StyledDivider />
      {isEditing ? (
        <StyledForm labelAlign="left" layout="vertical" form={form} initialValues={record}>
          {rows.map((row) => {
            const { formElement, name, label, formItemProps } = row;
            return (
              <Form.Item name={name} label={label} {...formItemProps}>
                {formElement}
              </Form.Item>
            );
          })}
        </StyledForm>
      ) : (
        <StyledTable showHeader={false} dataSource={rows} columns={columns} pagination={false} />
      )}
    </StyledCard>
  );
}
