import api, { transformRequest, transformResponse } from "services/api";

export const courtOfLawApi = api
  .enhanceEndpoints({ addTagTypes: ["CourtsOfLaw"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCourtsOfLaw: build.query({
        query: () => "/api/v1/core/courts-of-law/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CourtsOfLaw", id })),
                { type: "CourtsOfLaw", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CourtsOfLaw', id: 'LIST' }` is invalidated
              [{ type: "CourtsOfLaw", id: "LIST" }],
      }),
      addCourtOfLaw: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/courts-of-law/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CourtsOfLaw-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CourtsOfLaw", id: "LIST" }],
      }),
      updateCourtOfLaw: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/courts-of-law/${payload.courtId}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CourtsOfLaw-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CourtsOfLaw", id: "LIST" }],
      }),
    }),
  });

export const { useFetchCourtsOfLawQuery, useAddCourtOfLawMutation, useUpdateCourtOfLawMutation } =
  courtOfLawApi;
