import api, { transformRequest, transformResponse } from "services/api";

export const feePlanApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "SettlementRules",
      "PaymentRules",
      "SettlementAndPaymentRuleSet",
      "SettlementAndPaymentRules",
      "AccountSettlementPaymentRules",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAccountSettlementPaymentRules: build.query({
        query: (payload) => ({
          url: "/api/v1/common/accounts/settlement-payment-rules/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "AccountSettlementPaymentRules", id })),
                { type: "AccountSettlementPaymentRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AccountPaymentRules', id: 'LIST' }` is invalidated
              [{ type: "AccountSettlementPaymentRules", id: "LIST" }],
      }),

      fetchSettlementRules: build.query({
        query: () => "api/v1/common/settlement-rules/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "SettlementRules", id })),
                { type: "SettlementRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'SettlementRules', id: 'LIST' }` is invalidated
              [{ type: "SettlementRules", id: "LIST" }],
      }),
      addSettlementRule: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/settlement-rules/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "SettlementRules", id: "LIST" }],
      }),
      updateSettlementRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "SettlementRules", id: arg.id }],
      }),
      deleteSettlementRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "SettlementRules", id: "LIST" }],
      }),

      fetchPaymentRules: build.query({
        query: () => "api/v1/common/payment-rules/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "PaymentRules", id })),
                { type: "PaymentRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'PaymentRules', id: 'LIST' }` is invalidated
              [{ type: "PaymentRules", id: "LIST" }],
      }),
      addPaymentRule: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/payment-rules/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "PaymentRules", id: "LIST" }],
      }),
      updatePaymentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/payment-rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "PaymentRules", id: arg.id }],
      }),
      deletePaymentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/payment-rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "PaymentRules", id: "LIST" }],
      }),

      fetchSettlementAndPaymentRuleSets: build.query({
        query: () => "api/v1/common/settlement-payment-rulesets/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "SettlementAndPaymentRuleSet", id })),
                { type: "SettlementAndPaymentRuleSet", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'SettlementAndPaymentRuleSet', id: 'LIST' }` is invalidated
              [{ type: "SettlementAndPaymentRuleSet", id: "LIST" }],
      }),
      fetchSettlementAndPaymentRuleSet: build.query({
        query: (payload) => `api/v1/core/settlement-payment-rulesets/${payload.id}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) => [{ type: "SettlementAndPaymentRuleSet", id: result.id }],
      }),
      addSettlementAndPaymentRuleSet: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/settlement-payment-rulesets/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "SettlementAndPaymentRuleSet", id: "LIST" }],
      }),
      updateSettlementAndPaymentRuleSet: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-payment-rulesets/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "SettlementAndPaymentRuleSet", id: arg.id },
          { type: "AccountSettlementPaymentRules", id: "LIST" },
          { type: "AccountSettlementPaymentRules", id: arg.id },
        ],
      }),
      deleteSettlementAndPaymentRuleSet: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-payment-rulesets/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "SettlementAndPaymentRuleSet", id: "LIST" },
          { type: "AccountSettlementPaymentRules", id: "LIST" },
          { type: "AccountSettlementPaymentRules", id: arg.id },
        ],
      }),

      fetchSettlementAndPaymentRules: build.query({
        query: (payload) => `api/v1/common/settlement-payment-rulesets/${payload.ruleSetId}/rules/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "SettlementAndPaymentRules", id })),
                { type: "SettlementAndPaymentRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'SettlementAndPaymentRules', id: 'LIST' }` is invalidated
              [{ type: "SettlementAndPaymentRules", id: "LIST" }],
      }),
      addSettlementAndPaymentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-payment-rulesets/${payload.ruleSetId}/rules/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "SettlementAndPaymentRules", id: "LIST" }],
      }),
      updateSettlementAndPaymentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-payment-rulesets/${payload.ruleSetId}/rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "SettlementAndPaymentRules", id: arg.id },
          { type: "AccountSettlementPaymentRules", id: arg.ruleSetId },
        ],
      }),
      deleteSettlementAndPaymentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/settlement-payment-rulesets/${payload.ruleSetId}/rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "SettlementAndPaymentRules", id: "LIST" },
          { type: "AccountSettlementPaymentRules", id: arg.ruleSetId },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useAddSettlementRuleMutation,
  useFetchSettlementRulesQuery,
  useAddPaymentRuleMutation,
  useAddSettlementAndPaymentRuleSetMutation,
  useDeleteSettlementRuleMutation,
  useDeletePaymentRuleMutation,
  useDeleteSettlementAndPaymentRuleSetMutation,
  useFetchSettlementAndPaymentRuleSetQuery,
  useFetchSettlementAndPaymentRuleSetsQuery,
  useUpdateSettlementRuleMutation,
  useUpdatePaymentRuleMutation,
  useUpdateSettlementAndPaymentRuleSetMutation,
  useAddSettlementAndPaymentRuleMutation,
  useDeleteSettlementAndPaymentRuleMutation,
  useFetchPaymentRulesQuery,
  useUpdateSettlementAndPaymentRuleMutation,
  useFetchSettlementAndPaymentRulesQuery,
  useLazyFetchSettlementAndPaymentRuleSetQuery,
  useFetchAccountSettlementPaymentRulesQuery,
} = feePlanApi;
