import { FilterOutlined } from "@ant-design/icons";
import { Checkbox, Col, Input, List, Row, Typography } from "antd";
import VirtualList from "rc-virtual-list";
import { useMemo, useState } from "react";
import styled from "styled-components";

const pluralize = (count, noun, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`;

const StyledRowCheckbox = styled(Checkbox)`
  padding: 15px;
  margin-left: 5px;
`;

const StyledGlobalCheckbox = styled(Checkbox)`
  padding: 5px 0;
`;

const StyledFilterOutlined = styled(FilterOutlined)`
  color: rgba(0, 0, 0, 0.25);
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const StyledRow = styled(Row)`
  padding: 10px 20px;
  background: #efefef;
`;

const StyledText = styled(Typography.Text)`
  line-height: 2;
`;

const StyledTitle = styled.h4`
  margin: 5px 30px;
  line-height: 1.5;
`;

/* 
  DataSource must include id and name fields
  fetchMore is optional callback to fetch more data
 */
export default function SelectList({
  containerHeight = 400,
  dataSource = [],
  value: selected = [],
  onChange: setSelected = undefined,
  fetchMore = undefined,
  entityName = "item",
}) {
  // Filter input value
  const [filter, setFilter] = useState("");

  // Capitalize first letter of entity name
  const entityNameCapitalized = `${entityName.charAt(0).toUpperCase()}${entityName.slice(1)}`;

  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === containerHeight) {
      fetchMore();
    }
  };

  const onFilterChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const filteredDataSource = useMemo(
    () => dataSource.filter((item) => item.name.toLowerCase().includes(filter?.toLowerCase())),
    [dataSource, filter],
  );

  const onRowCheckboxChange = (e) => {
    const { checked } = e.target;
    const { id } = e.target;
    if (checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  };

  const onGlobalCheckboxChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelected(filteredDataSource.map((item) => item.id));
    } else {
      setSelected([]);
    }
  };

  return (
    <>
      <StyledInput
        placeholder="Filter ..."
        prefix={<StyledFilterOutlined />}
        onChange={onFilterChange}
        value={filter}
        allowClear
      />

      <StyledRow>
        <StyledGlobalCheckbox
          onChange={onGlobalCheckboxChange}
          indeterminate={selected.length > 0 && selected.length < dataSource.length}
          checked={selected.length === dataSource.length}
        />
        <StyledTitle>{entityNameCapitalized}</StyledTitle>
      </StyledRow>
      <List>
        <VirtualList
          data={filteredDataSource}
          height={containerHeight}
          itemHeight={52}
          itemKey="id"
          onScroll={fetchMore ? onScroll : undefined}
        >
          {(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={
                  <StyledRowCheckbox
                    id={item.id}
                    onChange={onRowCheckboxChange}
                    checked={selected.includes(item.id)}
                  />
                }
                title={item.name}
                description={`ID: ${item.id}`}
              />
            </List.Item>
          )}
        </VirtualList>
      </List>
      <StyledRow>
        <Col>
          <StyledText>{pluralize(selected.length, entityName)} selected</StyledText>
        </Col>
      </StyledRow>
    </>
  );
}
