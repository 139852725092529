import api, { transformRequest, transformResponse } from "services/api";

export const garnishments = api
  .enhanceEndpoints({
    addTagTypes: ["Garnishments"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtorGarnishments: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/garnishments/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Garnishments", id })),
                { type: "Garnishments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Garnishments', id: 'LIST' }` is invalidated
              [{ type: "Garnishments", id: "LIST" }],
      }),
      createDebtorGarnishment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/garnishments/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [{ type: "Garnishments", id: "LIST" }],
      }),
      updateDebtorGarnishment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/garnishments/${payload.bankruptcyId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [{ type: "Garnishments", id: "LIST" }],
      }),
      deleteDebtorGarnishment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/garnishments/${payload.bankruptcyId}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "Garnishments", id: "LIST" }],
      }),
    }),

    overrideExisting: false,
  });

export const {
  useFetchDebtorGarnishmentsQuery,
  useCreateDebtorGarnishmentMutation,
  useUpdateDebtorGarnishmentMutation,
  useDeleteDebtorGarnishmentMutation,
} = garnishments;
