import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Descriptions,
  Dropdown,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { capitalizeFirstLetter, formatCurrency, formatDate, idToObjectMap } from "common/utils";
import currency from "currency.js";
import { useFetchCourtsOfLawQuery } from "features/courtsOfLawTable/courtsOfLawAPI";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  collectorsWithRolesSelector,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useGetLawFirmsQuery } from "features/lawFirmsTable/lawFirmsAPI";
import AddJudgmentModal from "features/legal/components/addJudgmentModal";
import AddLawsuitModal from "features/legal/components/addLawsuitModal";
import AddLegalFeesModal from "features/legal/components/addLegalFeesModal";
import LegalLettersModal from "features/legal/components/legalLettersModal";
import { useDeleteLawsuitMutation, useFetchLawsuitsQuery } from "features/legal/legalAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useNavigate } from "react-router-dom";

const StyledRow = styled(Row)`
  margin-bottom: 12px;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 24px;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledTag = styled(Tag)`
  margin: 4px 4px;
`;

const StyledTable = styled(Table)`
  cursor: pointer;
  width: 100%;

  & .ant-table-cell:not(th) {
    padding: 4px 8px !important;
  }
`;

export default function LegalInfo({ debtorId }) {
  const [currentLawsuit, setCurrentLawsuit] = useState(null);
  const [isLegalWizardView, setIsLegalWizardView] = useState(null);
  const [isJudgmentWizardView, setIsJudgmentWizardView] = useState(null);
  const [isLegalFeeView, setIsLegalFeeView] = useState(null);
  const [isLegalLettersView, setLegalLettersView] = useState(null);
  const { data: lawFirms } = useGetLawFirmsQuery();
  const { data: courtsOfLaw } = useFetchCourtsOfLawQuery();
  const { data: lawsuits, isLoading } = useFetchLawsuitsQuery({ debtorId });
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const [postDeleteLawsuit] = useDeleteLawsuitMutation();
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const {
    data: {
      [PERMISSIONS.LAWSUIT__GET]: isGetLawsuitAuthorized,
      [PERMISSIONS.LAWSUIT__CREATE]: isCreateLawsuitAuthorized,
      [PERMISSIONS.LAWSUIT__UPDATE]: isUpdateLawsuitAuthorized,
      [PERMISSIONS.LAWSUIT__DELETE]: isDeleteLawsuitAuthorized,
      [PERMISSIONS.LAWSUIT__APPLY_JUDGEMENT]: isLawsuitApplyJudgmentAuthorized,
      [PERMISSIONS.LEGAL__DOWNLOAD_DOCUMENTS]: isLegalDownloadDocuments,
    },
  } = useAuthorizations();
  const navigate = useNavigate();

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors);
  }, [creditors]);

  const addLegal = () => {
    setIsLegalWizardView(true);
    setCurrentLawsuit(null);
  };

  const editLawsuit = (lawsuit) => {
    setIsLegalWizardView(true);
    setCurrentLawsuit(lawsuit);
  };

  const applyJudgment = (lawsuit) => {
    setIsJudgmentWizardView(true);
    setCurrentLawsuit(lawsuit);
  };

  const addLegalFees = (lawsuit) => {
    setIsLegalFeeView(true);
    setCurrentLawsuit(lawsuit);
  };

  const onCancelJudgmentWizard = () => {
    setIsJudgmentWizardView(false);
  };

  const onDoneJudgmentWizard = () => {
    setIsJudgmentWizardView(false);
  };

  const onCancelLegalWizard = () => {
    setIsLegalWizardView(false);
  };

  const onCancelLegalFees = () => {
    setIsLegalFeeView(false);
  };

  const onDoneLegalWizard = () => {
    setIsLegalWizardView(false);
  };

  const sendLetters = (lawsuit) => {
    setLegalLettersView(lawsuit);
  };
  const onDoneLegalLetters = () => {
    setLegalLettersView(false);
  };
  const onCancelLegalLetters = () => {
    setLegalLettersView(false);
  };

  const items = [
    {
      label: "Apply Judgment",
      key: "apply_judgment",
      // actionHandler: applyJudgment,
      hidden: !isLawsuitApplyJudgmentAuthorized,
    },
    {
      label: "Create Legal Documents",
      key: "create_documents",
      // actionHandler: sendLetters,
      hidden: !isLegalDownloadDocuments,
    },
    {
      label: "Add Legal Fees",
      key: "add_legal_fees",
      // actionHandler: addLegalFees,
      hidden: false,
    },
  ]
    .filter((item) => !item.hidden)
    // insert dividers between items
    .flatMap((value, index, array) =>
      index < array.length - 1
        ? [
            value,
            {
              type: "divider",
            },
          ]
        : value,
    );

  const handleMenuClick = ({ key, domEvent }, record) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items dictionary
    const action = {
      apply_judgment: applyJudgment,
      create_documents: sendLetters,
      add_legal_fees: addLegalFees,
    }[key];
    action(record);
  };

  const columns = [
    {
      title: "Legal Case #",
      dataIndex: "legalCaseNumber",
      render: (text) => text || "-",
      key: "legalCaseNumber",
    },
    {
      title: "Account IDs",
      key: "accountIds",
      render: (text, record) => record.accountIds?.join(", ") ?? "-",
    },
    {
      title: "Debtor IDs",
      key: "debtorIds",
      render: (text, record) =>
        record.debtorIds
          ?.map((id) => {
            const debtorName = record.debtors?.find((debtor) => debtor.id === id)?.name;
            return debtorName ? `${id} - ${debtorName}` : id;
          })
          .join(", ") ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.legalStatus === "judgment") {
          return <Tag color="green">JUDGMENT APPLIED</Tag>;
        }
        if (record.status === "judgment_applied") {
          return <Tag color="green">JUDGMENT APPLIED</Tag>;
        }
        if (record.status === "in_litigation") {
          return <Tag color="orange">IN LITIGATION</Tag>;
        }
        if (record.status === "partial_judgment") {
          return <Tag color="orange">PARTIAL JUDGMENT</Tag>;
        }
        return "-";
      },
    },
    {
      title: "Law Firm/Attorney",
      dataIndex: "lawFirmId",
      key: "lawFirmId",
      render: (text, record) => lawFirms?.find((firm) => firm.id === text)?.name ?? "-",
    },
    {
      title: "Total Balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
      render: (text, record) => {
        const totalBalance = record.accounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.totalBalance),
          currency(0, { precision: 4 }),
        ).value;

        const totalPrincipal = record.accounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.principalBalance),
          currency(0, { precision: 4 }),
        ).value;

        const totalInterest = record.accounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.interestBalance),
          currency(0, { precision: 4 }),
        ).value;

        const totalFees = record.accounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.totalFeesBalance),
          currency(0, { precision: 4 }),
        ).value;

        const balanceItems = [
          {
            key: "principal",
            label: "Principal",
            value: formatCurrency(totalPrincipal),
          },
          {
            key: "interest",
            label: "Interest",
            value: formatCurrency(totalInterest),
          },
          {
            key: "totalFees",
            label: "Fees",
            value: formatCurrency(totalFees),
          },
          {
            key: "total",
            label: <strong>Total</strong>,
            value: <strong>{formatCurrency(totalBalance)}</strong>,
          },
        ];
        const totalBalanceContent = (
          <Col>
            <Descriptions bordered column={1} size="small">
              {balanceItems.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
        );
        return (
          <Row align="middle">
            <StyledInfoValue>{formatCurrency(totalBalance)}</StyledInfoValue>
            <Popover
              content={totalBalanceContent}
              title="Total Balance Breakdown"
              trigger="hover"
              placement="right"
            >
              <StyledQuestionCircleOutlined />
            </Popover>
          </Row>
        );
      },
    },
    {
      title: "Total Debtor Balance",
      dataIndex: "totalDebtorLawsuitBalance",
      key: "totalDebtorLawsuitBalance",
      render: (text, record) => {
        // filter out accounts that belongs to this debtor
        const debtorAccounts = record.accounts.filter(
          (account) => !account.debtorIds?.includes(debtorId),
        );

        const totalBalance = debtorAccounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.totalBalance),
          currency(0, { precision: 4 }),
        ).value;

        const totalPrincipal = debtorAccounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.principalBalance),
          currency(0, { precision: 4 }),
        ).value;

        const totalInterest = debtorAccounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.interestBalance),
          currency(0, { precision: 4 }),
        ).value;

        const totalFees = debtorAccounts.reduce(
          (acc, account) => acc.add(account.paymentCategories.totalFeesBalance),
          currency(0, { precision: 4 }),
        ).value;

        const balanceItems = [
          {
            key: "principal",
            label: "Principal",
            value: formatCurrency(totalPrincipal),
          },
          {
            key: "interest",
            label: "Interest",
            value: formatCurrency(totalInterest),
          },
          {
            key: "totalFees",
            label: "Fees",
            value: formatCurrency(totalFees),
          },
          {
            key: "total",
            label: <strong>Total</strong>,
            value: <strong>{formatCurrency(totalBalance)}</strong>,
          },
        ];
        const totalBalanceContent = (
          <Col>
            <Descriptions bordered column={1} size="small">
              {balanceItems.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
        );
        return (
          <Row align="middle">
            <StyledInfoValue>{formatCurrency(totalBalance)}</StyledInfoValue>
            <Popover
              content={totalBalanceContent}
              title="Total Balance Breakdown"
              trigger="hover"
              placement="right"
            >
              <StyledQuestionCircleOutlined />
            </Popover>
          </Row>
        );
      },
    },
    {
      title: "Placement Date",
      dataIndex: "placementDate",
      key: "placementDate",
      render: (text, record) => formatDate(record.placementDate) ?? "-",
    },
    {
      title: "Acknowledgement Date",
      dataIndex: "acknowledgementDate",
      key: "acknowledgementDate",
      render: (text, record) => formatDate(record.acknowledgementDate) ?? "-",
    },
    {
      title: "Closed Date",
      dataIndex: "closeDate",
      key: "closeDate",
      render: (text, record) => formatDate(record.closeDate) ?? "-",
    },
    {
      title: "Court Code",
      dataIndex: "courtId",
      key: "courtId",
      render: (text) => courtsOfLaw?.find((court) => court.id === text)?.code ?? "-",
    },
    {
      title: "Judge Name",
      dataIndex: "judgeName",
      key: "judgeName",
      render: (text) => text || "-",
    },
    {
      title: "Agency Approval Date",
      dataIndex: "agencyApprovalDate",
      key: "agencyApprovalDate",
      render: (text, record) => formatDate(record.agencyApprovalDate) ?? "-",
    },
    {
      title: "Filing Date",
      dataIndex: "filingDate",
      key: "filingDate",
      render: (text, record) => formatDate(record.filingDate) ?? "-",
    },
    {
      title: "Process Date",
      dataIndex: "processDate",
      key: "processDate",
      render: (text, record) => formatDate(record.processDate) ?? "-",
    },
    {
      title: "Answered Date",
      dataIndex: "answeredDate",
      key: "answeredDate",
      render: (text, record) => formatDate(record.answeredDate) ?? "-",
    },
    {
      title: "Next Court Date",
      dataIndex: "nextCourtDate",
      key: "nextCourtDate",
      render: (text, record) => formatDate(record.nextCourtDate) ?? "-",
    },
    {
      title: "Dismissal Date",
      dataIndex: "dismissalDate",
      key: "dismissalDate",
      render: (text, record) => formatDate(record.dismissalDate) ?? "-",
    },
    {
      title: "Actions",
      fixed: "right",
      className: "invoice-actions",
      width: 50,
      key: "actions",
      render: (_, record) => {
        return (
          <Row wrap={false} align="middle">
            <Space size="middle">
              {isUpdateLawsuitAuthorized && (
                <Tooltip placement="bottom" title="Edit" key="edit">
                  <EditOutlined key="edit" onClick={() => editLawsuit(record)} />
                </Tooltip>
              )}
              {isDeleteLawsuitAuthorized && (
                <Popconfirm
                  placement="topLeft"
                  okText="Yes"
                  title="Are you sure you want to delete this lawsuit?"
                  onConfirm={async () => {
                    const result = await postDeleteLawsuit({
                      debtorId,
                      lawsuitId: record.id,
                    });
                    if ("data" in result) {
                      message.success("Lawsuit successfully removed");
                    } else {
                      message.error("Failed to remove lawsuit");
                    }
                  }}
                >
                  <DeleteOutlined
                    disabled={lawsuits?.find((lawsuit) => lawsuit.id === record?.id)?.entryDate}
                    key="delete"
                  />
                </Popconfirm>
              )}
            </Space>
            {(isLawsuitApplyJudgmentAuthorized || isLegalDownloadDocuments) && (
              <Dropdown
                menu={{
                  // @ts-ignore
                  items,
                  onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp, record),
                }}
                trigger={["click"]}
              >
                <Button type="text">
                  <StyledEllipsisOutlined />
                </Button>
              </Dropdown>
            )}
          </Row>
        );
      },
    },
  ];

  const expandableColumns = [
    {
      title: "Account ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text, record) => (
        <Col>
          {record?.legalStatus === "judgment" ? (
            <StyledTag color="green">JUDGMENT APPLIED</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment_applied_archived" ? (
            <StyledTag color="green">JUDGMENT ARCHIVED</StyledTag>
          ) : null}
          {record?.legalStatus === "in_litigation" ? (
            <StyledTag color="orange">IN LITIGATION</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment_applied" ? (
            <StyledTag color="green">JUDGMENT APPLIED</StyledTag>
          ) : null}
        </Col>
      ),
    },
    {
      title: "Current Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        const balanceItems = [
          {
            key: "principal",
            label: "Principal",
            value: formatCurrency(record.paymentCategories.principalBalance),
          },
          {
            key: "interest",
            label: "Interest",
            value: formatCurrency(record.paymentCategories.interestBalance),
          },
          {
            key: "totalFees",
            label: "Fees",
            value: formatCurrency(record.paymentCategories.totalFeesBalance),
          },
          {
            key: "total",
            label: <strong>Total</strong>,
            value: <strong>{formatCurrency(record.paymentCategories.totalBalance)}</strong>,
          },
        ];
        const totalBalanceContent = (
          <Col>
            <Descriptions bordered column={1} size="small">
              {balanceItems.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
        );
        return (
          <Row align="middle">
            <StyledInfoValue>
              {formatCurrency(record.paymentCategories.totalBalance)}
            </StyledInfoValue>
            <Popover
              content={totalBalanceContent}
              title="Total Balance Breakdown"
              trigger="hover"
              placement="right"
            >
              <StyledQuestionCircleOutlined />
            </Popover>
          </Row>
        );
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (text, record) => {
        const creditor = creditorIdToCreditorMap[record.creditorId];
        if (creditor?.code) {
          return `${creditor?.name} (${creditor?.code})`;
        }
        return creditor?.name ?? "-";
      },
    },
    {
      title: "Current Creditor",
      dataIndex: "currentCreditor",
      key: "currentCreditor",
      render: (text, record) => record.currentCreditorName ?? "-",
    },
    {
      title: "Co-Debtors",
      dataIndex: "coDebtors",
      key: "coDebtors",
      render: (text, record) => {
        const codebtorIds = record.debtorIds?.filter((id) => id.toString() !== debtorId) ?? [];

        if (codebtorIds.length === 0) {
          return "-";
        }

        return (
          <Col>
            {codebtorIds.map((codebtorId) => (
              <div key={codebtorId}>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(`/debtors/${codebtorId}`);
                  }}
                >
                  {/* NOTE: We don't pull these codebtor names, because it'd be too expensive of a query on the backend. */}
                  {codebtorId}
                </Button>
              </div>
            ))}
          </Col>
        );
      },
    },
    {
      title: "Collector",
      dataIndex: "collector",
      key: "collector",
      render: (text, record) =>
        capitalizeFirstLetter(
          collectorFullName(collectors?.find((x) => x.id === record.assigneeId)),
        ),
    },
  ];

  const ExpandedRow = useCallback(
    (record) => {
      return (
        <>
          <h4>Related Accounts</h4>
          <StyledTable
            bordered
            scroll={{ x: "max-content" }}
            // @ts-ignore
            columns={expandableColumns}
            dataSource={record.accounts.concat(record.judgmentAccounts ?? []).filter((account) => {
              return (
                account.legalStatus === "in_litigation" || // The account is in litigation and waiting for a judgment.
                account.legalStatus === "judgment" // Status for the judgment account, set when it is created from an original account.
              );
            })}
            pagination={false}
            loading={isLoading}
          />
        </>
      );
    },
    // we don't want columns to be a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, lawsuits],
  );

  return isGetLawsuitAuthorized ? (
    <>
      <Col>
        {isCreateLawsuitAuthorized && (
          <StyledRow align="middle">
            <Button onClick={addLegal} icon={<PlusOutlined />} type="link">
              Add Lawsuit
            </Button>
          </StyledRow>
        )}
        <StyledTable
          bordered
          pagination={{ position: [] }}
          scroll={{ x: "max-content" }}
          // @ts-ignore
          columns={columns}
          dataSource={lawsuits}
          expandable={{
            expandedRowRender: ExpandedRow,
          }}
          loading={isLoading}
        />
      </Col>
      {isLegalWizardView && (
        <AddLawsuitModal
          lawsuit={currentLawsuit}
          open={isLegalWizardView}
          onCancel={onCancelLegalWizard}
          onOk={onDoneLegalWizard}
        />
      )}
      {isJudgmentWizardView && (
        <AddJudgmentModal
          lawsuit={currentLawsuit}
          onOk={onDoneJudgmentWizard}
          onCancel={onCancelJudgmentWizard}
        />
      )}
      {isLegalFeeView && (
        <AddLegalFeesModal
          lawsuit={currentLawsuit}
          onCancel={onCancelLegalFees}
          title="Add Legal Fees"
        />
      )}
      <LegalLettersModal
        title="Legal Letters"
        open={isLegalLettersView}
        onOk={onDoneLegalLetters}
        onCancel={onCancelLegalLetters}
      />
    </>
  ) : null;
}
