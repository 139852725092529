import { Form, Input, InputNumber, Modal } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  useAddPaymentRuleMutation,
  useUpdatePaymentRuleMutation,
} from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export default function PaymentRuleModal({ title, onOk, onCancel, selectedRule }) {
  const [form] = Form.useForm();

  const [addPaymentRule] = useAddPaymentRuleMutation();
  const [updatePaymentRule] = useUpdatePaymentRuleMutation();

  const onSubmit = async () => {
    const newRule = await form.validateFields();
    const result = selectedRule ? await updatePaymentRule(newRule) : await addPaymentRule(newRule);

    if ("data" in result) {
      await onOk();
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const onModalCancel = async () => {
    form.resetFields();
    await onCancel();
  };

  return (
    <Modal maskClosable={false} title={title} open onOk={onSubmit} onCancel={onModalCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        initialValues={selectedRule}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="minimumPaymentAmount" label="Minimum Amount">
          <StyledInputNumber min="0" prefix="$" step="0.01" />
        </Form.Item>
        <h4>One Time Payment</h4>
        <Form.Item name="maximumDelayInDays" label="Maximum Delay (days)">
          <StyledInputNumber min="1" step="1" />
        </Form.Item>
        <h4>Payment Plans</h4>
        <Form.Item name="maximumPlanPaymentCount" label="Maximum Number of Plan Payments">
          <StyledInputNumber min="1" step="1" />
        </Form.Item>
        <Form.Item name="maximumPlanLengthInDays" label="Maximum Plan Length (days)">
          <StyledInputNumber min="1" step="1" />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
