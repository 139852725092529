import { Form, message, Modal, Select } from "antd";

import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useFetchLetterTemplatesQuery } from "features/letterTemplates/letterTemplatesAPI";
import moment from "moment-timezone";
import styled from "styled-components";
import { downloadFileFromUrl } from "common/utils";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import { useDownloadInHouseLettersMutation } from "../documentCenterAPI";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function DownloadInHouseLetterModal({
  open,
  onOk,
  onCancel,
  accountIds = null,
  useAsyncJob = true,
}) {
  const [form] = useForm();
  const [downloadInHouseLetters, { isLoading: isInHouseLettersDownloading }] =
    useDownloadInHouseLettersMutation();
  const { data: communicationMethods = [], isLoading: isLoadingCommunicationMethods } =
    useFetchCommunicationMethodsQuery();

  const communicationMethodInHouseLetterTemplateIds = new Set(
    communicationMethods.flatMap((commMethod) =>
      commMethod.medium === "in_house_letter" ? commMethod.letterTemplateIds : [],
    ),
  );

  const { data: letterTemplates = [], isLoading: isLoadingLetterTemplates } =
    useFetchLetterTemplatesQuery();

  const isLoading = isLoadingCommunicationMethods && isLoadingLetterTemplates;

  const letterTemplateOptions = letterTemplates
    .filter((template) => communicationMethodInHouseLetterTemplateIds.has(template.id))
    .map((template) => ({
      value: template.id,
      label: template.filename,
    }));

  const onSubmit = async () => {
    const values = await form.validateFields();
    const { dateRange, letterTemplateIds, renderFormat } = values;
    const [startDate, endDate] = dateRange;

    const result = await downloadInHouseLetters({
      letterTemplateIds,
      startDate,
      endDate,
      renderFormat,
      useAsyncJob,
      ...(accountIds !== null ? { accountIds } : {}),
    });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      if (useAsyncJob) {
        return message.success(
          "In-House letters file is being generated. Please check back later in the Generated Documents section.",
        );
      }
      downloadFileFromUrl(result.data.fileUrl, result.data.filename);
      return message.success("Starting to download In-House letters");
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const disabledAfterTodayDate = (current) => {
    const today = moment().endOf("day");
    return current && current > today;
  };

  const initialValues = {
    letterTemplateIds: letterTemplateOptions.map((template) => template.value),
    renderFormat: "pdf",
  };

  return (
    <Modal
      maskClosable={false}
      title="Download In-House Letters"
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{
        loading: isLoading,
      }}
      confirmLoading={isInHouseLettersDownloading}
    >
      <StyledForm layout="vertical" form={form} initialValues={initialValues}>
        <Form.Item
          label="Letter Template"
          name="letterTemplateIds"
          rules={[{ required: true, message: "Select letter template" }]}
        >
          <Select mode="multiple" disabled={isLoading} options={letterTemplateOptions} />
        </Form.Item>
        <Form.Item
          name="dateRange"
          label="Date Range"
          rules={[{ required: true, message: "Select date range" }]}
        >
          <AktDateRangePicker disabledDate={disabledAfterTodayDate} picker="date" allowClear />
        </Form.Item>
        <Form.Item
          label="File Format"
          name="renderFormat"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select
            placeholder="Select one..."
            options={[
              {
                label: "PDF",
                value: "pdf",
              },
              {
                label: "Docx",
                value: "docx",
              },
            ]}
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default DownloadInHouseLetterModal;
