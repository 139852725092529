import { Form } from "antd";
import ActionBar from "components/formWizard/components/ActionBar";
import Error from "components/formWizard/components/Error";
import { useFormWizard } from "components/formWizard/formWizardContext";
import { cloneElement } from "react";
import styled from "styled-components";

const StyledContent = styled.div`
  margin-top: 16px;
`;

const StyledWrapper = styled.div`
  max-width: 400px;
  width: 100%;
`;

function FormWizardContent() {
  const { steps, current, initialValues } = useFormWizard();
  const getDefaultFormProps = (step, index) => ({
    layout: "vertical",
    name: `step${index}`,
  });

  return (
    <StyledContent>
      {steps.map((step, index) => {
        const formProps = {
          ...getDefaultFormProps(step, index),
          ...step.formProps,
        };

        return (
          <div hidden={index !== current} key={step.title}>
            <Form {...formProps} initialValues={initialValues}>
              <StyledWrapper {...step.wrapperProps}>
                {cloneElement(step.form)}
                <Error />
              </StyledWrapper>
              <ActionBar />
            </Form>
          </div>
        );
      })}
    </StyledContent>
  );
}

export default FormWizardContent;
