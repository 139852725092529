import api, { transformRequest, transformResponse } from "services/api";

export const workflowsApi = api
  .enhanceEndpoints({ addTagTypes: ["Workflows", "WorkflowDetails", "Schema", "Model"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchSchema: build.query({
        query: () => "/api/v1/core/workflows/schema/",
        // @ts-ignore
        transformResponse: (response) => transformResponse(response),
        providesTags: () => [{ type: "Schema", id: "LIST" }],
      }),
      fetchWorkflowModel: build.query({
        query: (payload) => ({
          url: "/api/v1/core/workflows/models/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results, error, arg) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Model", id: `${arg?.model}-${id}` })),
                { type: "Model", id: `${arg?.model}-LIST` },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Model', id: 'LIST' }` is invalidated
              [{ type: "Model", id: `${arg?.model}-LIST` }],
      }),
      fetchWorkflowModelsWithSearch: build.query({
        query: (payload) => ({
          url: "/api/v1/core/workflows/models/search/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results, error, arg) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({
                  type: "Model",
                  id: `${arg?.model}-${arg?.prefix}-${id}`,
                })),
                { type: "Model", id: `${arg?.model}-${arg?.prefix}-LIST` },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Model', id: 'LIST' }` is invalidated
              [{ type: "Model", id: `${arg?.model}-${arg?.prefix}-LIST` }],
      }),
      fetchWorkflows: build.query({
        query: () => "/api/v1/core/workflows/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Workflows", id })),
                { type: "Workflows", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Workflows', id: 'LIST' }` is invalidated
              [{ type: "Workflows", id: "LIST" }],
      }),
      fetchWorkflow: build.query({
        query: (payload) => `/api/v1/core/workflows/${payload.workflowId}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result, error, arg) => [{ type: "WorkflowDetails", id: arg.workflowId }],
      }),
      createWorkflow: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/workflows/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Workflows-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "Workflows", id: "LIST" }],
      }),
      updateWorkflow: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflows/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Workflows-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "Workflows", id: "LIST" },
          { type: "Workflows", id: arg.id },
          { type: "WorkflowDetails", id: arg.id },
        ],
      }),
      deleteWorkflow: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflows/${payload.workflowId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all Workflows-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "Workflows", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchWorkflowsQuery,
  useFetchWorkflowQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,
  useFetchSchemaQuery,
  useFetchWorkflowModelQuery,
  useLazyFetchWorkflowModelsWithSearchQuery,
} = workflowsApi;
