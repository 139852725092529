import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Popconfirm, Row, Space, Table } from "antd";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useDeleteWorkflowMutation, useFetchWorkflowsQuery } from "features/workflows/workflowAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import WorkflowModal from "./workflowModal";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function WorkflowTable() {
  const navigate = useNavigate();
  const [deleteworkflow] = useDeleteWorkflowMutation();
  const { data: workflows, isLoading } = useFetchWorkflowsQuery({
    orderBy: "-id",
  });
  const [currentWorkflow, setCurrentWorkflow] = useState();

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handleEdit = (record) => {
    setIsAddModalVisible(true);
    setCurrentWorkflow(record);
  };

  const {
    data: {
      [PERMISSIONS.WORKFLOW__GET]: isWorkflowGetAuthorized,
      [PERMISSIONS.WORKFLOW__CREATE]: isWorkflowCreateAuthorized,
      [PERMISSIONS.WORKFLOW__UPDATE]: isWorkflowUpdateAuthorized,
      [PERMISSIONS.WORKFLOW__DELETE]: isWorkflowDeleteAuthorized,
    },
  } = useAuthorizations();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Button onClick={() => navigate(`/workflows/${record.id}/`)} type="link">
          {record.name}
        </Button>
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isWorkflowUpdateAuthorized && !isWorkflowDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isWorkflowUpdateAuthorized && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => handleEdit(record)} />
            </AntDToolTip>
          )}
          {isWorkflowDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this workflow?"
              onConfirm={() => deleteworkflow({ workflowId: record.id })}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return isWorkflowGetAuthorized ? (
    <>
      <Row align="middle">
        <span>Manage workflows that will be applied to different accounts. </span>
        {isWorkflowCreateAuthorized && (
          <Button onClick={() => setIsAddModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Workflow
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={workflows}
      />
      {isAddModalVisible && (
        <WorkflowModal
          onOk={() => {
            setIsAddModalVisible(false);
            setCurrentWorkflow(null);
          }}
          onCancel={() => {
            setIsAddModalVisible(false);
            setCurrentWorkflow(null);
          }}
          open={isAddModalVisible}
          title={`${currentWorkflow ? "Edit" : "Add"} Workflow`}
          selectedWorkflow={currentWorkflow}
        />
      )}
    </>
  ) : null;
}
