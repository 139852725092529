import api, { transformRequest, transformResponse } from "services/api";

export const dataImportAPI = api
  .enhanceEndpoints({
    addTagTypes: ["ImportHistory"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchImportHistory: build.query({
        query: (queryParams = { type: "", status: "" }) => {
          const params = new URLSearchParams();
          if (queryParams.type) params.append("type", queryParams.type);
          if (queryParams.status) params.append("status", queryParams.status);
          return `/api/v1/core/data-import/?${params.toString()}`;
        },
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? [{ type: "ImportHistory", id: "LIST" }]
            : [{ type: "ImportHistory", id: "LIST" }],
      }),
      postDataImportCreate: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/data-import/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ImportHistory", id: "LIST" }],
      }),
    }),
  });

export const { useFetchImportHistoryQuery, usePostDataImportCreateMutation } = dataImportAPI;
