import DebtorMergeGroupTable from "features/mergeGroups/components/debtorMergeGroupTable";
import DebtorMergeGroupRuleTable from "features/mergeGroups/components/debtorMergeGroupRuleTable";

export default function MergeGroups() {
  return (
    <>
      <DebtorMergeGroupRuleTable />
      <DebtorMergeGroupTable />
    </>
  );
}
