import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useUserType } from "features/auth";
import { DebtorSelector } from "features/manageDebtors";
import CommercialDebtorTable from "features/manageDebtors/components/commercialDebtorTable";
import ConsumerDebtorTable from "features/manageDebtors/components/consumerDebtorTable";
import styled from "styled-components";

const StyledDivider = styled(Divider)`
  margin: 12px 0;
  padding: 12px 0;
`;

const StyledNestedDiv = styled(`div`)`
  text-align: center;
`;

const StyledDiv = styled(`div`)`
  margin-bottom: 16px;
`;

function ManageDebtors({
  debtType,
  dataSource,
  handleDelete,
  handleAdd,
  onSelect,
  isLoading = false,
  // readOnly is a bigger override that disables all actions
  readOnly = false,
}) {
  const { isAgencyUserType } = useUserType();
  return (
    <StyledDiv>
      {isAgencyUserType && (
        <DebtorSelector selectedDebtors={dataSource} onSelectDebtor={onSelect} />
      )}
      {debtType === "consumer" && (
        <ConsumerDebtorTable
          dataSource={dataSource}
          handleDelete={handleDelete}
          isLoading={isLoading}
          readOnly={readOnly}
        />
      )}
      {debtType === "commercial" && (
        <CommercialDebtorTable
          dataSource={dataSource}
          handleDelete={handleDelete}
          isLoading={isLoading}
          readOnly={readOnly}
        />
      )}
      {!readOnly && (
        <>
          <StyledDivider plain>or</StyledDivider>
          <StyledNestedDiv>
            <Button icon={<PlusCircleOutlined />} onClick={handleAdd}>
              Manually Add Debtor
            </Button>
          </StyledNestedDiv>
        </>
      )}
    </StyledDiv>
  );
}

export default ManageDebtors;
