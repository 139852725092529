import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchFeaturesQuery } from "features/permissions/permissionsAPI";
import { useEffect, useState } from "react";

/*
// usage example

import useFeatureFlag from './useFeatureFlag';

const PrivateComponent = () => {
  const { data: hasFeature } = useFeatureFlag("account_bulk_action_async");

  return hasFeature ? (
    <h1>Account Bulk Action Async</h1>
  ) : (
    <p>403.</p>
  );
};
*/

/**
 * Generates a list of feature names for a given feature key.
 *
 * @param {string} featureKey - The feature key, e.g., 'account.no_limit'. The key can include multiple features separated by dots.
 *
 * @returns {Array} Returns an array of all possible combinations of the feature key, e.g. ['account', 'account.no_limit'].
 *
 * @example
 * console.log(getFeatureCombinations('account.no_limit')); // ['account', 'account.no_limit']
 * console.log(getFeatureCombinations('account')); // ['account']
 * console.log(getFeatureCombinations('account.no_limit.delta')); // ['account', 'account.no_limit', 'account.no_limit.delta']
 */
const getFeatureCombinations = (featureKey) => {
  return (
    featureKey
      // split the feature name by dot, ie `account.no_limit` becomes ['account', 'no_limit']
      .split(".")
      // make an array of all possible combinations of the feature name, ie ['account', 'account.no_limit']
      .map((_, index, arr) => arr.slice(0, index + 1).join("."))
  );
};

/**
 * Checks if all features specified by the feature key are enabled.
 *
 * @param {string} featureKey - The feature key, e.g., 'account.no_limit.create'. The featureKey includes dot separate features and an action at the end.
 * @param {Array} features - An array of feature objects. Each feature object should have a 'name' property (string) and an 'isEnabled' property (boolean).
 *
 * @returns {boolean} Returns true if all features specified by the feature key are enabled, false otherwise. If the feature key is falsy, the function returns false.
 *
 * @example
 * const features = [{ name: 'account', isEnabled: true }, { name: 'account.no_limit', isEnabled: true }];
 * console.log(hasFeatureFlag('account.no_limit', features)); // true
 */
export const hasFeatureFlag = (featureKey, features) => {
  if (!featureKey) {
    return false;
  }

  const featureCombinations = getFeatureCombinations(featureKey);

  return (
    features
      // filter the features by the possible combinations
      .filter((feature) => featureCombinations.includes(feature.name))
      // make sure ALL the features are enabled
      .every((feature) => feature.isEnabled)
  );
};

const useFeatureFlag = (featureKey) => {
  const [hasFeature, setHasFeature] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isSuccess: isFeatureSuccess,
    isError: isFeatureError,
  } = useFetchFeaturesQuery();

  const {
    data: me,
    isLoading: isLoadingMe,
    isSuccess: isMeSuccess,
    isError: isMeError,
  } = useFetchMeQuery();

  useEffect(() => {
    if (isMeSuccess && featureKey && isFeatureSuccess) {
      const isFeatureEnabled = hasFeatureFlag(featureKey, features);

      // Set the state based on your feature check
      setHasFeature(isFeatureEnabled);
      setIsLoading(false);
    } else if (isMeError || isFeatureError) {
      setHasFeature(false);
      setIsLoading(false);
    }
  }, [me, isMeSuccess, isMeError, isFeatureSuccess, isFeatureError, features, featureKey]);

  return {
    data: hasFeature,
    isLoading: isLoadingMe || isLoading || isLoadingFeatures,
    isError: isMeError || isFeatureError,
  };
};

export default useFeatureFlag;
