import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Popconfirm, Row, Space, Table, message } from "antd";
import { getColumnSearchProps } from "common/antd-utils";
import { snakeToCamelCase } from "common/utils";
import currency from "currency.js";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import EditTaxPlanModal from "features/taxPlans/components/editTaxPlanModal";
import { useDeleteTaxPlanMutation, useFetchTaxPlansQuery } from "features/taxPlans/taxPlansAPI";
import { useRef, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
`;

export default function TaxPlanTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: taxPlans, isLoading } = useFetchTaxPlansQuery();
  const [selectedTaxPlan, setSelectedTaxPlan] = useState(null);
  const [deleteTaxPlan] = useDeleteTaxPlanMutation();
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const {
    data: {
      [PERMISSIONS.TAX_PLAN__GET]: isTaxPlanGetAuthorized,
      [PERMISSIONS.TAX_PLAN__CREATE]: isTaxPlanCreateAuthorized,
      [PERMISSIONS.TAX_PLAN__UPDATE]: isTaxPlanUpdateAuthorized,
      [PERMISSIONS.TAX_PLAN__DELETE]: isTaxPlanDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (record) => {
    const result = await deleteTaxPlan({ id: record.id });
    if ("data" in result) {
      message.success("Tax plan removed successfully!");
    }
  };

  const onUpdateAccountClick = (record) => {
    setModalVisible(true);
    setSelectedTaxPlan(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Percent Contingency",
      children: constants?.paymentCategories?.invoiceable.map((category) => ({
        title: `${category.display} (%)`,
        dataIndex: snakeToCamelCase(category.value),
        render: (text) => currency(text, { precision: 4 }).multiply(100).value || "",
        key: category.display,
      })),
      key: "percentContingecy",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isTaxPlanUpdateAuthorized && !isTaxPlanDeleteAuthorized,
      render: (text, record) => (
        <Space size="middle">
          {isTaxPlanUpdateAuthorized && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onUpdateAccountClick(record)} />
            </AntDToolTip>
          )}
          {isTaxPlanDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this tax plan?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete-tax-plan" />
            </Popconfirm>
          )}
        </Space>
      ),
      fixed: "right",
    },
  ].filter((column) => !column.hidden);

  const onNewRuleOk = () => {
    setModalVisible(false);
  };

  return isTaxPlanGetAuthorized ? (
    <>
      <h4>Tax Plans</h4>
      <Row align="middle">
        <span>Configure client contingency tax plans.</span>
        {isTaxPlanCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Tax Plan
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={taxPlans}
      />
      {isModalVisible && (
        <EditTaxPlanModal
          title={selectedTaxPlan ? "Edit Tax Plan" : "Add Tax Plan"}
          open={isModalVisible}
          onOk={onNewRuleOk}
          onCancel={() => {
            setModalVisible(false);
            setSelectedTaxPlan(null);
          }}
          taxPlan={selectedTaxPlan}
        />
      )}
    </>
  ) : null;
}
