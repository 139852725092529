import { Card } from "antd";
import { FormWizard } from "components/formWizard";
import AddressesStep from "features/addCreditorWizard/wizardSteps/addressesStep";
import BaseStep from "features/addCreditorWizard/wizardSteps/baseStep";
import CreditorContactsStep from "features/addCreditorWizard/wizardSteps/contactsStep";
import CreditReportingStep from "features/addCreditorWizard/wizardSteps/creditReportingStep";
import InvoiceStep from "features/addCreditorWizard/wizardSteps/invoicesStep";
import PaymentConfigurationStep from "features/addCreditorWizard/wizardSteps/paymentConfigurationStep";
import {
  useCreateCreditorMutation,
  useFetchCreditorAddressesQuery,
  useUpdateCreditorMutation,
} from "features/creditors/agencyPortal/creditorsAPI";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-top: 16px;
`;

function AddCreditorWizard({ onDone, onCancel, currentCreditor, setCurrentCreditor }) {
  const { data: creditorAddresses } = useFetchCreditorAddressesQuery(
    {
      // @ts-ignore
      creditorId: currentCreditor?.id,
    },
    // @ts-ignore
    { skip: !currentCreditor?.id },
  );
  const [updateCreditor] = useUpdateCreditorMutation();
  const [createCreditor] = useCreateCreditorMutation();

  const onNext = async (creditor) => {
    const result = await (currentCreditor
      ? // @ts-ignore
        updateCreditor({ ...creditor, id: currentCreditor?.id })
      : createCreditor(creditor));
    if ("data" in result) {
      setCurrentCreditor(result.data);
    }
    return result;
  };

  const initialValues = {
    status: "active",
    invoiceConfig: {
      principalRemit: {
        agency: "net",
        creditor: "net",
      },
      feesRemit: {
        agency: "net",
        creditor: "net",
      },
      interestRemit: {
        agency: "net",
        creditor: "net",
      },
      adjustments: "net",
      billing: "net_accounts_receivable",
      credit: "credit_apply",
      consolidation: "single",
      paymentMethodType: "checks",
      taxRemitType: "net",
    },
    creditReportingConfig: {
      isEnabled: false,
      includeInterest: false,
      includeFees: false,
      reporterType: "collection_agency",
      paidInFullAccountStatusCode: "DA",
    },
  };

  const creditorSteps = [
    {
      title: "Creditor Basic info",
      form: <BaseStep />,
      onNext: async (data) => onNext(data),
    },
    {
      title: "Addresses",
      // @ts-ignore
      form: <AddressesStep creditorId={currentCreditor?.id} />,
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
      onNext: async (data) => {
        const mailingAddressCounter = creditorAddresses.reduce(
          (acc, creditorAddress) => (creditorAddress.type === "mail" ? acc + 1 : acc),
          0,
        );
        if (mailingAddressCounter <= 0) {
          throw new Error("At least one mailing address is required");
        }
        return onNext(data);
      },
    },
    {
      title: "Client Contacts",
      // @ts-ignore
      form: <CreditorContactsStep creditorId={currentCreditor?.id} />,
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
    {
      title: "Credit Reporting",
      form: <CreditReportingStep />,
      onNext: async (data) => onNext(data),
    },
    /*
        {
          title: "Litigation Settings",
          form: <LitigationSettingsStep />,
          onNext: async (data) => onNext(data),
        },
        */
    {
      title: "Invoices",
      form: <InvoiceStep />,
      onNext: async (data) => onNext(data),
    },
    {
      title: "Payment Default Configuration",
      form: <PaymentConfigurationStep currentCreditor={currentCreditor} />,
      onNext: async (data) => onNext(data),
    },
  ];

  return (
    <StyledCard>
      <FormWizard
        canSkipSteps={!!currentCreditor}
        steps={creditorSteps}
        onCancel={onCancel}
        onDone={onDone}
        initialValues={currentCreditor ?? initialValues}
      />
    </StyledCard>
  );
}

export default AddCreditorWizard;
