import useUserType from "features/auth/hooks/useUserType";

export default function UserTypeErrorElement({ route }) {
  const { isAgencyUserType, isCreditorUserType } = useUserType();

  if (isAgencyUserType) {
    return route.agency;
  }
  if (isCreditorUserType) {
    return route.creditor;
  }
  return route.fallback;
}
