import { DownloadOutlined } from "@ant-design/icons";
import { Button, Collapse, List, Table, Tag } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { camelToWords, downloadFileFromUrl, snakeToCamelCase } from "common/utils";
import DownloadCard from "features/documentCenter/components/downloadCard";
import DownloadChecksModal from "features/documentCenter/components/downloadChecksModal";
import DownloadCreditReportModal from "features/documentCenter/components/downloadCreditReportModal";
import DownloadInHouseLetterModal from "features/documentCenter/components/downloadInHouseLetterModal";
import DownloadLetterVendorExportModal from "features/documentCenter/components/downloadLetterVendorExportModal";
import DownloadPublishedInvoicesModal from "features/documentCenter/components/downloadPublishedInvoicesModal";
import { useFetchReportsQuery } from "features/documentCenter/documentCenterAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledList = styled(List)`
  & .ant-list-item {
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const StyledCollapse = styled(Collapse)`
  margin-top: 16px;
  background-color: #f5f5f5;
`;

const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    align-items: center !important;
    padding-bottom: 0 !important;
    padding-left: 8px !important;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
`;

function DocumentCenter() {
  const { data: isLetterVendorAuthorized } = useAuthorization(PERMISSIONS.LETTER_VENDOR__GET);
  const { data: reports } = useFetchReportsQuery();

  const CARDS = [
    {
      title: "Checks",
      description: "Download checks that match selected criteria.",
      modal: DownloadChecksModal,
    },
    {
      title: "Published Invoices",
      description: "Download published invoices that match selected criteria.",
      modal: DownloadPublishedInvoicesModal,
    },
    ...(isLetterVendorAuthorized
      ? [
          {
            title: "Letter Vendor Export",
            description: "Download vendor letters that match selected criteria.",
            modal: DownloadLetterVendorExportModal,
          },
        ]
      : []),
    {
      title: "Metro2",
      description: "Download Metro2 files that match selected criteria.",
      modal: DownloadCreditReportModal,
    },
    {
      title: "In-House Letters",
      description: "Download In-House letters that match selected criteria.",
      modal: DownloadInHouseLetterModal,
    },
  ];

  const columns = [
    {
      title: "Document Type",
      dataIndex: "fileType",
      key: "fileType",
      render: (text, record) => camelToWords(snakeToCamelCase(record.fileType)),
    },
    {
      title: "Generated At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(record.createdAt).format(DATE_TIME_FORMAT),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.status === "error") {
          return <Tag color="red">FAILED</Tag>;
        }
        if (record.status === "scheduled") {
          return <Tag>PENDING</Tag>;
        }
        if (record.status === "done") {
          return (
            <StyledButton
              icon={<DownloadOutlined />}
              type="link"
              onClick={() => downloadFileFromUrl(record.fileUrl, record.filename)}
            >
              Ready to Download
            </StyledButton>
          );
        }
        if (record.status === "in_progress") {
          return <Tag color="yellow">IN PROGRESS</Tag>;
        }
      },
    },
  ];

  return (
    <>
      <StyledList
        grid={{
          gutter: 0,
        }}
        dataSource={CARDS}
        renderItem={(card) => (
          <List.Item>
            {/* @ts-ignore */}
            <DownloadCard {...card} title={card.title} />
          </List.Item>
        )}
      />
      <StyledCollapse defaultActiveKey="history" bordered={false}>
        <StyledPanel header={<h3>Generated Documents (Last 7 days)</h3>} key="history">
          <Table scroll={{ x: "max-content" }} columns={columns} bordered dataSource={reports} />
        </StyledPanel>
      </StyledCollapse>
    </>
  );
}

export default DocumentCenter;
