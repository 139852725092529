import { PageHeader } from "components/pageHeader";
import { DocumentCenter } from "features/documentCenter";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function DocumentCenterPage() {
  return (
    <PageLayout>
      <PageHeader>Document Center</PageHeader>
      <DocumentCenter />
    </PageLayout>
  );
}

export default DocumentCenterPage;
