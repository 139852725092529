import { Button, Result } from "antd";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <PageLayout>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, page is not found."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </PageLayout>
  );
}

export default NotFoundPage;
