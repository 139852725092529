import { Checkbox, Col, Collapse, Form, InputNumber, Row, Select, Space } from "antd";
import { AktDateRangePicker } from "components/aktDatePicker";
import { IncludeExcludeInput } from "components/includeExcludeInput";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchCreditorTagsQuery, useFetchDebtorTagsQuery } from "features/tags/tagsAPI";
import { useFetchImportHistoryQuery } from "features/dataImport/agencyPortal/dataImportAPI";
import styled from "styled-components";
import moment from "moment-timezone";

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledSpan = styled.span`
  margin-bottom: 24px;
  display: block;
  font-size: 13px;
`;

const FilterTitle = styled.div`
  font-weight: 600;
  padding: 0 0 2px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-right: 4px;
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 24px;
`;

export function AccountFiltersStep() {
  const { data: defaultWorkflowStates = [], isLoading: isDefaultWorkflowStatesLoading } =
    useFetchAgencyWorkflowStates();
  const { collectorsWithRoles: collectors = [], isLoading: isCollectorsLoading } =
    useFetchCollectorsQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        collectorsWithRoles: collectorsWithRolesSelector(result),
      }),
    });
  const { data: creditorTags = [], isLoading: isCreditorTagsLoading } = useFetchCreditorTagsQuery();
  const { data: constants = {}, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();
  const { data: debtorTags = [], isLoading: isDebtorTagsLoading } = useFetchDebtorTagsQuery();
  const { data: creditors = [], isLoading: isCreditorsLoading } = useFetchCreditorSummariesQuery();
  const { data: productOfferings = [], isLoading: isProductOfferingsLoading } =
    useFetchProductOfferingsQuery();
  const { data: accountImports = [], isLoading: isAccountImportsLoading } =
    useFetchImportHistoryQuery({ type: "bulk_action_account", status: "success" });
  const { data: debtorImports = [], isLoading: isDebtorImportsLoading } =
    useFetchImportHistoryQuery({ type: "bulk_action_debtor", status: "success" });

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const items = [
    {
      key: "account",
      label: "Account Filters",
      children: (
        <>
          <IncludeExcludeInput
            label="Forwarding Entity"
            includeName={["filters", "hasForwardingEntity"]}
            excludeName={["filters", "excludeHasForwardingEntity"]}
          />

          <IncludeExcludeInput
            label="Deceased"
            includeName={["filters", "isDebtorDeceased"]}
            excludeName={["filters", "excludeIsDebtorDeceased"]}
          />

          <IncludeExcludeInput
            label="Is Settled"
            includeName={["filters", "isSettled"]}
            excludeName={["filters", "excludeIsSettled"]}
          />

          <IncludeExcludeInput
            label="Is Paid In Full"
            includeName={["filters", "isPaidInFull"]}
            excludeName={["filters", "excludeIsPaidInFull"]}
          />

          <IncludeExcludeInput
            label="Do Not Call"
            includeName={["filters", "isDoNotCall"]}
            excludeName={["filters", "excludeIsDoNotCall"]}
          />

          <IncludeExcludeInput
            label="Priority"
            includeName={["filters", "isPriority"]}
            excludeName={["filters", "excludeIsPriority"]}
          />

          <FilterTitle>Statuses</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeStatusCodes"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Status..."
                  filterOption={filterOption}
                  options={defaultWorkflowStates.map((state) => ({
                    label: [state.code, state.name].join(" - "),
                    value: state.code,
                    title: state.description,
                  }))}
                  disabled={isDefaultWorkflowStatesLoading}
                  loading={isDefaultWorkflowStatesLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeStatusCodes"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Status..."
                  filterOption={filterOption}
                  options={defaultWorkflowStates.map((state) => ({
                    label: [state.code, state.name].join(" - "),
                    value: state.code,
                    title: state.description,
                  }))}
                  disabled={isDefaultWorkflowStatesLoading}
                  loading={isDefaultWorkflowStatesLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Account Balance</FilterTitle>
          <Space>
            <Form.Item name={["filters", "minAccountBalance"]}>
              <InputNumber placeholder="Min" controls={false} min="0" prefix="$" step="0.01" />
            </Form.Item>
            <Form.Item name={["filters", "maxAccountBalance"]}>
              <InputNumber placeholder="Max" controls={false} min="0" prefix="$" step="0.01" />
            </Form.Item>
          </Space>

          <FilterTitle>Follow-up Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "followUpDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeFollowUpDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Upload Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "uploadDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeUploadDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Date of Service</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeDateOfService"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeDateOfService"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Turnover Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeTurnoverDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeTurnoverDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Date of First Delinquency</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeDateOfFirstDelinquency"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeDateOfFirstDelinquency"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Itemization Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeItemizationDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeItemizationDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Charge Off Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeChargeOffDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeChargeOffDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Interest Start Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeInterestStartDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeInterestStartDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Interest End Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeInterestEndDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeInterestEndDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Last Payment Date</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeLastPaymentDate"]} label="Include">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeLastPaymentDate"]} label="Exclude">
                <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name={["filters", "collectionDebtTypes"]} label="Debt Type">
            <Checkbox.Group
              options={[
                { label: "Consumer", value: "consumer" },
                { label: "Commercial", value: "commercial" },
              ]}
            />
          </Form.Item>

          <FilterTitle>Assignees</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "assigneeIds"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Assignees..."
                  filterOption={filterOption}
                  options={
                    collectors && [
                      {
                        label: "Unassigned",
                        value: null,
                      },
                      ...(collectors.map((c) => ({
                        label: collectorFullName(c),
                        value: c.id,
                      })) ?? []),
                    ]
                  }
                  disabled={isCollectorsLoading}
                  loading={isCollectorsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeAssigneeIds"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Assignees..."
                  filterOption={filterOption}
                  options={
                    collectors && [
                      {
                        label: "Unassigned",
                        value: null,
                      },
                      ...collectors.map((c) => ({
                        label: collectorFullName(c),
                        value: c.id,
                      })),
                    ]
                  }
                  disabled={isCollectorsLoading}
                  loading={isCollectorsLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Sales Representative</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "salesRepIds"]} label="Include">
                <Select
                  popupMatchSelectWidth={false}
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  placeholder="Select a sales rep..."
                  options={collectors.map((user) => ({
                    label: collectorFullName(user),
                    value: user.id,
                  }))}
                  mode="multiple"
                  disabled={isCollectorsLoading}
                  loading={isCollectorsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeSalesRepIds"]} label="Exclude">
                <Select
                  popupMatchSelectWidth={false}
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  placeholder="Select a sales rep..."
                  options={collectors.map((user) => ({
                    label: collectorFullName(user),
                    value: user.id,
                  }))}
                  mode="multiple"
                  disabled={isCollectorsLoading}
                  loading={isCollectorsLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Product Offerings</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeProductOfferingIds"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Product Offering..."
                  filterOption={filterOption}
                  options={productOfferings.map((productOffering) => ({
                    label: productOffering.name,
                    value: productOffering.id,
                  }))}
                  disabled={isProductOfferingsLoading}
                  loading={isProductOfferingsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeProductOfferingIds"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Product Offering..."
                  filterOption={filterOption}
                  options={productOfferings.map((productOffering) => ({
                    label: productOffering.name,
                    value: productOffering.id,
                  }))}
                  disabled={isProductOfferingsLoading}
                  loading={isProductOfferingsLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <FilterTitle>Account Imports</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeAccountDataImportIds"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  allowClear
                  placeholder="Account Imports..."
                  filterOption={filterOption}
                  options={accountImports.map((accountImport) => ({
                    label: `${accountImport.filename} - ${moment(accountImport.createdAt).format(
                      "MM/DD/YY HH:mm",
                    )}`,
                    value: accountImport.id,
                  }))}
                  disabled={isAccountImportsLoading}
                  loading={isAccountImportsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeAccountDataImportIds"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  allowClear
                  placeholder="Account Imports..."
                  filterOption={filterOption}
                  options={accountImports.map((accountImport) => ({
                    label: `${accountImport.filename} - ${moment(accountImport.createdAt).format(
                      "MM/DD/YY HH:mm",
                    )}`,
                    value: accountImport.id,
                  }))}
                  disabled={isAccountImportsLoading}
                  loading={isAccountImportsLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Score</FilterTitle>
          <Space>
            <Form.Item name={["filters", "minExternalScore"]}>
              <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
            </Form.Item>
            <Form.Item name={["filters", "maxExternalScore"]}>
              <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
            </Form.Item>
          </Space>

          <FilterTitle>Intelitech I-Score</FilterTitle>
          <Space>
            <Form.Item name={["filters", "minIntelitechIScore"]}>
              <InputNumber placeholder="Min" controls={false} min="0" step="1" max="999" />
            </Form.Item>
            <Form.Item name={["filters", "maxIntelitechIScore"]}>
              <InputNumber placeholder="Max" controls={false} min="0" step="1" max="999" />
            </Form.Item>
          </Space>

          <FilterTitle>Intelitech Medal-Score</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "includeIntelitechMedalScore"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Medal Scores..."
                  filterOption={filterOption}
                  options={constants?.intelitechMedalScores ?? []}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeIntelitechMedalScore"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Medal Scores..."
                  filterOption={filterOption}
                  options={constants?.intelitechMedalScores ?? []}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Intelitech Q-Score</FilterTitle>
          <Space>
            <Form.Item name={["filters", "minIntelitechQScore"]}>
              <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
            </Form.Item>
            <Form.Item name={["filters", "maxIntelitechQScore"]}>
              <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
            </Form.Item>
          </Space>

          <FilterTitle>Intelitech R-Score</FilterTitle>
          <Space>
            <Form.Item name={["filters", "minIntelitechRScore"]}>
              <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
            </Form.Item>
            <Form.Item name={["filters", "maxIntelitechRScore"]}>
              <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
            </Form.Item>
          </Space>

          <FilterTitle>Intelitech FPG-Value</FilterTitle>
          <Space>
            <Form.Item name={["filters", "minIntelitechFPGValue"]}>
              <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
            </Form.Item>
            <Form.Item name={["filters", "maxIntelitechFPGValue"]}>
              <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
            </Form.Item>
          </Space>
        </>
      ),
    },
    {
      key: "legal",
      label: "Legal Filters",
      children: (
        <>
          <IncludeExcludeInput
            label="Lawsuit Filed"
            includeName={["filters", "hasLawsuit"]}
            excludeName={["filters", "excludeHasLawsuit"]}
          />
          <IncludeExcludeInput
            label="Has Judgment"
            includeName={["filters", "includeHasJudgment"]}
            excludeName={["filters", "excludeHasJudgment"]}
          />
        </>
      ),
    },
    {
      key: "creditReporting",
      label: "Credit Reporting Filters",
      children: (
        <>
          <IncludeExcludeInput
            label="Surpress From Credit Reporting"
            includeName={["filters", "includeSuppressFromCreditReporting"]}
            excludeName={["filters", "excludeSuppressFromCreditReporting"]}
          />
          <IncludeExcludeInput
            label="Delete From Credit Reporting"
            includeName={["filters", "includeDeleteFromCreditReporting"]}
            excludeName={["filters", "excludeDeleteFromCreditReporting"]}
          />
        </>
      ),
    },
    {
      key: "debtor",
      label: "Debtor Filters",
      children: (
        <>
          <FilterTitle>Debtor Location</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "debtorStates"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select location..."
                  filterOption={filterOption}
                  options={(constants.states ?? []).map((state) => ({
                    label: state.display,
                    value: state.value,
                  }))}
                  disabled={isConstantsLoading}
                  loading={isConstantsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeDebtorStates"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select location..."
                  filterOption={filterOption}
                  options={(constants.states ?? []).map((state) => ({
                    label: state.display,
                    value: state.value,
                  }))}
                  disabled={isConstantsLoading}
                  loading={isConstantsLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Debtor Tags</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "debtorTagIds"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Debtor Tags..."
                  filterOption={filterOption}
                  options={debtorTags.map((tag) => ({
                    value: tag.id,
                    label: tag.name,
                  }))}
                  disabled={isDebtorTagsLoading}
                  loading={isDebtorTagsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeDebtorTagIds"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Debtor Tags..."
                  filterOption={filterOption}
                  options={debtorTags.map((tag) => ({
                    value: tag.id,
                    label: tag.name,
                  }))}
                  disabled={isDebtorTagsLoading}
                  loading={isDebtorTagsLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <FilterTitle>Debtors Imports</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "debtorDataImportIds"]} label="Include">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Debtors Imports..."
                  filterOption={filterOption}
                  options={debtorImports.map((debtorImport) => ({
                    value: debtorImport.id,
                    label: `${debtorImport.filename} - ${moment(debtorImport.createdAt).format(
                      "MM/DD/YY HH:mm",
                    )}`,
                  }))}
                  disabled={isDebtorImportsLoading}
                  loading={isDebtorImportsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeDebtorDataImportIds"]} label="Exclude">
                <StyledSelect
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Debtors Imports..."
                  filterOption={filterOption}
                  options={debtorImports.map((debtorImport) => ({
                    value: debtorImport.id,
                    label: `${debtorImport.filename} - ${moment(debtorImport.createdAt).format(
                      "MM/DD/YY HH:mm",
                    )}`,
                  }))}
                  disabled={isDebtorImportsLoading}
                  loading={isDebtorImportsLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "client",
      label: "Client Filters",
      children: (
        <>
          <FilterTitle>Clients</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "creditorIds"]} label="Include">
                <StyledSelect
                  maxTagTextLength={20}
                  mode="multiple"
                  placeholder="Select Clients..."
                  filterOption={filterOption}
                  options={creditors.map((creditor) => ({
                    value: creditor.id,
                    label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
                  }))}
                  disabled={isCreditorsLoading}
                  loading={isCreditorsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeCreditorIds"]} label="Exclude">
                <StyledSelect
                  maxTagTextLength={20}
                  mode="multiple"
                  placeholder="Select Clients..."
                  filterOption={filterOption}
                  options={creditors.map((creditor) => ({
                    value: creditor.id,
                    label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
                  }))}
                  disabled={isCreditorsLoading}
                  loading={isCreditorsLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Client Tags</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "creditorTagIds"]} label="Include">
                <StyledSelect
                  maxTagTextLength={20}
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Client Tags..."
                  filterOption={filterOption}
                  options={creditorTags.map((creditorTag) => ({
                    value: creditorTag.id,
                    label: creditorTag.name,
                  }))}
                  disabled={isCreditorTagsLoading}
                  loading={isCreditorTagsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeCreditorTagIds"]} label="Exclude">
                <StyledSelect
                  maxTagTextLength={20}
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  placeholder="Select Client Tags..."
                  filterOption={filterOption}
                  options={creditorTags.map((creditorTag) => ({
                    value: creditorTag.id,
                    label: creditorTag.name,
                  }))}
                  disabled={isCreditorTagsLoading}
                  loading={isCreditorTagsLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <FilterTitle>Client Codes</FilterTitle>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={["filters", "creditorCodes"]} label="Include">
                <StyledSelect
                  maxTagTextLength={20}
                  mode="multiple"
                  placeholder="Select Codes..."
                  filterOption={filterOption}
                  options={creditors
                    .filter((creditor) => !!creditor.code)
                    .map((creditor) => ({
                      value: creditor.code,
                      label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
                    }))}
                  disabled={isCreditorsLoading}
                  loading={isCreditorsLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["filters", "excludeCreditorCodes"]} label="Exclude">
                <StyledSelect
                  maxTagTextLength={20}
                  mode="multiple"
                  placeholder="Select Codes..."
                  filterOption={filterOption}
                  options={creditors
                    .filter((creditor) => !!creditor.code)
                    .map((creditor) => ({
                      value: creditor.code,
                      label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
                    }))}
                  disabled={isCreditorsLoading}
                  loading={isCreditorsLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <StyledHeader>Filters</StyledHeader>
      <StyledSpan>Accounts matching these filter criteria will be selected.</StyledSpan>

      <Form.Item hidden name="type" />

      <StyledCollapse accordion defaultActiveKey={["account"]}>
        {items.map((item) => (
          <Collapse.Panel header={item.label} key={item.key} forceRender>
            {item.children}
          </Collapse.Panel>
        ))}
      </StyledCollapse>
    </>
  );
}
