import api, { transformRequest, transformResponse } from "services/api";

export const udfApi = api
  .enhanceEndpoints({ addTagTypes: ["DebtorUdfCustomField", "DebtorUdfCustomFieldDromoSchema"] })
  .injectEndpoints({
    endpoints: (build) => ({
      addDebtorUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-custom-fields/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "DebtorUdfCustomField", id: "LIST" }],
      }),
      fetchAllDebtorUdfCustomFields: build.query({
        query: () => `/api/v1/core/debtor-custom-fields/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorUdfCustomField", id })),
                { type: "DebtorUdfCustomField", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorUdfCustomField', id: 'LIST' }` is invalidated
              [{ type: "DebtorUdfCustomField", id: "LIST" }],
      }),
      fetchAllDebtorUdfCustomFieldsDromoSchema: build.query({
        query: () => `/api/v1/core/debtor-custom-fields/dromo-schema/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [{ type: "DebtorUdfCustomFieldDromoSchema", id: "LIST" }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorUdfCustomFieldDromoSchema', id: 'LIST' }` is invalidated
              [{ type: "DebtorUdfCustomFieldDromoSchema", id: "LIST" }],
      }),
      updateDebtorUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-custom-fields/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "DebtorUdfCustomField", id: "LIST" }],
      }),
      deleteDebtorUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-custom-fields/${payload.id}/delete/`,
          method: "POST",
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "DebtorUdfCustomField", id: "LIST" }],
      }),
    }),
  });

export const {
  useAddDebtorUdfCustomFieldMutation,
  useFetchAllDebtorUdfCustomFieldsQuery,
  useUpdateDebtorUdfCustomFieldMutation,
  useDeleteDebtorUdfCustomFieldMutation,
  useFetchAllDebtorUdfCustomFieldsDromoSchemaQuery,
} = udfApi;
