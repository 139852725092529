import { Button, Result } from "antd";
import PageLayout from "layouts/creditorPortal/dashboard/pageLayout";
import { useNavigate } from "react-router-dom";

function ErrorBoundaryPage() {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <Result
        status="500"
        title="Error"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={() => navigate(0)}>
            Refresh Page
          </Button>
        }
      />
    </PageLayout>
  );
}

export default ErrorBoundaryPage;
