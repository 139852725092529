import { Form, Input, Select } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import { useFetchCourtsOfLawQuery } from "features/courtsOfLawTable/courtsOfLawAPI";

export default function CourtStep() {
  const { data: courtsOfLaw } = useFetchCourtsOfLawQuery();

  return (
    <>
      <Form.Item
        name={["court", "courtId"]}
        label="Court Code"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Select
          placeholder="Select a court code..."
          options={courtsOfLaw?.map((court) => ({ label: court.code, value: court.id }))}
        />
      </Form.Item>
      <Form.Item label="Legal Case Number" name={["court", "legalCaseNumber"]}>
        <Input />
      </Form.Item>
      <Form.Item label="Judge Name" name={["court", "judgeName"]}>
        <Input />
      </Form.Item>
      <Form.Item label="Agency Approval Date" name={["court", "agencyApprovalDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Filing Date" name={["court", "filingDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Process Date" name={["court", "processDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Answered Date" name={["court", "answeredDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Next Court Date" name={["court", "nextCourtDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Dismissal Date" name={["court", "dismissalDate"]}>
        <AktDatePicker />
      </Form.Item>
    </>
  );
}
