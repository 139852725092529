import api, {
  replaceEmptyArraysWithNulls,
  transformCursorResponse,
  transformRequest,
  transformResponse,
} from "services/api";

export const accountsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "CreditorPortalDebtorAccounts",
      "CreditorPortalAccounts",
      "CreditorPortalDebtorProfile",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorPortalWorklistAccounts: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/accounts/worklist/`,
          method: "POST",
          body: replaceEmptyArraysWithNulls(transformRequest(payload)),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "CreditorPortalAccounts", id })),
                { type: "CreditorPortalAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Accounts', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalAccounts", id: "LIST" }],
      }),
      fetchCreditorPortalAccounts: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/accounts/`,
          method: "POST",
          body: replaceEmptyArraysWithNulls(transformRequest(payload)),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "CreditorPortalAccounts", id })),
                { type: "CreditorPortalAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalAccounts', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalAccounts", id: "LIST" }],
      }),
      postCreditorPortalAccount: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/accounts/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [{ type: "CreditorPortalAccounts", id: "LIST" }],
      }),
      searchCreditorPortalDebtors: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/search/`,
          method: "GET",
          params: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      searchCreditorPortalDebtorsByType: build.query({
        query: (payload) =>
          `/api/v1/creditor/debtors/search/?query=${payload.query}&query_field_type=${payload.queryFieldType}`,
        transformResponse: (response) => transformResponse(response),
      }),
      fetchCreditorPortalDebtorProfile: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        // @ts-ignore
        transformResponse: (response) => transformResponse(response),
        providesTags: (result, error, arg) => [
          { type: "CreditorPortalDebtorProfile", id: arg.debtorId },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchCreditorPortalAccountsQuery,
  useFetchCreditorPortalWorklistAccountsQuery,
  useLazyFetchCreditorPortalAccountsQuery,
  usePostCreditorPortalAccountMutation,
  useLazySearchCreditorPortalDebtorsQuery,
  useLazySearchCreditorPortalDebtorsByTypeQuery,
  useFetchCreditorPortalDebtorProfileQuery,
  useLazyFetchCreditorPortalDebtorProfileQuery,
} = accountsApi;
