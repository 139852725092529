import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  message,
} from "antd";
import { formatCurrency, formatDate, idToObjectMap } from "common/utils";
import { CursorTable } from "components/cursorTable";
import { PageHeader } from "components/pageHeader";
import currency from "currency.js";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import AddInvoiceModal from "features/invoices/components/addInvoiceModal";
import BulkCreateInvoiceModal from "features/invoices/components/bulkCreateInvoiceModal";
import DownloadInvoiceModal from "features/invoices/components/downloadInvoiceModal";
import LogPaymentModal from "features/invoices/components/logPaymentModal";
import {
  useBulkDeleteInvoicesMutation,
  useBulkPublishInvoicesMutation,
  useBulkUpdateInvoicesMutation,
  useFetchInvoicesQuery,
  useFetchSupportedFiltersQuery,
  useUpdateInvoiceMutation,
} from "features/invoices/invoiceAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  margin: 4px 4px;
  text-transform: uppercase;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledActions = styled(Row)`
  margin-left: 47px;
  border-left: 1px solid #f0f0f0;
  height: 100%;
  align-items: center;
`;

const StyledTable = styled(CursorTable)`
  white-space: pre;
  cursor: pointer;
  .invoice-noclick {
    pointer-events: none;
  }

  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 300px);
    overflow: scroll;
  }
`;

const ButtonWithMargin = styled(Button)`
  margin: 0px 10px;
  color: #444;
  &:hover {
    background: #f0f0f0;
  }
`;

const StyledInput = styled(Input)`
  pointer-events: auto;
  width: 220px;
`;

const StyledSelect = styled(Select)`
  pointer-events: auto;
  width: 100px;
`;

const StyledTextArea = styled(Input.TextArea)`
  pointer-events: auto;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1890ff;
  margin-left: 4px;
  pointer-events: auto;
`;

const StyledActionContainer = styled(Row)`
  margin-top: 16px;
  background: #fff;
  height: 48px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

const StyledMoreOutlined = styled(MoreOutlined)`
  font-size: 18px;
`;

const DEFAULT_PAGE_SIZE = 10;

function InvoiceList() {
  const { state } = useLocation();
  const [virtualPage, setVirtualPage] = useState(1);
  const [tableFilters, setTableFilters] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    prevToken: null,
    nextToken: null,
  });

  // state hooks
  const [referenceNumber, setReferenceNumber] = useState("");
  const [notesText, setNotesText] = useState("");
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
  const [showBulkCreateInvoiceModal, setShowBulkCreateInvoiceModal] = useState(false);
  const [showDownloadInvoiceModal, setShowDownloadInvoiceModal] = useState(false);
  const [showLogPaymentModal, setShowLogPaymentModal] = useState(false);
  const [creditorId, setCreditorId] = useState(state?.creditorId ?? null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // data hooks
  const [deleteInvoices, { isLoading: isDeleteInvoicesLoading }] = useBulkDeleteInvoicesMutation();
  const [updateInvoice, { isLoading: isInvoiceLoading }] = useUpdateInvoiceMutation();
  const [publishInvoices, { isLoading: isPublishInvoicesLoading }] =
    useBulkPublishInvoicesMutation();
  const [updateInvoices, { isLoading: isUpdatedInvoicesLoading }] = useBulkUpdateInvoicesMutation();
  const {
    data: { results: invoices, meta: { prevToken, nextToken, pageSize } } = {
      results: [],
      meta: { pageSize: DEFAULT_PAGE_SIZE, prevToken: null, nextToken: null },
    },
    isLoading: isFetchInvoicesLoading,
    isFetching: isFetchInvoicesFetching,
    refetch: fetchInvoices,
  } = useFetchInvoicesQuery(
    {
      ...tableFilters,
      creditorId,
      virtualPage,
      ...pagination,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const {
    data: supportedFilters = {
      statuses: [],
      dateRanges: [],
    },
  } = useFetchSupportedFiltersQuery();

  // permission hooks
  const {
    data: {
      [PERMISSIONS.INVOICE__GET]: isInvoiceGetAuthorized,
      [PERMISSIONS.INVOICE__DELETE]: isInvoiceDeleteAuthorized,
    },
  } = useAuthorizations();

  // selected rows
  const onRowSelectionChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onRowSelectionChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const deselectRows = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors);
  }, [creditors]);

  const creditorName = useMemo(() => {
    return creditorIdToCreditorMap[creditorId]?.name;
  }, [creditorIdToCreditorMap, creditorId]);

  const onPaymentMethodSelect = useCallback(
    async (invoiceId, newMethodType) => {
      const result = await updateInvoice({
        invoiceId,
        payload: { creditorPaymentMethodType: newMethodType },
      });
      if ("data" in result) {
        message.success("Successfully changed!");
      }
    },
    [updateInvoice],
  );

  const submitNotes = useCallback(
    async (invoiceId, oldNotes) => {
      if (notesText !== oldNotes) {
        const result = await updateInvoice({
          invoiceId,
          payload: { notes: notesText },
        });
        if ("data" in result) {
          message.success("Successfully changed!");
        }
      }
    },
    [updateInvoice, notesText],
  );

  const onPaymentReferenceNumberChange = useCallback(
    async (invoiceId, oldReferenceNumber) => {
      if (referenceNumber !== oldReferenceNumber) {
        const result = await updateInvoice({
          invoiceId,
          payload: { creditorPaymentReferenceNumber: referenceNumber },
        });
        if ("data" in result) {
          message.success("Successfully changed!");
        }
        setReferenceNumber("");
      }
    },
    [updateInvoice, referenceNumber],
  );

  const preventPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMenuClick = useCallback(({ item, domEvent }) => {
    // This prevents undesired consequences when clicking on a dropdown item
    preventPropagation(domEvent);

    // Call the action handler defined in the items dictionary
    item.props.actionHandler();
  }, []);

  const onPaginationChange = (newPage, newPageSize) => {
    setPagination({
      pageSize: newPageSize,
      prevToken: null,
      nextToken: null,
    });
    setVirtualPage(newPage);
  };

  const handleTableChange = (newPagination, newFilters, sorter) => {
    if (newPagination && Object.keys(newPagination).length > 0) {
      setVirtualPage(newPagination.virtualPage);
      setPagination({
        pageSize: newPagination.pageSize,
        prevToken: newPagination.prevToken,
        nextToken: newPagination.nextToken,
      });
    } else {
      setPagination({
        pageSize,
        prevToken: null,
        nextToken: null,
      });
    }

    if (newFilters && Object.keys(newFilters).length > 0) {
      let filterSubquery = {};
      // "statuses": [
      //   "draft"
      // ],
      if (newFilters.status) {
        filterSubquery = { ...filterSubquery, statuses: newFilters.status };
      }

      // "date_ranges": [
      //   { end_date: "2024-08-07", start_date: "2024-07-07" ]
      // ]
      if (newFilters.startDate && newFilters.startDate.length > 0) {
        const [invoiceStartDate, invoiceEndDate] = newFilters.startDate[0];
        filterSubquery = {
          ...filterSubquery,
          invoiceStartDate,
          invoiceEndDate,
        };
      }

      setTableFilters(filterSubquery);
    }
  };

  const columnsAll = useMemo(
    () => [
      {
        title: "Invoice ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Invoice Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.name || `Invoice ${record.id}`,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          if (record.asyncJob && record.asyncJob?.status !== "done") {
            return <StyledTag color="blue">Generating</StyledTag>;
          }
          if (record.status?.toLowerCase() === "published") {
            return <StyledTag color="yellow">published</StyledTag>;
          }
          if (record.status?.toLowerCase() === "delivered") {
            return <StyledTag color="yellow">delivered</StyledTag>;
          }
          if (record.status?.toLowerCase() === "draft") {
            return <StyledTag>draft</StyledTag>;
          }
          if (record.status?.toLowerCase() === "paid_in_full") {
            return <StyledTag color="green">Paid</StyledTag>;
          }
          return <StyledTag />;
        },
        filters: supportedFilters.statuses.map((status) => ({
          text: status.display,
          value: status.value,
        })),
      },
      {
        title: "Client Name",
        dataIndex: "creditorId",
        key: "creditorId",
        render: (text, record) => creditorIdToCreditorMap[record.creditorId]?.name,
      },
      {
        title: "Client Code",
        dataIndex: "creditorId",
        key: "creditorId",
        render: (text, record) => creditorIdToCreditorMap[record.creditorId]?.code || "-",
      },
      {
        title: "Date Range",
        dataIndex: "startDate",
        key: "startDate",
        render: (text, record) => (
          <>
            {formatDate(record.startDate)} - {formatDate(record.endDate)}
          </>
        ),
        filters: supportedFilters.dateRanges.map((dateRange) => ({
          text: `${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`,
          value: [dateRange.startDate, dateRange.endDate],
        })),
      },
      {
        title: "Paid ($)",
        dataIndex: "amountCreditorPaid",
        key: "amountCreditorPaid",
        render: (text, record) => formatCurrency(record.amountCreditorPaid),
      },
      {
        title: "Remaining Balance ($)",
        key: "totalAmountCreditorOwes",
        render: (text, record) =>
          currency(record.summaryTotalAmountDueToAgency, { precision: 2 })
            .subtract(record.amountCreditorPaid)
            .format(),
      },
      {
        title: `Amount ${creditorName ?? "Client"}\nOwes Us ($)`,
        align: "center",
        dataIndex: "summaryTotalAmountDueToAgency",
        key: "summaryTotalAmountDueToAgency",
        render: (text, record) => formatCurrency(record.summaryTotalAmountDueToAgency),
      },
      {
        title: `Amount We\nOwe\n${creditorName ?? "Client"} ($)`,
        align: "center",
        dataIndex: "summaryTotalAmountDueToCreditor",
        key: "summaryTotalAmountDueToCreditor",
        render: (text, record) => formatCurrency(record.summaryTotalAmountDueToCreditor),
      },
      {
        title: "Payment Type",
        align: "center",
        className: "invoice-noclick",
        dataIndex: "creditorPaymentMethodType",
        key: "creditorPaymentMethodType",
        render: (text, record) => (
          <StyledSelect
            placeholder="Select one..."
            value={record?.creditorPaymentMethodType}
            popupMatchSelectWidth={false}
            onClick={preventPropagation}
            options={[
              {
                label: "ACH",
                value: "ach",
              },
              {
                label: "Checks",
                value: "checks",
              },
            ]}
            onChange={(value) => onPaymentMethodSelect(record.id, value)}
          />
        ),
      },
      {
        title: (
          <>
            ACH / Check Number
            <Tooltip title="Payment reference number is only editable after the invoice is published">
              <StyledQuestionCircleOutlined />
            </Tooltip>
          </>
        ),
        className: "invoice-noclick",
        dataIndex: "creditorPaymentReferenceNumber",
        key: "creditorPaymentReferenceNumber",
        render: (text, record) =>
          record.status?.toLowerCase() === "draft" ? (
            <span>{record?.creditorPaymentReferenceNumber || "-"}</span>
          ) : (
            <StyledInput
              disabled={record.status?.toLowerCase() === "draft"}
              placeholder="Enter ACH or Check Number..."
              defaultValue={record.creditorPaymentReferenceNumber}
              onFocus={() => setReferenceNumber(record.creditorPaymentReferenceNumber)}
              onBlur={(e) => {
                onPaymentReferenceNumberChange(record.id, record.creditorPaymentReferenceNumber);
              }}
              onChange={(e) => setReferenceNumber(e.target.value)}
              onClick={preventPropagation}
            />
          ),
      },
      {
        title: "Notes",
        className: "invoice-noclick",
        dataIndex: "notes",
        width: 400,
        key: "notes",
        render: (text, record) => (
          <StyledTextArea
            onBlur={() => submitNotes(record.id, record.notes)}
            onFocus={() => setNotesText(record.notes)}
            onChange={(e) => setNotesText(e.target.value)}
            placeholder="Add Notes"
            onClick={preventPropagation}
            defaultValue={record.notes}
          />
        ),
      },
    ],
    [
      supportedFilters,
      creditorName,
      creditorIdToCreditorMap,
      onPaymentMethodSelect,
      onPaymentReferenceNumberChange,
      submitNotes,
    ],
  );

  const columnsPerCreditor = useMemo(
    () => columnsAll.filter((each) => each.key !== "creditorName" && each.key !== "creditorCode"),
    [columnsAll],
  );

  const [columns, setColumns] = useState(columnsAll);

  useEffect(() => {
    if (creditorId !== null) {
      setColumns(columnsPerCreditor);
    } else {
      setColumns(columnsAll);
    }
  }, [creditorId, creditors, columnsAll, columnsPerCreditor]);

  const gotoViewInvoiceDetails = (record) => {
    const url = `/invoices/${record.id}`;

    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const invoiceMenuItems = [
    { label: "All Clients", value: null },
    ...(creditors ?? []).map((creditor) => ({
      label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
      value: creditor.id,
    })),
  ];

  const showPublishInvoiceConfirm = () => {
    Modal.confirm({
      title:
        "You will not be able to edit the invoices after they are published. Are you sure you want to publish these invoices?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const invoiceIds = selectedRows.map((each) => each.id);
        const result = publishInvoices({ invoiceIds });
        if ("error" in result) {
          return message.error("Failed to publish invoices");
        }
        message.success("Invoices were published successfully");
        deselectRows();
      },
      onCancel() {},
    });
  };

  const showDraftInvoiceConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to revert these invoices back to draft?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const invoiceIds = selectedRows.map((each) => each.id);
        const result = updateInvoices({ invoiceIds, status: "draft" });
        if ("error" in result) {
          return message.error("Failed to revert invoices to draft");
        }
        message.success("Invoices were successfully reverted to draft");
        deselectRows();
      },
      onCancel() {},
    });
  };

  const showPainInFullInvoiceConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to mark these invoices as paid in full?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const invoiceIds = selectedRows.map((each) => each.id);
        const result = updateInvoices({ invoiceIds, status: "paid_in_full" });
        if ("error" in result) {
          return message.error("Failed to mark invoices as paid");
        }
        message.success("Invoices were successfully marked as paid");
        deselectRows();
      },
      onCancel() {},
    });
  };

  const showDeliveredInvoiceConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to mark these invoices as delivered?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const invoiceIds = selectedRows.map((each) => each.id);
        const result = updateInvoices({ invoiceIds, status: "delivered" });
        if ("error" in result) {
          return message.error("Failed to mark invoices as delivered");
        }
        message.success("Invoices were successfully marked as delivered");
        deselectRows();
      },
      onCancel() {},
    });
  };

  const actionItems = [
    {
      label: `Log Payment`,
      key: "logPayment",
      actionHandler: () => {
        setShowLogPaymentModal(true);
      },
      disabled: selectedRows?.length !== 1,
    },
    {
      label: "Publish",
      key: "publish",
      actionHandler: showPublishInvoiceConfirm,
      disabled: !hasSelected,
    },
    {
      label: "Revert to Draft",
      key: "revertToDraft",
      actionHandler: showDraftInvoiceConfirm,
      disabled: !hasSelected,
    },
    {
      label: "Mark as Paid in Full",
      key: "paidInFull",
      actionHandler: showPainInFullInvoiceConfirm,
      disabled: !hasSelected,
    },
    {
      label: "Mark as Delivered",
      key: "delivered",
      actionHandler: showDeliveredInvoiceConfirm,
      disabled: !hasSelected,
    },
  ];

  const isLoading =
    isFetchInvoicesLoading ||
    isFetchInvoicesFetching ||
    isInvoiceLoading ||
    isPublishInvoicesLoading ||
    isDeleteInvoicesLoading ||
    isUpdatedInvoicesLoading;

  return isInvoiceGetAuthorized ? (
    <>
      <StyledRow align="middle">
        <PageHeader>Invoices</PageHeader>
        {creditorId === null ? (
          <Button
            onClick={() => setShowBulkCreateInvoiceModal(true)}
            icon={<PlusOutlined />}
            type="link"
          >
            Add Invoices
          </Button>
        ) : (
          <Button onClick={() => setShowAddInvoiceModal(true)} icon={<PlusOutlined />} type="link">
            Add Invoice
          </Button>
        )}
      </StyledRow>
      <Select
        defaultValue={creditorId}
        showSearch
        style={{
          width: 350,
        }}
        placeholder="Select one..."
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) => {
          if (optionA.value === null) {
            return -1;
          }
          if (optionB.value === null) {
            return 1;
          }
          return (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase());
        }}
        onChange={setCreditorId}
        options={invoiceMenuItems}
      />
      <StyledActionContainer>
        <StyledActions wrap={false}>
          {!hasSelected && (
            <ButtonWithMargin
              shape="circle"
              icon={<ReloadOutlined />}
              type="text"
              onClick={fetchInvoices}
            />
          )}
          {hasSelected && (
            <ButtonWithMargin
              shape="circle"
              icon={<DownloadOutlined />}
              type="text"
              onClick={() => setShowDownloadInvoiceModal(true)}
            />
          )}
          {hasSelected && isInvoiceDeleteAuthorized && (
            <Popconfirm
              placement="topRight"
              okText="Yes"
              title={`Are you sure you want to delete ${selectedRows.length} invoice(s)?`}
              onCancel={(e) => {
                preventPropagation(e);
              }}
              onConfirm={async (e) => {
                preventPropagation(e);
                const invoiceIds = selectedRows.map((each) => each.id);
                const result = deleteInvoices({ invoiceIds });
                if ("error" in result) {
                  return message.error("Failed to delete invoices");
                }
                message.success("Invoices were deleted successfully");
                deselectRows();
              }}
            >
              <Button shape="circle" icon={<DeleteOutlined />} type="text" />
            </Popconfirm>
          )}
          <Dropdown
            menu={{
              items: actionItems,
              onClick: handleMenuClick,
            }}
            trigger={["click"]}
          >
            <Button type="text">
              <StyledMoreOutlined />
            </Button>
          </Dropdown>
        </StyledActions>
      </StyledActionContainer>
      <StyledTable
        sticky
        pagination={{
          pagination: onPaginationChange,
        }}
        rowSelection={rowSelection}
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={invoices}
        onRow={(record) => ({
          onClick: (e) => {
            // allow users to select text so it does not trigger a click into the row
            const selection = window.getSelection().toString();
            // do not navigate if the user is selecting text or if the bulk action in progress
            if (selection.length <= 0 && !isLoading) {
              gotoViewInvoiceDetails(record);
            }
          },
        })}
        onChange={handleTableChange}
        pageSize={pageSize}
        prevToken={prevToken}
        nextToken={nextToken}
        virtualPage={virtualPage}
        setVirtualPage={setVirtualPage}
      />
      {showBulkCreateInvoiceModal && (
        <BulkCreateInvoiceModal
          title="Add Invoices"
          open={showBulkCreateInvoiceModal}
          onCancel={() => setShowBulkCreateInvoiceModal(false)}
          onOk={() => setShowBulkCreateInvoiceModal(false)}
        />
      )}
      <AddInvoiceModal
        title="Add Invoice"
        open={showAddInvoiceModal}
        onCancel={() => setShowAddInvoiceModal(false)}
        onOk={() => setShowAddInvoiceModal(false)}
        creditorId={creditorId}
      />
      <DownloadInvoiceModal
        open={showDownloadInvoiceModal}
        onCancel={() => setShowDownloadInvoiceModal(false)}
        onOk={() => setShowDownloadInvoiceModal(false)}
        invoiceIds={selectedRows.map((each) => each.id)}
      />
      {showLogPaymentModal && (
        <LogPaymentModal
          title="Log Payment"
          open={showLogPaymentModal}
          onCancel={() => setShowLogPaymentModal(false)}
          onOk={() => {
            setShowLogPaymentModal(false);
            deselectRows();
          }}
          selectedRows={selectedRows}
        />
      )}
    </>
  ) : null;
}

export default InvoiceList;
