import { resetStateAction } from "common/redux/resetStateAction";
import { useRefreshTokenMutation } from "features/auth/authAPI";
import { selectIsLoggedIn, selectRefreshToken, updateAccessToken } from "features/auth/authSlice";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

function LocalProtectedRoute() {
  const dispatch = useDispatch();
  const navigate = useRef(useNavigate());
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const refreshToken = useSelector(selectRefreshToken);

  const [refreshTokenRequest, { isLoading, isUninitialized }] = useRefreshTokenMutation();

  // fetches refresh token
  const makeRefreshTokenRequest = useCallback(async () => {
    const result = await refreshTokenRequest({ refresh: refreshToken });
    if ("data" in result) {
      dispatch(updateAccessToken(result.data));
    }
    if ("error" in result) {
      // Refresh failed
      dispatch(resetStateAction());
      navigate.current("/login", {
        state: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [dispatch, refreshToken, refreshTokenRequest]);

  // We attempt to refresh the token on load
  useEffect(() => {
    if (refreshToken) {
      makeRefreshTokenRequest();
    } else {
      // Refresh failed
      dispatch(resetStateAction());
      navigate.current("/login", {
        state: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [refreshToken, makeRefreshTokenRequest, dispatch, navigate]);

  // Refresh token every 24 hours
  useEffect(() => {
    const timer = setTimeout(() => makeRefreshTokenRequest(), 1000 * 60 * 60 * 24);
    return () => clearTimeout(timer);
  }, [makeRefreshTokenRequest]);

  // Compose providers that rely on authentication access
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      state={{
        returnTo: window.location.pathname,
      }}
    />
  );
}

export default LocalProtectedRoute;
