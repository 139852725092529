import { getLoginForm } from "features/auth/components/getAuthComponents";
import PageLayout from "layouts/login/pageLayout";

function Login() {
  const LoginForm = getLoginForm();

  return (
    <PageLayout>
      <LoginForm />
    </PageLayout>
  );
}

export default Login;
