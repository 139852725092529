import { TableFormList } from "components/formList";
import {
  useCreateForwardingEntityContactMutation,
  useFetchForwardingEntityContactsQuery,
  useUpdateForwardingEntityContactMutation,
  useDeleteForwardingEntityContactMutation,
} from "features/forwardingEntities/forwardingEntitiesAPI";

export default function ContactStep({ forwardingEntityId }) {
  const { data: forwardingEntityAddresses, isLoading } = useFetchForwardingEntityContactsQuery(
    {
      forwardingEntityId,
    },
    { skip: !forwardingEntityId },
  );
  const [updateForwardingEntityContact] = useUpdateForwardingEntityContactMutation();
  const [deleteForwardingEntityContact] = useDeleteForwardingEntityContactMutation();
  const [createForwardingEntityContact] = useCreateForwardingEntityContactMutation();

  const columns = [
    {
      dataIndex: "name",
      editable: true,
      inputType: "text",
      title: "Name",
      rules: [
        {
          required: true,
          pattern: /^[a-zA-Z'"\-\d ]{2,30}$/,
          message: "Name must be alphanumeric",
        },
      ],
    },
    {
      dataIndex: "role",
      render: (_, record) =>
        ({
          account_manager: "Account Manager",
          accountant: "Accountant",
          owner: "Owner",
          primary_contact: "Primary Contact",
          secondary_contact: "Secondary Contact",
          senior_management: "Senior Management",
          other: "Other",
        }[record.role]),
      editable: true,
      inputType: "select",
      rules: [{ required: true, message: "Please select role" }],
      options: [
        { label: "Account Manager", value: "account_manager" },
        { label: "Accountant", value: "accountant" },
        { label: "Owner", value: "owner" },
        { label: "Primary Contact", value: "primary_contact" },
        { label: "Secondary Contact", value: "secondary_contact" },
        { label: "Senior Management", value: "senior_management" },
        { label: "Other", value: "other" },
      ],
      title: "Role",
    },
    {
      dataIndex: "workPhone",
      editable: true,
      inputType: "text",
      title: "Work Phone",
      rules: [
        {
          pattern:
            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: "Enter a valid phone number",
        },
      ],
    },
    {
      dataIndex: "cellPhone",
      editable: true,
      inputType: "text",
      rules: [
        {
          pattern:
            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: "Enter a valid phone number",
        },
      ],
      title: "Cell Phone",
    },
    {
      dataIndex: "email",
      editable: true,
      inputType: "text",
      rules: [
        {
          pattern:
            /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i,
          message: "Enter a valid email",
        },
      ],
      title: "Email",
    },
    {
      dataIndex: "fax",
      editable: true,
      inputType: "text",
      rules: [
        {
          pattern:
            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: "Enter a valid phone number",
        },
      ],
      title: "Fax",
    },
    {
      dataIndex: "notes",
      editable: true,
      inputType: "text",
      title: "Notes",
    },
  ];

  return (
    <TableFormList
      loading={isLoading}
      columns={columns}
      data={forwardingEntityAddresses}
      onDelete={(contact) =>
        deleteForwardingEntityContact({
          forwardingEntityId,
          contact,
        })
      }
      onSave={(contact) => {
        const action = contact.id ? updateForwardingEntityContact : createForwardingEntityContact;
        return action({
          forwardingEntityId,
          contact,
        });
      }}
    />
  );
}
