import { downloadFile } from "common/utils";
import api, { transformCursorResponse, transformRequest, transformResponse } from "services/api";

export const invoiceApi = api
  .enhanceEndpoints({
    addTagTypes: ["Invoices", "InvoiceItems", "InvoicePayments", "FileDownloads"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchInvoices: build.query({
        query: ({ creditorId, ...restParams }) => ({
          url: `/api/v1/core/invoices/`,
          method: "POST",
          body:
            creditorId === null || creditorId === undefined
              ? transformRequest(restParams)
              : transformRequest({ creditorId, ...restParams }),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: ({ results }) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Invoices", id })),
                { type: "Invoices", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Invoices', id: 'LIST' }` is invalidated
              [{ type: "Invoices", id: "LIST" }],
      }),
      fetchSupportedFilters: build.query({
        query: (payload) => ({
          url: `/api/v1/core/invoices/supported-filters/`,
          method: "GET",
          params: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      fetchInvoice: build.query({
        query: (payload) => `/api/v1/core/invoices/${payload.invoiceId}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) =>
          result
            ? // successful query
              [{ type: "Invoices", id: result.id }]
            : [],
      }),
      createInvoice: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/invoices/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Invoices", id: "LIST" }],
      }),
      bulkCreateInvoices: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/invoices/bulk-create/",
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Invoices", id: "LIST" }],
      }),
      bulkPreviewInvoices: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/invoices/preview-bulk-create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      bulkPublishInvoices: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/invoices/bulk-publish/",
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Invoices", id: "LIST" }],
      }),
      bulkUpdateInvoices: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/invoices/bulk-update/",
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Invoices", id: "LIST" }],
      }),
      bulkDeleteInvoices: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/invoices/bulk-delete/",
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [
          { type: "Invoices", id: "LIST" },
          { type: "InvoicePayments", id: "LIST" },
        ],
      }),
      postPayment: build.mutation({
        query: ({ invoiceId, payload }) => ({
          url: `/api/v1/core/invoices/${invoiceId}/log-payment/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Invoices", id: arg.invoiceId },
          { type: "InvoicePayments", id: "LIST" },
        ],
      }),
      publishInvoice: build.mutation({
        query: (invoiceId) => ({
          url: `/api/v1/core/invoices/${invoiceId}/publish/`,
          method: "POST",
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "Invoices", id: arg.invoiceId }],
      }),
      draftInvoice: build.mutation({
        query: (invoiceId) => ({
          url: `/api/v1/core/invoices/${invoiceId}/draft/`,
          method: "POST",
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "Invoices", id: arg.invoiceId }],
      }),
      deleteInvoice: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/invoices/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [
          { type: "Invoices", id: "LIST" },
          { type: "InvoicePayments", id: "LIST" },
        ],
      }),
      fetchInvoiceItems: build.query({
        query: (payload) => ({
          url: `/api/v1/core/invoices/${payload.invoiceId}/items/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: ({ results }) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "InvoiceItems", id })),
                { type: "InvoiceItems", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'InvoiceItems', id: 'LIST' }` is invalidated
              [{ type: "InvoiceItems", id: "LIST" }],
      }),
      fetchInvoicePayments: build.query({
        query: (payload) => ({
          url: "/api/v1/core/invoices/invoice-payments/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "InvoicePayments", id })),
                { type: "InvoicePayments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'InvoicePayments', id: 'LIST' }` is invalidated
              [{ type: "InvoicePayments", id: "LIST" }],
      }),
      deleteInvoicePayment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/invoices/invoice-payments/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "InvoicePayments", id: "LIST" }],
      }),
      updateInvoice: build.mutation({
        query: ({ invoiceId, payload }) => ({
          url: `/api/v1/core/invoices/${invoiceId}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Invoices", id: arg.invoiceId },
          { type: "InvoiceItems", id: "LIST" },
        ],
      }),
      addAdjustment: build.mutation({
        query: ({ invoiceId, payload }) => ({
          url: `/api/v1/core/invoices/${invoiceId}/apply-adjustment/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Invoices", id: arg.invoiceId },
          { type: "InvoiceItems", id: "LIST" },
        ],
      }),
      deleteInvoiceItem: build.mutation({
        query: ({ invoiceId, invoiceItemId }) => ({
          url: `/api/v1/core/invoices/${invoiceId}/items/${invoiceItemId}/delete/`,
          method: "POST",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Invoices", id: arg.invoiceId },
          { type: "InvoiceItems", id: "LIST" },
        ],
      }),
      downloadInvoices: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/invoices/download/`,
          method: "POST",
          body: transformRequest(payload),
          responseHandler: (response) => response.blob(),
        }),
        transformResponse: downloadFile,
      }),
      downloadInvoicesAsync: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/invoices/download-async/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FileDownloads", id: "LIST" }],
      }),
      downloadChecks: build.query({
        query: (payload) => ({
          url: `/api/v1/core/invoices/download-checks/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchInvoicesQuery,
  useLazyFetchInvoicesQuery,
  useFetchInvoiceQuery,
  useFetchInvoiceItemsQuery,
  useFetchInvoicePaymentsQuery,
  useDeleteInvoicePaymentMutation,
  useCreateInvoiceMutation,
  useBulkCreateInvoicesMutation,
  useBulkPublishInvoicesMutation,
  useBulkDeleteInvoicesMutation,
  useBulkUpdateInvoicesMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useAddAdjustmentMutation,
  useDeleteInvoiceItemMutation,
  usePostPaymentMutation,
  usePublishInvoiceMutation,
  useDraftInvoiceMutation,
  useLazyDownloadChecksQuery,
  useBulkPreviewInvoicesMutation,
  useDownloadInvoicesAsyncMutation,
  useDownloadInvoicesMutation,
  useFetchSupportedFiltersQuery,
} = invoiceApi;
