import api, { transformRequest, transformResponse } from "services/api";

export const dataImportAPI = api
  .enhanceEndpoints({
    addTagTypes: ["CreditorPortalImportHistory"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorPortalImportHistory: build.query({
        query: () => ({
          url: `/api/v1/creditor/data-imports/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? [{ type: "CreditorPortalImportHistory", id: "LIST" }]
            : [{ type: "CreditorPortalImportHistory", id: "LIST" }],
      }),
      postCreditorPortalDataImportCreate: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/data-imports/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorPortalImportHistory", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchCreditorPortalImportHistoryQuery,
  usePostCreditorPortalDataImportCreateMutation,
} = dataImportAPI;
