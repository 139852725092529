import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Popconfirm, Row, Space, Tooltip } from "antd";
import { booleanToYesNo, camelToSnakeCase, snakeToCamelCase } from "common/utils";
import { CursorTable } from "components/cursorTable";
import { PageHeader } from "components/pageHeader";
import { SearchWithDropdown } from "components/searchWithDropdown";
import { TriggerModal } from "components/triggerModal";
import { AddCreditorWizard } from "features/addCreditorWizard";
import { TableAppearanceModal } from "features/appearance";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { useUserType } from "features/auth";
import { useFetchMeQuery } from "features/auth/authAPI";
import {
  useDeleteCreditorMutation,
  useFetchCreditorsQuery,
  useLazySearchCreditorsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import CreditorTableFilters, { SIDER_ID } from "features/creditors/components/creditorTableFilters";
import { useFetchFeePlanRuleSetsQuery } from "features/feePlans/feePlansAPI";
import { useFetchTaxPlanRuleGroupsQuery } from "features/taxPlans/taxPlansAPI";
import {
  collectorsWithRolesSelector,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useFetchAllClientUdfCustomFieldsQuery } from "features/userDefinedFields/clientUserDefinedFieldsAPI";
import { useAppearance } from "providers/appearanceProvider";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

const StyledCol = styled(Col)`
  overflow: auto;
`;

const StyledTable = styled(CursorTable)`
  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }
`;

const StyledMoreOutlined = styled(MoreOutlined)`
  font-size: 17px;
`;

const ButtonWithMargin = styled(Button)`
  margin: 0px 10px;
  &:hover {
    background: #f0f0f0;
  }
`;

const INITIAL_PAGE_SIZE = 100;
const TABLE_ID = "clientList";
const INITIAL_ORDER_BY = null;

function Creditors() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreditorWizardView, setIsCreditorWizardView] = useState(false);
  const [virtualPage, setVirtualPage] = useState(1);
  const [prevToken, setPrevToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [currentCreditor, setCurrentCreditor] = useState();
  const { isCreditorUserType } = useUserType();
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  // keeps track of the current slice of creditor summaries to display
  const { data: feePlanRuleSets } = useFetchFeePlanRuleSetsQuery();
  const { data: taxPlanRuleGroups } = useFetchTaxPlanRuleGroupsQuery();
  const {
    data: {
      [PERMISSIONS.CREDITOR__GET]: isCreditorGetAuthorized,
      [PERMISSIONS.CREDITOR__CREATE]: isCreditorCreateAuthorized,
      [PERMISSIONS.CREDITOR__UPDATE]: isCreditorUpdateAuthorized,
      [PERMISSIONS.CREDITOR__DELETE]: isCreditorDeleteAuthorized,
    },
  } = useAuthorizations();
  const { isLoading: isAppearanceLoading, appearance } = useAppearance();
  const { data: me } = useFetchMeQuery();
  const { data: clientUdfCustomFields } = useFetchAllClientUdfCustomFieldsQuery();

  const getOrderBy = useMemo(() => {
    // If orderBy is in the search params, use it first
    if (searchParams.get("orderBy")) {
      return searchParams.get("orderBy");
    }
    // If appearance is loaded and has a defaultSortOrder, use it second
    if (
      appearance &&
      appearance.items?.[TABLE_ID]?.defaultSortOrder?.direction &&
      appearance.items?.[TABLE_ID]?.defaultSortOrder?.column
    ) {
      return `${
        appearance.items?.[TABLE_ID]?.defaultSortOrder?.direction === "ascend" ? "" : "-"
      }${camelToSnakeCase(appearance.items?.[TABLE_ID]?.defaultSortOrder?.column)}`;
    }
    // If all else fails, use the default
    return INITIAL_ORDER_BY;
  }, [appearance, searchParams]);

  const getPageSize = useMemo(() => {
    // If pageSize is in the search params, use it first
    if (searchParams.get("pageSize")) {
      return searchParams.get("pageSize");
    }
    // If appearance is loaded and has a pageSize, use it second
    if (appearance && appearance.items?.[TABLE_ID]?.pageSize) {
      return appearance.items?.[TABLE_ID]?.pageSize;
    }

    // If no page size in search params, use the default
    return INITIAL_PAGE_SIZE;
  }, [appearance, searchParams]);

  const {
    data: creditors,
    isLoading,
    isFetching,
  } = useFetchCreditorsQuery(
    {
      filters,
      nextToken,
      orderBy: getOrderBy,
      pageSize: getPageSize,
      prevToken,
    },
    {
      skip:
        // prevents fetch until appearances are loaded
        isAppearanceLoading,
    },
  );

  const [deleteCreditor] = useDeleteCreditorMutation();

  const importantClientUdfCustomFields = useMemo(
    () => clientUdfCustomFields?.filter((udf) => udf.isImportant),
    [clientUdfCustomFields],
  );

  const handleAddCreditorClick = () => {
    setIsCreditorWizardView(true);
    setCurrentCreditor(null);
  };

  const handleEditCreditorClick = (record) => {
    setIsCreditorWizardView(true);
    setCurrentCreditor(record);
  };

  const handleSetVirtualPage = (newVirtualPage) => {
    setVirtualPage(newVirtualPage);
  };

  const getSortDirection = (key) => {
    if (getOrderBy === key) {
      return "ascend";
    }
    if (getOrderBy === `-${key}`) {
      return "descend";
    }
    return null;
  };

  const setParams = (pageSize, orderBy) => {
    const params = {};
    if (pageSize && pageSize !== "null") {
      params.pageSize = pageSize;
    }

    if (orderBy && orderBy !== "null") {
      params.orderBy = orderBy;
    }

    // @ts-ignore
    setSearchParams(params);
  };

  const handleTableChange = (newPagination, _, sorter) => {
    if (newPagination && Object.keys(newPagination).length > 0) {
      setVirtualPage(newPagination.virtualPage);
      setPrevToken(newPagination.prevToken);
      setNextToken(newPagination.nextToken);
      // This will reset orderBy and pageSize in useMemo
      if (newPagination.pageSize) {
        setParams(newPagination.pageSize, searchParams.get("orderBy"));
      }

      if (newPagination.orderBy) {
        setParams(searchParams.get("pageSize"), newPagination.orderBy);
      }
    }

    if (sorter) {
      const newOrderBy = `${sorter.order === "descend" ? "-" : ""}${camelToSnakeCase(
        sorter.field,
      )}`;
      setVirtualPage(1);
      setPrevToken(null);
      setNextToken(null);
      // This will reset orderBy in useMemo
      setParams(searchParams.get("pageSize"), newOrderBy);
    }
  };

  const preventPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMenuClick = useCallback(({ item, domEvent }) => {
    // This prevents undesired consequences when clicking on a dropdown item
    preventPropagation(domEvent);

    // Call the action handler defined in the items dictionary
    item.props.actionHandler();
  }, []);

  const actionItems = useCallback(
    (record) => [
      {
        label: "View accounts",
        key: "accounts",
        actionHandler: () =>
          navigate("/accounts", {
            state: {
              creditorFilter: record.id,
            },
          }),
      },
    ],
    [navigate],
  );

  const defaultColumns = [
    {
      title: "Client Id",
      dataIndex: "id",
      key: "id",
      width: 125,
      sorter: (a, b) => {
        return a.id > b.id;
      },
      sortOrder: getSortDirection("id"),
      visible: true,
    },
    {
      title: "Client Code",
      dataIndex: "code",
      key: "code",
      width: 150,
      sorter: (a, b) => {
        return a.id > b.id;
      },
      sortOrder: getSortDirection("code"),
      visible: true,
    },
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      sorter: (a, b) => {
        return a.id > b.id;
      },
      render: (_, record) => record.name,
      sortOrder: getSortDirection("name"),
      visible: true,
    },
    {
      title: "Fee Plan Rule Set",
      key: "feePlanRuleSet",
      render: (_, record) =>
        feePlanRuleSets?.find(
          (ruleSet) => ruleSet.id === record?.invoiceConfig?.feePlanAssignmentRulesetId,
        )?.name,
      width: 150,
      visible: true,
    },
    {
      title: "Tax Plan Rule Group",
      key: "taxPlanRuleGroup",
      render: (_, record) =>
        taxPlanRuleGroups?.find(
          (ruleGroup) => ruleGroup.id === record?.invoiceConfig?.taxPlanRuleGroupId,
        )?.name,
      width: 150,
      visible: true,
    },
    {
      title: "Parent Company",
      dataIndex: "parentCompany",
      key: "parentCompany",
      render: (_, record) =>
        (creditors?.results ?? []).find((creditor) => creditor.id === record?.parentId)?.name,
      width: 150,
      visible: true,
    },
    {
      title: "Client Status",
      dataIndex: "status",
      key: "status",
      width: 175,
      sorter: (a, b) => {
        return a.id > b.id;
      },
      sortOrder: getSortDirection("status"),
      visible: true,
    },
    {
      title: "Sales Rep",
      dataIndex: "salesRepId",
      key: "salesRepId",
      hidden: isCreditorUserType,
      width: 175,
      render: (_, record) =>
        collectorFullName(collectors.find((each) => each.id === record?.salesRepId)),
      visible: true,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: 400,
      visible: true,
    },
    {
      title: "Contact Name",
      dataIndex: ["bestContact", "name"],
      key: "contactName",
      width: 150,
      visible: true,
    },
    {
      title: "Contact Business Phone",
      dataIndex: ["bestContact", "workPhone"],
      key: "workPhone",
      width: 200,
      visible: true,
    },
    {
      title: "Contact Cell Phone",
      dataIndex: ["bestContact", "cellPhone"],
      key: "cellPhone",
      width: 200,
      visible: true,
    },
    {
      title: "Contact Email",
      dataIndex: ["bestContact", "email"],
      key: "email",
      width: 150,
      visible: true,
    },
    {
      title: "Contact Fax",
      dataIndex: ["bestContact", "fax"],
      key: "fax",
      width: 150,
      visible: true,
    },
    {
      title: "Run Credit Reporting",
      dataIndex: ["creditReportingConfig", "isEnabled"],
      key: "creditReportingConfig.isEnabled",
      render: (_, record) => booleanToYesNo(record?.creditReportingConfig?.isEnabled),
      width: 200,
      visible: true,
    },
    {
      title: "Payment Method",
      dataIndex: ["invoiceConfig", "paymentMethodType"],
      key: "paymentMethod",
      width: 150,
      visible: true,
    },
    ...(importantClientUdfCustomFields?.map((udf) => ({
      title: udf.name,
      dataIndex: udf.slug,
      key: udf.slug,
      visible: true,
      render: (text, record) => {
        return record.customFields?.[snakeToCamelCase(udf.slug)] || "-";
      },
      width: 150,
      // Currently, sorting by custom fields does not work as expected because our current pagination style
      // (cursor pagination) require a unique field to sort by, and custom fields are not unique.
      // TODO: Undo below comment when we switch the pagination
      // sorter: {
      //   fields: [`debtor__custom_fields__${udf.slug}`],
      // },
    })) ?? []),
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isCreditorUpdateAuthorized && !isCreditorDeleteAuthorized,
      render: (_, record) => (
        <Space size={1}>
          {isCreditorUpdateAuthorized && (
            <Button
              shape="circle"
              icon={<EditOutlined />}
              type="text"
              onClick={(e) => {
                handleEditCreditorClick(record);
              }}
            />
          )}
          {isCreditorDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this creditor?"
              onConfirm={() => deleteCreditor(record)}
            >
              <Button shape="circle" icon={<DeleteOutlined />} type="text" />
            </Popconfirm>
          )}
          <Dropdown
            menu={{
              items: actionItems(record),
              onClick: handleMenuClick,
            }}
            trigger={["click"]}
          >
            <Button shape="circle" type="text">
              <StyledMoreOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
      width: 100,
      visible: true,
    },
  ].filter((column) => !column.hidden);

  const reconciledColumns = reconcileColumns(defaultColumns, appearance?.items?.[TABLE_ID]).filter(
    (column) => column.visible,
  );

  const gotoCreditorProfile = (record) => {
    const url = `/creditors/${record.id}`;

    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  // Needed because the actions column is fixed and virtually rendered so that pointer-events: none does not work
  const isActionsColumn = (element) => {
    return (
      element.classList.contains("ant-table-cell-fix-right") ||
      element.classList.contains("ant-popover") ||
      element.classList.contains("ant-popconfirm") ||
      element.nextSibling === null ||
      element.tagName === "svg" ||
      element.tagName === "path"
    );
  };

  return isCreditorGetAuthorized ? (
    <>
      <StyledRow align="middle" justify="space-between">
        <Space size="small">
          <PageHeader>Clients</PageHeader>
          {!isCreditorWizardView && isCreditorCreateAuthorized && (
            <Button onClick={handleAddCreditorClick} icon={<PlusOutlined />} type="link">
              Add Client
            </Button>
          )}
        </Space>
        <Space size="small">
          {!isCreditorWizardView && (
            <SearchWithDropdown
              searchHook={useLazySearchCreditorsQuery}
              searchResultGetter={(creditor, index) => ({
                label: (
                  <a target="_blank" rel="noopener noreferrer" href={`/creditors/${creditor.id}`}>
                    {creditor.name} (Client ID: {creditor.id})
                  </a>
                ),
                key: index,
              })}
              inputProps={{
                placeholder: "Client Search",
                title: "Search: Enter client ID, name...",
              }}
            />
          )}
          {me?.isStaff && (
            <TriggerModal
              modal={TableAppearanceModal}
              tableId={TABLE_ID}
              defaultColumns={defaultColumns}
            >
              <Tooltip title="Customize Appearance">
                <ButtonWithMargin shape="circle" icon={<SettingOutlined />} type="text" />
              </Tooltip>
            </TriggerModal>
          )}
        </Space>
      </StyledRow>
      {isCreditorWizardView ? (
        <AddCreditorWizard
          onDone={() => setIsCreditorWizardView(false)}
          onCancel={() => setIsCreditorWizardView(false)}
          currentCreditor={currentCreditor}
          setCurrentCreditor={setCurrentCreditor}
        />
      ) : (
        <Row wrap={false}>
          {!appearance?.items?.[SIDER_ID]?.hidePanel && (
            <CreditorTableFilters filters={filters} setFilters={setFilters} />
          )}
          <StyledCol>
            <StyledTable
              sticky
              bordered
              loading={isLoading || isFetching || isAppearanceLoading}
              scroll={{ x: "max-content" }}
              // rowSelection={rowSelection}
              pageSize={getPageSize}
              prevToken={creditors?.meta?.prevToken}
              nextToken={creditors?.meta?.nextToken}
              resultCount={creditors?.meta?.resultCount}
              virtualPage={virtualPage}
              setVirtualPage={handleSetVirtualPage}
              // @ts-ignore
              columns={reconciledColumns}
              dataSource={creditors?.results}
              onRow={(record) => ({
                onClick: (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  // allow users to select text so it does not trigger a click into the row
                  const selection = window.getSelection().toString();
                  // do not navigate if the user is selecting text or if the bulk action in progress
                  if (selection.length <= 0 && !isLoading && !isActionsColumn(e.target)) {
                    gotoCreditorProfile(record);
                  }
                },
              })}
              onChange={handleTableChange}
              // https://github.com/ant-design/ant-design/issues/16747#issuecomment-612047300
              sortOrder={["ascend", "descend", "ascend"]}
            />
          </StyledCol>
        </Row>
      )}
    </>
  ) : null;
}

export default Creditors;
