import api, { transformRequest, transformResponse } from "services/api";

export const debtorApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "DebtorProfiles",
      "DebtorAddresses",
      "DebtorPhoneNumbers",
      "DebtorEmployments",
      "DebtorContacts",
      "DebtorNotes",
      "DebtorSystemLogs",
      "CommunicationLimitsByDebtor",
      "DuplicateDebtorMerge",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtorAddresses: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/addresses/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorAddresses", id })),
                { type: "DebtorAddresses", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorAddresses', id: 'LIST' }` is invalidated
              [{ type: "DebtorAddresses", id: "LIST" }],
      }),
      fetchDebtorPhoneNumbers: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/phone-numbers/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorPhoneNumbers", id })),
                { type: "DebtorPhoneNumbers", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorPhoneNumbers', id: 'LIST' }` is invalidated
              [{ type: "DebtorPhoneNumbers", id: "LIST" }],
      }),
      fetchDebtorEmployments: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/employment/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorEmployments", id })),
                { type: "DebtorEmployments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorEmployments', id: 'LIST' }` is invalidated
              [{ type: "DebtorEmployments", id: "LIST" }],
      }),
      fetchDebtorContacts: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/contacts/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorContacts", id })),
                { type: "DebtorContacts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorContacts', id: 'LIST' }` is invalidated
              [{ type: "DebtorContacts", id: "LIST" }],
      }),
      fetchDebtorEmails: build.query({
        query: (payload) => `/api/v1/common/debtors/${payload.debtorId}/emails/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorEmails", id })),
                { type: "DebtorEmails", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorEmails', id: 'LIST' }` is invalidated
              [{ type: "DebtorEmails", id: "LIST" }],
      }),

      createDebtorAddresses: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/addresses/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAddresses-type and DebtorSystemLogs-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorAddresses", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
          { type: "CommunicationLimitsByDebtor", id: arg.debtorId },
        ],
      }),
      createDebtorPhoneNumber: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/phone-numbers/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorPhoneNumbers-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorPhoneNumbers", id: "LIST" }],
      }),
      createDebtorEmployments: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/employment/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorEmployments-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorEmployments", id: "LIST" }],
      }),
      createDebtorEmail: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/emails/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorEmails-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorEmails", id: "LIST" }],
      }),
      createDebtorContacts: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/contacts/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorContacts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorContacts", id: "LIST" }],
      }),

      updateDebtorAddresses: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/addresses/${payload.addressId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAddresses-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorAddresses", id: "LIST" },
          { type: "CommunicationLimitsByDebtor", id: arg.debtorId },
        ],
      }),
      updateDebtorPhoneNumber: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/phone-numbers/${payload.phoneNumberId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorPhoneNumbers-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorPhoneNumbers", id: "LIST" }],
      }),
      updateDebtorEmployments: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/employment/${payload.employmentId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorEmployments-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorEmployments", id: "LIST" }],
      }),
      updateDebtorEmail: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/emails/${payload.emailId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorEmployments-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorEmails", id: "LIST" }],
      }),
      updateDebtorContacts: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/contacts/${payload.debtorContactId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorContacts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorContacts", id: "LIST" }],
      }),
      updateDebtorNotes: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/notes/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorNotes-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorNotes", id: "LIST" },
          { type: "DebtorProfiles", id: arg.debtorId },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      updateDebtorTags: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/tags/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorProfiles", id: arg.debtorId },
          {
            type: "DebtorProfiles",
            id: "LIST",
          },
        ],
      }),
      deleteDebtorAddresses: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/addresses/${payload.addressId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all DebtorAddresses-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorAddresses", id: "LIST" },
          { type: "CommunicationLimitsByDebtor", id: arg.debtorId },
        ],
      }),
      deleteDebtorPhoneNumber: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/phone-numbers/${payload.phoneNumberId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all DebtorAddresses-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorPhoneNumbers", id: "LIST" }],
      }),
      deleteDebtorEmployment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/employment/${payload.employmentId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [{ type: "DebtorEmployments", id: "LIST" }],
      }),
      archiveDebtorContacts: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/contacts/${payload.contactId}/archive/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all DebtorContacts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "DebtorContacts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      deleteDebtorEmail: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/emails/${payload.emailId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: [{ type: "DebtorEmails", id: "LIST" }],
      }),
      fetchEligibleDebtorsForMerge: build.query({
        query: (payload) => ({
          url: `/api/v1/core/merge-eligible-debtors/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DuplicateDebtorMerge", id })),
                { type: "DuplicateDebtorMerge", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DuplicateDebtorMerge', id: 'LIST' }` is invalidated
              [{ type: "DuplicateDebtorMerge", id: "LIST" }],
      }),
      mergeEligibleDebtors: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/merge-eligible-debtors/${payload.mergeEligibleDebtorsId}/merge/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [
          { type: "DuplicateDebtorMerge", id: "LIST" },
          { type: "DebtorProfiles", id: "LIST" },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateDebtorAddressesMutation,
  useCreateDebtorContactsMutation,
  useCreateDebtorEmploymentsMutation,
  useCreateDebtorPhoneNumberMutation,
  useCreateDebtorEmailMutation,
  useFetchDebtorAddressesQuery,
  useFetchDebtorContactsQuery,
  useFetchDebtorEmploymentsQuery,
  useFetchDebtorEmailsQuery,
  useFetchDebtorPhoneNumbersQuery,
  useUpdateDebtorAddressesMutation,
  useUpdateDebtorContactsMutation,
  useUpdateDebtorEmploymentsMutation,
  useUpdateDebtorPhoneNumberMutation,
  useUpdateDebtorEmailMutation,
  useUpdateDebtorNotesMutation,
  useUpdateDebtorTagsMutation,
  useDeleteDebtorAddressesMutation,
  useDeleteDebtorPhoneNumberMutation,
  useArchiveDebtorContactsMutation,
  useDeleteDebtorEmploymentMutation,
  useDeleteDebtorEmailMutation,
  useFetchEligibleDebtorsForMergeQuery,
  useMergeEligibleDebtorsMutation,
} = debtorApi;
