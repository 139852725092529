import api, { transformRequest, transformResponse } from "services/api";

export const workflowStatesAPI = api
  .enhanceEndpoints({ addTagTypes: ["WorkflowState"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchWorkflowStates: build.query({
        query: () => `/api/v1/core/workflow-states/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "WorkflowState", id })),
                { type: "WorkflowState", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'WorkflowState', id: 'LIST' }` is invalidated
              [{ type: "WorkflowState", id: "LIST" }],
      }),
      updateWorkflowState: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflow-states/${payload.stateId}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all BureauSettings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "WorkflowState", id: "LIST" }],
      }),
      deleteWorkflowState: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflow-states/${payload.stateId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all WorkflowState-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "WorkflowState", id: "LIST" }],
      }),
      createWorkflowState: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflow-states/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all WorkflowState-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "WorkflowState", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchWorkflowStatesQuery,
  useUpdateWorkflowStateMutation,
  useDeleteWorkflowStateMutation,
  useCreateWorkflowStateMutation,
} = workflowStatesAPI;
