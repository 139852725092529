import { Form } from "antd";
import { useFetchAllLegalCustomFieldsQuery } from "features/userDefinedFields/legalCustomFieldsAPI";
import { snakeToCamelCase, udfToComponentMap } from "common/utils";

export default function LegalCustomFieldsStep() {
  const { data: legalCustomFields } = useFetchAllLegalCustomFieldsQuery();
  return (
    <>
      {legalCustomFields?.map((field) => (
        <Form.Item
          key={field.id}
          label={field.name}
          name={["letterVariables", `${snakeToCamelCase(field.slug)}`]}
          rules={[{ required: field.isRequired }]}
        >
          {udfToComponentMap[field.type]}
        </Form.Item>
      ))}
      {legalCustomFields?.length === 0 && <h4>There are no custom legal variables configured.</h4>}
    </>
  );
}
