import { EditOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { useFetchDebtorProfileQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useUpdateDebtorNotesMutation } from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const { TextArea } = Input;

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
  & .ant-input-disabled {
    color: #000 !important;
  }
`;

const StyledHeaderText = styled.h4`
  margin-top: 0;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-evenly;
`;

const StyledUnsavedText = styled.span`
  color: gray;
  font-weight: normal;
  font-size: 12px;
`;

export function DebtorNotesCard() {
  const { debtorId } = useParams();
  const [debtorNote, setDebtorNote] = useState({
    id: null,
    notes: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [updateDebtorNotes, { isLoading: isUpdateLoading }] = useUpdateDebtorNotesMutation();
  const { data: debtorPersonalInfo } = useFetchDebtorProfileQuery({ debtorId });
  const {
    data: {
      [PERMISSIONS.DEBTOR_NOTE__GET]: isGetNotesAuthorized,
      [PERMISSIONS.DEBTOR_NOTE__UPDATE]: isUpdateNotesAuthorized,
    },
  } = useAuthorizations();

  useEffect(() => {
    // Set notes state with the fetched notes
    setDebtorNote({
      id: debtorPersonalInfo?.id,
      notes: debtorPersonalInfo?.notes,
    });
  }, [debtorPersonalInfo]);

  const toggleIsEditing = async () => {
    if (isEditing) {
      const result = await updateDebtorNotes({
        debtorId: debtorPersonalInfo?.id,
        notes: debtorNote.notes,
      });
      if ("data" in result) {
        message.success("Notes saved successfully!");
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleTextChange = (e) => {
    const { value } = e.target;
    setDebtorNote((prev) => ({ ...prev, notes: value }));
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  return isGetNotesAuthorized ? (
    <StyledCard>
      <StyledHeader>
        <StyledHeaderText>
          Notes{" "}
          {debtorNote.notes !== debtorPersonalInfo?.notes ? (
            <StyledUnsavedText>(unsaved)</StyledUnsavedText>
          ) : null}
        </StyledHeaderText>
        {!isEditing ? (
          <Button type="link" icon={<EditOutlined />} onClick={toggleIsEditing} />
        ) : (
          <StyledButtonContainer>
            <Button loading={isUpdateLoading} onClick={toggleIsEditing} type="primary">
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </StyledButtonContainer>
        )}
      </StyledHeader>
      <TextArea
        value={debtorNote.notes || debtorPersonalInfo?.notes}
        onChange={handleTextChange}
        rows={2}
        placeholder="Add Notes"
        disabled={!isUpdateNotesAuthorized || !isEditing}
      />
    </StyledCard>
  ) : null;
}
