import { PageHeader } from "components/pageHeader";
import { MetabaseReportsTable } from "features/metabase";
import PageLayout from "layouts/creditorPortal/dashboard/pageLayout";

function MetabaseReportsPage() {
  return (
    <PageLayout>
      <PageHeader>Metabase Reports</PageHeader>
      <MetabaseReportsTable />
    </PageLayout>
  );
}

export default MetabaseReportsPage;
