import api, { transformMultipart, transformRequest, transformResponse } from "services/api";

export const documentsApi = api
  .enhanceEndpoints({ addTagTypes: ["CreditorPortalDocuments", "CreditorPortalDebtorSystemLogs"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getCreditorPortalDebtorDocuments: build.query({
        query: ({ debtorId }) => `/api/v1/creditor/debtors/${debtorId}/documents/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDocuments", id })),
                { type: "CreditorPortalDocuments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDocuments', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDocuments", id: "LIST" }],
      }),
      deleteCreditorPortalDebtorDocument: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/documents/${payload.documentId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "CreditorPortalDocuments", id: "LIST" }],
      }),
      postCreditorPortalDebtorDocuments: build.mutation({
        query: ({ debtorId, file, ...values }) => ({
          url: `/api/v1/creditor/debtors/${debtorId}/documents/create/`,
          method: "POST",
          body: transformMultipart(values, file),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Documents-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "CreditorPortalDocuments", id: "LIST" },
          { type: "CreditorPortalDebtorSystemLogs", id: "LIST" },
        ],
      }),
    }),
  });

export const {
  useDeleteCreditorPortalDebtorDocumentMutation,
  useGetCreditorPortalDebtorDocumentsQuery,
  useLazyGetCreditorPortalDebtorDocumentsQuery,
  usePostCreditorPortalDebtorDocumentsMutation,
} = documentsApi;
