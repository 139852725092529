import { Form, Input, Modal, Radio, Select, message } from "antd";
import { useGetEmployersQuery } from "features/employersTable/employersAPI";
import {
  useCreateDebtorGarnishmentMutation,
  useUpdateDebtorGarnishmentMutation,
} from "features/garnishments/garnishmentsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchLawsuitJudgmentsQuery } from "features/legal/legalAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

const GARNISHMENT_OPTIONS = [
  {
    label: "Wage",
    value: "wage",
  },
  {
    label: "Bank Account",
    value: "bank_account",
  },
];

function AddGarnishmentModal({ garnishmentRecord, debtorId, title, open, onOk, onCancel }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const [updateGarnishment] = useUpdateDebtorGarnishmentMutation();
  const [createGarnishment] = useCreateDebtorGarnishmentMutation();
  const { data: employers, isLoading: isEmployersLoading } = useGetEmployersQuery();
  const { data: judgments, isLoading: isLawsuitJudgmentsLoading } = useFetchLawsuitJudgmentsQuery(
    { debtorId },
    {
      skip: !debtorId,
    },
  );
  const [form] = Form.useForm();

  const handleOk = async () => {
    const values = form.getFieldsValue();
    const result = garnishmentRecord
      ? await updateGarnishment({ debtorId, ...values })
      : await createGarnishment({ debtorId, ...values });

    if ("data" in result) {
      message.success("Garnishment record saved successfully!");
      onOk();
    }
    if ("error" in result) {
      message.error("An error occurred. Please try again.");
    }
  };

  // See https://ant.design/components/form/ `validateMessages` to see how to customize error
  // messages.
  const validateMessages = {
    required: "This is a required field",
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={handleOk} onCancel={onCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        autoComplete="off"
        validateMessages={validateMessages}
        initialValues={garnishmentRecord}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="lawsuitJudgmentId" label="Judgment" rules={[{ required: true }]}>
          <Select
            showSearch
            filterOption={(input, option) =>
              // @ts-ignore
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={judgments?.map((judgment) => ({
              value: judgment.id,
              label: `ID: ${judgment.id} (Account ID: ${judgment?.accountId})`,
            }))}
            placeholder="Select Judgment..."
            disabled={isLawsuitJudgmentsLoading}
          />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select placeholder="Select One..." options={GARNISHMENT_OPTIONS} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) => {
            const type = getFieldValue("type");

            if (type === "wage")
              return (
                <>
                  <Form.Item name="isExistingEmployer">
                    <Radio.Group>
                      <Radio value>Existing Employer</Radio>
                      <Radio value={false}>Manually Enter Employer Data</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.isExistingEmployer !== currentValues.isExistingEmployer
                    }
                  >
                    {() => {
                      const isExistingEmployer = getFieldValue("isExistingEmployer");

                      if (isExistingEmployer) {
                        return (
                          <Form.Item
                            name="employerId"
                            label="Employer"
                            rules={[{ required: true }]}
                          >
                            <Select
                              showSearch
                              filterOption={(input, option) =>
                                // @ts-ignore
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              options={employers?.map((employment) => ({
                                value: employment.id,
                                label: employment.name,
                              }))}
                              placeholder="Select Employer..."
                              disabled={isEmployersLoading}
                            />
                          </Form.Item>
                        );
                      }

                      if (isExistingEmployer === false) {
                        return (
                          <>
                            <Form.Item
                              name="employerName"
                              label="Employer Name"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Employer Name..." />
                            </Form.Item>
                            <Form.Item
                              name={["employerAddress1"]}
                              label="Employer Address Line 1"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Employer Address..." />
                            </Form.Item>
                            <Form.Item name={["employerAddress2"]} label="Employer Address Line 2">
                              <Input placeholder="Enter Employer Address..." />
                            </Form.Item>
                            <Form.Item
                              name={["employerAddressCity"]}
                              label="Employer City"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Employer City..." />
                            </Form.Item>
                            <Form.Item
                              name={["employerAddressState"]}
                              label="Employer State"
                              rules={[{ required: true }]}
                            >
                              <Select
                                options={constants?.states.map((state) => ({
                                  label: state.display,
                                  value: state.value,
                                }))}
                                placeholder="Select one..."
                              />
                            </Form.Item>
                            <Form.Item
                              name={["employerAddressZipCode"]}
                              label="Employer Zip Code"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Employer Zip Code..." />
                            </Form.Item>
                            <Form.Item
                              name="employerWorkPhoneNumber"
                              label="Employer Work Phone Number"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Employer Work Phone Number..." />
                            </Form.Item>
                            <Form.Item
                              name="employerCellPhoneNumber"
                              label="Employer Cell Phone Number"
                            >
                              <Input placeholder="Enter Employer Cell Phone Number..." />
                            </Form.Item>
                          </>
                        );
                      }
                    }}
                  </Form.Item>
                </>
              );

            if (type === "bank_account")
              return (
                <>
                  <Form.Item name="bankName" label="Bank Name" rules={[{ required: true }]}>
                    <Input placeholder="Enter Bank Name..." />
                  </Form.Item>
                  <Form.Item
                    name="bankRoutingNumber"
                    label="Bank Routing Number"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Bank Routing Number..." />
                  </Form.Item>
                  <Form.Item
                    name="bankAccountNumber"
                    label="Bank Account Number"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Bank Account Number..." />
                  </Form.Item>
                  <Form.Item
                    name={["bankAddress1"]}
                    label="Bank Address Line 1"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Bank Address..." />
                  </Form.Item>
                  <Form.Item name={["bankAddress2"]} label="Bank Address Line 2">
                    <Input placeholder="Enter Bank Address..." />
                  </Form.Item>
                  <Form.Item
                    name={["bankAddressCity"]}
                    label="Bank City"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Bank City..." />
                  </Form.Item>
                  <Form.Item
                    name={["bankAddressState"]}
                    label="Bank State"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={constants?.states.map((state) => ({
                        label: state.display,
                        value: state.value,
                      }))}
                      placeholder="Select one..."
                    />
                  </Form.Item>
                  <Form.Item
                    name={["bankAddressZipCode"]}
                    label="Bank Zip Code"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Bank Zip Code..." />
                  </Form.Item>
                  <Form.Item
                    name="bankPhoneNumber"
                    label="Bank Phone Number"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Bank Phone Number..." />
                  </Form.Item>
                  <Form.Item name="notes" label="Notes">
                    <Input.TextArea placeholder="Enter Notes..." />
                  </Form.Item>
                </>
              );

            return null;
          }}
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default AddGarnishmentModal;
