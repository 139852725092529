import { Button, Col, Form, Row, Select } from "antd";
import PhoneOptionsModal from "features/bulkActions/components/phoneOptionsModal";
import { useState } from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  max-width: 400px !important;
  margin-right: 4px;
`;

export default function SendDialerCampaignSubForm({ setHideParentModal, operationType }) {
  const form = Form.useFormInstance();
  const [dialerOptionsModalOpen, setDialerOptionsModalOpen] = useState(false);

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onConfigureDialerClick = () => {
    const provider = form.getFieldValue(["operations", "sendDialerCampaign", "provider"]);
    if (provider === "tcn") {
      setDialerOptionsModalOpen(true);
      setHideParentModal(true);
    }
  };

  return (
    <>
      <Row align="middle">
        <Col span={12}>
          <Form.Item
            name={["operations", "sendDialerCampaign", "provider"]}
            label="Provider"
            rules={[
              {
                required: true,
                message: "Please select a provider",
              },
            ]}
          >
            <StyledSelect
              showSearch
              filterOption={filterOption}
              popupMatchSelectWidth={false}
              options={[
                {
                  label: "TCN",
                  value: "tcn",
                },
              ]}
              placeholder="Select one..."
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item hidden name={["operations", "sendDialerCampaign", "phoneConfigs"]} />
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev?.operations?.sendDialerCampaign?.provider !==
              curr?.operations?.sendDialerCampaign?.provider
            }
          >
            {({ getFieldValue }) => {
              const provider = getFieldValue(["operations", "sendDialerCampaign", "provider"]);
              const options = getFieldValue(["operations", "sendDialerCampaign", "phoneConfigs"]);
              if (!provider) {
                return null;
              }
              if (!options) {
                return (
                  <Button type="link" onClick={onConfigureDialerClick}>
                    Configure dialer
                  </Button>
                );
              }
              return (
                <Button type="link" onClick={onConfigureDialerClick}>
                  Re-configure dialer
                </Button>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <PhoneOptionsModal
        operationType={operationType}
        open={dialerOptionsModalOpen}
        provider={form.getFieldValue(["operations", "sendDialerCampaign", "provider"])}
        onCancel={() => {
          setDialerOptionsModalOpen(false);
          setHideParentModal(false);
        }}
        onSubmit={(data) => {
          form.setFieldValue(
            ["operations", "sendDialerCampaign", "phoneConfigs"],
            data.phoneConfigs,
          );
          setDialerOptionsModalOpen(false);
          setHideParentModal(false);
        }}
        title="Configure Dialer Campaign"
      />
    </>
  );
}
