import api, { transformRequest, transformResponse } from "services/api";

export const priorityAccountRuleApi = api
  .enhanceEndpoints({
    addTagTypes: ["PriorityAccountRules"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchPriorityAccountRules: build.query({
        query: () => "/api/v1/core/account-priority-rules/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "PriorityAccountRules", id })),
                { type: "PriorityAccountRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'PriorityAccountRules', id: 'LIST' }` is invalidated
              [{ type: "PriorityAccountRules", id: "LIST" }],
      }),
      addPriorityAccountRule: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/account-priority-rules/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "PriorityAccountRules", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const { useAddPriorityAccountRuleMutation, useFetchPriorityAccountRulesQuery } =
  priorityAccountRuleApi;
