import api, { transformRequest, transformResponse } from "services/api";

export const legalApi = api
  .enhanceEndpoints({
    addTagTypes: ["Lawsuits", "LegalFees", "DebtorAccounts", "Judgments", "Allocations"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchLawsuits: build.query({
        query: ({ debtorId }) => `/api/v1/core/debtors/${debtorId}/lawsuits/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Lawsuits", id })),
                { type: "Lawsuits", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Lawsuits', id: 'LIST' }` is invalidated
              [{ type: "Lawsuits", id: "LIST" }],
      }),
      applyJudgment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/apply-judgment/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "Lawsuits", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
          { type: "Judgments", id: "LIST" },
        ],
      }),
      applyAdvancedJudgment: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/apply-judgment-advanced/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error) =>
          error
            ? []
            : [
                { type: "Lawsuits", id: "LIST" },
                { type: "DebtorAccounts", id: "LIST" },
                { type: "Judgments", id: "LIST" },
                { type: "Allocations", id: "LIST" },
              ],
      }),
      fetchJudgments: build.query({
        query: (payload) =>
          `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/judgments/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Judgments", id })),
                { type: "Judgments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Lawsuits', id: 'LIST' }` is invalidated
              [{ type: "Judgments", id: "LIST" }],
      }),
      fetchLawsuitAllocations: build.query({
        query: (payload) =>
          `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/lawsuit-allocations/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Allocations", id })),
                { type: "Allocations", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Lawsuits', id: 'LIST' }` is invalidated
              [{ type: "Allocations", id: "LIST" }],
      }),
      fetchLawsuitJudgments: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/lawsuit-judgments/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Judgments", id })),
                { type: "Judgments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Judgments', id: 'LIST' }` is invalidated
              [{ type: "Judgments", id: "LIST" }],
      }),
      deleteLawsuit: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "Lawsuits", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      updateLawsuit: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "Lawsuits", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      addLawsuit: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/lawsuits/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "Lawsuits", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      fetchLegalFees: build.query({
        query: ({ debtorId, lawsuitId }) =>
          `/api/v1/core/debtors/${debtorId}/lawsuits/${lawsuitId}/legal-fees/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "LegalFees", id })),
                { type: "LegalFees", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Lawsuits', id: 'LIST' }` is invalidated
              [{ type: "LegalFees", id: "LIST" }],
      }),
      addLegalFees: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/lawsuits/${payload.lawsuitId}/legal-fees/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "Lawsuits", id: "LIST" },
          { type: "LegalFees", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorAccounts", id: arg.debtorId },
        ],
      }),
      submitLegalCustomFieldValues: build.mutation({
        query: ({ debtorId, lawsuitId, ...payload }) => ({
          url: `/api/v1/core/debtors/${debtorId}/lawsuits/${lawsuitId}/letter-variables/submit/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Lawsuits-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "Lawsuits", id: "LIST" }],
      }),
      fetchEligibleDebtors: build.query({
        query: ({ debtorId }) => `/api/v1/core/debtors/${debtorId}/lawsuits/eligible-debtors/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorAccounts", id })),
                { type: "DebtorAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorAccounts', id: 'LIST' }` is invalidated
              [{ type: "DebtorAccounts", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchLawsuitsQuery,
  useApplyJudgmentMutation,
  useApplyAdvancedJudgmentMutation,
  useDeleteLawsuitMutation,
  useAddLawsuitMutation,
  useSubmitLegalCustomFieldValuesMutation,
  useUpdateLawsuitMutation,
  useFetchEligibleDebtorsQuery,
  useFetchLegalFeesQuery,
  useAddLegalFeesMutation,
  useFetchJudgmentsQuery,
  useFetchLawsuitJudgmentsQuery,
  useFetchLawsuitAllocationsQuery,
} = legalApi;
