import {
  DownloadOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, FloatButton, Row, Select, Table, Tag, Tooltip } from "antd";
import { DATE_FORMAT } from "common/constants";
import {
  camelToSnakeCase,
  capitalizeFirstLetter,
  formatCurrency,
  formatDate,
  idToObjectMap,
  snakeToCamelCase,
} from "common/utils";
import { CursorTable } from "components/cursorTable";
import { SearchWithDropdown } from "components/searchWithDropdown";
import { TriggerModal } from "components/triggerModal";
import {
  INITIAL_ORDER_BY,
  INITIAL_PAGE_SIZE,
  resetFilters,
  selectAccountsTableSlice,
  setCreditorIds,
  setDailyQueueAccounts,
  setOrderBy,
  setPagination,
  setSelectedRows,
  setVirtualPage,
  setWorklistId,
} from "features/accountsTable/accountsTableSlice";
import {
  useFetchWorklistAccountsQuery,
  useLazySearchDebtorsByTypeQuery,
} from "features/accountsTable/agencyPortal/accountsTableAPI";
import AccountsTableFilters from "features/accountsTable/components/accountsTableFilters";
import AddManualCommercialAccountModal from "features/accountsTable/components/addManualCommercialAccountModal";
import AddManualConsumerAccountModal from "features/accountsTable/components/addManualConsumerAccountModal";
import AssignAgentModal from "features/accountsTable/components/assignAgentModal";
import MergeAccountsModal from "features/accountsTable/components/mergeAccountsModal";
import SelectAddAccountsModeModal from "features/accountsTable/components/selectAddAccountsModeModal";
import { TableAppearanceModal } from "features/appearance";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { TABLE_COLUMNS } from "features/appearance/constants";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  useFetchBackendConstantsQuery,
  useFetchClientSummaryQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useFetchAllAccountUdfCustomFieldsQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useFetchDebtorUdfCustomFieldsQuery } from "features/userDefinedFields/debtorUserDefinedFieldsAPI";
import reconcileColumnsWithWorklist from "features/workList/utils";
import { useFetchWorklistsQuery } from "features/workList/workListAPI";
import moment from "moment-timezone";
import { useAppearance } from "providers/appearanceProvider";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DownloadInHouseLetterModal from "features/documentCenter/components/downloadInHouseLetterModal";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const ButtonWithMargin = styled(Button)`
  margin: 0px 10px;
  &:hover {
    background: #f0f0f0;
  }
`;

const StyledActionContainer = styled(Row)`
  background: #fff;
  height: 48px;
  margin-top: 1px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

const StyledActions = styled(Row)`
  margin-left: 47px;
  border-left: 1px solid #f0f0f0;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledTable = styled(CursorTable)`
  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }

  & .ant-table-cell {
    padding: 4px 8px !important;
  }
`;

const StyledCol = styled(Col)`
  overflow: auto;
`;

const StyledEmptyTable = styled(Table)`
  margin-top: 1px;
  margin-left: 1px;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

const StyledValue = styled.span`
  font-size: 12px;
  color: gray;
`;

const StyledHeader = styled.h2`
  color: #0b3948;
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const HeaderContainerCol = styled(Col)`
  margin-bottom: 12px;
`;

const StyledDivider = styled(Divider)`
  background-color: #cfc1c1;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledSelect = styled(Select)`
  margin-top: 16px;

  .ant-select-selector {
    border: none !important;
    background: none !important;
  }

  .ant-select-selection-item {
    padding-inline-end: 24px;
    font-size: 1.5em;
    font-weight: 600;
  }
`;

const TABLE_ID = "accountWorklist";

export const getDefaultColumns = ({
  agencySettings,
  collectors,
  creditorIdToCreditorMap,
  importantAccountUdfCustomFields,
  importantDebtorUdfCustomFields,
}) => [
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__DEBTOR_ID,
    title: "Debtor ID",
    visible: true,
    dataIndex: "debtorExternalId",
    render: (text, record) => record?.debtorExternalId ?? "-",
    width: 150,
    sorter: {
      fields: ["debtor_id"],
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__CLIENT_REFERENCE_ID,
    title: "Client Reference ID",
    dataIndex: "clientReferenceId",
    visible: true,
    render: (text, record) => record?.clientReferenceId ?? "-",
    width: 200,
    sorter: {},
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__EXTERNAL_ID,
    title: "External ID",
    visible: true,
    dataIndex: "externalId",
    render: (text, record) => record?.externalId ?? "-",
    width: 150,
    sorter: {},
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__FOLLOW_UP_DATE,
    title: "Follow-Up Date",
    visible: true,
    width: 160,
    dataIndex: "followUpDate",
    render: (text, record) => formatDate(record.followUpDate),
    sorter: {
      compare: (a, b) => {
        if (a.followUpDate < b.followUpDate) {
          return -1;
        }
        if (a.followUpDate > b.followUpDate) {
          return 1;
        }
        return 0;
      },
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__DEBTOR,
    title: "Debtor",
    visible: true,
    width: 250,
    dataIndex: "debtorName",
    render: (text, record) => {
      if (record.isNew && record.isPriority) {
        return (
          <>
            <Tag color="red">NEW</Tag>
            <Tag color="green">PRIORITY</Tag>
            <span>{text}</span>
          </>
        );
      }
      if (record.isNew) {
        return (
          <>
            <Tag color="red">NEW</Tag>
            <span>{text}</span>
          </>
        );
      }
      if (record.isPriority) {
        return (
          <>
            <Tag color="green">PRIORITY</Tag>
            <span>{text}</span>
          </>
        );
      }
      return <span>{capitalizeFirstLetter(record.debtorName || record.companyName)}</span>;
    },
    sorter: {
      fields: ["debtor__debtor_contact__first_name"],
      compare: (a, b) => {
        const aName = a.debtorName || a.companyName;
        const bName = b.debtorName || b.companyName;

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      },
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__COLLECTOR_NAME,
    title: "Collector Name",
    visible: true,
    width: 150,
    render: (text, record) =>
      capitalizeFirstLetter(collectorFullName(collectors?.find((x) => x.id === record.assigneeId))),
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LEGAL_STATUS,
    title: "Legal Status",
    visible: true,
    width: 150,
    dataIndex: "legalStatus",
    render: (text, record) => {
      if (record.legalStatus === "judgment") {
        return <Tag color="green">JUDGMENT APPLIED</Tag>;
      }
      if (record.legalStatus === "judgment_applied") {
        return <Tag color="green">JUDGMENT APPLIED</Tag>;
      }
      if (record.legalStatus === "in_litigation") {
        return <Tag color="orange">IN LITIGATION</Tag>;
      }
      return "-";
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__STATUS,
    title: "Status",
    visible: true,
    width: 150,
    dataIndex: "statusName",
    render: (text, record) => {
      if (!text || !record.statusCode) {
        return "None";
      }
      return `${record.statusCode} - ${text}`;
    },
    sorter: {
      fields: ["workflow_task__state__code"],
      compare: (a, b) => {
        const aName = a.statusCode;
        const bName = b.statusCode;

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      },
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__CURRENT_BALANCE,
    title: "Current Balance",
    visible: true,
    width: 300,
    children: [
      {
        title: "Original Amount",
        dataIndex: "originalTotal",
        render: (text) => formatCurrency(text),
        width: 150,
      },
      {
        title: "Total Balance",
        dataIndex: "totalBalance",
        render: (text, record) => formatCurrency(record.totalBalance),
        width: 150,
      },
    ],
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__TURNOVER_DATE,
    title: "Turnover Date",
    visible: true,
    width: 150,
    dataIndex: "turnoverDate",
    render: (text) => formatDate(text),
    sorter: {
      compare: (a, b) => {
        if (a.turnoverDate < b.turnoverDate) {
          return -1;
        }
        if (a.turnoverDate > b.turnoverDate) {
          return 1;
        }
        return 0;
      },
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__EMPLOYMENT,
    title: "Employment",
    visible: true,
    width: 150,
    dataIndex: "debtorEmployer",
    render: (text, record) => record.debtorEmployer || "Unemployed",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__CLIENT,
    title: "Client",
    visible: true,
    width: 400,
    render: (text, record) => {
      const creditor = creditorIdToCreditorMap[record.creditorId];
      if (creditor?.code) {
        return `${creditor?.name} (${creditor?.code})`;
      }
      return creditor?.name ?? "-";
    },
    sorter: {
      fields: ["creditor__name", "creditor__code"],
      compare: (a, b) => {
        const aName = creditorIdToCreditorMap[a.creditorId]?.name;
        const bName = creditorIdToCreditorMap[b.creditorId]?.name;

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      },
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__DEBT_TYPE,
    title: "Debt Type",
    visible: true,
    width: 150,
    render: (text, _) => capitalizeFirstLetter(text),
    dataIndex: "collectionDebtType",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__EXTERNAL_SCORE,
    title: "Score",
    visible: true,
    width: 150,
    sorter: {
      compare: (a, b) => parseFloat(a.score) - parseFloat(b.score),
    },
    dataIndex: "externalScore",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LAST_PAYMENT_DATE,
    title: "Last Payment Date",
    visible: true,
    width: 200,
    dataIndex: "lastPaymentDate",
    render: (text, record) => formatDate(record.lastPaymentDate) ?? "-",
    sorter: {
      compare: (a, b) => {
        if (a.lastPaymentDate < b.lastPaymentDate) {
          return -1;
        }
        if (a.lastPaymentDate > b.lastPaymentDate) {
          return 1;
        }
        return 0;
      },
    },
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LAST_LETTER_SENT_DATE,
    title: "Last Letter Sent Date",
    visible: true,
    width: 200,
    dataIndex: "latestLetterSentAt",
    render: (text, record) => formatDate(record.latestLetterSentAt) ?? "-",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LATEST_LETTER_SENT_NAME,
    title: "Last Letter Sent Name",
    visible: false,
    width: 200,
    dataIndex: "latestLetterSentName",
    render: (text, record) => capitalizeFirstLetter(record.latestLetterSentName) ?? "-",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__NUMBER_OF_LETTER_SENT_TASKS,
    title: "Number of Letters Sent",
    visible: false,
    width: 150,
    dataIndex: "numberOfLetterSentTasks",
    render: (text, record) => record.numberOfLetterSentTasks ?? "-",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LATEST_CALL_DATETIME,
    title: "Last Call Date",
    visible: false,
    width: 200,
    dataIndex: "latestCallDatetime",
    render: (text, record) => formatDate(record.latestCallDatetime) ?? "-",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__NUMBER_OF_CALLS,
    title: "Number of Calls",
    visible: false,
    width: 150,
    dataIndex: "numberOfCalls",
    render: (text, record) => record.numberOfCalls ?? "-",
  },
  {
    key: TABLE_COLUMNS.ACCOUNT_WORKLIST__JUDGMENT_DATE,
    title: "Judgment Date",
    visible: true,
    width: 150,
    dataIndex: "judgmentDate",
    render: (text, record) => formatDate(record.judgmentDate) ?? "-",
  },
  {
    title: "Intelitech Scores",
    key: "intelitechScores",
    visible: agencySettings?.isIntelitechEnabled,
    children: [
      {
        key: TABLE_COLUMNS.ACCOUNT_WORKLIST__INTELITECH_I_SCORE,
        title: "I-Score",
        visible: agencySettings?.isIntelitechEnabled,
        width: 125,
        dataIndex: "intelitechIScore",
        render: (text, record) => record.intelitechIScore ?? "-",
      },
      {
        key: TABLE_COLUMNS.ACCOUNT_WORKLIST__INTELITECH_MEDAL_SCORE,
        title: "Medal-Score",
        visible: agencySettings?.isIntelitechEnabled,
        width: 125,
        dataIndex: "intelitechMedalScore",
        render: (text, record) => record.intelitechMedalScore || "-",
      },
      {
        key: TABLE_COLUMNS.ACCOUNT_WORKLIST__INTELITECH_Q_SCORE,
        title: "Q-Score",
        visible: agencySettings?.isIntelitechEnabled,
        width: 125,
        dataIndex: "intelitechQScore",
        render: (text, record) => record.intelitechQScore ?? "-",
      },
      {
        key: TABLE_COLUMNS.ACCOUNT_WORKLIST__INTELITECH_R_SCORE,
        title: "R-Score",
        visible: agencySettings?.isIntelitechEnabled,
        width: 125,
        dataIndex: "intelitechRScore",
        render: (text, record) => record.intelitechRScore ?? "-",
      },
      {
        key: TABLE_COLUMNS.ACCOUNT_WORKLIST__INTELITECH_FPG_VALUE,
        title: "FPG-Value",
        visible: agencySettings?.isIntelitechEnabled,
        width: 125,
        dataIndex: "intelitechFpgValue",
        render: (text, record) => record.intelitechFpgValue ?? "-",
      },
    ],
  },

  ...(importantAccountUdfCustomFields?.map((udf) => ({
    title: udf.name,
    dataIndex: udf.slug,
    key: udf.slug,
    render: (text, record) => {
      return record.customFields?.[snakeToCamelCase(udf.slug)] || "-";
    },
    width: 150,
    // Currently, sorting by custom fields does not work as expected because our current pagination style
    // (cursor pagination) require a unique field to sort by, and custom fields are not unique.
    // TODO: Undo below comment when we switch the pagination
    // sorter: {
    //   fields: [`custom_fields__${udf.slug}`],
    // },
  })) ?? []),
  ...(importantDebtorUdfCustomFields?.map((udf) => ({
    title: udf.name,
    dataIndex: udf.slug,
    key: udf.slug,
    render: (text, record) =>
      record.debtors // NOTE: This `.debtors` field is no longer returend from the API
        ?.map((debtor) => debtor?.customFields?.[snakeToCamelCase(udf.slug)])
        ?.filter((x) => x !== null && x !== undefined)
        ?.join(",") || "-",
    width: 150,
    // Currently, sorting by custom fields does not work as expected because our current pagination style
    // (cursor pagination) require a unique field to sort by, and custom fields are not unique.
    // TODO: Undo below comment when we switch the pagination
    // sorter: {
    //   fields: [`debtor__custom_fields__${udf.slug}`],
    // },
  })) ?? []),
];

function AccountsTable() {
  const location = useLocation();
  // When creditorFilter is passed in as a location state, we want to set certain filters and then reset location state
  const hasCreditorFilter =
    location.state?.creditorFilter !== null && location.state?.creditorFilter !== undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAssignAccountModalVisible, setAssignAccountModalVisible] = useState(false);
  const [isMergeAccountsModalVisible, setMergeAccountsModalVisible] = useState(false);
  const [isAddAccountModalVisible, setAddAccountModalVisible] = useState(false);
  const [isMergeDebtorsButtonEnabled, setMergeDebtorsButtonEnabled] = useState(false);
  const { data: constants, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();
  const [isAddAccountManualModalVisible, setAddConsumerAccountManuallyModalVisible] =
    useState(false);
  const [isAddCommercialAccountManuallyModalVisible, setAddCommercialAccountManuallyModalVisible] =
    useState(false);
  const { data: clientSummary } = useFetchClientSummaryQuery();
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const { data: collectors } = useFetchCollectorsQuery();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { isLoading: isAppearanceLoading, appearance } = useAppearance();
  const {
    data: worklists,
    isLoading: isWorklistLoading,
    isSuccess: isWorklistSuccess,
  } = useFetchWorklistsQuery();
  const { data: agencySettings } = useFetchAgencyQuery();
  const {
    data: {
      [PERMISSIONS.ACCOUNT_WORKLIST__ASSIGN_COLLECTOR]: isAssignCollectorAuthorized,
      [PERMISSIONS.ACCOUNT_WORKLIST__MERGE_ACCOUNTS]: isMergeAccountsAuthorized,
      [PERMISSIONS.ACCOUNT_WORKLIST__DOWNLOAD_LETTERS]: isDownloadLettersAuthorized,
      [PERMISSIONS.ACCOUNT__CREATE]: isAccountCreateAuthorized,
      [PERMISSIONS.ACCOUNT__GET]: isAccountGetAuthorized,
    },
  } = useAuthorizations();

  // Set the worklistId to the first worklist in the list
  useEffect(() => {
    if (worklists?.length >= 1 && !accountsTableSlice.worklistId) {
      const systemWorklist = worklists.find((worklist) => worklist.isSystem);
      // If there is a system worklist, set that as the default worklist
      dispatch(setWorklistId(systemWorklist ? systemWorklist.id : worklists[0].id));
    }
  }, [accountsTableSlice.worklistId, dispatch, worklists]);

  const currentWorklist = useMemo(
    () => worklists?.find((worklist) => worklist.id === accountsTableSlice.worklistId),
    [accountsTableSlice.worklistId, worklists],
  );

  const { data: accountUdfCustomFields } = useFetchAllAccountUdfCustomFieldsQuery();
  const { data: debtorUdfCustomFields } = useFetchDebtorUdfCustomFieldsQuery();
  const { data: me } = useFetchMeQuery();

  // cherry-pick udf that have "important" set to true
  const importantAccountUdfCustomFields = useMemo(
    () => accountUdfCustomFields?.filter((udf) => udf.isImportant),
    [accountUdfCustomFields],
  );
  const importantDebtorUdfCustomFields = useMemo(
    () => debtorUdfCustomFields?.filter((udf) => udf.isImportant),
    [debtorUdfCustomFields],
  );

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors);
  }, [creditors]);

  const defaultColumns = useMemo(
    () =>
      getDefaultColumns({
        agencySettings,
        collectors,
        creditorIdToCreditorMap,
        importantAccountUdfCustomFields,
        importantDebtorUdfCustomFields,
      }),
    [
      agencySettings,
      collectors,
      creditorIdToCreditorMap,
      importantAccountUdfCustomFields,
      importantDebtorUdfCustomFields,
    ],
  );

  const reconciledWithAppearances = reconcileColumns(
    defaultColumns,
    appearance?.items?.[TABLE_ID],
  ).filter((column) => column.visible);

  const reconciledColumns = reconcileColumnsWithWorklist(
    reconciledWithAppearances,
    currentWorklist?.defaultSort,
  );

  const getOrderBy = useMemo(() => {
    // If the accountsTableSlice.orderBy is set to something besides INITIAL_ORDER_BY, then that means the user must've manually selected a column
    if (accountsTableSlice.orderBy !== null) {
      return accountsTableSlice.orderBy;
    }
    // If worklist sortorder is set, use that

    if (currentWorklist?.defaultSort && reconciledColumns?.length > 0) {
      // account_worklist.column_name split by . to get the field name
      const column = reconciledColumns.find(
        (c) => c.key === currentWorklist.defaultSort.replace(/^-/, ""),
      );

      const order = currentWorklist.defaultSort.startsWith("-") ? "descend" : "ascend";

      if (column?.sorter) {
        const sortFields = column.sorter?.fields ?? [column.dataIndex];
        const getOrderString = (field) =>
          `${order === "descend" ? "-" : ""}${camelToSnakeCase(field)}`;
        const orderBy = sortFields.map(getOrderString).join(",");
        return orderBy;
      }
    }
    // If the defaultSortOrder is set in the appearance, find the table column that is the default sort column.
    const currentSorterFieldNames = appearance?.items?.[TABLE_ID]?.columns.find(
      (column) => column.key === appearance?.items?.[TABLE_ID]?.defaultSortOrder?.column,
    )?.sorterFieldNames;
    if (currentSorterFieldNames) {
      return currentSorterFieldNames
        .map((field) => {
          return `${
            appearance?.items?.[TABLE_ID]?.defaultSortOrder?.direction === "descend" ? "-" : ""
          }${camelToSnakeCase(field)}`;
        })
        .join(",");
    }
    // Otherwise, we default to the INITIAL_ORDER_BY.
    return INITIAL_ORDER_BY;
  }, [
    accountsTableSlice?.orderBy,
    appearance?.items,
    currentWorklist?.defaultSort,
    reconciledColumns,
  ]);

  const getPageSize = useMemo(() => {
    if (accountsTableSlice?.pageSize !== null) {
      return accountsTableSlice?.pageSize;
    }
    if (appearance?.items?.[TABLE_ID]?.pageSize) {
      return appearance?.items?.[TABLE_ID]?.pageSize;
    }
    return INITIAL_PAGE_SIZE;
  }, [accountsTableSlice?.pageSize, appearance?.items]);

  const onPaginationChange = (page, pageSize) => {
    dispatch(setPagination({ virtualPage: 1, prevToken: null, nextToken: null, pageSize }));
  };

  const { isLoading: isTableLoading, isFetching: isTableFetching } = useFetchWorklistAccountsQuery(
    {
      orderBy: getOrderBy,
      pageSize: getPageSize,
      nextToken: accountsTableSlice.nextToken,
      prevToken: accountsTableSlice.prevToken,
      worklistId: accountsTableSlice.worklistId,
      filters: {
        ...accountsTableSlice.filters,
        followUpStartDate:
          accountsTableSlice.isShowDailyQueue && !accountsTableSlice.filters.followUpStartDate
            ? null
            : accountsTableSlice.filters.followUpStartDate,
        followUpEndDate:
          accountsTableSlice.isShowDailyQueue && !accountsTableSlice.filters.followUpEndDate
            ? moment().startOf("day").format(DATE_FORMAT)
            : accountsTableSlice.filters.followUpEndDate,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      // If creditorFilter is passed in, skip the query
      skip: hasCreditorFilter || isAppearanceLoading || isWorklistLoading,
    },
  );

  useEffect(() => {
    // If creditorFilter is passed in, reset all filters and set creditorIds
    if (hasCreditorFilter) {
      dispatch(resetFilters());
      dispatch(setDailyQueueAccounts(false));
      dispatch(setCreditorIds([location.state.creditorFilter]));
      // reset the state in the history so that when user clicks back, it will not be stuck on the same page
      navigate(location.pathname, { replace: true });
    }
  }, [dispatch, location.pathname, hasCreditorFilter, navigate, location.state?.creditorFilter]);

  const handleSetVirtualPage = (newVirtualPage) => {
    dispatch(setVirtualPage(newVirtualPage));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination && Object.keys(pagination).length > 0) {
      dispatch(setPagination(pagination));
    }

    if (sorter) {
      if (!sorter.column) {
        return dispatch(setOrderBy(null));
      }
      // In case custom sort is specified
      const sortFields = sorter.column?.sorter?.fields;
      // Converts field name, ie "name" to orderString, ie "-name"
      const getOrderString = (field) =>
        `${sorter.order === "descend" ? "-" : ""}${camelToSnakeCase(field)}`;
      // If custom sort is specified, use that, otherwise use default sort
      const orderBy = sortFields
        ? sortFields.map(getOrderString).join(",")
        : getOrderString(sorter.field);
      dispatch(setOrderBy(orderBy));
    }
  };

  const onAssignClick = () => {
    setAssignAccountModalVisible(true);
  };

  const onMergeAccountsModalOk = async () => {
    dispatch(setSelectedRows([]));
    setMergeAccountsModalVisible(false);
  };

  const onMergeAccountsModalCancel = () => {
    setMergeAccountsModalVisible(false);
  };

  useEffect(() => {
    const debtorIds = accountsTableSlice.selectedRows.map((row) => row.debtorId);
    setMergeDebtorsButtonEnabled(debtorIds.length === 2 && debtorIds[0] !== debtorIds[1]);
  }, [accountsTableSlice.selectedRows, accountsTableSlice?.filteredAccounts]);

  const onMergeClick = () => {
    setMergeAccountsModalVisible(true);
  };

  const onSelectedAccountChange = (_, selectedRows) => {
    dispatch(setSelectedRows(selectedRows));
  };

  const onAssignAgentOk = () => {
    dispatch(setSelectedRows([]));
    setAssignAccountModalVisible(false);
  };

  const onAssignAgentCancel = () => {
    setAssignAccountModalVisible(false);
  };

  const onSelectAddAccountsModeOk = async (isConsumerUpload) => {
    if (isConsumerUpload) {
      setAddConsumerAccountManuallyModalVisible(true);
    } else {
      setAddCommercialAccountManuallyModalVisible(true);
    }
    setAddAccountModalVisible(false);
  };

  const onSelectAddAccountsModeCancel = () => {
    setAddAccountModalVisible(false);
  };

  const rowSelection = {
    selectedRowKeys: accountsTableSlice.selectedRows.map((row) => row.key),
    onChange: onSelectedAccountChange,
    preserveSelectedRowKeys: true,
  };
  const hasSelected = accountsTableSlice.selectedRows.length > 0;

  const showSelectAddAccountsModeModal = () => {
    setAddAccountModalVisible(true);
  };

  const onAddConsumerAccountManualModalOk = () => {
    setAddConsumerAccountManuallyModalVisible(false);
  };

  const onAddConsumerAccountManualModalCancel = () => {
    setAddConsumerAccountManuallyModalVisible(false);
  };

  const onAddCommercialAccountManualModalOk = () => {
    setAddCommercialAccountManuallyModalVisible(false);
  };

  const onAddCommercialAccountManualModalCancel = () => {
    setAddCommercialAccountManuallyModalVisible(false);
  };

  const gotoDebtorProfile = (record) => {
    const url = `/debtors/${record.debtorId}`;
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const currentClient = clientSummary?.currentClient;
  const salesRep = clientSummary?.rootClient?.salesRep ?? clientSummary?.currentClient?.salesRep;

  return isAccountGetAuthorized ? (
    <div>
      <FloatButton.BackTop />
      <Row align="middle" justify="space-between">
        <HeaderContainerCol flex="auto">
          <Row align="middle">
            {worklists?.length > 0 && isWorklistSuccess && (
              <StyledSelect
                placeholder="Select Worklist..."
                options={[
                  ...(worklists?.map((worklist) => ({
                    label: worklist.name,
                    value: worklist.id,
                  })) ?? []),
                ]}
                value={accountsTableSlice?.worklistId}
                onChange={(id) => dispatch(setWorklistId(id))}
                disabled={worklists.length === 1}
                popupMatchSelectWidth={false}
              />
            )}
            {isAccountCreateAuthorized && (
              <StyledButton
                onClick={() => showSelectAddAccountsModeModal()}
                icon={<PlusOutlined />}
                type="link"
              >
                Add Account
              </StyledButton>
            )}
          </Row>
          {(currentClient?.bestContact || currentClient?.bestAddress) && (
            <>
              <StyledValue>
                <strong>Client:</strong> {currentClient?.name || "-"}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>
                Address: {currentClient?.bestAddress?.address1},{" "}
                {currentClient?.bestAddress?.address2
                  ? `${currentClient?.bestAddress?.address2},`
                  : ""}{" "}
                {currentClient?.bestAddress?.city}, {currentClient?.bestAddress?.state}{" "}
                {currentClient?.bestAddress?.zipCode}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>
                Phone:{" "}
                {currentClient?.bestContact?.cellPhone ||
                  currentClient?.bestContact?.workPhone ||
                  "-"}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>Email: {currentClient?.bestContact?.email || "-"}</StyledValue>
            </>
          )}
          {salesRep && (
            <div>
              <StyledValue>
                <strong>Sales Rep:</strong>{" "}
                {salesRep?.firstName || salesRep?.lastName
                  ? `${salesRep?.firstName} ${salesRep?.lastName}`
                  : "--"}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>
                Phone: {salesRep?.phoneNumber || "-"}
                {salesRep?.phoneExtension ? ` Ext. ${salesRep?.phoneExtension || ""}` : ""}
              </StyledValue>
            </div>
          )}
        </HeaderContainerCol>
        <SearchWithDropdown
          // @ts-ignore
          initialValues={{ queryFieldType: "consumer_name" }}
          searchHook={useLazySearchDebtorsByTypeQuery}
          selectProps={{
            loading: isConstantsLoading,
            options: constants?.debtorSearchCriteria?.map((criteria) => ({
              label: criteria.display,
              value: criteria.value,
            })) ?? [
              {
                label: "...",
                value: "consumer_name",
              },
            ],
          }}
          searchResultGetter={(debtor, index) => ({
            label: (
              <a target="_blank" rel="noopener noreferrer" href={`/debtors/${debtor.id}`}>
                {collectorFullName(debtor)} (Debtor ID: {debtor.id}, Account IDs: [
                {debtor.accountIds.join(", ")}])
              </a>
            ),
            key: index,
          })}
          inputProps={{
            placeholder: "Debtor Search",
            title: "Search: Enter debtor ID, SSN, name, or account #...",
          }}
        />
      </Row>
      <Row wrap={false}>
        <AccountsTableFilters />
        <StyledCol>
          <Row wrap={false}>
            <StyledActionContainer>
              <StyledActions wrap={false}>
                <StyledDiv>
                  {isAssignCollectorAuthorized && (
                    <Tooltip
                      title={
                        !hasSelected
                          ? "Select account(s) to assign"
                          : `Assign ${accountsTableSlice.selectedRows.length} Accounts`
                      }
                    >
                      <ButtonWithMargin
                        shape="circle"
                        icon={<UserSwitchOutlined />}
                        type="text"
                        disabled={!hasSelected}
                        onClick={onAssignClick}
                      />
                    </Tooltip>
                  )}
                  {isMergeAccountsAuthorized && (
                    <Tooltip
                      title={
                        accountsTableSlice.selectedRows.length !== 2
                          ? "Select 2 accounts to merge"
                          : `Merge ${accountsTableSlice.selectedRows.length} Accounts`
                      }
                    >
                      <ButtonWithMargin
                        shape="circle"
                        icon={<MergeCellsOutlined />}
                        type="text"
                        disabled={!isMergeDebtorsButtonEnabled}
                        onClick={onMergeClick}
                      />
                    </Tooltip>
                  )}
                  {isDownloadLettersAuthorized && (
                    <TriggerModal
                      modal={DownloadInHouseLetterModal}
                      isDisabled={!hasSelected}
                      accountIds={accountsTableSlice.selectedRows.map((row) => row.id)}
                      useAsyncJob={false}
                    >
                      <Tooltip title="Select an account(s) to download letters">
                        <ButtonWithMargin
                          disabled={!hasSelected}
                          shape="circle"
                          icon={<DownloadOutlined />}
                          type="text"
                        />
                      </Tooltip>
                    </TriggerModal>
                  )}
                </StyledDiv>
                {me?.isStaff && (
                  <TriggerModal
                    modal={TableAppearanceModal}
                    tableId={TABLE_ID}
                    defaultColumns={defaultColumns}
                  >
                    <Tooltip title="Customize Appearance">
                      <ButtonWithMargin shape="circle" icon={<SettingOutlined />} type="text" />
                    </Tooltip>
                  </TriggerModal>
                )}
                {/* <Dropdown
                  menu={{
                    items,
                    onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp),
                  }}
                  trigger={["click"]}
                >
                  <Button type="text">
                    <StyledMoreOutlined />
                  </Button>
                </Dropdown> */}
              </StyledActions>
            </StyledActionContainer>
          </Row>
          {accountsTableSlice?.filteredAccounts.length === 0 ? (
            // @ts-ignore
            <StyledEmptyTable
              scroll={{ x: "max-content" }}
              columns={reconciledColumns}
              loading={
                isTableLoading || isTableFetching || hasCreditorFilter || isAppearanceLoading
              }
            />
          ) : (
            <StyledTable
              sticky
              bordered
              loading={
                isTableLoading || isTableFetching || hasCreditorFilter || isAppearanceLoading
              }
              rowSelection={rowSelection}
              pageSize={getPageSize}
              prevToken={accountsTableSlice?.resultPrevToken}
              nextToken={accountsTableSlice?.resultNextToken}
              resultCount={accountsTableSlice?.resultCount}
              virtualPage={accountsTableSlice?.virtualPage}
              setVirtualPage={handleSetVirtualPage}
              columns={reconciledColumns}
              dataSource={accountsTableSlice?.filteredAccounts}
              onRow={(record) => ({
                onClick: (e) => {
                  // allow users to select text so it does not trigger a click into the row
                  const selection = window.getSelection().toString();
                  if (selection.length <= 0 && !isTableLoading) {
                    gotoDebtorProfile(record);
                  }
                },
              })}
              pagination={{
                onChange: onPaginationChange,
              }}
              onChange={handleTableChange}
              // https://github.com/ant-design/ant-design/issues/16747#issuecomment-612047300
              sortDirections={["ascend", "descend", "ascend"]}
            />
          )}
        </StyledCol>
      </Row>
      {isAddAccountManualModalVisible && (
        <AddManualConsumerAccountModal
          title="Add Consumer Account"
          open={isAddAccountManualModalVisible}
          onOk={onAddConsumerAccountManualModalOk}
          onCancel={onAddConsumerAccountManualModalCancel}
        />
      )}
      {isAddCommercialAccountManuallyModalVisible && (
        <AddManualCommercialAccountModal
          title="Add Commercial Account"
          open={isAddCommercialAccountManuallyModalVisible}
          onOk={onAddCommercialAccountManualModalOk}
          onCancel={onAddCommercialAccountManualModalCancel}
        />
      )}
      <MergeAccountsModal
        title="Merge Accounts"
        open={isMergeAccountsModalVisible}
        onOk={onMergeAccountsModalOk}
        onCancel={onMergeAccountsModalCancel}
      />
      <AssignAgentModal
        title="Assign Collector"
        open={isAssignAccountModalVisible}
        onOk={onAssignAgentOk}
        onCancel={onAssignAgentCancel}
      />
      <SelectAddAccountsModeModal
        title="Add Account"
        open={isAddAccountModalVisible}
        onOk={onSelectAddAccountsModeOk}
        onCancel={onSelectAddAccountsModeCancel}
      />
    </div>
  ) : null;
}

export default AccountsTable;
