import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Space } from "antd";
import { useForm } from "antd/lib/form/Form";

function AddEventModal({ open, onOk, onCancel }) {
  const [form] = useForm();

  const handleChange = (value) => {
    console.log(`selected ${form.getFieldValue("users")}`);
    // undefined is added to form.getFieldValue('users') array whenever item is selected
    const users = form.getFieldValue("users");
    users[users.length - 1] = { item: value };
    form.setFieldsValue({ users });
  };

  return (
    <Modal
      maskClosable={false}
      title="Define Event"
      open={open}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form layout="vertical" name="dynamic_form_nest_item" form={form} autoComplete="off">
        <Form.Item
          label="Event Name:"
          name="eventName"
          rules={[{ required: true, message: "Please enter an event name" }]}
        >
          <Input />
        </Form.Item>
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, "item"]}
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                    }
                    rules={[{ required: true, message: "Missing selection" }]}
                  >
                    <span>{index + 1}. </span>
                    <Select
                      placeholder="Select a function or event..."
                      style={{ width: 400 }}
                      onChange={handleChange}
                    >
                      <Select.OptGroup label="Function">
                        <Select.Option key="skiptrace" value="skiptrace">
                          Run Skiptrace
                        </Select.Option>
                        <Select.Option key="creditreport" value="creditreport">
                          Run Credit Report
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Event">
                        <Select.Option key="compliancecheck" value="compliancecheck">
                          Litigious Scrub
                        </Select.Option>
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Workflow
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default AddEventModal;
