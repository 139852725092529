import { Form, Input, Modal, Select } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useAddCourtOfLawMutation } from "features/courtsOfLawTable/courtsOfLawAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function AddCourtOfLawModal({ title, open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [addCourtOfLaw] = useAddCourtOfLawMutation();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onSubmit = async () => {
    const newCourt = await form.validateFields();
    const result = await addCourtOfLaw(newCourt);
    if ("data" in result) {
      onOk();
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      okText="Submit"
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item name="code" label="Court Code" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Court Type" rules={[{ required: true }]}>
          <Select
            options={constants?.courtTypes.map((courtType) => ({
              label: courtType.display,
              value: courtType.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="name" label="Court Name">
          <Input />
        </Form.Item>
        <Form.Item name="department" label="Department">
          <Input />
        </Form.Item>
        <Form.Item name="division" label="Division">
          <Input />
        </Form.Item>
        <Form.Item name="address1" label="Address Line 1" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="address2" label="Address Line 2">
          <Input />
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[{ pattern: /^[^\d]*$/, message: "Enter a valid city" }, { required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="state" label="State" rules={[{ required: true }]}>
          <Select
            filterOption={filterOption}
            showSearch
            options={constants?.states.map((state) => ({
              label: state.display,
              value: state.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="zipCode"
          label="Zip Code"
          rules={[
            {
              required: true,
              pattern: /^\d{5}(-\d{4})?$/,
              message: "Must be a valid zip code",
            },
          ]}
        >
          <Input placeholder="Enter zip code..." maxLength={5} />
        </Form.Item>
        <Form.Item name="county" label="County" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="district" label="District" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
          <Input placeholder="XXX-XXX-XXXX" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>
        <Form.Item name="url" label="URL">
          <Input />
        </Form.Item>
        <Form.Item name="notes" label="Notes / Description">
          <Input />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
