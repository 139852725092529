import { Col, Row, Table, Tag } from "antd";
import { DATE_TIME_FORMAT, PAYMENT_TRANSACTION_STATUS } from "common/constants";
import { toTitleCase } from "common/utils";
import { useGetPaymentTransactionsQuery } from "features/payments/paymentsAPI";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledInfoTitle = styled.div`
  color: grey;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

const StyledErrorText = styled.div`
  color: red;
`;

export default function TransactionsTable({ id: paymentIntentId }) {
  const { debtorId } = useParams();
  const { data: paymentTransactions, isLoading } = useGetPaymentTransactionsQuery({
    debtorId,
    paymentIntentId,
  });

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
      width: 150,
      defaultSortOrder: "descend",
      // NOTE: Transactions are generally processed in order, so by default we sort by latest first.
      sorter: (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10),
      render: (text, record) =>
        record.isSettled ? (
          <>
            <Tag color="green">SETTLEMENT</Tag>
            {text}
          </>
        ) : (
          text
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Processed At",
      dataIndex: "processedAt",
      key: "processedAt",
      width: 210,
      render: (_, record) => moment(record.createdAt).format(DATE_TIME_FORMAT),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (text, record) => `$${record.amount}`,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 150,
      render: (text, _) => {
        return text === PAYMENT_TRANSACTION_STATUS.SUCCESS ? (
          text
        ) : (
          <StyledErrorText>{text}</StyledErrorText>
        );
      },
    },
    {
      title: "Payment method",
      key: "paymentMethod",
      width: 200,
      render: (text, record) => {
        const { type, cardLast4Digits, bankAccountLast4Digits } = record.paymentMethod ?? {};
        if (type === "process_card") {
          return `Card ending in ${cardLast4Digits}`;
        }
        if (type === "process_ach") {
          return `ACH ending in ${bankAccountLast4Digits}`;
        }
        if (type === "paid_to_creditor_legal") {
          return `Paid to Creditor (Legal Payment)`;
        }
        if (type === "forwarding_entity_legal") {
          return `Paid to Forwarding Entity (Legal Payment)`;
        }
        return toTitleCase({ str: type, delimiter: "_" });
      },
    },
    {
      title: "Executed By",
      dataIndex: "executedByEmail",
      key: "executedByEmail",
      width: 300,
    },
    {
      title: "Payment Processor",
      dataIndex: "paymentProvider",
      key: "paymentProvider",
      width: 200,
      render: (text, _) => text || "-",
    },
    {
      title: "Payment Processor Response",
      key: "externalInfo",
      width: 400,
      render: (text, record) =>
        record.isProcessingRequired ? (
          <Col>
            <Row align="middle">
              <StyledInfoTitle>Transaction ID (PNREF):</StyledInfoTitle>
              <StyledInfoValue>{record.extId || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Authorization Code:</StyledInfoTitle>
              <StyledInfoValue>{record.approvalCode || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Result Code:</StyledInfoTitle>
              <StyledInfoValue>{record.extResultCode || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Result Details:</StyledInfoTitle>
              <StyledInfoValue>{record.extResultDetails || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Card Verification (CVV) Code:</StyledInfoTitle>
              <StyledInfoValue>{record.cvvResultCode || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Address Verification (AVS) Code:</StyledInfoTitle>
              <StyledInfoValue>{record.avsResultCode || "-"}</StyledInfoValue>
            </Row>
          </Col>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <>
      <h4>Transaction History</h4>
      <Table
        loading={isLoading}
        bordered
        rowClassName={() => "editable-row"}
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={paymentTransactions}
        pagination={false}
      />
    </>
  );
}
