import { Form } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import { useParams } from "react-router-dom";

export default function LawsuitDateStep({ lawsuitId }) {
  const { debtorId } = useParams();

  return (
    <>
      <Form.Item label="Placement Date" name={["attorney", "placementDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Acknowledgement Date" name={["attorney", "acknowledgementDate"]}>
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Closed Date" name={["attorney", "closeDate"]}>
        <AktDatePicker />
      </Form.Item>
    </>
  );
}
