import api, { transformRequest, transformResponse } from "services/api";

export const templatesApi = api
  .enhanceEndpoints({ addTagTypes: ["EmailTemplates"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getEmailTemplates: build.query({
        query: () => "/api/v1/core/email-templates/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "EmailTemplates", id })),
                { type: "EmailTemplates", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'EmailTemplates', id: 'LIST' }` is invalidated
              [{ type: "EmailTemplates", id: "LIST" }],
      }),
      postEmailTemplates: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/email-templates/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all EmailTemplates-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "EmailTemplates", id: "LIST" }],
      }),
    }),
  });

export const { useGetEmailTemplatesQuery, usePostEmailTemplatesMutation } = templatesApi;
