import { createSelector } from "@reduxjs/toolkit";
import { useFetchWorkflowsQuery } from "features/workflows/workflowAPI";
import { useFetchWorkflowStatesQuery } from "features/workflows/workflowStatesAPI";
import api, { transformResponse } from "services/api";

export const homeApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchBackendConstants: build.query({
      query: () => "/api/v1/common/supported-constants/",
      transformResponse: (response) => transformResponse(response),
    }),
    fetchClientSummary: build.query({
      query: () => "/api/v1/common/users/me/client/summary/",
      transformResponse: (response) => transformResponse(response),
    }),
    fetchCollectors: build.query({
      query: () => "/api/v1/core/users/",
      transformResponse: (response) => transformResponse(response),
    }),
  }),
  overrideExisting: false,
});

// This logic needs to go on the backend
const useFetchAgencyWorkflowStates = () => {
  const { data: workflows = [], isLoading: isWorkflowsLoading } = useFetchWorkflowsQuery();
  const { data: workflowsStates = [], isLoading: isWorkflowsStatesLoading } =
    useFetchWorkflowStatesQuery(
      { workflowId: workflows[0]?.id },
      {
        skip: workflows.length === 0,
      },
    );

  // simulates how RTK query hooks work
  return { data: workflowsStates, isLoading: isWorkflowsStatesLoading || isWorkflowsLoading };
};

export const {
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
  useFetchClientSummaryQuery,
} = homeApi;

export { useFetchAgencyWorkflowStates };

export const collectorsWithRolesSelector = createSelector(
  (res) => res.data,
  // @ts-ignore
  (data) => data?.filter((collector) => collector.groupIds.length > 0) ?? [],
);
