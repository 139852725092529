import { Collapse, FloatButton, Tabs } from "antd";
import { BankruptciesTable } from "features/bankruptcies";
import { CommunicationsTable } from "features/communicationsTable";
import CreditorDebtorAccountsTable from "features/debtorAccountsTable/creditorPortal/debtorAccountsTable";
import { SIDER_ID } from "features/debtorSider/creditorPortal/debtorSider";
import { DocumentsTable } from "features/documentsTable";
import PaymentCard from "features/payments";
import SystemLogTable from "features/debtorProfile/components/systemLogTable";
import { resetMakeAPayment } from "features/payments/paymentsSlice";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useAppearance } from "providers/appearanceProvider";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  margin-top: 16px;
  background-color: #fff;
`;
const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    align-items: center !important;
    padding-bottom: 0 !important;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
`;

const StyledDiv = styled.div`
  margin-left: ${(props) =>
    `${
      // @ts-ignore
      props.$hidePanel ? 0 : 400
    }px`};
`;

function DebtorProfile() {
  const { debtorId } = useParams();
  const { data: isMakePaymentAuthorized } = useAuthorization(PERMISSIONS.PAYMENT__CREATE);
  const { data: isBankruptcyAuthorized } = useAuthorization(PERMISSIONS.DEBTOR_BANKRUPTCY__GET);
  const { data: isSystemLogAuthorized } = useAuthorization(PERMISSIONS.SYSTEM_LOG__GET);
  const { appearance } = useAppearance();
  const dispatch = useDispatch();

  // Reset "Make a Payment" progress on new Debtor
  useEffect(() => {
    dispatch(resetMakeAPayment());
  }, [dispatch]);

  const overViewTabs = [
    {
      label: "Accounts",
      key: "accounts",
      children: <CreditorDebtorAccountsTable debtorId={debtorId} />,
    },
    {
      label: "Documents",
      key: "documents",
      children: <DocumentsTable debtorId={debtorId} />,
    },
    isBankruptcyAuthorized && {
      label: "Bankruptcy",
      key: "bankruptcy",
      children: <BankruptciesTable debtorId={debtorId} />,
    },
  ];

  const paymentTabs = [
    isMakePaymentAuthorized && {
      label: "Make a Payment",
      key: "paymentPlans",
      children: <PaymentCard />,
    },
  ];

  return (
    <StyledDiv
      // @ts-ignore
      $hidePanel={appearance?.items?.[SIDER_ID]?.hidePanel}
    >
      <FloatButton.BackTop />
      <div>
        <StyledCollapse defaultActiveKey={[1]} bordered={false}>
          <StyledPanel header={<h3>Overview</h3>} key={1}>
            <Tabs items={overViewTabs} />
          </StyledPanel>
        </StyledCollapse>
        <StyledCollapse defaultActiveKey={[2]} bordered={false}>
          <StyledPanel header={<h3>Communications</h3>} key={2}>
            <CommunicationsTable debtorId={debtorId} hasConsumerConsent />
          </StyledPanel>
        </StyledCollapse>
        {isMakePaymentAuthorized && (
          <StyledCollapse defaultActiveKey={[3]} bordered={false}>
            <StyledPanel header={<h3>Payments</h3>} key={3}>
              <Tabs items={paymentTabs} />
            </StyledPanel>
          </StyledCollapse>
        )}
        {isSystemLogAuthorized && (
          <StyledCollapse bordered={false}>
            <StyledPanel header={<h3>System Log</h3>} key={4}>
              <SystemLogTable debtorId={debtorId} />
            </StyledPanel>
          </StyledCollapse>
        )}
      </div>
    </StyledDiv>
  );
}

export default DebtorProfile;
