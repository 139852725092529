import { Form, InputNumber, Select, Table } from "antd";
import { camelToWords } from "common/utils";
import currency from "currency.js";
import { useState } from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledTable = styled(Table)`
  margin-bottom: 12px;
`;

function AccrualAmountSelect({ paymentCategories }) {
  const form = Form.useFormInstance();
  const initialDataSource = Object.entries(paymentCategories).map(([category, fullAmount]) => {
    return {
      key: category,
      category,
      payment: "none",
      amount: 0,
      fullAmount,
    };
  });

  const totalLine = {
    key: "total",
    category: "Total",
  };

  const [dataSource, setNewDataSource] = useState([...initialDataSource, totalLine]);

  const onSetRadio = (record) => (value) => {
    const newDataSource = dataSource.map((data) => {
      if (data.category === record.category) {
        return { ...data, payment: value };
      }
      return data;
    });

    setNewDataSource(newDataSource);

    if (value === "none") {
      // reset category amount to 0
      form.setFieldValue(
        ["judgment", "accountAllocations", 0, "paymentCategories", record.category],
        0,
      );
    } else {
      // reset category amount to the full amount
      form.setFieldValue(
        ["judgment", "accountAllocations", 0, "paymentCategories", record.category],
        record.fullAmount,
      );
    }
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value) => camelToWords(value),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (value, record) => {
        if (record.key !== "total")
          return (
            <StyledSelect onChange={onSetRadio(record)} defaultValue="fullAmount">
              <Select.Option value="fullAmount">
                Full Amount ({currency(record.fullAmount).format()})
              </Select.Option>
              <Select.Option value="partialAmount">Partial Amount</Select.Option>
              <Select.Option value="none">None</Select.Option>
            </StyledSelect>
          );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value, record) => {
        if (record.key !== "total") {
          return (
            <StyledFormItem
              name={["judgment", "accountAllocations", 0, "paymentCategories", record.category]}
              hidden={record.payment !== "partialAmount"}
            >
              <InputNumber
                min="0"
                prefix="$"
                step="0.01"
                max={paymentCategories[record.category]}
              />
            </StyledFormItem>
          );
        }
        return (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              // update total line when any of the payment categories change
              return Object.keys(prevValues.judgment.accountAllocations[0].paymentCategories).some(
                (key) =>
                  prevValues.judgment.accountAllocations[0].paymentCategories[key] !==
                  currentValues.judgment.accountAllocations[0].paymentCategories[key],
              );
            }}
          >
            {({ getFieldValue, setFieldValue }) => {
              const total = Object.values(
                getFieldValue(["judgment", "accountAllocations", 0, "paymentCategories"]),
              ).reduce((acc, curr) => acc.add(curr), currency(0, { precision: 4 })).value;

              setFieldValue(["judgment", "accountAllocations", 0, "judgmentPrincipal"], total);

              return (
                <StyledFormItem name={["judgment", "accountAllocations", 0, "judgmentPrincipal"]}>
                  <InputNumber min="0" prefix="$" step="0.01" disabled />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  return <StyledTable columns={columns} dataSource={dataSource} pagination={false} />;
}

export default AccrualAmountSelect;
