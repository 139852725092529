import { useFetchAppearancesQuery } from "features/appearance/appearanceAPI";
import { useFetchMeQuery } from "features/auth/authAPI";
import { createContext, useContext, useMemo } from "react";

const DEFAULT_CONTEXT_VALUE = {};

const AppearanceContext = createContext(DEFAULT_CONTEXT_VALUE);
AppearanceContext.displayName = "AppearanceContext";

function AppearanceProvider({ children }) {
  const { data: appearances, isLoading: isLoadingAppearances } = useFetchAppearancesQuery();
  const { data: me, isLoading: isMeLoading } = useFetchMeQuery();

  const appearanceConfigs = useMemo(() => {
    const appearance = appearances?.find((a) => a.id === me?.appearanceId);
    return {
      appearance,
      isLoading: isLoadingAppearances || isMeLoading,
    };
  }, [appearances, isLoadingAppearances, isMeLoading, me?.appearanceId]);

  return (
    <AppearanceContext.Provider value={appearanceConfigs}>{children}</AppearanceContext.Provider>
  );
}

export default AppearanceProvider;

export function useAppearance() {
  const context = useContext(AppearanceContext);
  if (context === undefined) {
    throw new Error(`useAppearance must be used within a AppearanceContext.Provider`);
  }
  return context;
}
