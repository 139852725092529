import WorkflowListPage from "pages/workflows/workflowListPage";
import WorkflowProfilePage from "pages/workflows/workflowProfilePage";
import { Route, Routes } from "react-router-dom";

function WorkflowsPage() {
  return (
    <Routes>
      <Route path="/" element={<WorkflowListPage />} />
      <Route path="/:workflowId/" element={<WorkflowProfilePage />} />
    </Routes>
  );
}

export default WorkflowsPage;
