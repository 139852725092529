import config from "app/envConfig";
import DromoUploader from "dromo-uploader-react";
import { useFetchMeQuery } from "features/auth/authAPI";
import styled from "styled-components";

// Dromo license key
// Not a secret, but should be kept in a .env file
const LICENSE_KEY = "6f6ffce4-d058-40d8-b38b-a3a2ff11d457";

// TODO: add real fields
const DEFAULT_FIELDS = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Email",
    key: "email_address",
  },
];

// styled Dromo button
const StyledDromoUploader = styled(DromoUploader)`
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  min-width: 32px;
  height: 32px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 7px;
  padding-right: 7px;
  color: #40a9ff;
  border-color: #40a9ff;

  &:hover {
    color: #ffffff;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
`;

function DromoButton({
  title = "Accounts",
  importIdentifier = "accounts",
  text = "Upload",
  onResults = undefined,
  fields = undefined,
  user = undefined,
  matchingStepHelpText = undefined,
}) {
  const { data: me, isLoading } = useFetchMeQuery();

  if (isLoading) {
    return null;
  }

  return (
    <StyledDromoUploader
      licenseKey={LICENSE_KEY}
      fields={fields ?? DEFAULT_FIELDS}
      user={
        user ?? {
          id: me.id.toString(),
          companyId: me.agencyId.toString(),
          name: me.firstName,
          email: me.email,
        }
      }
      settings={{
        importIdentifier,
        autoMapHeaders: true,
        invalidDataBehavior: "BLOCK_SUBMIT",
        matchingStep: {
          fuzzyMatchHeaders: true, // default
          helpText: matchingStepHelpText,
        },
        styleOverrides: {
          global: {
            textColor: "rgba(0, 0, 0, 0.88)",
            primaryTextColor: "rgba(0, 0, 0, 0.88)",
            customFontFamily:
              "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
          },
          primaryButton: {
            borderRadius: "0.25rem",
            backgroundColor: "#1677ff",
            textColor: "#ffffff",
            border: "1px solid #1677ff",
            hoverBackgroundColor: "#1677ff",
            hoverTextColor: "#fffff",
            hoverBorder: "1px solid #1677ff",
          },
          secondaryButton: {
            borderRadius: "0.25rem",
            backgroundColor: " #ffffff",
            textColor: "#40a9ff",
            border: "1px solid #40a9ff",
            hoverBackgroundColor: " #ffffff",
            hoverTextColor: "#fffff",
            hoverBorder: "1px solid #40a9ff",
          },
          tertiaryButton: {
            borderRadius: "0.25rem",
            backgroundColor: " #ffffff",
            textColor: "#40a9ff",
            border: "1px solid #40a9ff",
            hoverBackgroundColor: " #ffffff",
            hoverTextColor: "#fffff",
            hoverBorder: "1px solid #40a9ff",
          },
        },
        developmentMode: config.aktosEnv !== "prod",
        backendSyncMode: "FULL_DATA",
        title,
        version: "v2",
      }}
      onResults={
        onResults ??
        ((response) =>
          // Do something with the data here
          console.log("Response:", response))
      }
    >
      {text}
    </StyledDromoUploader>
  );
}

export default DromoButton;
