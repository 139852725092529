import { Col, Row, Table } from "antd";
import styled from "styled-components";

import { useGetEmailTemplatesQuery } from "features/emailTemplates/emailTemplatesAPI";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

function EmailTemplatesTable() {
  const { data: emailTemplates, isLoading } = useGetEmailTemplatesQuery();

  const columns = [
    {
      title: "External Template ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];

  return (
    <Col>
      <h4>Email</h4>
      <Row align="middle">
        <span>Note: a SendGrid account is required to create email templates.</span>
      </Row>
      <StyledTable bordered loading={isLoading} columns={columns} dataSource={emailTemplates} />
    </Col>
  );
}

export default EmailTemplatesTable;
