import { DatePicker } from "antd";
import { DATE_FORMAT } from "common/constants";
import moment from "moment-timezone";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { useCallback, useMemo } from "react";

const MomentInfusedDateRangePicker = DatePicker.generatePicker(momentGenerateConfig).RangePicker;

function AktDateRangePicker(props) {
  const { value, onChange, ...rest } = props;

  // Allows for override of the format
  const format = rest.format ?? DATE_FORMAT;

  // We guess the format of the input, and if it's not valid, we try again without the format
  const bestGuessMoment = useCallback(
    (input) => {
      let momentObject = moment(input, format);
      if (!momentObject.isValid()) {
        momentObject = moment(input);
      }
      return momentObject;
    },
    [format],
  );

  const innerValue = useMemo(() => {
    if (value === null) return null;
    if (value === undefined) return undefined;
    if (!Array.isArray(value)) return null;
    const [val1, val2] = value;

    return [
      bestGuessMoment(val1).isValid() ? bestGuessMoment(val1) : null,
      bestGuessMoment(val2).isValid() ? bestGuessMoment(val2) : null,
    ];
  }, [bestGuessMoment, value]);

  const innerOnChange = useCallback(
    (val) => {
      if (val === null) return onChange(null);
      if (val === undefined) return onChange(undefined);
      if (!Array.isArray(val)) return null;
      const [val1, val2] = val;

      return onChange([
        val1?.isValid() ? val1.format(format) : null,
        val2?.isValid() ? val2.format(format) : null,
      ]);
    },
    [format, onChange],
  );

  return (
    <MomentInfusedDateRangePicker
      {...rest}
      value={innerValue}
      onChange={innerOnChange}
      format={DATE_FORMAT}
    />
  );
}

export default AktDateRangePicker;
