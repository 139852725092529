import { Form, Input, Modal } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktPercentageInput } from "components/aktPercentageInput";
import {
  useAddSettlementRuleMutation,
  useUpdateSettlementRuleMutation,
} from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function SettlementRuleModal({ title, selectedRule, onOk, onCancel }) {
  const [form] = Form.useForm();
  const [addSettlementRule] = useAddSettlementRuleMutation();
  const [updateSettlementRule] = useUpdateSettlementRuleMutation();

  const onSubmit = async () => {
    const newRule = await form.validateFields();
    const result = selectedRule
      ? await updateSettlementRule(newRule)
      : await addSettlementRule(newRule);
    if ("data" in result) {
      await onOk();
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const onModalCancel = async () => {
    form.resetFields();
    await onCancel();
  };

  return (
    <Modal maskClosable={false} title={title} open onOk={onSubmit} onCancel={onModalCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        initialValues={selectedRule}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="thresholdPercentage" label="Threshold Percentage">
          <AktPercentageInput />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
