import { createSlice } from "@reduxjs/toolkit";
import config from "app/envConfig";
import { loadStateWithKey, saveStateWithKey } from "common/redux/redux-utils";

const initialState = config.useAuth0
  ? {
      accessToken: "",
    }
  : {
      accessToken: "",
      refreshToken: "",
    };

export const LOCAL_STORAGE_KEY = config.useAuth0 ? "rtk:auth0" : "rtk:auth";
const saveState = saveStateWithKey(LOCAL_STORAGE_KEY);
export const loadAuthSlice = () => ({
  ...initialState,
  ...loadStateWithKey(LOCAL_STORAGE_KEY),
});

const localAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateTokens(state, action) {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      saveState(state);
    },
    updateAccessToken(state, action) {
      state.accessToken = action.payload.access;
      saveState(state);
    },
  },
});

export const { updateTokens } = localAuthSlice.actions;

export const remoteAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAccessToken(state, action) {
      state.accessToken = action.payload;
      saveState(state);
    },
  },
});

export const authSlice = config.useAuth0 ? remoteAuthSlice : localAuthSlice;

export const { updateAccessToken } = authSlice.actions;

export const selectIsAccessTokenStoredInRtk = (state) => !!state.auth.accessToken;
export const selectAccessToken = (state) => state.auth.accessToken;

// Local  Only
export const selectIsLoggedIn = (state) => !!state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;

export default authSlice.reducer;
