export default [
  {
    key: "client_reference_id",
    label: "Account Client Reference ID",
    type: "string",
    validators: [
      { validate: "unique_case_insensitive" },
      { validate: "require_without_all", fields: ["external_id"] },
    ],
  },
  {
    key: "external_id",
    label: "External Account ID",
    type: "string",
    validators: [
      { validate: "unique_case_insensitive" },
      { validate: "require_without_all", fields: ["client_reference_id"] },
    ],
  },
];
