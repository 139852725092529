import api, { transformRequest, transformResponse } from "services/api";

export const communicationMethodsApi = api
  .enhanceEndpoints({ addTagTypes: ["CommunicationMethods"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCommunicationMethods: build.query({
        query: () => "/api/v1/core/communication/methods/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CommunicationMethods", id })),
                { type: "CommunicationMethods", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CommunicationMethods', id: 'LIST' }` is invalidated
              [{ type: "CommunicationMethods", id: "LIST" }],
      }),
      fetchCommunicationMethodPreview: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/methods/${payload.communicationMethodId}/preview/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CommunicationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationMethods", id: "LIST" }],
      }),
      createCommunicationMethod: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/communication/methods/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CommunicationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationMethods", id: "LIST" }],
      }),
      updateCommunicationMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/methods/${payload.communicationMethodId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CommunicationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationMethods", id: "LIST" }],
      }),
      archiveCommunicationMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/methods/${payload.communicationMethodId}/archive/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all CommunicationMethods-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationMethods", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchCommunicationMethodsQuery,
  useFetchCommunicationMethodPreviewMutation,
  useCreateCommunicationMethodMutation,
  useUpdateCommunicationMethodMutation,
  useArchiveCommunicationMethodMutation,
} = communicationMethodsApi;
