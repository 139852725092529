import api, {
  replaceEmptyArraysWithNulls,
  transformCursorResponse,
  transformRequest,
  transformResponse,
} from "services/api";

export const accountsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "DebtorAccounts",
      "Accounts",
      "DebtorProfiles",
      "DebtorSystemLogs",
      "CreditorGroup",
      "DuplicateDebtorMerge",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchWorklistAccounts: build.query({
        query: (payload) => ({
          url: `/api/v1/core/accounts/worklist/`,
          method: "POST",
          body: replaceEmptyArraysWithNulls(transformRequest(payload)),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "Accounts", id })),
                { type: "Accounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Accounts', id: 'LIST' }` is invalidated
              [{ type: "Accounts", id: "LIST" }],
      }),
      fetchAccounts: build.query({
        query: (payload) => ({
          url: `/api/v1/core/accounts/`,
          method: "POST",
          body: replaceEmptyArraysWithNulls(transformRequest(payload)),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "Accounts", id })),
                { type: "Accounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Accounts', id: 'LIST' }` is invalidated
              [{ type: "Accounts", id: "LIST" }],
      }),
      assignAccounts: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/accounts/assign/",
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [
          { type: "Accounts", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      postMergeDebtors: build.mutation({
        query: ({ primaryDebtorId, secondaryDebtorId, secondaryDebtorIds }) => ({
          url: `/api/v1/core/debtors/${primaryDebtorId}/merge/`,
          method: "POST",
          body: transformRequest({ secondaryDebtorId, secondaryDebtorIds }),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [
          { type: "Accounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
          { type: "CreditorGroup", id: "LIST" },
          { type: "DuplicateDebtorMerge", id: "LIST" },
        ],
      }),
      postBulkAccountCreate: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/bulk-create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [{ type: "Accounts", id: "LIST" }],
      }),
      postBulkAccounts: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/bulk/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [
          { type: "Accounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      postAccount: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [
          { type: "Accounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      searchDebtorsByType: build.query({
        query: (payload) =>
          `/api/v1/core/debtors/search/?query=${payload.query}&query_field_type=${payload.queryFieldType}`,
        transformResponse: (response) => transformResponse(response),
      }),
      searchDebtors: build.query({
        query: (payload) => ({
          url: `/api/v1/core/debtors/search/`,
          method: "GET",
          params: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      fetchDebtorProfile: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/`,
        // @ts-ignore
        transformResponse: (response) => transformResponse({ result: response.debtor }),
        providesTags: (result, error, arg) => [{ type: "DebtorProfiles", id: arg.debtorId }],
      }),
      updateDebtorProfile: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorProfiles", id: arg.id },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      applyBulkActions: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/bulk-delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all Documents-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "Accounts", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchAccountsQuery,
  useFetchWorklistAccountsQuery,
  useLazyFetchAccountsQuery,
  useAssignAccountsMutation,
  usePostMergeDebtorsMutation,
  usePostBulkAccountCreateMutation,
  usePostBulkAccountsMutation,
  usePostAccountMutation,
  useLazySearchDebtorsQuery,
  useLazySearchDebtorsByTypeQuery,
  useFetchDebtorProfileQuery,
  useUpdateDebtorProfileMutation,
  useApplyBulkActionsMutation,
} = accountsApi;
