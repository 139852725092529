import { Col, Row, Table } from "antd";
import CursorPagination from "components/cursorTable/components/cursorPagination";
import styled from "styled-components";

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledTable = styled(Table)`
  cursor: pointer;
`;

export default function CursorTable(props) {
  const {
    children,
    paginationPosition = "right",
    dataSource,
    onChange,
    pageSize,
    nextToken,
    prevToken,
    virtualPage,
    loading,
    ...rest
  } = props;

  const justifyPosition = {
    left: "start",
    center: "center",
    right: "end",
  }[paginationPosition.toLowerCase()];

  const handleOnPaginationChange = (pagination) => {
    onChange(pagination);
  };

  return (
    <>
      <StyledTable
        scroll={{ x: "max-content" }}
        {...rest}
        pagination={false}
        dataSource={dataSource}
        onChange={onChange}
        loading={loading}
      >
        {children}
      </StyledTable>
      <StyledRow justify={justifyPosition}>
        <Col>
          <CursorPagination
            virtualPage={virtualPage}
            nextToken={nextToken}
            prevToken={prevToken}
            onChange={handleOnPaginationChange}
            pageSize={pageSize}
            loading={loading}
          />
        </Col>
      </StyledRow>
    </>
  );
}
