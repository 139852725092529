import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useFetchWorkflowsQuery } from "../workflowAPI";
import WorkflowEventsTable from "./workflowEventsTable";

const StyledCol = styled(Col)`
  margin-left: 120px;
  margin-bottom: 16px;
`;

const StyledColButton = styled(Col)`
  align-self: flex-end;
  margin-right: 12px;
`;

const StyledContainer = styled(Row)`
  margin-bottom: 16px;
`;

const StyledCreditorName = styled.h2`
  margin-bottom: 0;
`;

const StyledValue = styled.span`
  font-size: 12px;
  color: gray;
`;

const StyledVertDivider = styled(Divider)`
  background-color: #000;
`;

export default function WorkflowProfile() {
  const { workflowId } = useParams();
  const { data: workflows } = useFetchWorkflowsQuery();
  const navigate = useNavigate();
  const currentWorkflow = workflows?.find((workflow) => workflow.id.toString() === workflowId);

  const tabItems = [
    {
      label: "Events",
      key: "events",
      children: (
        <StyledContainer>
          <WorkflowEventsTable />
        </StyledContainer>
      ),
    },
  ];

  return (
    <>
      <StyledCol id="overview">
        <Row>
          <StyledColButton>
            <Button
              type="ghost"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size="large"
              onClick={() => navigate("/workflows")}
            />
          </StyledColButton>
          <Col>
            <StyledCreditorName>{currentWorkflow?.name}</StyledCreditorName>
            <Col flex="auto">
              <StyledValue>Workflow ID: {currentWorkflow?.id}</StyledValue>
              <StyledVertDivider type="vertical" />
              <StyledValue>Description: {currentWorkflow?.description}</StyledValue>
            </Col>
          </Col>
        </Row>
      </StyledCol>
      <Tabs items={tabItems} tabPosition="left" />
    </>
  );
}
