import { Form, Input, Modal, Select, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useCreateLawFirmMutation,
  useUpdateLawFirmMutation,
} from "features/lawFirmsTable/lawFirmsAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function LawFirmModal({ title, onOk, onCancel, selectedLawFirm }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [addLawFirm] = useCreateLawFirmMutation();
  const [updateLawFirm] = useUpdateLawFirmMutation();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onSubmit = async () => {
    const fields = await form.validateFields();
    const action = selectedLawFirm
      ? updateLawFirm({ ...fields, lawFirmId: fields.id })
      : addLawFirm({ ...fields });
    const result = await action;

    if ("data" in result) {
      await form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      message.error(`Failed to save law firm. Please try again later.`);
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      okText="Submit"
      open
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={selectedLawFirm}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="code" label="Law Firm Code" rules={[{ required: true }]}>
          <Input placeholder="Enter Law Firm Code..." />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input placeholder="Enter Name..." />
        </Form.Item>
        <Form.Item name="address1" label="Address Line 1" rules={[{ required: true }]}>
          <Input placeholder="Enter Address..." />
        </Form.Item>
        <Form.Item name="address2" label="Address Line 2">
          <Input placeholder="Enter Address..." />
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[
            { pattern: /^[^\d]*$/, message: "Please enter a valid city" },
            { required: true },
          ]}
        >
          <Input placeholder="Enter City..." />
        </Form.Item>
        <Form.Item name="state" label="State" rules={[{ required: true }]}>
          <Select
            placeholder="Select one..."
            options={constants?.states.map((state) => ({
              label: state.display,
              value: state.value,
            }))}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
        <Form.Item
          name="zipCode"
          label="Zip Code"
          rules={[
            {
              required: true,
              pattern: /^\d{5}(-\d{4})?$/,
              message: "Must be a valid zip code",
            },
          ]}
        >
          <Input placeholder="Enter Zip Code..." maxLength={5} />
        </Form.Item>
        <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
          <Input placeholder="XXX-XXX-XXXX" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input placeholder="Enter Email..." />
        </Form.Item>
        <Form.Item name="notes" label="Notes / Description">
          <Input placeholder="Enter Notes..." />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
