import FeePlanRuleset from "features/feePlans/components/feePlanRuleset";
import FeePlanTable from "features/feePlans/components/feePlanTable";

export default function FeePlans() {
  return (
    <>
      <FeePlanTable />
      <FeePlanRuleset />
    </>
  );
}
