import { Checkbox, Form, Input, Modal, Row, message } from "antd";
import { TableFormList } from "components/formList";
import { FormWizard } from "components/formWizard";
import {
  firstPriorityFields,
  secondPriorityFields,
  thirdPriorityFields,
} from "features/mergeGroups/constants";
import {
  useAddDebtorMergeGroupRuleMutation,
  useUpdateDebtorMergeGroupRuleMutation,
} from "features/mergeGroups/debtorMergeGroupRuleAPI";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledRow = styled(Row)`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const StyledContent = styled.div`
  flex: 4;
  margin-left: 8px;
`;

export default function DebtorMergeGroupRuleModal({ title, onOk, onCancel, debtorMergeGroupRule }) {
  const [addDebtorMergeGroup] = useAddDebtorMergeGroupRuleMutation();
  const [editDebtorMergeGroup] = useUpdateDebtorMergeGroupRuleMutation();
  const [criterias, setCriterias] = useState([]);

  // Populates criterias during additional load
  useEffect(() => {
    if (debtorMergeGroupRule) {
      setCriterias(debtorMergeGroupRule.partialMatchParamsScore);
    }
  }, [debtorMergeGroupRule]);

  const handleDeleteCriteria = (criteria) => {
    setCriterias((prev) => {
      return prev.filter((d) => {
        return d.key !== criteria.key;
      });
    });
  };

  const handleAddCriteria = ({ index, ...criteria }) => {
    if (index === -1) {
      setCriterias((prev) => [...prev, { ...criteria, key: Math.random() }]);
    } else {
      setCriterias((prev) => {
        const copy = [...prev];
        copy[index] = criteria;
        return copy;
      });
    }
    return { data: true };
  };

  const onSubmitForm = async ({ step0, step1, step2 }) => {
    const payload = {
      ...step0,
      identifierMatchParams: Object.entries(step1.identifierMatchParams)
        .filter(([, value]) => value)
        .map(([key]) => key),
      disqualifyingMatchParams: Object.entries(step2.disqualifyingMatchParams)
        .filter(([, value]) => value)
        .map(([key]) => key),
      partialMatchParamsScore: criterias,
    };

    try {
      const action = debtorMergeGroupRule
        ? editDebtorMergeGroup({ id: debtorMergeGroupRule.id, ...payload })
        : addDebtorMergeGroup(payload);
      const result = await action;
      if ("data" in result) {
        const successMessage = debtorMergeGroupRule
          ? "Debtor merge group rule updated successfully!"
          : "Debtor merge group rule added successfully!";
        message.success(successMessage);
        onOk();
      }
    } catch (e) {
      message.error("Please make sure all required fields on all tabs are filled out.");
    }
  };

  const initialValues = {
    ...debtorMergeGroupRule,
    identifierMatchParams: debtorMergeGroupRule?.identifierMatchParams.reduce((acc, param) => {
      return { ...acc, [param]: true };
    }, {}),
    disqualifyingMatchParams: debtorMergeGroupRule?.disqualifyingMatchParams.reduce(
      (acc, param) => {
        return { ...acc, [param]: true };
      },
      {},
    ),
  };

  const columns = [
    {
      dataIndex: "criteria",
      editable: true,
      inputType: "select",
      title: "Criteria",
      options: thirdPriorityFields,
      render: (value) => {
        const field = thirdPriorityFields.find((f) => f.value === value);
        return field?.label;
      },
      rules: [
        {
          required: true,
          message: "Select Criteria",
        },
      ],
    },
    {
      dataIndex: "weight",
      editable: true,
      inputType: "number",
      title: "Weight",
      rules: [
        {
          required: true,
          message: "Enter Weight",
        },
      ],
    },
  ];

  const accountTyingSteps = [
    {
      title: "Basic Rule Info",
      form: (
        <>
          <StyledRow>
            <StyledContent>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input />
              </Form.Item>
            </StyledContent>
          </StyledRow>
          <StyledRow>
            <StyledContent>
              <Form.Item label="Description" name="notes">
                <Input />
              </Form.Item>
            </StyledContent>
          </StyledRow>
        </>
      ),
    },
    {
      title: "Identifier Match Params",
      form: (
        <>
          {firstPriorityFields.map((field) => (
            <StyledRow>
              <StyledContent>
                <Form.Item
                  valuePropName="checked"
                  key={field.value}
                  name={["identifierMatchParams", field.value]}
                >
                  <Checkbox>{field.label}</Checkbox>
                </Form.Item>
              </StyledContent>
            </StyledRow>
          ))}
        </>
      ),
    },
    {
      title: "Identifier Disqualifying Params",
      form: (
        <>
          {secondPriorityFields.map((field) => (
            <StyledRow>
              <StyledContent>
                <Form.Item
                  valuePropName="checked"
                  key={field.value}
                  name={["disqualifyingMatchParams", field.value]}
                >
                  <Checkbox>{field.label}</Checkbox>
                </Form.Item>
              </StyledContent>
            </StyledRow>
          ))}
        </>
      ),
    },
    {
      title: "Identifier Scoring",
      form: (
        <TableFormList
          addText="Add Criteria"
          defaultValues={{ criteria: thirdPriorityFields?.[0].value }}
          columns={columns}
          data={criterias}
          onDelete={handleDeleteCriteria}
          onSave={handleAddCriteria}
        />
      ),
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width={1000}
      open
    >
      <FormWizard
        canSkipSteps={!!debtorMergeGroupRule}
        steps={accountTyingSteps}
        onCancel={onCancel}
        onDone={onSubmitForm}
        initialValues={initialValues}
      />
    </Modal>
  );
}
