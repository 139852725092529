import { Form, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { capitalizeFirstLetter } from "common/utils";
import { useFetchBureauSettingsQuery } from "features/creditReporting/creditReportingAPI";
import { useCreateReportMutation } from "features/documentCenter/documentCenterAPI";
import { useMemo } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function DownloadCreditReportModal({ open, onOk, onCancel }) {
  const [form] = useForm();
  const [createReport] = useCreateReportMutation();
  const { data: creditBureaus } = useFetchBureauSettingsQuery();

  const onSubmit = async () => {
    const values = await form.validateFields();

    const result = await createReport({ ...values });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(
        "Metro2 file is being generated. Please check back later in the Generated Documents section.",
      );
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      message.error("Failed to generate Metro2 file.");
    }
  };

  const options = useMemo(
    () =>
      creditBureaus
        ?.filter((bureau) => bureau.isEnabled)
        .map((bureau) => ({
          label: capitalizeFirstLetter(bureau.type),
          value: bureau.type,
        })),
    [creditBureaus],
  );

  return (
    <Modal
      maskClosable={false}
      title="Download Credit Report"
      open={open}
      onOk={onSubmit}
      okText="Generate"
      onCancel={onCancel}
    >
      <StyledForm
        labelAlign="left"
        layout="vertical"
        form={form}
        initialValues={{
          creditBureauType: options?.[0]?.value,
        }}
      >
        <Form.Item
          label="Credit Bureau Type"
          name="creditBureauType"
          rules={[{ required: true, message: "Please select the Credit Bureau Type" }]}
          tooltip="Only credit bureaus that are enabled will be available for selection."
        >
          <Select options={options} placeholder="Select one..." />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default DownloadCreditReportModal;
