import { EllipsisOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Dropdown, Popover, Row, Select, Space, Table, Tag } from "antd";
import {
  customFieldTypeFormatFunctions,
  formatCurrency,
  formatDate,
  getCustomFieldsItems,
} from "common/utils";
import currency from "currency.js";
import { useFetchCreditorPortalDebtorProfileQuery } from "features/accountsTable/creditorPortal/accountsTableAPI";
import { useGetUserCreditorQuery } from "features/creditors/creditorPortal/creditorsAPI";
import BalanceHistoryModal from "features/debtorAccountsTable/components/balanceHistoryModal";
import CreditBureauSettingsModal from "features/debtorAccountsTable/components/creditBureauSettingsModal";
import CreditReportHistoryModal from "features/debtorAccountsTable/components/creditReportHistoryModal";
import ManageCodebtorsModal from "features/debtorAccountsTable/components/manageCodebtorsModal";
import { useFetchCreditorPortalDebtorAccountsQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import { useFetchCreditorPortalWorkflowStates } from "features/home/creditorPortal/homeAPI";
import { useFetchAllAccountUdfCustomFieldsQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  width: 100%;
`;

const StyledTag = styled(Tag)`
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledInfoTitle = styled.div`
  color: grey;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 24px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

function DebtorAccountsTable({ debtorId }) {
  const [activeRecord, setActiveRecord] = useState(undefined);
  const [isBalanceHistoryModalVisible, setBalanceHistoryModalVisible] = useState(false);
  const [isCreditBureauSettingsModalVisible, setCreditBureauSettingsModalVisible] = useState(false);
  const [isManageCodebtorsModalVisible, setManageCodebtorsModalVisible] = useState(false);
  const [isCreditReportHistoryModalVisible, setCreditReportHistoryModalVisible] = useState(false);
  const { data: debtorPersonalInfo } = useFetchCreditorPortalDebtorProfileQuery({ debtorId });
  const { data: currentCreditor } = useGetUserCreditorQuery();
  const { data: accountCustomFields } = useFetchAllAccountUdfCustomFieldsQuery();
  const navigate = useNavigate();
  const {
    data: debtorAccounts,
    isLoading,
    isFetching,
  } = useFetchCreditorPortalDebtorAccountsQuery({ debtorId });
  const { data: defaultWorkflowStates } = useFetchCreditorPortalWorkflowStates();

  const onDoneCreditBureauSettingsModal = async () => {
    setCreditBureauSettingsModalVisible(false);
    setActiveRecord(null);
  };

  const onCreditBureauSettingsModalCancel = () => {
    setCreditBureauSettingsModalVisible(false);
    setActiveRecord(null);
  };

  const onBalanceHistoryModalCancel = () => {
    setBalanceHistoryModalVisible(false);
    setActiveRecord(null);
  };

  const configureCodebtors = (record) => {
    setManageCodebtorsModalVisible(true);
    setActiveRecord(record);
  };

  const onManageCodebtorsModalCancel = () => {
    setManageCodebtorsModalVisible(false);
    setActiveRecord(null);
  };

  const onCreditReportHistoryModalCancel = () => {
    setCreditReportHistoryModalVisible(false);
    setActiveRecord(null);
  };

  const items = [
    {
      label: "View Credit Bureau Settings",
      key: "dispute",
    },
    {
      type: "divider",
    },
    {
      label: "View Codebtors",
      key: "codebtors",
    },
    {
      type: "divider",
    },
    {
      label: "View Balance History",
      key: "balances",
    },
    {
      type: "divider",
    },
    {
      label: "View Credit Report History",
      key: "creditReport",
    },
  ];

  const viewCreditBureauSettings = (record) => {
    setCreditBureauSettingsModalVisible(true);
    setActiveRecord(record);
  };

  const viewBalanceHistory = (record) => {
    setBalanceHistoryModalVisible(true);
    setActiveRecord(record);
  };

  const viewCreditReportHistory = (record) => {
    setCreditReportHistoryModalVisible(true);
    setActiveRecord(record);
  };

  const handleMenuClick = ({ key, domEvent }, record) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items dictionary
    const action = {
      dispute: viewCreditBureauSettings,
      balances: viewBalanceHistory,
      codebtors: configureCodebtors,
      creditReport: viewCreditReportHistory,
    }[key];
    action(record);
  };

  const columns = [
    {
      title: "Identifiers",
      render: (text, record) => (
        <Col>
          <Row align="middle">
            <StyledInfoTitle>Account ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.id ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Debtor ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.debtorExternalId ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>External ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.externalId ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Client Ref ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.clientReferenceId ?? "-"}</StyledInfoValue>
          </Row>
        </Col>
      ),
    },
    {
      title: "Tags",
      fixed: "left",
      width: 0,
      render: (text, record) => (
        <Col>
          {record?.legalStatus === "judgment_applied_archived" ? (
            <StyledTag color="green">JUDGMENT ARCHIVED</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment" ? (
            <StyledTag color="green">JUDGMENT APPLIED</StyledTag>
          ) : null}
          {record?.legalStatus === "in_litigation" ? (
            <StyledTag color="orange">in litigation</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment_applied" ? (
            <StyledTag color="green">judgment applied</StyledTag>
          ) : null}
        </Col>
      ),
    },
    {
      title: "Current Balance",
      children: [
        {
          title: "Original",
          width: 125,
          dataIndex: "originalTotal",
          render: (text, record) => {
            const balanceItems = [
              {
                key: "principal",
                label: "Principal",
                value: formatCurrency(record.principal),
              },
              {
                key: "originalInterest",
                label: `Interest (${
                  currency(record?.interestRate, { precision: 4 }).multiply(100).value
                }%)`,
                value: formatCurrency(record.originalInterest),
              },
              {
                key: "totalFees",
                label: "Fees",
                value: formatCurrency(record.totalFeesDue),
              },
              {
                key: "originalTotal",
                label: <strong>Total</strong>,
                value: <strong>{formatCurrency(record.originalTotal)}</strong>,
              },
            ];
            const totalBalanceContent = (
              <Col>
                <Descriptions bordered column={1} size="small">
                  {balanceItems.map((item) => (
                    <Descriptions.Item key={item.key} label={item.label}>
                      {item.value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            );
            return (
              <Row align="middle">
                <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
                <Popover
                  content={totalBalanceContent}
                  title="Original Balance Breakdown"
                  trigger="hover"
                  placement="right"
                >
                  <StyledQuestionCircleOutlined />
                </Popover>
              </Row>
            );
          },
        },
        {
          title: "Total Balance",
          dataIndex: "totalBalance",
          render: (text, record) => {
            const balanceItems = [
              {
                key: "principal",
                label: "Principal",
                value: formatCurrency(record.principalBalance),
              },
              {
                key: "interest",
                label: "Interest",
                value: formatCurrency(record.interestBalance),
              },
              {
                key: "totalFees",
                label: "Fees",
                value: formatCurrency(record.totalFeesBalance),
              },
              {
                key: "total",
                label: <strong>Total</strong>,
                value: <strong>{formatCurrency(text)}</strong>,
              },
            ];
            const totalBalanceContent = (
              <Col>
                <Descriptions bordered column={1} size="small">
                  {balanceItems.map((item) => (
                    <Descriptions.Item key={item.key} label={item.label}>
                      {item.value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            );
            return (
              <Row align="middle">
                <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
                <Popover
                  content={totalBalanceContent}
                  title="Total Balance Breakdown"
                  trigger="hover"
                  placement="right"
                >
                  <StyledQuestionCircleOutlined />
                </Popover>
              </Row>
            );
          },
          sorter: (a, b) => a.totalBalance - b.totalBalance,
        },
      ],
    },
    {
      title: "Last Payment",
      children: [
        {
          title: "Amount",
          dataIndex: "lastPaymentAmount",
          key: "lastPaymentAmount",
          render: (text) => (parseFloat(text) ? formatCurrency(text) : "-"),
        },
        {
          title: "Date",
          dataIndex: "lastPaymentDate",
          key: "lastPaymentDate",
          render: (text) => (text ? formatDate(text) : "-"),
        },
      ],
    },
    {
      title: "Status",
      dataIndex: "statusExternalName",
      render: (text, record) => {
        return (
          <StyledSelect
            disabled
            value={text || ""}
            popupMatchSelectWidth={400}
            options={defaultWorkflowStates?.map((state) => ({
              value: state.id,
              label: state.externalName,
            }))}
          />
        );
      },
      hideForRolledUpAccounts: true,
    },
    {
      title: "Co-Debtors",
      dataIndex: "debtors",
      render: (text, record) => {
        const codebtors = record?.debtors.filter((debtor) => debtor.id.toString() !== debtorId);
        if (codebtors.length === 0) {
          return "-";
        }

        return (
          <Col>
            {codebtors.map((codebtor) => (
              <div key={codebtor.id}>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(`/debtors/${codebtor.id}`);
                  }}
                >
                  {codebtor.firstName} {codebtor.middleName} {codebtor.lastName} ({codebtor.id})
                </Button>
              </div>
            ))}
          </Col>
        );
      },
      visible: true,
    },
    {
      title: "Debt Type",
      dataIndex: "collectionDebtType",
      render: (text, record) => (
        <Tag color={record.collectionDebtType?.toUpperCase() === "CONSUMER" ? "cyan" : "purple"}>
          {record.collectionDebtType?.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Other Key Dates",
      render: (text, record) => (
        <Col>
          <Row align="middle">
            <StyledInfoTitle>Service Date:</StyledInfoTitle>
            <StyledInfoValue>{formatDate(record?.dateOfService) ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Turnover Date:</StyledInfoTitle>
            <StyledInfoValue>{formatDate(record?.turnoverDate) ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Itemization Date:</StyledInfoTitle>
            <StyledInfoValue>{formatDate(record?.itemizationDate) ?? "-"}</StyledInfoValue>
          </Row>
          <Popover
            content={
              <>
                <Row align="middle">
                  <StyledInfoTitle>Service Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.dateOfService) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Turnover Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.turnoverDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Upload Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.uploadDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Itemization Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.itemizationDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Date of First Delinquency:</StyledInfoTitle>
                  <StyledInfoValue>
                    {formatDate(record?.dateOfFirstDelinquency) ?? "-"}
                  </StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Charge-off Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.chargeOffDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Interest Calculation Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.interestStartDate) ?? "-"}</StyledInfoValue>
                </Row>
              </>
            }
            title="Account Dates"
            trigger="click"
          >
            <StyledButton type="link">See All</StyledButton>
          </Popover>
        </Col>
      ),
    },
    {
      title: "User Defined Fields",
      dataIndex: "customFields",
      render: (text, record) => {
        const customFieldItems = getCustomFieldsItems(record.customFields, accountCustomFields);
        if (customFieldItems.length === 0) {
          return "-";
        }

        if (customFieldItems.length < 4) {
          return (
            <Col>
              {customFieldItems.map(({ field, slug, value }) => (
                <Row key={slug}>
                  <StyledInfoTitle>{field.name}:</StyledInfoTitle>
                  <StyledInfoValue>
                    {customFieldTypeFormatFunctions[field.type](value)}
                  </StyledInfoValue>
                </Row>
              ))}
            </Col>
          );
        }

        return (
          <Col>
            {customFieldItems.slice(0, 3).map(({ field, slug, value }) => (
              <Row key={slug}>
                <StyledInfoTitle>{field.name}:</StyledInfoTitle>
                <StyledInfoValue>
                  {customFieldTypeFormatFunctions[field.type](value)}
                </StyledInfoValue>
              </Row>
            ))}
            <Popover
              content={customFieldItems.map(({ field, slug, value }) => (
                <Row key={slug}>
                  <StyledInfoTitle>{field.name}:</StyledInfoTitle>
                  <StyledInfoValue>
                    {customFieldTypeFormatFunctions[field.type](value)}
                  </StyledInfoValue>
                </Row>
              ))}
              title="User Defined Fields"
              trigger="click"
            >
              <StyledButton type="link">See All</StyledButton>
            </Popover>
          </Col>
        );
      },
    },
    {
      title: "Actions",
      fixed: "right",
      align: "center",
      dataIndex: "action",
      render: (_, record) => (
        <Row wrap={false} align="middle">
          {/* <AntDToolTip placement="bottom" title="View Payment History">
              <HistoryOutlined
                key="history"
                onClick={() =>
                  setModalContent(
                    <MakeAPaymentModal
                      debts={debtorProfileSlice.accounts}
                      title="Debt"
                      open
                      onOk={paymentOnOk}
                      onCancel={onCancel}
                    />,
                  )
                }
              />
            </AntDToolTip> */}
          <Dropdown
            menu={{
              // @ts-ignore
              items,
              onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp, record),
            }}
            trigger={["click"]}
          >
            <Button type="text">
              <Space>
                <StyledEllipsisOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Row>
      ),
      hideForRolledUpAccounts: true,
    },
  ];

  const ExpandedRow = useCallback(
    (record) => {
      return (
        <>
          <h4>Rolled-up Accounts</h4>
          <StyledTable
            loading={isLoading}
            bordered
            scroll={{ x: "max-content" }}
            // @ts-ignore
            columns={columns.filter((column) => !column.hideForRolledUpAccounts)}
            dataSource={debtorAccounts.filter(
              (debtorAccount) => debtorAccount.legalParentId === record.id,
            )}
            pagination={false}
          />
        </>
      );
    },
    // we don't want columns to be a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debtorAccounts, isLoading],
  );

  return (
    <>
      {isCreditBureauSettingsModalVisible && (
        <CreditBureauSettingsModal
          account={activeRecord}
          title="Credit Bureau Settings"
          open={isCreditBureauSettingsModalVisible}
          onOk={onDoneCreditBureauSettingsModal}
          onCancel={onCreditBureauSettingsModalCancel}
        />
      )}
      {isManageCodebtorsModalVisible && (
        <ManageCodebtorsModal
          debtType={debtorPersonalInfo?.type}
          accountId={activeRecord.id}
          title="View Codebtors"
          open={isManageCodebtorsModalVisible}
          onCancel={onManageCodebtorsModalCancel}
          debtorId={debtorId}
        />
      )}
      {isCreditReportHistoryModalVisible && (
        <CreditReportHistoryModal
          account={activeRecord}
          title={`Credit Report History (Account ID: ${activeRecord?.id})`}
          open={isCreditReportHistoryModalVisible}
          onCancel={onCreditReportHistoryModalCancel}
          debtorId={debtorId}
        />
      )}
      <BalanceHistoryModal
        account={activeRecord}
        open={isBalanceHistoryModalVisible}
        onCancel={onBalanceHistoryModalCancel}
      />
      <StyledTable
        loading={isLoading || isFetching}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={debtorAccounts?.filter((debtorAccount) => !debtorAccount.legalParentId)}
        expandable={{
          expandedRowRender: ExpandedRow,
          rowExpandable: (record) =>
            // @ts-ignore
            debtorAccounts?.filter((debtorAccount) => debtorAccount.legalParentId === record?.id)
              .length > 0,
          showExpandColumn:
            debtorAccounts?.filter((debtorAccount) => debtorAccount.legalParentId).length > 0,
        }}
      />
    </>
  );
}

export default DebtorAccountsTable;
