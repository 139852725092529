import { Card, Form, Select } from "antd";
import {
  useFetchCreditorPortalCreditorSummariesQuery,
  useLazyFetchCreditorPortalCreditorsQuery,
} from "features/creditors/creditorPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchCreditorPortalProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";

import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 400px;
`;

export default function CreditorAccountUploadParamsForm({ onSubmit }) {
  const [form] = Form.useForm();
  const { data: constants = {}, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();
  const { data: productOfferings = [], isLoading: isProductOfferingsLoading } =
    useFetchCreditorPortalProductOfferingsQuery();
  const [
    fetchCreditors,
    {
      data: creditorsResponse,
      isLoading: isCreditorDetailsLoading,
      isFetching: isCreditorDetailsFetching,
    },
  ] = useLazyFetchCreditorPortalCreditorsQuery();
  const { data: creditors, isLoading: isCreditorsSummariesLoading } =
    useFetchCreditorPortalCreditorSummariesQuery();

  const onSelectChange = async () => {
    const validatedFields = await form.validateFields();
    onSubmit(validatedFields);
  };

  const onCreditorIdChange = async (creditorId) => {
    form.setFieldsValue({ creditorId, cbrClassCode: null, productOfferingId: null });
    fetchCreditors({ filters: { creditorIds: [creditorId] } });
  };

  const selectedCreditor = creditorsResponse?.results?.[0];

  return (
    <Card>
      <StyledForm layout="vertical" form={form}>
        <Form.Item
          label="Client"
          name="creditorId"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={creditors?.map((creditor) => ({
              value: creditor.id,
              label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
            }))}
            onChange={onCreditorIdChange}
            loading={isCreditorsSummariesLoading}
            disabled={isCreditorsSummariesLoading}
          />
        </Form.Item>

        <Form.Item
          name="cbrClassCode"
          label="CBR Class Code"
          rules={[{ required: true, message: "Please select the CBR Class Code" }]}
        >
          <Select
            placeholder="Select the CBR Class Code"
            options={(constants.cbrClassCodes ?? [])
              .filter((dt) => {
                return selectedCreditor?.cbrClassCodes.includes(dt.value);
              })
              .map((cbrClassCodeOption) => ({
                value: cbrClassCodeOption.value,
                label: cbrClassCodeOption.display,
              }))}
            onSelect={onSelectChange}
            disabled={!selectedCreditor}
            loading={isCreditorDetailsLoading || isCreditorDetailsFetching || isConstantsLoading}
          />
        </Form.Item>
        <Form.Item
          name="productOfferingId"
          label="Product"
          rules={[{ required: true, message: "Please select a product" }]}
        >
          <Select
            placeholder="Select a product"
            options={productOfferings.map((product) => ({
              value: product.id,
              label: product.name,
            }))}
            onSelect={onSelectChange}
            disabled={!selectedCreditor}
            loading={
              isProductOfferingsLoading || isCreditorDetailsLoading || isCreditorDetailsFetching
            }
          />
        </Form.Item>
      </StyledForm>
    </Card>
  );
}
