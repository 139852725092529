import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Space, Table, message } from "antd";
import { PageHeader } from "components/pageHeader";
import {
  useDeleteAppearanceMutation,
  useFetchAppearancesQuery,
} from "features/appearance/appearanceAPI";
import styled from "styled-components";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

export default function AppearanceManagementTable() {
  const {
    data: appearances,
    isLoading: isTableLoading,
    isFetching: isTableFetching,
  } = useFetchAppearancesQuery();
  const [deleteAppearance] = useDeleteAppearanceMutation();

  const onDeleteAppearance = async (record) => {
    const result = await deleteAppearance(record);
    if ("data" in result) {
      message.success("Appearance deleted successfully!");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this appearance?"
            onConfirm={() => onDeleteAppearance({ id: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        </Space>
      ),
      fixed: "right",
    },
  ];

  return (
    <>
      <StyledHeader>Appearances</StyledHeader>
      <Table
        loading={isTableLoading || isTableFetching}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={appearances}
      />
    </>
  );
}
