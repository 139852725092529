import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import AddSettlementAndPaymentRulesetModal from "features/settlementAndPaymentRules/components/addSettlementAndPaymentRulesetModal";
import { SettlementAndPaymentRulesetTable } from "features/settlementAndPaymentRules/components/settlementAndPaymentRulesetTable";
import {
  useDeleteSettlementAndPaymentRuleSetMutation,
  useFetchPaymentRulesQuery,
  useFetchSettlementAndPaymentRuleSetQuery,
  useFetchSettlementAndPaymentRuleSetsQuery,
} from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import { useCallback, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function SettlementAndPaymentRuleset() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRuleSet, setSelectedRuleSet] = useState(null);
  const [deleteSettlementAndPaymentRuleSet] = useDeleteSettlementAndPaymentRuleSetMutation();
  const { data: feePlanRuleSets, isLoading } = useFetchSettlementAndPaymentRuleSetsQuery();
  const { data: paymentRules } = useFetchPaymentRulesQuery();
  const { data: settlementRules } = useFetchSettlementAndPaymentRuleSetQuery();
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const {
    data: {
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__GET]: isSettlementPaymentRuleGetAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__CREATE]: isSettlementPaymentRuleCreateAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__UPDATE]: isSettlementPaymentRuleUpdateAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__DELETE]: isSettlementPaymentRuleDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (record) => {
    const result = await deleteSettlementAndPaymentRuleSet({ id: record.id });

    if ("data" in result) {
      message.success("Settlement and Payment Rule Set deleted successfully!");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Default Settlement Rule",
      dataIndex: "defaultSettlementRule",
      key: "defaultSettlementRule",
      render: (index, ruleSet) =>
        ruleSet.defaultSettlementRule
          ? ruleSet.defaultSettlementRule.name ??
            `Settlement Rule #${ruleSet.defaultSettlementRule.id}`
          : "-",
    },
    {
      title: "Default Payment Rule",
      dataIndex: "defaultPaymentRule",
      key: "defaultPaymentRule",
      render: (index, ruleSet) =>
        ruleSet.defaultPaymentRule
          ? ruleSet.defaultPaymentRule.name ?? `Payment Rule #${ruleSet.defaultPaymentRule.id}`
          : "-",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isSettlementPaymentRuleUpdateAuthorized && !isSettlementPaymentRuleDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isSettlementPaymentRuleUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedRuleSet(record);
                setModalVisible(true);
              }}
            />
          )}
          {isSettlementPaymentRuleDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this fee plan rule set?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  const onNewRuleOk = () => {
    setModalVisible(false);
    setSelectedRuleSet(null);
  };

  const TableWithPlans = useCallback(
    // bundle feePlans and productOfferings with SettlementAndPaymentRulesTable
    (props) => (
      <SettlementAndPaymentRulesetTable
        {...props}
        paymentRules={paymentRules}
        productOfferings={productOfferings}
        settlementRules={settlementRules}
      />
    ),
    [paymentRules, productOfferings, settlementRules],
  );

  return isSettlementPaymentRuleGetAuthorized ? (
    <>
      <h4>Payment And Settlement Rule Sets</h4>
      <Row align="middle">
        <span>
          Configure a group of settlement and payment rules and the account criteria that apply to
          each
        </span>
        {isSettlementPaymentRuleCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Payment and Settlement Rule Set
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        expandable={{
          expandedRowRender: TableWithPlans,
          // @ts-ignore
          rowExpandable: (record) => record.rules?.length > 0,
        }}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={feePlanRuleSets}
      />
      {/* The isModalVisible forces the modal to rerender itself and the form inside when toggling the modal's visibility
          This forced rerendering is needed for the form to use the latest initialValues */}
      {isModalVisible && (
        <AddSettlementAndPaymentRulesetModal
          title={`${selectedRuleSet ? "Edit" : "Add"} Payment And Settlement Rule Set`}
          selectedRuleSet={selectedRuleSet}
          setSelectedRuleSet={setSelectedRuleSet}
          open
          onOk={onNewRuleOk}
          onCancel={() => {
            setModalVisible(false);
            setSelectedRuleSet(null);
          }}
        />
      )}
    </>
  ) : null;
}
