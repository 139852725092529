import { Modal } from "antd";
import { populateKey } from "common/utils";
import { AddDebtorWizard } from "features/addDebtorsWizard";
import { useUserType } from "features/auth";
import {
  useCreateAccountDebtorMutation,
  useFetchDebtorAccountsQuery,
  useLinkAccountDebtorMutation,
  useUnlinkAccountDebtorMutation,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchCreditorPortalDebtorAccountsQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import ManageDebtors from "features/manageDebtors/manageDebtors";
import { useMemo, useState } from "react";

function ManageCodebtorsModal({ title, open, onCancel, accountId, debtorId, debtType }) {
  const { isCreditorUserType, isAgencyUserType } = useUserType();
  const [isAddingDebtor, setIsAddingDebtor] = useState(false);
  const [createAccountDebtor] = useCreateAccountDebtorMutation();
  const [unlinkDebtor] = useUnlinkAccountDebtorMutation();
  const [linkDebtor] = useLinkAccountDebtorMutation();
  const { data: agencyDebtorAccounts, isLoading: isAgencyLoading } = useFetchDebtorAccountsQuery(
    { debtorId },
    {
      skip: isCreditorUserType,
    },
  );
  const { data: creditorAccounts, isLoading: isCreditorLoading } =
    useFetchCreditorPortalDebtorAccountsQuery(
      { debtorId },
      {
        skip: isAgencyUserType,
      },
    );

  const [debtorAccounts, isLoading] = isCreditorUserType
    ? [creditorAccounts, isCreditorLoading]
    : [agencyDebtorAccounts, isAgencyLoading];

  const account = useMemo(() => {
    const selectedAccount = { ...debtorAccounts?.find((a) => a.id === accountId) };
    // add a name property to each debtor in debtorAccounts such that it can be used in the ManageDebtors component
    if (selectedAccount) {
      selectedAccount.debtors = selectedAccount.debtors.map((debtor) => {
        return {
          ...debtor,
          name: [debtor?.firstName, debtor?.middleName, debtor?.lastName].filter(Boolean).join(" "),
        };
      });
    }

    return selectedAccount;
  }, [accountId, debtorAccounts]);

  const handleDelete = (debtor) => {
    unlinkDebtor({ accountId, debtorIds: [debtor.id] });
  };

  const onSelect = (debtor) => {
    linkDebtor({ accountId, debtor_ids: [debtor.id] });
  };

  const onAddDebtorCancelled = () => {
    setIsAddingDebtor(false);
  };

  const onDebtorAdded = ({ email, ...debtor }) => {
    const payload = {
      accountId,
      ...debtor,
      // TODO: Hack to get around the fact that the backend expects a list of emails
      emails: email ? [{ type: "personal", address: email }] : [],
    };

    createAccountDebtor(payload);
    setIsAddingDebtor(false);
  };

  const handleAdd = () => {
    setIsAddingDebtor(true);
  };

  return (
    <Modal
      width={900}
      maskClosable={false}
      title={isAddingDebtor ? "Add New Debtor" : title}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
      okButtonProps={{ disabled: isCreditorUserType }}
    >
      {isAddingDebtor ? (
        <AddDebtorWizard
          debtType={debtType}
          onCancel={onAddDebtorCancelled}
          onDone={onDebtorAdded}
        />
      ) : (
        <ManageDebtors
          debtType={debtType}
          dataSource={populateKey(account?.debtors)}
          handleDelete={handleDelete}
          onSelect={onSelect}
          handleAdd={handleAdd}
          isLoading={isLoading}
          readOnly={isCreditorUserType}
        />
      )}
    </Modal>
  );
}

export default ManageCodebtorsModal;
