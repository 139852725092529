import config from "app/envConfig";
import LocalLoginForm from "features/auth/components/localLoginForm";
import LocalProtectedRoute from "features/auth/components/localProtectedRoute";
import LoginForm from "features/auth/components/loginForm";
import ProtectedRoute from "features/auth/components/protectedRoute";

export function getLoginForm() {
  return config.useAuth0 ? LoginForm : LocalLoginForm;
}

export function getProtectedRoute() {
  return config.useAuth0 ? ProtectedRoute : LocalProtectedRoute;
}
