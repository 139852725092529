import { Modal, message } from "antd";
import { FormWizard } from "components/formWizard";
import { usePostAccountMutation } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { usePostCreditorPortalAccountMutation } from "features/accountsTable/creditorPortal/accountsTableAPI";
import AccountStep from "features/accountsTable/wizardSteps/accountStep";
import CreditorStep from "features/accountsTable/wizardSteps/creditorStep";
import DocumentStep from "features/accountsTable/wizardSteps/documentStep";
import { AddDebtorWizard } from "features/addDebtorsWizard";
import { useUserType } from "features/auth";
import { usePostDocumentsMutation } from "features/documentsTable/agencyPortal/documentsAPI";
import { usePostCreditorPortalDebtorDocumentsMutation } from "features/documentsTable/creditorPortal/documentsAPI";
import ManageDebtors from "features/manageDebtors/manageDebtors";
import { useState } from "react";
import moment from "moment-timezone";
import { DATE_FORMAT } from "common/constants";

function AddManualConsumerAccountsModal({ title, open, onOk, onCancel }) {
  const { isAgencyUserType } = useUserType();
  const [isAddingDebtor, setIsAddingDebtor] = useState(false);
  const [debtors, setDebtors] = useState([]);
  const [creditorId, setCreditorId] = useState(null);

  const handleDeleteDebtor = (debtor, index) => {
    setDebtors((prevDebtors) => {
      return prevDebtors.filter((_, i) => {
        return i !== index;
      });
    });
  };

  const handleAddDebtor = (debtor) => {
    setDebtors((prev) => [...prev, { ...debtor, type: "ADDED", key: Math.random() }]);
    setIsAddingDebtor(false);
  };

  const onSelect = (debtor) => {
    setDebtors((prev) => [...prev, { ...debtor, type: "SELECTED" }]);
  };

  const [uploadAgencyDebtorDocument] = usePostDocumentsMutation();
  const [uploadCreditorPortalDebtorDocument] = usePostCreditorPortalDebtorDocumentsMutation();
  const uploadDocument = isAgencyUserType
    ? uploadAgencyDebtorDocument
    : uploadCreditorPortalDebtorDocument;

  const [addAgencyAccountManual] = usePostAccountMutation();
  const [addCreditorPortalAccountManual] = usePostCreditorPortalAccountMutation();
  const addAccountManual = isAgencyUserType
    ? addAgencyAccountManual
    : addCreditorPortalAccountManual;

  const onSubmitForm = async ({ step1, step2, step3 }) => {
    const newDebtors = debtors.filter((debtor) => debtor.type === "ADDED");

    const debtorIds = debtors
      .filter((debtor) => debtor.type === "SELECTED")
      .map((debtor) => debtor.id);
    const values = { ...step1, ...step2, ...step3, newDebtors, debtorIds };

    values.newDebtors = values.newDebtors.map(({ email, ...debtor }) => ({
      ...debtor,
      // TODO: Hack to get around the fact that the backend expects a list of emails
      emails: email ? [{ type: "personal", address: email }] : [],
    }));

    // Hardcode to consumer account
    values.collectionDebtType = "consumer";

    const accountUploadResult = await addAccountManual(values);
    if ("data" in accountUploadResult) {
      message.success("Account added successfully!");
      const { debtorId } = accountUploadResult.data;
      if (values.file) {
        const documentUploadResult = await uploadDocument({
          filename: values.filename,
          type: values.type,
          notes: values.notes,
          file: values.file,
          debtorId,
        });
        if ("data" in documentUploadResult) {
          message.success("Document added successfully!");
        } else {
          message.error("Document upload failed");
        }
      }
      onOk();
    }
  };

  const onAddDebtorCancelled = () => {
    setIsAddingDebtor(false);
  };

  const handleAdd = () => {
    setIsAddingDebtor(true);
  };

  const onNext = async (data) => {
    setCreditorId(data.creditorId);
  };

  const validateDebtors = async () => {
    if (debtors.length === 0) {
      return { error: new Error("Please add at least one debtor") };
    }
  };

  const steps = [
    {
      title: "Debtor Information",
      form: (
        <ManageDebtors
          debtType="consumer"
          dataSource={debtors}
          handleDelete={handleDeleteDebtor}
          onSelect={onSelect}
          handleAdd={handleAdd}
        />
      ),
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
      onNext: async () => validateDebtors(),
    },
    {
      title: "Client Information",
      form: <CreditorStep />,
      onNext: async (data) => onNext(data),
    },
    {
      title: "Account Information",
      form: <AccountStep creditorId={creditorId} />,
    },
    {
      title: "Documents",
      form: <DocumentStep />,
    },
  ];

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width={1600}
    >
      {isAddingDebtor ? (
        <AddDebtorWizard
          debtType="consumer"
          onCancel={onAddDebtorCancelled}
          onDone={handleAddDebtor}
        />
      ) : (
        <FormWizard
          steps={steps}
          onCancel={onCancel}
          onDone={onSubmitForm}
          initialValues={{ turnoverDate: moment().format(DATE_FORMAT) }}
        />
      )}
    </Modal>
  );
}

export default AddManualConsumerAccountsModal;
