import { downloadFile } from "common/utils";
import api, { transformRequest, transformResponse } from "services/api";

export const downloadCentreApi = api
  .enhanceEndpoints({ addTagTypes: ["FileDownloads"] })
  .injectEndpoints({
    endpoints: (build) => ({
      exportLetters: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/tasks/export-letters/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FileDownloads", id: "LIST" }],
      }),
      downloadDebtValidationHatteras: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debt-validation/hatteras/download/`,
          method: "POST",
          body: transformRequest(payload),
          responseHandler: (response) => response.blob(),
        }),
        transformResponse: downloadFile,
      }),
      createReport: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/credit-reports/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FileDownloads", id: "LIST" }],
      }),
      downloadInHouseLetters: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/in-house-letters/download/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FileDownloads", id: "LIST" }],
      }),
      fetchReports: build.query({
        query: () => ({
          url: `/api/v1/core/file-downloads/`,
          method: "POST",
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "FileDownloads", id })),
                { type: "FileDownloads", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'FileDownloads', id: 'LIST' }` is invalidated
              [{ type: "FileDownloads", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchReportsQuery,
  useDownloadDebtValidationHatterasMutation,
  useDownloadInHouseLettersMutation,
  useCreateReportMutation,
  useExportLettersMutation,
} = downloadCentreApi;
