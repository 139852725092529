import { Card, Tabs } from "antd";
import WorkflowStatesTable from "features/workflows/components/workflowStatesTable";
import WorkflowTable from "features/workflows/components/workflowTable";

function WorkflowTabs() {
  const items = [
    {
      label: "Statuses",
      key: "statuses",
      children: <WorkflowStatesTable />,
    },
    {
      label: "Workflows",
      key: "workflows",
      children: <WorkflowTable />,
    },
  ];

  return (
    <Card>
      <Tabs items={items} />
    </Card>
  );
}

export default WorkflowTabs;
