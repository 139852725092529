import api, { transformRequest, transformResponse } from "services/api";

export const permissionsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "Permissions",
      "PermissionsSummary",
      "MyPermissions",
      "MyPermissionsSummary",
      "Features",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchMyPermissions: build.query({
        query: () => `/api/v1/core/users/me/permissions/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: () => [{ type: "MyPermissions", id: "LIST" }],
      }),
      fetchFeatures: build.query({
        query: () => `/api/v1/common/feature-flags/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: () => [{ type: "Features", id: "LIST" }],
      }),
      fetchMyPermissionsSummary: build.query({
        query: () => `/api/v1/common/users/me/permissions/summary/`,
        // @ts-ignore
        transformResponse: (response) => response.result,
        providesTags: () => [{ type: "MyPermissionsSummary", id: "LIST" }],
      }),
      fetchPermissions: build.query({
        query: (payload) => `/api/v1/core/users/${payload.userId}/permissions/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: () => [{ type: "Permissions", id: "LIST" }],
      }),
      fetchPermissionsSummary: build.query({
        query: (payload) => `/api/v1/core/users/${payload.userId}/permissions/summary/`,
        // @ts-ignore
        transformResponse: (response) => response.result,
        providesTags: () => [{ type: "PermissionsSummary", id: "LIST" }],
      }),
      grantPermission: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/users/${payload.userId}/permissions/grant/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Permissions", id: "LIST" }],
      }),
      revokePermission: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/users/${payload.userId}/permissions/revoke/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "Permissions", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchMyPermissionsQuery,
  useFetchFeaturesQuery,
  useFetchMyPermissionsSummaryQuery,
  useFetchPermissionsQuery,
  useGrantPermissionMutation,
  useRevokePermissionMutation,
} = permissionsApi;
