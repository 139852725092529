import { useAuth0 } from "@auth0/auth0-react";
import { Button, Form } from "antd";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 300px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

function LoginForm() {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <h3>Log in to Your Aktos Account</h3>
      <StyledForm className="login-form">
        <Form.Item>
          <StyledButton
            type="primary"
            htmlType="submit"
            className="login-form-button"
            data-testid="login-button"
            onClick={loginWithRedirect}
          >
            Log in
          </StyledButton>
        </Form.Item>
      </StyledForm>
    </>
  );
}

export default LoginForm;
