import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, Tooltip, message } from "antd";
import { camelToWords, snakeToCamelCase } from "common/utils";
import { PageHeader } from "components/pageHeader";
import {
  useDeleteAuthenticationMethodMutation,
  useFetchAuthenticationMethodsQuery,
} from "features/authenticationMethods/authenticationMethodsAPI";
import ManageAuthenticationMethodModal from "features/authenticationMethods/components/manageAuthenticationMethodModal";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useState } from "react";
import styled from "styled-components";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

export default function AuthenticationMethodManagementTable() {
  const {
    data: authenticationMethods,
    isLoading: isTableLoading,
    isFetching: isTableFetching,
  } = useFetchAuthenticationMethodsQuery();
  const [deleteAuthenticationMethod] = useDeleteAuthenticationMethodMutation();
  const [isAuthenticationMethodModalVisible, setIsAuthenticationMethodModalVisible] =
    useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const { data: isAuthenticationMethodUpdateAuthorized } = useAuthorization(
    PERMISSIONS.DISPUTE__UPDATE,
  );

  const onEditAuthenticationMethod = (record) => {
    setIsAuthenticationMethodModalVisible(true);
    setActiveRecord(record);
  };

  const onDeleteAuthenticationMethod = async (record) => {
    const result = await deleteAuthenticationMethod(record);
    if ("data" in result) {
      message.success("Authentication method deleted successfully!");
    }
    if ("error" in result) {
      message.error("Failed to delete authentication method");
    }
  };

  const onManageAuthenticationMethodModalCancel = () => {
    setIsAuthenticationMethodModalVisible(false);
    setActiveRecord(null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text) => camelToWords(snakeToCamelCase(text)),
      key: "type",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isAuthenticationMethodUpdateAuthorized,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip placement="bottom" title="Edit" key="edit">
            <EditOutlined key="edit" onClick={() => onEditAuthenticationMethod(record)} />
          </Tooltip>
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this authentication method?"
            onConfirm={() => onDeleteAuthenticationMethod({ id: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        </Space>
      ),
      fixed: "right",
    },
  ].filter((column) => !column.hidden);

  return (
    <>
      <StyledHeader>Authentication Methods</StyledHeader>
      <Row align="middle">
        <Button
          onClick={() => setIsAuthenticationMethodModalVisible(true)}
          type="link"
          icon={<PlusOutlined />}
        >
          Add Authentication Method
        </Button>
      </Row>
      <Table
        loading={isTableLoading || isTableFetching}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={authenticationMethods}
      />
      {isAuthenticationMethodModalVisible && (
        <ManageAuthenticationMethodModal
          authenticationMethod={activeRecord}
          onOk={onManageAuthenticationMethodModalCancel}
          onCancel={onManageAuthenticationMethodModalCancel}
        />
      )}
    </>
  );
}
