import api, { transformRequest, transformResponse } from "services/api";

export const debtorAccounts = api
  .enhanceEndpoints({
    addTagTypes: ["CreditorPortalDebtorAccounts"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorPortalDebtorAccounts: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/accounts/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorAccounts", id })),
                { type: "CreditorPortalDebtorAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorAccounts', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorAccounts", id: "LIST" }],
      }),
      fetchCreditorPortalAccountBalanceHistory: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/accounts/${payload.accountId}/balance-history/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      fetchCreditorPortalAccount: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/accounts/${payload.accountId}/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      fetchCreditReportHistory: build.query({
        query: (payload) => ({
          url: `/api/v1/common/accounts/${payload.accountId}/credit-report-history/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
    }),

    overrideExisting: false,
  });

export const {
  useFetchCreditorPortalDebtorAccountsQuery,
  useFetchCreditorPortalAccountBalanceHistoryQuery,
  useLazyFetchCreditorPortalAccountBalanceHistoryQuery,
} = debtorAccounts;
