import { Modal, Table } from "antd";
import { capitalizeFirstLetter } from "common/utils";
import { useFetchCreditReportHistoryQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import {
  BCII_CODES,
  BCCC_CODES,
} from "features/debtorAccountsTable/components/creditBureauSettingsModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input, Tooltip } from "antd";
import styled from "styled-components";

const CBR_CODES = [
  { value: "93", label: "93 - Assigned to Collection" },
  { value: "62", label: "62 - Paid in Full Collection" },
  { value: "64", label: "64 - Paid in Full Charge Off" },
  { value: "DA", label: "D - Delete Account" },
  { value: "DF", label: "DF - Delete Account Fraud" },
];

const getLabelForValue = (value, codesArray) => {
  const code = codesArray.find((code) => code.value === value);
  return code ? code.label : "Unknown Code";
};

export default function CreditReportHistoryModal({ account, title, open, onCancel, debtorId }) {
  const { data: creditReportHistory, isLoading } = useFetchCreditReportHistoryQuery({
    debtorIds: [debtorId],
    accountId: account.id,
  });
  const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
    color: #1677ff;
    margin-left: 4px;
  `;

  const CBR_CODES = [
    { value: "93", label: "93 - Assigned to Collection" },
    { value: "62", label: "62 - Paid in Full Collection" },
    { value: "64", label: "64 - Paid in Full Charge Off" },
    { value: "DA", label: "D - Delete Account" },
    { value: "DF", label: "DF - Delete Account Fraud" },
  ];

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={1000}
    >
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        dataSource={creditReportHistory}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Debtor ID",
            dataIndex: "debtorId",
            key: "debtorId",
          },
          {
            title: "Report Date",
            dataIndex: "reportDate",
            key: "reportDate",
          },
          {
            title: "Credit Bureau Type",
            dataIndex: "creditBureauType",
            key: "creditBureauType",
            render: (text) => capitalizeFirstLetter(text),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text) => (
              <span>
                {text}
                <Tooltip title={getLabelForValue(text, CBR_CODES)} trigger="hover">
                  <StyledInfoCircleOutlined />
                </Tooltip>
              </span>
            ),
          },
          {
            title: "BCII Code",
            dataIndex: "bciiCode",
            key: "bciiCode",
            render: (text) => (
              <span>
                {text}
                <Tooltip title={getLabelForValue(text, BCII_CODES)} trigger="hover">
                  <StyledInfoCircleOutlined />
                </Tooltip>
              </span>
            ),
          },
          {
            title: "Compliance Condition Code",
            dataIndex: "complianceConditionCode",
            key: "complianceConditionCode",
            render: (text) => (
              <span>
                {text}
                <Tooltip title={getLabelForValue(text, BCCC_CODES)} trigger="hover">
                  <StyledInfoCircleOutlined />
                </Tooltip>
              </span>
            ),
          },
        ]}
      />
    </Modal>
  );
}
