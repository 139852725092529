import { Button, Form, Alert, Modal, message } from "antd";
import {
  useFetchEligibleDebtorsForMergeQuery,
  useMergeEligibleDebtorsMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useDeleteEligibleDebtorsForGroupMutation } from "features/mergeGroups/debtorMergeGroupAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
`;

export default function SimilarDebtorsCard({ currentDebtor }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: mergedEligibleDebtors } = useFetchEligibleDebtorsForMergeQuery({
    debtorIds: [currentDebtor?.id],
  });
  const [deleteSimilarDebtors, { isLoading: deleteLoading }] =
    useDeleteEligibleDebtorsForGroupMutation();
  const [mergeSimilarDebtors, { isLoading: mergeLoading }] = useMergeEligibleDebtorsMutation();
  const {
    data: { [PERMISSIONS.DEBTOR_MERGE_GROUP__MERGE]: canMerge },
  } = useAuthorizations();
  const navigate = useNavigate();

  const onMergeAll = async () => {
    //  There will always be max one "mergeEligibleDebtor" instance
    const result = await mergeSimilarDebtors({
      mergeEligibleDebtorsId: mergedEligibleDebtors[0]?.id,
    });
    if ("data" in result) {
      message.success(`Debtor merge has been initiated!`);
      navigate(`/debtors/${mergedEligibleDebtors[0]?.parentDebtorId}`);
    }
  };

  const onDeleteAll = async () => {
    const result = await deleteSimilarDebtors({
      mergeEligibleDebtorsId: mergedEligibleDebtors[0]?.id,
    });
    if ("data" in result) {
      message.success(`Debtor match deleted successfully!`);
    }
    setIsModalVisible(false);
  };

  if (mergedEligibleDebtors?.length) {
    if (mergedEligibleDebtors[0]?.asyncJob?.id) {
      return (
        <Alert
          showIcon
          message="This debtor will soon be archived."
          description={
            <>
              <div>
                This debtor is currently being merged with another debtor. This process may take a
                few minutes. Please check back later.
              </div>
              <StyledButton
                onClick={() => navigate(`/debtors/${mergedEligibleDebtors[0]?.parentDebtorId}`)}
                type="primary"
              >
                View Debtor
              </StyledButton>
            </>
          }
          type="warning"
        />
      );
    }
    return (
      <>
        <Alert
          showIcon
          message={`${mergedEligibleDebtors[0]?.childrenDebtors?.length} duplicate(s) found `}
          description={
            <>
              <div>
                Debtors with similar profiles were detected. Merging the duplicates will combine
                them into one debtor.
              </div>
              <StyledButton onClick={() => setIsModalVisible(true)} type="primary">
                See Details
              </StyledButton>
            </>
          }
          type="warning"
        />
        {isModalVisible && (
          <Modal
            open={isModalVisible}
            title="Similar Debtors Found"
            width={800}
            onOk={() => setIsModalVisible(false)}
            // onOk={onMergeAll} // TODO: Remove this comment after merge peformance is fixed
            maskClosable={false}
            onCancel={() => setIsModalVisible(false)}
            // TODO: Commenting this out for now to temporarily remove the actions
            // footer={
            //   canMerge ? (
            //     [
            //       <Popconfirm
            //         placement="topRight"
            //         okText="Yes"
            //         title="Are you sure you want to delete these matches?"
            //         onConfirm={onDeleteAll}
            //       >
            //         <Button loading={deleteLoading} key="delete">
            //           Delete All
            //         </Button>
            //       </Popconfirm>,
            //       <Button loading={mergeLoading} key="merge" onClick={onMergeAll} type="primary">
            //         Merge All
            //       </Button>,
            //     ]
            //   ) : (
            //     <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
            //   )
            // }
          >
            <StyledCard>
              <Form labelAlign="left">
                {mergedEligibleDebtors[0]?.parentDebtorId !== currentDebtor?.id && (
                  <StyledContainer>
                    <span>
                      <a
                        href={`/debtors/${mergedEligibleDebtors[0]?.parentDebtorId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {mergedEligibleDebtors[0]?.parentDebtor?.name} (
                        {mergedEligibleDebtors[0]?.parentDebtor?.externalId})
                      </a>
                    </span>
                  </StyledContainer>
                )}
                {mergedEligibleDebtors[0]?.childrenDebtors
                  ?.filter((debtor) => debtor.id !== currentDebtor?.id)
                  ?.map((debtor) => (
                    <StyledContainer key={debtor.id}>
                      <span>
                        <a href={`/debtors/${debtor?.id}`} target="_blank" rel="noreferrer">
                          {debtor.name} ({debtor.externalId})
                        </a>
                      </span>
                    </StyledContainer>
                  ))}
              </Form>
            </StyledCard>
          </Modal>
        )}
      </>
    );
  }

  if (currentDebtor?.mergeParentId !== null && currentDebtor?.mergeParentId !== undefined) {
    return (
      <Alert
        showIcon
        message="This debtor has been archived."
        description={
          <>
            <div>Click here to view the merged debtor.</div>
            <StyledButton
              onClick={() => navigate(`/debtors/${currentDebtor?.mergeParentId}`)}
              type="primary"
            >
              View Debtor
            </StyledButton>
          </>
        }
        type="warning"
      />
    );
  }
  return null;
}
