import { AccountsTable } from "features/accountsTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function AccountsPage() {
  return (
    <PageLayout>
      <Content>
        <AccountsTable />
      </Content>
    </PageLayout>
  );
}

export default AccountsPage;
