import { Table, Tag } from "antd";
import { idToObjectMap, populateKey } from "common/utils";
import { useMemo } from "react";

export function SettlementAndPaymentRulesetTable({
  rules,
  productOfferings,
  paymentRules,
  settlementRules,
}) {
  const productOfferingsById = useMemo(() => {
    return idToObjectMap(productOfferings);
  }, [productOfferings]);

  const columns = [
    {
      dataIndex: "paymentRuleId",
      key: "paymentRuleId",
      render: (index, rule) =>
        rule.paymentRuleId
          ? paymentRules?.find((paymentRule) => paymentRule.id === rule.paymentRuleId)?.name ??
            `Payment Rule #${rule.paymentRuleId}`
          : "-",
      editable: true,
      inputType: "select",
      title: "Payment Rule",
      options: paymentRules?.map((paymentRule) => ({
        label: paymentRule.name,
        value: paymentRule.id,
      })),
    },
    {
      dataIndex: "settlementRuleId",
      key: "settlementRuleId",
      render: (index, rule) =>
        rule.settlementRuleId
          ? settlementRules?.find((settlementRule) => settlementRule.id === rule.settlementRuleId)
              ?.name ?? `Settlement Plan #${rule.settlementRuleId}`
          : "-",
      editable: true,
      inputType: "select",
      title: "Settlement Plan",
      options: settlementRules?.map((settlementRule) => ({
        label: settlementRule.name,
        value: settlementRule.id,
      })),
    },
    {
      title: "Legal Status",
      dataIndex: "hasLawsuit",
      key: "hasLawsuit",
      render: (index, rule) =>
        ({
          true: <Tag color="red">Contains Legal</Tag>,
          false: <Tag color="green">No Legal</Tag>,
          null: "-",
        }[rule.hasLawsuit]),
    },
    {
      title: "Product",
      dataIndex: "productOfferingId",
      key: "productOfferingId",
      render: (index, rule) => productOfferingsById[rule.productOfferingId]?.name ?? "-",
    },
    {
      title: "Account Balance",
      children: [
        {
          title: "From",
          dataIndex: "minAccountBalance",
          key: "minAccountBalance",
          render: (index, rule) => (rule.minAccountBalance ? `$${rule.minAccountBalance}` : "-"),
        },
        {
          title: "To",
          dataIndex: "maxAccountBalance",
          key: "maxAccountBalance",
          render: (index, rule) => (rule.maxAccountBalance ? `$${rule.maxAccountBalance}` : "-"),
        },
      ],
      key: "dollarAmount",
    },
    {
      title: "Days Since Upload",
      children: [
        {
          title: "From",
          dataIndex: "minAccountAgeDays",
          key: "minAccountAgeDays",
          render: (index, rule) => rule?.minAccountAgeDays ?? "-",
        },
        {
          title: "To",
          dataIndex: "maxAccountAgeDays",
          key: "maxAccountAgeDays",
          render: (index, rule) => rule?.maxAccountAgeDays ?? "-",
        },
      ],
      key: "daysSinceUpload",
    },
  ];
  return <Table bordered columns={columns} dataSource={populateKey(rules)} pagination={false} />;
}
