import InvoiceDetail from "features/invoices/components/invoiceDetail";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function InvoiceDetailPage() {
  return (
    <PageLayout>
      <Content>
        <InvoiceDetail />
      </Content>
    </PageLayout>
  );
}

export default InvoiceDetailPage;
