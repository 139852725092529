export default [
  {
    key: "debtor_id",
    label: "External Debtor ID",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "phone_number",
    label: "Debtor Phone Number",
    type: "string",
    validators: [{ validate: "required" }],
  },
];
