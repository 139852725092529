import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { getColumnSearchProps } from "common/antd-utils";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import EditTaxPlanRuleGroupModal from "features/taxPlans/components/editTaxPlanRuleGroupModal";
import {
  useDeleteTaxPlanRuleGroupMutation,
  useFetchTaxPlanRuleGroupsQuery,
  useFetchTaxPlansQuery,
} from "features/taxPlans/taxPlansAPI";
import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import TaxPlanRuleGroupEntry from "./taxPlanRuleGroupEntry";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function TaxRuleGroupTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRuleGroup, setSelectedRuleGroup] = useState(null);
  const [deleteTaxPlanRuleGroup] = useDeleteTaxPlanRuleGroupMutation();
  const { data: taxPlanRuleGroups = [], isLoading } = useFetchTaxPlanRuleGroupsQuery();
  const { data: constants = { states: [] } } = useFetchBackendConstantsQuery();
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const { data: taxPlans } = useFetchTaxPlansQuery();

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const {
    data: {
      [PERMISSIONS.TAX_PLAN__GET]: isTaxPlanRuleGroupGetAuthorized,
      [PERMISSIONS.TAX_PLAN__CREATE]: isTaxPlanRuleGroupCreateAuthorized,
      [PERMISSIONS.TAX_PLAN__UPDATE]: isTaxPlanRuleGroupUpdateAuthorized,
      [PERMISSIONS.TAX_PLAN__DELETE]: isTaxPlanRuleGroupDeleteAuthorized,
    },
  } = useAuthorizations();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isTaxPlanRuleGroupUpdateAuthorized && !isTaxPlanRuleGroupDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isTaxPlanRuleGroupUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedRuleGroup(record);
                setModalVisible(true);
              }}
            />
          )}
          {isTaxPlanRuleGroupDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this tax plan rule group?"
              onConfirm={async () => {
                const result = await deleteTaxPlanRuleGroup({
                  id: record.id,
                });
                if ("data" in result) {
                  message.success("Tax plan rule group removed successfully!");
                }
              }}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  const onNewRuleOk = () => {
    setModalVisible(false);
    setSelectedRuleGroup(null);
  };

  const TaxPlanRuleGroupEntryWithData = useCallback(
    (props) => {
      const taxPlanRuleGroup = taxPlanRuleGroups.find((ruleGroup) => ruleGroup.id === props.id);
      return (
        <TaxPlanRuleGroupEntry
          {...props}
          taxPlans={taxPlans}
          taxPlanRuleGroup={taxPlanRuleGroup}
          states={constants.states}
        />
      );
    },
    [taxPlanRuleGroups, taxPlans, constants.states],
  );

  return isTaxPlanRuleGroupGetAuthorized ? (
    <>
      <h4>Tax Plan Rule Groups</h4>
      <Row align="middle">
        <span>Configure a group of tax plans and the account criteria for which each apply.</span>
        {isTaxPlanRuleGroupCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Tax Plan Rule Group
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        expandable={{
          expandedRowRender: TaxPlanRuleGroupEntryWithData,
          // @ts-ignore
          rowExpandable: (record) => record.rules?.length > 0,
        }}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={taxPlanRuleGroups}
      />
      {/* The isModalVisible forces the modal to rerender itself and the form inside when toggling the modal's visibility
          This forced rerendering is needed for the form to use the latest initialValues */}
      {isModalVisible && (
        <EditTaxPlanRuleGroupModal
          title={selectedRuleGroup ? "Edit Tax Plan Rule Group" : "Add Tax Plan Rule Group"}
          selectedRuleGroup={selectedRuleGroup}
          setSelectedRuleGroup={setSelectedRuleGroup}
          open
          onOk={onNewRuleOk}
          onCancel={() => {
            setModalVisible(false);
            setSelectedRuleGroup(null);
          }}
        />
      )}
    </>
  ) : null;
}
