import api, { transformRequest, transformResponse } from "services/api";

export const communicationSequencesApi = api
  .enhanceEndpoints({ addTagTypes: ["CommunicationSequences", "CommunicationSequenceSteps"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCommunicationSequences: build.query({
        query: () => "/api/v1/core/communication/sequences/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CommunicationSequences", id })),
                { type: "CommunicationSequences", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CommunicationSequences', id: 'LIST' }` is invalidated
              [{ type: "CommunicationSequences", id: "LIST" }],
      }),
      fetchCommunicationSequence: build.query({
        query: (payload) => `/api/v1/core/communication/sequences/${payload.sequenceId}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result, error, arg) => [
          { type: "CommunicationSequences", id: arg.sequenceId },
        ],
      }),
      createCommunicationSequence: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/communication/sequences/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CommunicationSequences-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationSequences", id: "LIST" }],
      }),
      updateCommunicationSequence: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/sequences/${payload.sequenceId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CommunicationSequences-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationSequences", id: "LIST" }],
      }),
      archiveCommunicationSequence: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/sequences/${payload.sequenceId}/archive/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all CommunicationSequences-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CommunicationSequences", id: "LIST" }],
      }),
      fetchCommunicationSequenceSteps: build.query({
        query: (payload) => ({
          url: "api/v1/core/communication/sequences/steps/",
          method: "GET",
          params: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CommunicationSequenceSteps", id })),
                { type: "CommunicationSequenceSteps", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CommunicationSequenceSteps', id: 'LIST' }` is invalidated
              [{ type: "CommunicationSequenceSteps", id: "LIST" }],
      }),
      addCommunicationSequenceStep: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/sequences/${payload.sequenceId}/steps/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CommunicationSequenceSteps", id: "LIST" }],
      }),
      updateCommunicationSequenceStep: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/sequences/${payload.sequenceId}/steps/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "CommunicationSequenceSteps", id: arg.id },
        ],
      }),
      deleteCommunicationSequenceStep: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/sequences/${payload.sequenceId}/steps/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "CommunicationSequenceSteps", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchCommunicationSequencesQuery,
  useFetchCommunicationSequenceQuery,
  useCreateCommunicationSequenceMutation,
  useUpdateCommunicationSequenceMutation,
  useArchiveCommunicationSequenceMutation,
  useAddCommunicationSequenceStepMutation,
  useUpdateCommunicationSequenceStepMutation,
  useDeleteCommunicationSequenceStepMutation,
  useFetchCommunicationSequenceStepsQuery,
} = communicationSequencesApi;
