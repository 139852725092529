import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Popover, Select } from "antd";
import { useUserType } from "features/auth";
import {
  useFetchCreditorSummariesQuery,
  useLazyFetchCreditorsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import {
  useFetchCreditorPortalCreditorSummariesQuery,
  useLazyFetchCreditorPortalCreditorsQuery,
} from "features/creditors/creditorPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

import {
  useFetchCreditorPortalProductOfferingsQuery,
  useFetchProductOfferingsQuery,
} from "features/productOfferings/productOfferingsAPI";
import styled from "styled-components";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1890ff;
  cursor: pointer;
  margin-left: 4px;
`;

function CreditorStep() {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const form = Form.useFormInstance();
  const [
    fetchAgencyCreditors,
    { data: agencyCreditorsResponse, isLoading: isAgencyCreditorsLoading },
  ] = useLazyFetchCreditorsQuery();
  const [
    fetchCreditorPortalCreditors,
    { data: creditorPortalCreditorsResponse, isLoading: isCreditorPortalLoading },
  ] = useLazyFetchCreditorPortalCreditorsQuery();
  const { data: creditors, isLoading: isCreditorsSummariesLoading } =
    useFetchCreditorSummariesQuery({}, { skip: !isAgencyUserType });
  const { data: creditorPortalCreditors, isLoading: isCreditorPortalCreditorSummariesLoading } =
    useFetchCreditorPortalCreditorSummariesQuery({}, { skip: isAgencyUserType });

  const { data: constants = [], isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();
  const { data: agencyProductOfferings = [], isLoading: isAgencyProductOfferingsLoading } =
    useFetchProductOfferingsQuery({}, { skip: !isAgencyUserType });
  const { data: creditorProductOfferings = [], isLoading: isCreditorProductOfferingsLoading } =
    useFetchCreditorPortalProductOfferingsQuery({}, { skip: isAgencyUserType });

  const fetchCreditors = isAgencyUserType ? fetchAgencyCreditors : fetchCreditorPortalCreditors;
  const productOfferings = isAgencyUserType ? agencyProductOfferings : creditorProductOfferings;
  const isProductOfferingsLoading = isAgencyUserType
    ? isAgencyProductOfferingsLoading
    : isCreditorProductOfferingsLoading;
  const creditorsResponse = isAgencyUserType
    ? agencyCreditorsResponse
    : creditorPortalCreditorsResponse;
  const isLoading = isAgencyUserType ? isAgencyCreditorsLoading : isCreditorPortalLoading;

  const selectedCreditor = creditorsResponse?.results?.[0];

  const onCreditorIdChange = async (creditorId) => {
    form.setFieldsValue({ creditorId, cbrClassCode: null, productOfferingId: null });
    fetchCreditors({ filters: { creditorIds: [creditorId] } });
  };

  return (
    <>
      {isAgencyUserType && (
        <Form.Item
          label="Client"
          name="creditorId"
          rules={[{ required: true, message: "This field is required." }]}
          tooltip={selectedCreditor?.notes ?? null}
        >
          <Select
            allowClear
            data-testid="creditor-select"
            showSearch
            placeholder="Select one..."
            filterOption={(input, option) =>
              (option.label.toString() ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={creditors?.map((creditor) => ({
              value: creditor.id,
              label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
            }))}
            loading={isCreditorsSummariesLoading}
            onChange={onCreditorIdChange}
          />
        </Form.Item>
      )}
      {isCreditorUserType && (
        <Form.Item
          label="Client"
          name="creditorId"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Select
            allowClear
            data-testid="creditor-select"
            showSearch
            placeholder="Select one..."
            filterOption={(input, option) =>
              (option.label.toString() ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={creditorPortalCreditors?.map((creditor) => ({
              value: creditor.id,
              label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
            }))}
            loading={isCreditorPortalCreditorSummariesLoading}
            onChange={onCreditorIdChange}
          />
        </Form.Item>
      )}
      <Form.Item valuePropName="checked" name="isDifferentCurrentCreditor">
        {/* See https://aktos.atlassian.net/browse/AK-1595 for more details. */}
        <Checkbox>
          Different Current Creditor
          <Popover
            content="Represents the current owner of the debt, and is what is used for the Debt Validation Notice."
            trigger="hover"
            placement="top"
          >
            <StyledQuestionCircleOutlined />
          </Popover>
        </Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isDifferentCurrentCreditor !== currentValues.isDifferentCurrentCreditor
        }
      >
        {({ getFieldValue }) => {
          const isDifferentCurrentCreditor = getFieldValue("isDifferentCurrentCreditor");
          if (isDifferentCurrentCreditor) {
            return (
              <Form.Item
                name="currentCreditorName"
                label="Current Creditor Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the current creditor name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item valuePropName="checked" name="isDifferentOriginalCreditor">
        {/* See https://aktos.atlassian.net/browse/AK-1595 for more details. */}
        <Checkbox>
          Different Original Creditor
          <Popover
            content="Represents the original creditor the debtor originally owed the money to, and is what is used for Credit Reporting."
            trigger="hover"
            placement="top"
          >
            <StyledQuestionCircleOutlined />
          </Popover>
        </Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isDifferentOriginalCreditor !== currentValues.isDifferentOriginalCreditor
        }
      >
        {({ getFieldValue }) => {
          const isDifferentOriginalCreditor = getFieldValue("isDifferentOriginalCreditor");
          if (isDifferentOriginalCreditor) {
            return (
              <Form.Item
                name="originalCreditorName"
                label="Original Creditor Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the original creditor name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item
        name="cbrClassCode"
        label="CBR Class Code"
        rules={[{ required: true, message: "Please select the CBR Class Code" }]}
      >
        <Select
          placeholder="Select the CBR Class Code"
          options={constants.cbrClassCodes
            .filter((dt) => {
              return (selectedCreditor?.cbrClassCodes ?? []).includes(dt.value);
            })
            .map((cbrClassCodeOption) => ({
              label: cbrClassCodeOption.display,
              value: cbrClassCodeOption.value,
            }))}
          loading={isConstantsLoading || isLoading}
          disabled={!selectedCreditor}
        />
      </Form.Item>
      <Form.Item
        name="productOfferingId"
        label="Product"
        rules={[{ required: true, message: "Please select a product" }]}
      >
        <Select
          placeholder="Select a product"
          options={productOfferings
            .filter((product) => {
              return (selectedCreditor?.productOfferingIds ?? []).includes(product.id);
            })
            .map((product) => ({
              label: product.name,
              value: product.id,
            }))}
          loading={isProductOfferingsLoading || isLoading}
          disabled={!selectedCreditor}
        />
      </Form.Item>
    </>
  );
}

export default CreditorStep;
