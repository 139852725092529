import api, { transformRequest, transformResponse } from "services/api";

export const organizationSettingsApi = api
  .enhanceEndpoints({
    addTagTypes: ["ForwardingEntities", "ForwardingEntityContacts", "ForwardingEntityAddresses"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchForwardingEntities: build.query({
        query: () => "/api/v1/core/forwarding-entities/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "ForwardingEntities", id })),
                { type: "ForwardingEntities", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ForwardingEntities', id: 'LIST' }` is invalidated
              [{ type: "ForwardingEntities", id: "LIST" }],
      }),
      fetchForwardingEntityContacts: build.query({
        query: (payload) =>
          `/api/v1/core/forwarding-entities/${payload.forwardingEntityId}/contacts/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "ForwardingEntityContacts", id })),
                { type: "ForwardingEntityContacts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ForwardingEntityContacts', id: 'LIST' }` is invalidated
              [{ type: "ForwardingEntityContacts", id: "LIST" }],
      }),
      fetchForwardingEntityAddresses: build.query({
        query: (payload) =>
          `/api/v1/core/forwarding-entities/${payload.forwardingEntityId}/addresses/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "ForwardingEntityAddresses", id })),
                { type: "ForwardingEntityAddresses", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ForwardingEntityAddresses', id: 'LIST' }` is invalidated
              [{ type: "ForwardingEntityAddresses", id: "LIST" }],
      }),
      createForwardingEntity: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/forwarding-entities/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ForwardingEntities", id: "LIST" }],
      }),
      updateForwardingEntity: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/forwarding-entities/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "ForwardingEntities", id: arg.id }],
      }),
      deleteForwardingEntity: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/forwarding-entities/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "ForwardingEntities", id: "LIST" }],
      }),
      createForwardingEntityAddress: build.mutation({
        query: ({ forwardingEntityId, address }) => ({
          url: `/api/v1/core/forwarding-entities/${forwardingEntityId}/addresses/create/`,
          method: "POST",
          body: transformRequest(address),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ForwardingEntityAddresses", id: "LIST" }],
      }),
      deleteForwardingEntityAddress: build.mutation({
        query: ({ forwardingEntityId, address }) => ({
          url: `/api/v1/core/forwarding-entities/${forwardingEntityId}/addresses/${address.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "ForwardingEntityAddresses", id: "LIST" }],
      }),
      updateForwardingEntityAddress: build.mutation({
        query: ({ forwardingEntityId, address }) => ({
          url: `/api/v1/core/forwarding-entities/${forwardingEntityId}/addresses/${address.id}/update/`,
          method: "POST",
          body: transformRequest(address),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "ForwardingEntityAddresses", id: arg.address.id },
        ],
      }),
      createForwardingEntityContact: build.mutation({
        query: ({ forwardingEntityId, contact }) => ({
          url: `/api/v1/core/forwarding-entities/${forwardingEntityId}/contacts/create/`,
          method: "POST",
          body: transformRequest(contact),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ForwardingEntityContacts", id: "LIST" }],
      }),
      deleteForwardingEntityContact: build.mutation({
        query: ({ forwardingEntityId, contact }) => ({
          url: `/api/v1/core/forwarding-entities/${forwardingEntityId}/contacts/${contact.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "ForwardingEntityContacts", id: "LIST" }],
      }),
      updateForwardingEntityContact: build.mutation({
        query: ({ forwardingEntityId, contact }) => ({
          url: `/api/v1/core/forwarding-entities/${forwardingEntityId}/contacts/${contact.id}/update/`,
          method: "POST",
          body: transformRequest(contact),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "ForwardingEntityContacts", id: arg.contact.id },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchForwardingEntitiesQuery,
  useFetchForwardingEntityContactsQuery,
  useFetchForwardingEntityAddressesQuery,
  useCreateForwardingEntityMutation,
  useUpdateForwardingEntityMutation,
  useCreateForwardingEntityAddressMutation,
  useUpdateForwardingEntityAddressMutation,
  useCreateForwardingEntityContactMutation,
  useUpdateForwardingEntityContactMutation,
  useDeleteForwardingEntityAddressMutation,
  useDeleteForwardingEntityContactMutation,
  useDeleteForwardingEntityMutation,
} = organizationSettingsApi;
