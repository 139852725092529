import { Form, Input } from "antd";
import { AktDatePicker } from "components/aktDatePicker";

function BaseStep({ debtType }) {
  if (debtType === "consumer") {
    return (
      <>
        <Form.Item name="type" initialValue="consumer" hidden />
        <Form.Item label="First name" name="firstName" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Middle name" name="middleName">
          <Input />
        </Form.Item>
        <Form.Item label="Last name" name="lastName" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="SSN" name="ssn">
          <Input placeholder="XXX-XX-XXXX" />
        </Form.Item>
        <Form.Item label="Date of Birth" name="dob">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Driver's License" name="driversLicense">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
      </>
    );
  }
  return (
    <>
      <Form.Item name="type" initialValue="commercial" hidden />
      <Form.Item label="Company Name" name="companyName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="EIN" name="ein">
        <Input />
      </Form.Item>
      <Form.Item label="Phone" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="Fax" name="fax">
        <Input />
      </Form.Item>
    </>
  );
}

export default BaseStep;
