import { useFetchMeQuery } from "features/auth/authAPI";

/*
// usage example

import useUserType from './useUserType';

const PrivateComponent = () => {
  const { data: isAgencyUserType } = useUserType();

  return isAgencyUserType ? (
    <h1>I Am an agency</h1>
  ) : (
    <p>I am not an agency, oops.</p>
  );
};
*/

const useUserType = () => {
  const { data: me, ...rest } = useFetchMeQuery();

  const userType = me?.type;

  return {
    data: userType,
    isAgencyUserType: userType === "agency",
    isCreditorUserType: userType === "creditor",
    ...rest,
  };
};

export default useUserType;
