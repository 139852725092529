import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { PageHeader } from "components/pageHeader";
import currency from "currency.js";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import PaymentRuleModal from "features/settlementAndPaymentRules/components/paymentRuleModal";
import {
  useDeletePaymentRuleMutation,
  useFetchPaymentRulesQuery,
} from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

export default function PaymentRulesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: paymentRules, isLoading } = useFetchPaymentRulesQuery();
  const [selectedRule, setSelectedRule] = useState(null);
  const [deletePaymentRule] = useDeletePaymentRuleMutation();

  const onNewRule = async () => {
    setModalVisible(false);
    setSelectedRule(null);
  };

  const {
    data: {
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__GET]: isSettlementPaymentRuleGetAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__CREATE]: isSettlementPaymentRuleCreateAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__UPDATE]: isSettlementPaymentRuleUpdateAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__DELETE]: isSettlementPaymentRuleDeleteAuthorized,
    },
  } = useAuthorizations();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Minimum Payment Amount",
      dataIndex: "minimumPaymentAmount",
      key: "minimumPaymentAmount",
      render: (amount) => (amount ? `$${currency(amount, { precision: 4 }).value}` : "-"),
    },
    {
      title: "Maximum Delay (days)",
      dataIndex: "maximumDelayInDays",
      key: "maximumDelayInDays",
    },
    {
      title: "Maximum Number of Payments",
      dataIndex: "maximumPlanPaymentCount",
      key: "maximumPlanPaymentCount",
      render: (amount) => amount ?? "-",
    },
    {
      title: "Maximum Plan Length (days)",
      dataIndex: "maximumPlanLengthInDays",
      key: "maximumPlanLengthInDays",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isSettlementPaymentRuleUpdateAuthorized && !isSettlementPaymentRuleDeleteAuthorized,
      render: (record) => (
        <Space size="middle">
          {isSettlementPaymentRuleUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedRule(record);
                setModalVisible(true);
              }}
            />
          )}
          {isSettlementPaymentRuleDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this payment rule?"
              onConfirm={async () => {
                const result = await deletePaymentRule({ id: record.id });
                if ("data" in result) {
                  message.success("Payment rule deleted successfully!");
                }
              }}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return isSettlementPaymentRuleGetAuthorized ? (
    <>
      <StyledHeader>Payment Rules</StyledHeader>
      <StyledRow align="middle">
        <span>Define rules for one-time payments and payment plans.</span>
        {isSettlementPaymentRuleCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} icon={<PlusOutlined />} type="link">
            Add Rule
          </Button>
        )}
      </StyledRow>
      <Table
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={paymentRules}
      />
      {isModalVisible && (
        <PaymentRuleModal
          selectedRule={selectedRule}
          title="Add Payment Rule"
          onOk={onNewRule}
          onCancel={() => {
            setSelectedRule(null);
            setModalVisible(false);
          }}
        />
      )}
    </>
  ) : null;
}
