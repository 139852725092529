import { EmployersTable } from "features/employersTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function EmployersPage() {
  return (
    <PageLayout>
      <Content>
        <EmployersTable />
      </Content>
    </PageLayout>
  );
}

export default EmployersPage;
