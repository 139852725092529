import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Space, message } from "antd";
import { getFirstItem } from "common/utils";
import { selectAccountsTableSlice } from "features/accountsTable/accountsTableSlice";
import { usePostMergeDebtorsMutation } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useState } from "react";
import { useSelector } from "react-redux";

function MergeAccountsModal({ title, open, onOk, onCancel }) {
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const [selectedDebtor, setSelectedDebtor] = useState(null);
  const [mergeDebtors, { isLoading }] = usePostMergeDebtorsMutation();

  const resetFields = () => {
    setSelectedDebtor(null);
  };

  const debtors = accountsTableSlice.selectedRows.map((row) => ({
    id: row.debtorId,
    name: row.debtorName,
  }));

  const onSubmit = async () => {
    // validate the form
    if (!selectedDebtor?.id) return;

    const selectedDebtorIds = accountsTableSlice?.filteredAccounts
      .map((row) => row.debtorId)
      .filter((id) => id !== selectedDebtor.id);

    const result = await mergeDebtors({
      primaryDebtorId: selectedDebtor.id,
      secondaryDebtorId: getFirstItem(selectedDebtorIds),
    });
    if ("data" in result) {
      message.success(`Merged debtors.`);
      onOk();
      resetFields();
    }
    if ("error" in result) {
      message.error(`Failed to merge accounts. Please try again later.`);
    }
  };

  return (
    <Modal
      maskClosable={false}
      okButtonProps={{ loading: isLoading, disabled: isLoading }}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <span style={{ marginRight: "8px" }}>Select which name to use:</span>
      <Dropdown
        trigger={["click"]}
        menu={{
          items: debtors.map((debtor) =>
            debtor ? { label: debtor.name, key: debtor.id } : undefined,
          ),
          onClick: ({ key }) => {
            setSelectedDebtor(debtors.find((debtor) => debtor.id === parseInt(key, 10)));
          },
        }}
      >
        <Button>
          <Space>
            {selectedDebtor?.name || "Select a name..."}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Modal>
  );
}

export default MergeAccountsModal;
