import { Form, Input, Modal, Select, message } from "antd";

import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useLazyDownloadChecksQuery } from "features/invoices/invoiceAPI";
import { useFetchLetterTemplatesQuery } from "features/letterTemplates/letterTemplatesAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function DownloadChecksModal({ open, onOk, onCancel }) {
  const [form] = useForm();
  const { data: letterTemplates, isLoading: isLoadingLetterTemplates } =
    useFetchLetterTemplatesQuery();
  const [downloadChecks, { isLoading: isLoadingChecks }] = useLazyDownloadChecksQuery();

  const onSubmit = async () => {
    const values = await form.validateFields();
    const { invoiceDateRange, letterTemplateId, initialCheckNumber } = values;
    const [invoiceStartDate, invoiceEndDate] = invoiceDateRange;

    const result = await downloadChecks({
      letterTemplateId,
      initialCheckNumber,
      invoiceStartDate,
      invoiceEndDate,
    });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(
        "Checks file is being generated. Please check back later in the Generated Documents section.",
      );
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const disabledAfterTodayDate = (current) => {
    const today = moment().endOf("day");
    return current && current > today;
  };

  return (
    <Modal
      maskClosable={false}
      title="Download Checks"
      open={open}
      confirmLoading={isLoadingChecks}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        layout="vertical"
        form={form}
        disabled={isLoadingChecks}
        initialValues={{
          publishedDate: [
            moment().startOf("month").format(DATE_FORMAT),
            moment().endOf("month").format(DATE_FORMAT),
          ],
        }}
      >
        <Form.Item
          label="Letter Template"
          name="letterTemplateId"
          rules={[{ required: true, message: "Select letter template" }]}
        >
          <Select
            disabled={isLoadingChecks || isLoadingLetterTemplates}
            options={letterTemplates
              ?.filter((template) => template.type === "invoice_check")
              .map((template) => ({
                value: template.id,
                label: template.filename,
              }))}
          />
        </Form.Item>
        <Form.Item
          name="invoiceDateRange"
          label="Invoice Dates"
          rules={[{ required: true, message: "Select invoice date range" }]}
        >
          <AktDateRangePicker disabledDate={disabledAfterTodayDate} picker="date" allowClear />
        </Form.Item>
        <Form.Item
          name="initialCheckNumber"
          label="Initial Check Number"
          rules={[{ required: true, message: "The field is required" }]}
        >
          <Input placeholder="Initial Check Number" />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default DownloadChecksModal;
