import api, { transformResponse } from "services/api";

export const priorityAccountRuleApi = api
  .enhanceEndpoints({
    addTagTypes: ["MetaBaseReports", "MetaBaseReportDetails"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchMetabaseReports: build.query({
        query: () => "/api/v1/core/reports/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "MetaBaseReports", id })),
                { type: "MetaBaseReports", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'MetaBaseReports', id: 'LIST' }` is invalidated
              [{ type: "MetaBaseReports", id: "LIST" }],
      }),
      fetchMetabaseReportDetails: build.query({
        query: (payload) => `/api/v1/core/reports/${payload.id}`,
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "MetaBaseReportDetails", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const { useFetchMetabaseReportDetailsQuery, useFetchMetabaseReportsQuery } =
  priorityAccountRuleApi;
