import { UploadOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Modal, Select, Tag, Upload, message } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import {
  useCreateDisputeMutation,
  useFetchDisputeCategoriesQuery,
  useUpdateAccountMutation,
  useUpdateDisputeMutation,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { BCCC_CODES } from "features/debtorAccountsTable/components/creditBureauSettingsModal";
import { usePostDocumentsMutation } from "features/documentsTable/agencyPortal/documentsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  margin-left: 5px;
  text-transform: uppercase;
`;

const StyledDragger = styled(Upload.Dragger)`
  margin: 12px 0;
  cursor: pointer;
`;

const StyledP = styled.p`
  color: grey;
  font-size: 12px;
  font-style: italic;
`;

function ManageDisputeModal({ title, open, onOk, onCancel, account, debtorId }) {
  // const { isCreditorUserType } = useUserType();
  const { dispute } = account;
  const { data: categories } = useFetchDisputeCategoriesQuery({ creditorId: account?.creditorId });
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createDispute, { isLoading: createLoading }] = useCreateDisputeMutation();
  const [updateDispute, { isLoading: updateLoading }] = useUpdateDisputeMutation();
  const [updateAccountDebt, { isLoading: updateAccountLoading }] = useUpdateAccountMutation();
  const [postDocuments] = usePostDocumentsMutation();
  const [form] = Form.useForm();

  const disabledDate = (current) => {
    // Can not select days tomorrow and later
    return current && current > moment().endOf("day");
  };

  const [fileList, setFileList] = useState([]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const draggerProps = {
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onDispute = async () => {
    const values = await form.validateFields();
    const disputeAction = dispute?.status ? updateDispute : createDispute;
    const result = await disputeAction({
      accountId: account?.id,
      disputeId: dispute?.id,
      ...values.dispute,
    });
    await updateAccountDebt({
      accountId: account?.id,
      complianceConditionCode: values?.complianceConditionCode,
    });
    if ("data" in result) {
      if (values?.file) {
        const disputeDocumentType = constants.documentTypes?.find(
          (documentType) => documentType.value === "dispute",
        )?.value;
        // We only support sending a single file
        const documentUploadResult = await postDocuments({
          debtorId,
          disputeId: result?.data?.result?.id,
          file: values?.file[0],
          filename: values?.file[0].name,
          type: disputeDocumentType,
        });
        if ("data" in documentUploadResult) {
          message.success(`${values?.file[0].name} file uploaded successfully`);
        }
      }

      message.success(
        `Dispute has been ${dispute?.status ? "updated" : "created"} for this account`,
      );
      setFileList([]);
      onOk(); // Close the modal using the parent callback
    }
  };

  const initialValues = {
    dispute: {
      ...dispute,
      amount: dispute?.amount ?? account?.originalTotal,
    },
    complianceConditionCode: account?.complianceConditionCode || null,
  };

  return (
    <Modal
      maskClosable={false}
      title={
        <>
          {title}
          {dispute?.status === "resolved" ? <StyledTag color="green">resolved</StyledTag> : null}
          {dispute?.status && dispute?.status !== "resolved" ? (
            <StyledTag color="red">disputed</StyledTag>
          ) : null}
        </>
      }
      open={open}
      okButtonProps={{
        loading: createLoading || updateLoading || updateAccountLoading,
      }}
      onOk={onDispute}
      okText={!dispute?.status ? "Create Dispute" : "Update"}
      onCancel={onCancel}
      confirmLoading={createLoading || updateLoading || updateAccountLoading}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        labelAlign="left"
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="Amount in Dispute"
          name={["dispute", "amount"]}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} prefix="$" controls={false} step={0.01} />
        </Form.Item>
        <Form.Item label="Category" name={["dispute", "categoryTagIds"]}>
          <Select
            mode="multiple"
            options={categories?.map((each) => ({
              label: each.name,
              value: each.id,
            }))}
          />
        </Form.Item>
        <Form.Item label="Status" name={["dispute", "status"]} rules={[{ required: true }]}>
          <Select
            options={constants?.disputeStatuses.map((each) => ({
              label: each.display,
              value: each.value,
            }))}
          />
        </Form.Item>
        <Form.Item name={["complianceConditionCode"]} label="Bureau Compliance Condition Code">
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select an option"
            options={[
              {
                label: "None",
                value: null,
              },
              ...BCCC_CODES,
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Date Received"
          name={["dispute", "receivedDate"]}
          rules={[{ required: true }]}
        >
          <AktDatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item label="Date Resolved" name={["dispute", "resolvedDate"]}>
          <AktDatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item label="Notes" name={["dispute", "notes"]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="file" valuePropName="fileList" getValueFromEvent={normFile}>
          <StyledDragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload documentation
            </p>
          </StyledDragger>
        </Form.Item>
        <StyledP>Note: Please visit the Documents tab to see previously uploaded files.</StyledP>
      </Form>
    </Modal>
  );
}

export default ManageDisputeModal;
