import { TableFormList } from "components/formList";
import {
  useCreateCreditorAddressMutation,
  useDeleteCreditorAddressMutation,
  useFetchCreditorAddressesQuery,
  useUpdateCreditorAddressMutation,
} from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useMemo } from "react";

export default function AddressesStep({ creditorId }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: creditorAddresses, isLoading } = useFetchCreditorAddressesQuery(
    {
      creditorId,
    },
    { skip: !creditorId },
  );
  const [updateCreditorAddress] = useUpdateCreditorAddressMutation();
  const [deleteCreditorAddress] = useDeleteCreditorAddressMutation();
  const [createCreditorAddress] = useCreateCreditorAddressMutation();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const addressTypeOptions = useMemo(() => {
    return constants?.addressTypes
      ?.filter((type) => type.value !== "home")
      .map(({ value, display }) => ({ value, label: display }));
  }, [constants?.addressTypes]);

  const columns = [
    {
      dataIndex: "type",
      editable: true,
      inputType: "select",
      title: "Type",
      options: addressTypeOptions,
    },
    {
      dataIndex: "address1",
      editable: true,
      inputType: "text",
      title: "Address 1",
      rules: [
        {
          required: true,
          message: "Enter Address 1",
        },
      ],
    },
    {
      dataIndex: "address2",
      editable: true,
      inputType: "text",
      title: "Address 2",
    },
    {
      dataIndex: "city",
      editable: true,
      inputType: "text",
      title: "City",
      rules: [
        {
          required: true,
          message: "Enter city",
        },
      ],
    },
    {
      dataIndex: "state",
      editable: true,
      inputType: "select",
      rules: [
        {
          required: true,
          message: "Select state",
        },
      ],
      options: constants?.states.map((state) => ({
        label: state.display,
        value: state.value,
      })),
      inputProps: {
        filterOption,
        showSearch: true,
      },
      title: "State",
    },
    {
      dataIndex: "zipCode",
      editable: true,
      inputType: "text",
      rules: [
        {
          required: true,
          pattern: /^\d{5}(-\d{4})?$/,
          message: "Must be a valid zip code",
        },
      ],
      title: "Zip Code",
    },
    {
      dataIndex: "notes",
      editable: true,
      inputType: "text",
      title: "Notes",
    },
  ];

  return (
    <TableFormList
      loading={isLoading}
      defaultValues={{ type: "mail" }}
      columns={columns}
      data={creditorAddresses}
      onDelete={(address) =>
        deleteCreditorAddress({
          creditorId,
          address,
        })
      }
      onSave={(address) => {
        const action = address.id ? updateCreditorAddress : createCreditorAddress;
        return action({
          creditorId,
          address,
        });
      }}
    />
  );
}
