import { useAuth0 } from "@auth0/auth0-react";
import config from "app/envConfig";
import { resetStateAction } from "common/redux/resetStateAction";
import { useDispatch } from "react-redux";

function Logout() {
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  dispatch(resetStateAction());

  if (config.useAuth0) {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
  }
}

export default Logout;
