import { SORT_ORDER } from "features/appearance/constants";

export default function reconcileColumnsWithWorklist(defaultColumns, defaultSort) {
  if (!defaultSort) {
    return defaultColumns;
  }

  const defaultColumnKey = defaultSort.replace(/^-/, "");
  const defaultDirection = defaultSort.startsWith("-") ? SORT_ORDER.DESCEND : SORT_ORDER.ASCEND;

  return defaultColumns.map((column) => {
    return {
      ...column,
      defaultSortOrder: column.key === defaultColumnKey ? defaultDirection : undefined,
    };
  });
}
