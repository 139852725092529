import { Form, Input, Modal, Select, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToSnakeCase, camelToWords, snakeToCamelCase } from "common/utils";
import {
  useCreateAuthenticationMethodMutation,
  useFetchAuthenticationSchemaQuery,
  useUpdateAuthenticationMethodMutation,
} from "features/authenticationMethods/authenticationMethodsAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function ManageAuthenticationMethodModal({ onOk, onCancel, authenticationMethod }) {
  const [form] = Form.useForm();
  const [addAuthenticationMethod, { isLoading: isCreateAuthenticationMethodLoading }] =
    useCreateAuthenticationMethodMutation();
  const [editAuthenticationMethod, { isLoading: isUpdateAuthenticationMethodLoading }] =
    useUpdateAuthenticationMethodMutation();
  const { data: authenticationMethodsSchema, isLoading: isSchemaLoading } =
    useFetchAuthenticationSchemaQuery();

  const onSubmit = async () => {
    const newRule = await form.validateFields();
    const action = authenticationMethod ? editAuthenticationMethod : addAuthenticationMethod;
    const result = await action(newRule);
    if ("data" in result) {
      const successMessage = authenticationMethod
        ? "Authentication method updated successfully!"
        : "Authentication method added successfully!";
      message.success(successMessage);
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      message.error(`Failed to ${authenticationMethod ? "update" : "add"} authentication method`);
    }
  };

  const initialValues = {
    ...authenticationMethod,
  };

  const typeOptions = Object.keys(authenticationMethodsSchema ?? {}).map((key) => ({
    value: camelToSnakeCase(key),
    label: camelToWords(key),
  }));

  return (
    <Modal
      maskClosable={false}
      title={
        authenticationMethod
          ? `Authentication Method #${authenticationMethod.id}`
          : "New Authentication Method"
      }
      open
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{
        loading:
          isSchemaLoading ||
          isCreateAuthenticationMethodLoading ||
          isUpdateAuthenticationMethodLoading,
      }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Select
            options={typeOptions}
            loading={isSchemaLoading}
            disabled={isSchemaLoading}
            placeholder="Select one..."
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) => {
            const typeValue = getFieldValue("type");
            const type = typeValue ? snakeToCamelCase(typeValue) : null;
            if (!type) return null;

            const fieldKeys = Object.keys(authenticationMethodsSchema?.[type] ?? {});
            if (!fieldKeys.length) return null;

            return fieldKeys.map((fieldKey) => {
              const field = authenticationMethodsSchema?.[type]?.[fieldKey];
              return (
                <Form.Item
                  key={fieldKey}
                  name={fieldKey}
                  label={camelToWords(fieldKey)}
                  rules={[
                    {
                      required: field?.isRequired,
                      message: "This field is required.",
                    },
                  ]}
                >
                  {field.type === "str" && field.isSecret && <Input type="password" />}
                  {field.type === "str" && !field.isSecret && <Input />}
                  {field.type === "int" && <Input type="number" />}
                </Form.Item>
              );
            });
          }}
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
