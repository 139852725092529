import { useState, useMemo, createContext } from "react";

const SiderContext = createContext({
  isCollapsed: false,
  toggleSidebar: () => {
    /* empty body */
  },
  displayName: "SiderContext",
});

function SiderProvider({ children }) {
  const [isCollapsed, setCollapsedState] = useState(false);

  const siderContextValue = useMemo(
    () => ({
      isCollapsed,
      toggleSidebar: () => {
        setCollapsedState(!isCollapsed);
      },
      displayName: "SiderContext",
    }),
    [isCollapsed],
  );

  return <SiderContext.Provider value={siderContextValue}>{children}</SiderContext.Provider>;
}

export default SiderProvider;
