import { Form } from "antd";
import { useFetchAllDebtorUdfCustomFieldsQuery } from "features/userDefinedFields/agencyPortal/debtorUserDefinedFieldsAPI";
import { useFetchAllCreditorPortalDebtorUdfCustomFieldsQuery } from "features/userDefinedFields/creditorPortal/debtorUserDefinedFieldsAPI";
import { snakeToCamelCase, udfToComponentMap } from "common/utils";
import { useUserType } from "features/auth";

export default function DebtorCustomFields() {
  const { isAgencyUserType } = useUserType();

  const { data: agencyDebtorCustomFields } = useFetchAllDebtorUdfCustomFieldsQuery(
    {},
    { skip: !isAgencyUserType },
  );
  const { data: creditorPortalDebtorCustomFields } =
    useFetchAllCreditorPortalDebtorUdfCustomFieldsQuery({}, { skip: isAgencyUserType });

  const debtorCustomFields = isAgencyUserType
    ? agencyDebtorCustomFields
    : creditorPortalDebtorCustomFields;

  return (
    <>
      {(debtorCustomFields ?? []).map((field) => (
        <Form.Item
          key={field.id}
          label={field.name}
          name={["customFields", `${snakeToCamelCase(field.slug)}`]}
          rules={[{ required: field.isRequired }]}
        >
          {udfToComponentMap[field.type]}
        </Form.Item>
      ))}
    </>
  );
}
