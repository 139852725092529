import api, { transformResponse } from "services/api";

export const debtorApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "CreditorPortalDebtorProfiles",
      "CreditorPortalDebtorAddresses",
      "CreditorPortalDebtorPhoneNumbers",
      "CreditorPortalDebtorEmails",
      "CreditorPortalDebtorEmployments",
      "CreditorPortalDebtorContacts",
      "CreditorPortalDebtorNotes",
      "CreditorPortalDebtorSystemLogs",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorPortalDebtorAddresses: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/addresses/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorAddresses", id })),
                { type: "CreditorPortalDebtorAddresses", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorAddresses', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorAddresses", id: "LIST" }],
      }),
      fetchCreditorPortalDebtorPhoneNumbers: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/phone-numbers/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorPhoneNumbers", id })),
                { type: "CreditorPortalDebtorPhoneNumbers", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorPhoneNumbers', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorPhoneNumbers", id: "LIST" }],
      }),
      fetchCreditorPortalDebtorEmails: build.query({
        query: (payload) => ({
          url: `/api/v1/common/debtors/${payload.debtorId}/emails/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorEmails", id })),
                { type: "CreditorPortalDebtorEmails", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorEmails', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorEmails", id: "LIST" }],
      }),
      fetchCreditorPortalDebtorEmployments: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/employments/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorEmployments", id })),
                { type: "CreditorPortalDebtorEmployments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorEmployments', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorEmployments", id: "LIST" }],
      }),
      fetchCreditorPortalDebtorContacts: build.query({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/contacts/`,
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorContacts", id })),
                { type: "CreditorPortalDebtorContacts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorContacts', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorContacts", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchCreditorPortalDebtorAddressesQuery,
  useFetchCreditorPortalDebtorContactsQuery,
  useLazyFetchCreditorPortalDebtorContactsQuery,
  useFetchCreditorPortalDebtorEmploymentsQuery,
  useFetchCreditorPortalDebtorPhoneNumbersQuery,
  useFetchCreditorPortalDebtorEmailsQuery,
} = debtorApi;
