import { useState } from "react";
import { Button } from "antd";
import styled from "styled-components";

const StyledDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(props) => (props.isExpanded ? "normal" : "nowrap")};
`;

export default function TruncatedText({ text, maxLength }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedText = `${text?.substring(0, maxLength)}...`;

  return (
    <div>
      {text?.length > maxLength ? (
        <StyledDiv isExpanded={isExpanded}>
          {isExpanded ? text : truncatedText}
          <Button type="link" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "See Less" : "See More"}
          </Button>
        </StyledDiv>
      ) : (
        <div>{text}</div>
      )}
    </div>
  );
}
