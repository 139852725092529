import { Form, Input, Modal, Select, message } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import { useUserType } from "features/auth";
import {
  useUpdateDebtorBankruptcyMutation,
  useCreateDebtorBankruptcyMutation,
} from "features/bankruptcies/bankruptciesAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function AddBankruptcyModal({ bankruptcyRecord, debtorId, title, open, onOk, onCancel }) {
  const { isAgencyUserType } = useUserType();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [updateBankruptcy] = useUpdateDebtorBankruptcyMutation();
  const [createBankruptcy] = useCreateDebtorBankruptcyMutation();
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (bankruptcyRecord) {
        await updateBankruptcy({ debtorId, bankruptcyId: bankruptcyRecord.id, ...values });
      } else {
        await createBankruptcy({ debtorId, ...values });
      }
      message.success("Bankruptcy record saved successfully!");
      form.resetFields();
      onOk();
    } catch (error) {
      message.error("An error occurred. Please try again.");
    }
  };

  // See https://ant.design/components/form/ `validateMessages` to see how to customize error
  // messages.
  const validateMessages = {
    required: "This is a required field",
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={handleOk} onCancel={onCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        autoComplete="off"
        validateMessages={validateMessages}
        initialValues={bankruptcyRecord}
      >
        <Form.Item label="Case Number" name="caseNumber">
          <Input placeholder="Enter case number..." />
        </Form.Item>
        <Form.Item label="Filing Status" name="filingStatus">
          <Input placeholder="Enter filing status..." />
        </Form.Item>
        <Form.Item label="Chapter" name="chapter">
          <Select
            placeholder="Select chapter..."
            options={constants?.bankruptcyChapters?.map((each) => ({
              label: each.display,
              value: each.value,
            }))}
          />
        </Form.Item>
        <Form.Item label="Filing Date" name="filingDate">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Dismissal Date" name="dismissalDate">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Discharge Date" name="dischargeDate">
          <AktDatePicker />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default AddBankruptcyModal;
