import api, { transformCursorResponse, transformRequest, transformResponse } from "services/api";

export const bulkActionsAPI = api
  .enhanceEndpoints({ addTagTypes: ["BulkActions", "SkipTraceWorkflows"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtors: build.query({
        query: (payload) => ({
          url: "/api/v1/core/debtors/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformCursorResponse(response),
      }),
      fetchAllBulkActions: build.query({
        query: (payload) => ({
          url: "/api/v1/core/accounts/bulk-actions/",
          method: "POST",
          body: transformRequest({ include_statuses: ["one_off_scheduled", "recurring"] }),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "BulkActions", id })),
                { type: "BulkActions", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'BulkActions', id: 'LIST' }` is invalidated
              [{ type: "BulkActions", id: "LIST" }],
      }),
      fetchBulkAction: build.query({
        query: (payload) => `/api/v1/core/accounts/bulk-actions/${payload.actionId}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) => (results ? [{ type: "BulkActions", id: results.id }] : []),
      }),
      fetchBulkActionHistory: build.query({
        query: () => "/api/v1/core/accounts/bulk-actions/tasks/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "BulkActions", id })),
                { type: "BulkActions", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'BulkActions', id: 'LIST' }` is invalidated
              [{ type: "BulkActions", id: "LIST" }],
      }),
      createBulkAction: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/bulk-actions/create/",
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all BulkActions-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "BulkActions", id: "LIST" }],
      }),
      updateBulkAction: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/bulk-actions/${payload.actionId}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all BulkActions-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "BulkActions", id: "LIST" }],
      }),
      deleteBulkAction: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/bulk-actions/${payload.actionId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all BulkActions-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "BulkActions", id: "LIST" }],
      }),
      fetchSkipTraceWorkflows: build.query({
        query: (payload) => ({
          url: "/api/v1/core/skip-trace-settings/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all SkipTraceWorkflows-type queries providing the `LIST` id - after all, depending of the sort order,
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "SkipTraceWorkflows", id })),
                { type: "SkipTraceWorkflows", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'SkipTraceWorkflows', id: 'LIST' }` is invalidated
              [{ type: "SkipTraceWorkflows", id: "LIST" }],
      }),
    }),
  });

export const {
  useLazyFetchDebtorsQuery,
  useFetchAllBulkActionsQuery,
  useFetchBulkActionQuery,
  useFetchBulkActionHistoryQuery,
  useCreateBulkActionMutation,
  useUpdateBulkActionMutation,
  useDeleteBulkActionMutation,
  useFetchSkipTraceWorkflowsQuery,
} = bulkActionsAPI;
