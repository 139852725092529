import { Result } from "antd";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-bottom: 32px;
  padding-top: 8px;
`;

export default function FinishStep() {
  return (
    <StyledCard>
      <Result
        status="success"
        title="Finish"
        subTitle="Note: Newly created jobs may not run immediately. Check the Job History to view the status of a job."
      />
    </StyledCard>
  );
}
