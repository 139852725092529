import { Form, Input, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useCreateInvoiceMutation } from "features/invoices/invoiceAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function AddInvoiceModal({ title, creditorId, open, onCancel, onOk }) {
  const [form] = useForm();
  const [createInvoice, { isLoading }] = useCreateInvoiceMutation();

  const onSubmit = async () => {
    const {
      picker: [startDate, endDate],
      ...newInvoice
    } = await form.validateFields();

    const result = await createInvoice({
      ...newInvoice,
      startDate,
      endDate,
      creditorId,
    });
    if ("data" in result) {
      message.success("Invoice added successfully!");
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm layout="vertical" form={form}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Invoice Name" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="Date Range"
          name="picker"
          initialValue={[
            moment().startOf("month").format(DATE_FORMAT),
            moment().endOf("month").format(DATE_FORMAT),
          ]}
        >
          <AktDateRangePicker picker="date" />
        </Form.Item>
        {/*
        <Form.Item name="cbrClassCodes" label="CBR Class Codes">
          <Select mode="multiple">
            {constants?.cbrClassCodes.map((d) => (
              <Select.Option key={d.value} value={d.value}>
                {d.display}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
            */}
        <Form.Item label="Notes" name="notes" initialValue="">
          <TextArea rows={4} placeholder="Note" />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default AddInvoiceModal;
