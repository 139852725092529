import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Tooltip as AntDToolTip,
  Button,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import AddDebtorTagModal from "features/tags/components/addDebtorTagModal";
import { useDeleteDebtorTagMutation, useFetchDebtorTagsQuery } from "features/tags/tagsAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
`;

export default function DebtorTagTable() {
  const { data: debtorTags, isLoading } = useFetchDebtorTagsQuery();
  const [deleteDebtorTag] = useDeleteDebtorTagMutation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const {
    data: {
      [PERMISSIONS.DEBTOR_TAG__GET]: isDebtorTagGetAuthorized,
      [PERMISSIONS.DEBTOR_TAG__CREATE]: isDebtorTagCreateAuthorized,
      [PERMISSIONS.DEBTOR_TAG__UPDATE]: isDebtorTagUpdateAuthorized,
      [PERMISSIONS.DEBTOR_TAG__DELETE]: isDebtorTagDeleteAuthorized,
    },
  } = useAuthorizations();

  const onNewDebtorTag = async () => {
    setModalVisible(false);
  };

  const handleDelete = async (record) => {
    const result = await deleteDebtorTag({ id: record.id });
    if ("data" in result) {
      message.success("Deleted debtor tag successfully.");
    }
    if ("error" in result) {
      message.error("Unable to delete debtor tag.");
    }
  };

  const onEdit = (record) => {
    setModalVisible(true);
    setActiveRecord(record);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space size="middle">
          {text}
          {record.isSystem && <Tag color="red">SYSTEM</Tag>}
        </Space>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isDebtorTagUpdateAuthorized && !isDebtorTagDeleteAuthorized,
      render: (text, record) => (
        <Space size="middle">
          {isDebtorTagUpdateAuthorized && !record.isSystem && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onEdit(record)} />
            </AntDToolTip>
          )}
          {isDebtorTagDeleteAuthorized && !record.isSystem && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this debtor tag?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete-debtor-tag" />
            </Popconfirm>
          )}
        </Space>
      ),
      fixed: "right",
    },
  ].filter((column) => !column.hidden);

  return isDebtorTagGetAuthorized ? (
    <Col>
      <Row align="middle">
        <h4>Debtor</h4>
        {isDebtorTagCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Debtor Tag
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={debtorTags}
      />
      {isModalVisible && (
        <AddDebtorTagModal
          title={activeRecord ? "Edit Debtor Tag" : "Add Debtor Tag"}
          open
          onOk={onNewDebtorTag}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          debtorTag={activeRecord}
        />
      )}
    </Col>
  ) : null;
}
