import { Checkbox, Form, Input, Modal, Select, message } from "antd";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useAddAccountUdfCustomFieldMutation,
  useUpdateAccountUdfCustomFieldMutation,
} from "features/userDefinedFields/agencyPortal/accountUserDefinedFieldsAPI";
import {
  useAddCreditorUdfCustomFieldMutation,
  useUpdateCreditorUdfCustomFieldMutation,
} from "features/userDefinedFields/agencyPortal/creditorUserDefinedFieldsAPI";
import {
  useAddDebtorUdfCustomFieldMutation,
  useUpdateDebtorUdfCustomFieldMutation,
} from "features/userDefinedFields/agencyPortal/debtorUserDefinedFieldsAPI";
import {
  useAddLegalCustomFieldMutation,
  useUpdateLegalCustomFieldMutation,
} from "features/userDefinedFields/legalCustomFieldsAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function AddUserDefinedFieldModal({
  onSubmit,
  userDefinedField = undefined,
  onCancel,
  forType,
}) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const isNew = !userDefinedField;

  // API calls hooks
  const [addAccountUdfCustomField, { isLoading: isAddAccountUdfLoading }] =
    useAddAccountUdfCustomFieldMutation();
  const [updateAccountUdfCustomField, { isLoading: isUpdateAccountUdfLoading }] =
    useUpdateAccountUdfCustomFieldMutation();
  const [addCreditorUdfCustomField, { isLoading: isAddCreditorUdfLoading }] =
    useAddCreditorUdfCustomFieldMutation();
  const [updateCreditorUdfCustomField, { isLoading: isUpdateCreditorUdfLoading }] =
    useUpdateCreditorUdfCustomFieldMutation();
  const [addDebtorUdfCustomField, { isLoading: isAddDebtorUdfLoading }] =
    useAddDebtorUdfCustomFieldMutation();
  const [updateDebtorUdfCustomField, { isLoading: isUpdateDebtorUdfLoading }] =
    useUpdateDebtorUdfCustomFieldMutation();
  const [updateLegalCustomField, { isLoading: isUpdateLegalLoading }] =
    useUpdateLegalCustomFieldMutation();
  const [addCustomLegalField, { isLoading: isAddLegalLoading }] = useAddLegalCustomFieldMutation();

  const isLoading =
    isAddAccountUdfLoading ||
    isUpdateAccountUdfLoading ||
    isAddDebtorUdfLoading ||
    isUpdateDebtorUdfLoading ||
    isUpdateLegalLoading ||
    isAddLegalLoading;

  const createAction = {
    account: addAccountUdfCustomField,
    client: addCreditorUdfCustomField,
    debtor: addDebtorUdfCustomField,
    legal: addCustomLegalField,
  }[forType];

  const updateAction = {
    account: updateAccountUdfCustomField,
    client: updateCreditorUdfCustomField,
    debtor: updateDebtorUdfCustomField,
    legal: updateLegalCustomField,
  }[forType];

  const onOk = async () => {
    const fields = await form.validateFields();
    const action = isNew ? createAction : updateAction;
    const result = await action(fields);
    if ("data" in result) {
      const successText = {
        account: `User defined field ${isNew ? "created" : "updated"} successfully!`,
        client: `User defined field ${isNew ? "created" : "updated"} successfully!`,
        debtor: `User defined field ${isNew ? "created" : "updated"} successfully!`,
        legal: `Legal custom field ${isNew ? "created" : "updated"} successfully!`,
      }[forType];
      message.success(successText);
      await onSubmit(fields);
    }

    if ("error" in result) {
      const errorText = {
        account: `Failed to ${isNew ? "create" : "update"} user defined field`,
        client: `Failed to ${isNew ? "create" : "update"} user defined field`,
        debtor: `Failed to ${isNew ? "create" : "update"} user defined field`,
        legal: `Failed to ${isNew ? "create" : "update"} legal custom field`,
      }[forType];
      message.error(errorText);
    }
  };

  const initialValues = {
    ...userDefinedField,
  };

  const modalTitle = {
    account: `${isNew ? "Add" : "Edit"} Account User Defined Field`,
    client: `${isNew ? "Add" : "Edit"} Client User Defined Field`,
    debtor: `${isNew ? "Add" : "Edit"} Debtor User Defined Field`,
    legal: `${isNew ? "Add" : "Edit"} Legal Custom Field`,
  }[forType];

  return (
    <Modal
      title={modalTitle}
      open
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ loading: isLoading }}
    >
      <StyledForm form={form} initialValues={initialValues} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select
            options={constants?.customFieldTypes.map((type) => ({
              label: type.display,
              value: type.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="isRequired" valuePropName="checked">
          <Checkbox>Required?</Checkbox>
        </Form.Item>
        <Form.Item name="isImportant" valuePropName="checked">
          <Checkbox>Important?</Checkbox>
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
