import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Col, Popconfirm, Row, Space, Table, message } from "antd";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import AddProductOfferingModal from "features/productOfferings/components/addProductOfferingModal";
import {
  useDeleteProductOfferingMutation,
  useFetchProductOfferingsQuery,
} from "features/productOfferings/productOfferingsAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

export default function ProductOfferingTable() {
  const { data: productOfferings, isLoading } = useFetchProductOfferingsQuery();
  const [deleteProductOffering] = useDeleteProductOfferingMutation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const {
    data: {
      [PERMISSIONS.PRODUCT_OFFERING__GET]: isProductOfferingGetAuthorized,
      [PERMISSIONS.PRODUCT_OFFERING__CREATE]: isProductOfferingCreateAuthorized,
      [PERMISSIONS.PRODUCT_OFFERING__UPDATE]: isProductOfferingUpdateAuthorized,
      [PERMISSIONS.PRODUCT_OFFERING__DELETE]: isProductOfferingDeleteAuthorized,
    },
  } = useAuthorizations();

  const onNewProductOffering = async () => {
    setModalVisible(false);
    setActiveRecord(null);
  };

  const handleDelete = async (record) => {
    const result = await deleteProductOffering({ id: record.id });
    if ("data" in result) {
      message.success("Deleted product successfully.");
    }
  };

  const onEdit = (record) => {
    setModalVisible(true);
    setActiveRecord(record);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isProductOfferingUpdateAuthorized && !isProductOfferingDeleteAuthorized,
      render: (text, record) => (
        <Space size="middle">
          {isProductOfferingUpdateAuthorized && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onEdit(record)} />
            </AntDToolTip>
          )}
          {isProductOfferingDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this product?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete-product-offering" />
            </Popconfirm>
          )}
        </Space>
      ),
      fixed: "right",
    },
  ].filter((column) => !column.hidden);

  return isProductOfferingGetAuthorized ? (
    <Col>
      <Row align="middle">
        <span>Configure products.</span>
        {isProductOfferingCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Product
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={productOfferings}
      />
      {isModalVisible && (
        <AddProductOfferingModal
          title={activeRecord ? "Edit Product" : "Add Product"}
          onOk={onNewProductOffering}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          productOffering={activeRecord}
        />
      )}
    </Col>
  ) : null;
}
