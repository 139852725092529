import { SettingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Switch,
  Tooltip,
} from "antd";
import { debounce } from "common/utils";
import { AktDateRangePicker } from "components/aktDatePicker";
import { TriggerModal } from "components/triggerModal";
import {
  resetFilters,
  selectAccountsTableSlice,
  setAssigneeIds,
  setCollectionDebtTypes,
  setCreditorIds,
  setCreditorTagIds,
  setDailyQueueAccounts,
  setDebtorStates,
  setDebtorTagIds,
  setFilterExcludeLastCallDates,
  setFilterExcludeStatusCodes,
  setFilterExcludeTurnoverDates,
  setFilterFollowUpDates,
  setFilterIncludeLastCallDates,
  setFilterIncludeStatusCodes,
  setFilterIncludeTurnoverDates,
  setFilterMaxAccountBalance,
  setFilterMinAccountBalance,
  setFilterMinIntelitechIScore,
  setFilterMaxIntelitechIScore,
  setFilterIncludeIntelitechMedalScores,
  setFilterExcludeIntelitechMedalScores,
  setFilterMinIntelitechQScore,
  setFilterMaxIntelitechQScore,
  setFilterMinIntelitechRScore,
  setFilterMaxIntelitechRScore,
  setFilterMinIntelitechFPGScore,
  setFilterMaxIntelitechFPGScore,
  setFilterUploadDates,
  setHasLawsuit,
} from "features/accountsTable/accountsTableSlice";
import { TableAppearanceModal } from "features/appearance";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { SIDER_ITEMS } from "features/appearance/constants";
import { useUserType } from "features/auth";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";
import {
  useFetchCreditorSummariesQuery,
  useFetchCreditorTagsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchDebtorTagsQuery } from "features/tags/tagsAPI";
import moment from "moment-timezone";
import { useAppearance } from "providers/appearanceProvider";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledFilterHeader = styled.h4`
  font-weight: 600;
  color: #1677ff;
`;

const StyledSubHeader = styled.h5`
  margin-top: 0;
`;

const StyledRow = styled(Row)`
  align-items: baseline;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  min-width: 250px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 0;
`;

const StyledContainer = styled.div`
  height: calc(100vh - 230px);
  overflow: scroll;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  margin-bottom: 12px;
  min-width: 250px;
`;

const StyledSwitchContainer = styled(Row)`
  margin-top: 8px;
`;

const StyledMaxBalance = styled(Form.Item)`
  margin-left: 4px;
`;

const SIDER_ID = "accountFilters";

function AccountsTableFilters() {
  const { isAgencyUserType } = useUserType();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const { data: defaultWorkflowStates } = useFetchAgencyWorkflowStates();
  const { data: constants } = useFetchBackendConstantsQuery();
  const { appearance, isLoading: isAppearanceLoading } = useAppearance();
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const { data: agencySettings } = useFetchAgencyQuery();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: creditorTags } = useFetchCreditorTagsQuery();
  const { data: debtorTags } = useFetchDebtorTagsQuery();
  const { data: me } = useFetchMeQuery();

  const prepareDate = (date) => {
    if (!date) return null;
    return moment(date);
  };

  const initialValues = {
    ...accountsTableSlice.filters,
    followUpDate: [
      prepareDate(accountsTableSlice.filters?.followUpStartDate),
      prepareDate(accountsTableSlice.filters?.followUpEndDate),
    ],
    uploadDate: [
      prepareDate(accountsTableSlice.filters?.uploadDateStart),
      prepareDate(accountsTableSlice.filters?.uploadDateEnd),
    ],
    includeTurnoverDate: [
      prepareDate(accountsTableSlice.filters?.includeTurnoverDateStart),
      prepareDate(accountsTableSlice.filters?.includeTurnoverDateEnd),
    ],
    excludeTurnoverDate: [
      prepareDate(accountsTableSlice.filters?.excludeTurnoverDateStart),
      prepareDate(accountsTableSlice.filters?.excludeTurnoverDateEnd),
    ],
    includeLastCallDate: [
      prepareDate(accountsTableSlice.filters?.includeLastCallDateStart),
      prepareDate(accountsTableSlice.filters?.includeLastCallDateEnd),
    ],
    excludeLastCallDate: [
      prepareDate(accountsTableSlice.filters?.excludeLastCallDateStart),
      prepareDate(accountsTableSlice.filters?.excludeLastCallDateEnd),
    ],
  };

  const onDailyQueueChange = async (enable) => {
    await dispatch(setDailyQueueAccounts(enable));
  };

  // const onPriorityQueueChange = async (enable) => {
  //   await dispatch(setPriorityQueueAccounts(enable));
  // };

  const onIncludeStatusChange = async (values) => {
    dispatch(setFilterIncludeStatusCodes(values));
  };

  const onExcludeStatusChange = async (values) => {
    dispatch(setFilterExcludeStatusCodes(values));
  };

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMinBalanceChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMinAccountBalance(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMaxBalanceChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMaxAccountBalance(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMinIScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMinIntelitechIScore(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMaxIScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMaxIntelitechIScore(value));
    }, 1000),
    [dispatch],
  );

  const onIncludeIntelitechMedalScoresChange = async (values) => {
    if (values === null) {
      await dispatch(setFilterIncludeIntelitechMedalScores([]));
      return;
    }
    await dispatch(setFilterIncludeIntelitechMedalScores([...values]));
  };

  const onExcludeIntelitechMedalScoresChange = async (values) => {
    if (values === null) {
      await dispatch(setFilterExcludeIntelitechMedalScores([]));
      return;
    }
    await dispatch(setFilterExcludeIntelitechMedalScores([...values]));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMinQScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMinIntelitechQScore(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMaxQScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMaxIntelitechQScore(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMinRScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMinIntelitechRScore(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMaxRScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMaxIntelitechRScore(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMinFPGScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMinIntelitechFPGScore(value));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMaxFPGScoreChange = useCallback(
    debounce(async (value) => {
      await dispatch(setFilterMaxIntelitechFPGScore(value));
    }, 1000),
    [dispatch],
  );

  const onFollowUpChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterFollowUpDates());
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterFollowUpDates([start, end]));
  };

  const onLawsuitFiledChange = async (value) => {
    await dispatch(setHasLawsuit(value));
  };

  const onUploadDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterUploadDates());
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterUploadDates([start, end]));
  };

  const onIncludeTurnoverDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterIncludeTurnoverDates([]));
      return;
    }
    await dispatch(setFilterIncludeTurnoverDates([...value]));
  };

  const onExcludeTurnoverDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterExcludeTurnoverDates([]));
      return;
    }
    await dispatch(setFilterExcludeTurnoverDates([...value]));
  };

  const onIncludeLastCallDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterIncludeLastCallDates());
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterIncludeLastCallDates([start, end]));
  };

  const onExcludeLastCallDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterExcludeLastCallDates());
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterExcludeLastCallDates([start, end]));
  };

  const onCollectionTypeChange = async (value) => {
    if (value === null || value.length === 0) {
      await dispatch(setCollectionDebtTypes(null));
      return;
    }
    await dispatch(setCollectionDebtTypes(value));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebtorStatesChange = useCallback(
    debounce(async (values) => {
      await dispatch(setDebtorStates(values));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebtorTagIdsChange = useCallback(
    debounce(async (values) => {
      await dispatch(setDebtorTagIds(values));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAssigneeIdsChange = useCallback(
    debounce(async (values) => {
      await dispatch(setAssigneeIds(values));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCreditorIdsChange = useCallback(
    debounce(async (values) => {
      await dispatch(setCreditorIds(values));
    }, 1000),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCreditorTagIdsChange = useCallback(
    debounce(async (values) => {
      await dispatch(setCreditorTagIds(values));
    }, 1000),
    [dispatch],
  );

  const onResetClicked = async () => {
    await dispatch(resetFilters());
    form.resetFields();
  };

  const defaultItems = [
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__QUEUES,
      visible: true,
      title: "Queues",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item>
            <Row justify="space-between">
              <span>Daily Queue:</span>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={accountsTableSlice.isShowDailyQueue}
                onChange={onDailyQueueChange}
              />
            </Row>
            {/* <StyledSwitchContainer justify="space-between">
            <span>Priority Queue:</span>
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={accountsTableSlice.isShowPriorityQueue}
              onChange={onPriorityQueueChange}
            />
          </StyledSwitchContainer> */}
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__STATUSES,
      visible: true,
      title: "Statuses",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeStatusCodes">
            <Select
              mode="multiple"
              placeholder="Select Status..."
              onChange={onIncludeStatusChange}
              filterOption={filterOption}
              options={defaultWorkflowStates?.map((state) => ({
                label: [state.code, state.name].join(" - "),
                value: state.code,
                title: state.description,
              }))}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeStatusCodes">
            <Select
              mode="multiple"
              placeholder="Select Status..."
              onChange={onExcludeStatusChange}
              filterOption={filterOption}
              options={defaultWorkflowStates?.map((state) => ({
                label: [state.code, state.name].join(" - "),
                value: state.code,
                title: state.description,
              }))}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__ACCOUNT_BALANCE,
      visible: true,
      title: "Account Balance",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Row>
            <Form.Item name="minAccountBalance">
              <InputNumber
                placeholder="Min"
                controls={false}
                min="0"
                prefix="$"
                step="0.01"
                onChange={onMinBalanceChange}
              />
            </Form.Item>
            <StyledMaxBalance name="maxAccountBalance">
              <InputNumber
                placeholder="Max"
                controls={false}
                min="0"
                prefix="$"
                step="0.01"
                onChange={onMaxBalanceChange}
              />
            </StyledMaxBalance>
          </Row>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__FOLLOW_UP_DATE,
      visible: true,
      title: "Follow-up Date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="followUpDate">
            <AktDateRangePicker
              picker="date"
              onChange={onFollowUpChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          {/** new table filters */}
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__UPLOAD_DATE,
      visible: true,
      title: "Upload Date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="uploadDate">
            <AktDateRangePicker
              picker="date"
              onChange={onUploadDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__TURNOVER_DATE,
      visible: true,
      title: "Turnover date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeTurnoverDate">
            <AktDateRangePicker
              picker="date"
              onChange={onIncludeTurnoverDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeTurnoverDate">
            <AktDateRangePicker
              picker="date"
              onChange={onExcludeTurnoverDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__LAST_CALL_DATE,
      visible: true,
      title: "Last Call Date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeLastCallDate">
            <AktDateRangePicker
              picker="date"
              onChange={onIncludeLastCallDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeLastCallDate">
            <AktDateRangePicker
              picker="date"
              onChange={onExcludeLastCallDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__LAWSUIT,
      visible: true,
      title: "Lawsuit",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item>
            <StyledSwitchContainer justify="space-between">
              <span>Filed:</span>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={accountsTableSlice.filters.hasLawsuit}
                onChange={onLawsuitFiledChange}
              />
            </StyledSwitchContainer>
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__DEBT_TYPE,
      visible: true,
      title: "Debt Type",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="collectionDebtTypes">
            <Checkbox.Group
              onChange={onCollectionTypeChange}
              options={[
                { label: "Consumer", value: "consumer" },
                { label: "Commercial", value: "commercial" },
              ]}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__DEBTOR_LOCATION,
      visible: true,
      title: "Debtor Location",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="debtorStates">
            <Select
              mode="multiple"
              placeholder="Debtor states..."
              filterOption={filterOption}
              onChange={onDebtorStatesChange}
              options={constants?.states.map((state) => ({
                label: `${state.display} (${state.value})`,
                value: state.value,
              }))}
            />
          </Form.Item>
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__DEBTOR_TAGS,
      visible: true,
      title: "Debtor Tags",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="debtorTagIds">
            <Select
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              placeholder="Select Debtor Tags..."
              filterOption={filterOption}
              onChange={onDebtorTagIdsChange}
              options={debtorTags?.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__ASSIGNEES,
      visible: true,
      title: "Assignees",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="assigneeIds">
            <Select
              mode="multiple"
              placeholder="Assignees..."
              filterOption={filterOption}
              onChange={onAssigneeIdsChange}
              options={[
                {
                  label: "Unassigned",
                  value: null,
                },
                ...(collectors.map((c) => ({
                  label: collectorFullName(c),
                  value: c.id,
                })) ?? []),
              ]}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__CLIENT,
      visible: true,
      title: "Client",
      render: (item) =>
        isAgencyUserType && (
          <span key={item.key}>
            <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
            <Form.Item name="creditorIds">
              <Select
                maxTagTextLength={20}
                mode="multiple"
                placeholder="Select Clients..."
                filterOption={filterOption}
                onChange={onCreditorIdsChange}
                options={creditors?.map((creditor) => ({
                  value: creditor.id,
                  label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
                }))}
              />
            </Form.Item>
            <StyledDivider />
          </span>
        ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__CLIENT_TAGS,
      visible: true,
      title: "Client Tags",
      render: (item) =>
        isAgencyUserType && (
          <span key={item.key}>
            <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
            <Form.Item name="creditorTagIds">
              <Select
                maxTagTextLength={20}
                popupMatchSelectWidth={false}
                mode="multiple"
                placeholder="Select Client Tags..."
                filterOption={filterOption}
                onChange={onCreditorTagIdsChange}
                options={creditorTags?.map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              />
            </Form.Item>
            <StyledDivider />
          </span>
        ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__INTELITECH_I_SCORE,
      visible: agencySettings?.isIntelitechEnabled,
      title: "Intelitech I-Score",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Row>
            <Form.Item name="minIntelitechIScore">
              <InputNumber
                placeholder="Min"
                controls={false}
                min="0"
                step="1"
                max="999"
                onChange={onMinIScoreChange}
              />
            </Form.Item>
            <StyledMaxBalance name="maxIntelitechIScore">
              <InputNumber
                placeholder="Max"
                controls={false}
                min="0"
                max="999"
                step="1"
                onChange={onMaxIScoreChange}
              />
            </StyledMaxBalance>
          </Row>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__INTELITECH_MEDAL_SCORE,
      visible: agencySettings?.isIntelitechEnabled,
      title: "Intelitech Medal-Score",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeIntelitechMedalScores">
            <Select
              allowClear
              mode="multiple"
              placeholder="Select one..."
              popupMatchSelectWidth={false}
              onChange={onIncludeIntelitechMedalScoresChange}
              options={constants?.intelitechMedalScores ?? []}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeIntelitechMedalScores">
            <Select
              allowClear
              mode="multiple"
              placeholder="Select one..."
              onChange={onExcludeIntelitechMedalScoresChange}
              popupMatchSelectWidth={false}
              options={constants?.intelitechMedalScores ?? []}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__INTELITECH_Q_SCORE,
      visible: agencySettings?.isIntelitechEnabled,
      title: "Intelitech Q-Score",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Row>
            <Form.Item name="minIntelitechQScore">
              <InputNumber
                placeholder="Min"
                controls={false}
                min="0"
                step="0.01"
                onChange={onMinQScoreChange}
              />
            </Form.Item>
            <StyledMaxBalance name="maxIntelitechQScore">
              <InputNumber
                placeholder="Max"
                controls={false}
                min="0"
                step="0.01"
                onChange={onMaxQScoreChange}
              />
            </StyledMaxBalance>
          </Row>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__INTELITECH_R_SCORE,
      visible: agencySettings?.isIntelitechEnabled,
      title: "Intelitech R-Score",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Row>
            <Form.Item name="minIntelitechRScore">
              <InputNumber
                placeholder="Min"
                controls={false}
                min="0"
                step="0.01"
                onChange={onMinRScoreChange}
              />
            </Form.Item>
            <StyledMaxBalance name="maxIntelitechRScore">
              <InputNumber
                placeholder="Max"
                controls={false}
                min="0"
                step="0.01"
                onChange={onMaxRScoreChange}
              />
            </StyledMaxBalance>
          </Row>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__INTELITECH_FPG_VALUE,
      visible: agencySettings?.isIntelitechEnabled,
      title: "Intelitech FPG-Value",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Row>
            <Form.Item name="minIntelitechFpgValue">
              <InputNumber
                placeholder="Min"
                controls={false}
                min="0"
                step="0.01"
                onChange={onMinFPGScoreChange}
              />
            </Form.Item>
            <StyledMaxBalance name="maxIntelitechFpgValue">
              <InputNumber
                placeholder="Max"
                controls={false}
                min="0"
                step="0.01"
                onChange={onMaxFPGScoreChange}
              />
            </StyledMaxBalance>
          </Row>
          <StyledDivider />
        </span>
      ),
    },
  ];

  const reconciledItems = reconcileColumns(defaultItems, appearance?.items?.[SIDER_ID]).filter(
    (column) => column.visible,
  );

  if (appearance?.items?.[SIDER_ID]?.hidePanel || isAppearanceLoading) {
    return null;
  }

  return (
    <Col>
      <StyledRow justify="space-between">
        <h3>
          Filters
          {me?.isStaff && (
            <TriggerModal
              modal={TableAppearanceModal}
              tableId={SIDER_ID}
              defaultColumns={defaultItems}
              mode="sider"
              containerProps={{ style: { display: "inline" } }}
            >
              <Tooltip title="Customize Appearance">
                <Button shape="circle" icon={<SettingOutlined />} type="text" />
              </Tooltip>
            </TriggerModal>
          )}
        </h3>
        <Button type="link" onClick={onResetClicked}>
          Reset
        </Button>
      </StyledRow>
      <StyledContainer>
        {isAppearanceLoading ? (
          <Skeleton active />
        ) : (
          <Form
            form={form}
            labelAlign="right"
            initialValues={initialValues}
            validateMessages={{ required: "This is a required field" }}
            layout="vertical"
          >
            {reconciledItems.map((item) => item.render(item))}
            {/* <Divider />
        <h4>Scrubs</h4>
        <h4>Skip Trace</h4>
        <Row>
          <Form.Item name="skipTrace">
            <Checkbox.Group>
              <Row>
                <Row>
                  <Checkbox value="zero-to-hundred">TLOxp</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="lt-two-hundred">LexisNexis</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="lt-five-hundred">The Work Number</Checkbox>
                </Row>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Row>
        <Row>
          <Button type="primary" onClick={() => {}}>
            Run Skip Trace
          </Button>
        </Row> */}
            {/* <h4>Credit Report</h4>
        <Row>
          <Form.Item name="creditReport">
            <Checkbox.Group>
              <Row>
                <Row>
                  <Checkbox value="zero-to-hundred">Equifax</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="lt-two-hundred">Transunion</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="lt-five-hundred">Experian</Checkbox>
                </Row>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Row> */}
          </Form>
        )}
      </StyledContainer>
    </Col>
  );
}

export default AccountsTableFilters;
