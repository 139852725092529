import { Layout } from "antd";
import DebtorProfile from "features/debtorProfile/creditorPortal/debtorProfile";
import DebtorSider from "features/debtorSider/creditorPortal/debtorSider";
import PageLayout from "layouts/creditorPortal/dashboard/pageLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";

const StyledLayout = styled(Layout)`
  height: 100%;
`;

function DebtorsPage() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/accounts" />} />
      <Route
        path="/:debtorId"
        element={
          <PageLayout>
            <Layout hasSider>
              <DebtorSider />
              <StyledLayout>
                <DebtorProfile />
              </StyledLayout>
            </Layout>
          </PageLayout>
        }
      />
    </Routes>
  );
}

export default DebtorsPage;
