import { Form, Select, Tag } from "antd";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchSettlementAndPaymentRuleSetsQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    overflow: visible;
    white-space: pre;
  }
`;

export default function PaymentConfigurationStep({ currentCreditor }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: settlementAndPaymentRuleSets } = useFetchSettlementAndPaymentRuleSetsQuery();
  const categories =
    constants?.paymentCategories?.invoiceable.map((category) => ({
      value: category.value,
      label: category.display,
    })) ?? [];
  const [selectedAgencyCategoriesPriority, setSelectedAgencyCategoriesPriority] = useState(
    categories.reduce((elements, key) => ({ ...elements, [key]: null }), {}),
  );
  const [selectedCreditorCategoriesPriority, setSelectedCreditorCategoriesPriority] = useState(
    categories.reduce((elements, key) => ({ ...elements, [key]: null }), {}),
  );
  const [
    selectedForwardingEntityCategoriesPriority,
    setSelectedForwardingEntityCategoriesPriority,
  ] = useState(categories.reduce((elements, key) => ({ ...elements, [key]: null }), {}));

  const updateSelectedCategoriesPriority = (values, setCategoriesPriority) => {
    const priorities = values?.reduce((acc, categoryValue, index) => {
      return { ...acc, [categoryValue]: index + 1 };
    }, {});
    setCategoriesPriority(priorities);
  };

  useEffect(() => {
    const agencyValues = currentCreditor?.paymentConfig.paymentCategoryPriority ?? [];
    const creditorValues = currentCreditor?.paymentConfig.creditorPaymentCategoryPriority ?? [];
    const forwardingEntityValues =
      currentCreditor?.paymentConfig.forwardingEntityPaymentCategoryPriority ?? [];
    updateSelectedCategoriesPriority(agencyValues, setSelectedAgencyCategoriesPriority);
    updateSelectedCategoriesPriority(creditorValues, setSelectedCreditorCategoriesPriority);
    updateSelectedCategoriesPriority(
      forwardingEntityValues,
      setSelectedForwardingEntityCategoriesPriority,
    );
  }, [currentCreditor, setSelectedAgencyCategoriesPriority]);

  const tagRender = (props, categoriesPriority) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {categoriesPriority[value]}. {label}
      </Tag>
    );
  };

  const handleChange = (values) => {
    const priorities = {};
    values.forEach((categoryValue, index) => {
      priorities[categoryValue] = index + 1;
    });
    setSelectedAgencyCategoriesPriority(priorities);
  };

  const handleCreditorChange = (values) => {
    const priorities = {};
    values.forEach((categoryValue, index) => {
      priorities[categoryValue] = index + 1;
    });
    setSelectedCreditorCategoriesPriority(priorities);
  };

  const handleForwardingEntityChange = (values) => {
    const priorities = {};
    values.forEach((categoryValue, index) => {
      priorities[categoryValue] = index + 1;
    });
    setSelectedForwardingEntityCategoriesPriority(priorities);
  };

  return (
    <>
      <StyledFormItem
        name={["paymentConfig", "paymentCategoryPriority"]}
        label="Payment Category Allocation Priority (Paid to Agency)"
        rules={[{ required: true, message: "This field is required." }]}
        tooltip="Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the legal fees, principal, and interest in that order?)."
      >
        <Select
          placeholder="Select and Order Categories..."
          tagRender={(props) => tagRender(props, selectedAgencyCategoriesPriority)}
          mode="multiple"
          options={categories}
          onChange={handleChange}
        />
      </StyledFormItem>
      <StyledFormItem
        name={["paymentConfig", "creditorPaymentCategoryPriority"]}
        label="Payment Category Allocation Priority (Paid to Creditor)"
        rules={[{ required: true, message: "This field is required." }]}
        tooltip="Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the legal fees, principal, and interest in that order?)."
      >
        <Select
          placeholder="Select and Order Categories..."
          tagRender={(props) => tagRender(props, selectedCreditorCategoriesPriority)}
          mode="multiple"
          options={categories.filter((category) =>
            Object.keys(selectedAgencyCategoriesPriority).includes(category.value),
          )}
          onChange={handleCreditorChange}
        />
      </StyledFormItem>
      <StyledFormItem
        name={["paymentConfig", "forwardingEntityPaymentCategoryPriority"]}
        label="Payment Category Allocation Priority (Paid to Forwarding Entity)"
        rules={[{ required: true, message: "This field is required." }]}
        tooltip="Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the legal fees, principal, and interest in that order?)."
      >
        <Select
          placeholder="Select and Order Categories..."
          tagRender={(props) => tagRender(props, selectedForwardingEntityCategoriesPriority)}
          mode="multiple"
          options={categories.filter((category) =>
            Object.keys(selectedAgencyCategoriesPriority).includes(category.value),
          )}
          onChange={handleForwardingEntityChange}
        />
      </StyledFormItem>
      <StyledFormItem
        name={["paymentConfig", "settlementPaymentRulesetId"]}
        label="Payment Ruleset"
        tooltip="Select payment ruleset."
      >
        <Select
          placeholder="Select payment ruleset..."
          options={settlementAndPaymentRuleSets?.map((ruleSet) => ({
            value: ruleSet.id,
            label: ruleSet.name,
          }))}
        />
      </StyledFormItem>
    </>
  );
}
