/*
How to use:

1. export const LOCAL_STORAGE_KEY = config.useAuth0 ? "rtk:auth0" : "rtk:auth";
const saveState = saveStateWithKey(LOCAL_STORAGE_KEY);

2. reducers: {
    someAction(state, action) {
      // ... other logic
      saveState(state);
    },
  }

3. const loadSlice = loadStateWithKey(LOCAL_STORAGE_KEY);

const store = setupStore({
  [authSlice.name]: loadSlice()
  // ... others
});
*/

export const saveStateWithKey =
  (localStorageKey, ignoreKeys = []) =>
  (state) => {
    try {
      const filteredState = Object.keys(state).reduce((acc, key) => {
        if (!ignoreKeys.includes(key)) {
          acc[key] = state[key];
        }
        return acc;
      }, {});
      const serializedState = JSON.stringify(filteredState);
      localStorage.setItem(localStorageKey, serializedState);
    } catch (e) {
      // Ignore
    }
  };

export const loadStateWithKey = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};
