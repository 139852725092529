export default [
  {
    key: "updated_at",
    label: "Call Date",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "phone_number",
    label: "Phone Number",
    type: "string",
  },
  {
    key: "external_id",
    label: "Account ID",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "status",
    label: "Status",
    type: "string",
    validators: [{ validate: "required" }],
  },
];
