import { DownOutlined } from "@ant-design/icons";
import { Input, Modal, Tree } from "antd";
import { camelToWords } from "common/utils";
import { useState } from "react";
import styled from "styled-components";

const { Search } = Input;

const StyledDiv = styled.div`
  margin-bottom: 24px;
  height: "300px";
  overflow-y: "scroll";
  overflow-x: "scroll";
  border: "1px solid #d9d9d9";
  padding: "8px";
`;

const StyledSearch = styled(Search)`
  margin-bottom: 8px;
`;

function ViewDebtorCreditReportModal({ record, title, open, onOk, onCancel }) {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  // Helper function to generate unique keys for the tree nodes
  const generateKey = (parentKey, index) => {
    return parentKey ? `${parentKey}-${index}` : `${index}`;
  };

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  // Recursive function to generate tree structure from dynamic data
  const generateTreeData = (data, parentKey = "") => {
    if (Array.isArray(data)) {
      return data.map((item, index) => {
        const key = generateKey(parentKey, index);
        return {
          title: `Item ${index + 1}`,
          key,
          children: generateTreeData(item, key),
        };
      });
    }
    if (typeof data === "object" && data !== null) {
      return Object.keys(data).map((key, index) => {
        const childKey = generateKey(parentKey, index);
        let nodeTitle;
        if ((data[key] !== null && typeof data[key] === "object") || Array.isArray(data[key])) {
          nodeTitle = camelToWords(key);
        } else {
          nodeTitle = `${camelToWords(key)}: ${data[key]}`;
        }
        return {
          title: nodeTitle,
          key: childKey,
          children: generateTreeData(data[key], childKey),
        };
      });
    }
    // If the data is a primitive value, return it as a leaf node
    return [];
  };

  // Function to filter tree nodes based on search value
  const filterTreeData = (data, searchText) => {
    if (!searchText) return data;

    const filterNodes = (nodes) => {
      return nodes
        .map((node) => {
          const titleMatch = node.title.toLowerCase().includes(searchText.toLowerCase());
          const hasChildren = node.children && node.children.length > 0;

          if (!hasChildren) {
            return titleMatch ? node : null;
          }

          const filteredChildren = filterNodes(node.children);
          return titleMatch || filteredChildren.some((child) => child !== null)
            ? { ...node, children: filteredChildren }
            : null;
        })
        .filter((node) => node !== null);
    };

    return filterNodes(data);
  };

  // Dynamically generate the tree data from the input data
  const treeData = generateTreeData(record?.responseData);
  const filteredTreeData = filterTreeData(treeData, searchValue);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    const keys = [];

    const searchTree = (nodes) => {
      nodes.forEach((node) => {
        if (node.title.toLowerCase().includes(value.toLowerCase())) {
          keys.push(node.key);
        }
        if (node.children) {
          searchTree(node.children);
        }
      });
    };

    searchTree(treeData);
    setExpandedKeys(keys);
    setAutoExpandParent(true);
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onOk} onCancel={onCancel}>
      <StyledSearch placeholder="Search data" onChange={onSearch} />
      <StyledDiv>
        <Tree
          showLine
          switcherIcon={<DownOutlined />}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          treeData={filteredTreeData}
        />
      </StyledDiv>
    </Modal>
  );
}

export default ViewDebtorCreditReportModal;
