import { message, Select, Tag } from "antd";
import { useFetchDebtorProfileQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useUpdateDebtorTagsMutation } from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchDebtorTagsQuery } from "features/tags/tagsAPI";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-left: 8px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledTag = styled(Tag)`
  margin: 2px 2px;
`;

export function DebtorTagsCard() {
  const { debtorId } = useParams();
  const [updateDebtorTags] = useUpdateDebtorTagsMutation();
  const { data: debtorPersonalInfo } = useFetchDebtorProfileQuery({ debtorId });
  const [currentDebtorTags, setCurrentDebtorTags] = useState({
    id: null,
    debtorTagIds: [],
  });
  const { data: debtorTags } = useFetchDebtorTagsQuery();

  useEffect(() => {
    if (debtorPersonalInfo?.id !== undefined && debtorPersonalInfo?.debtorTagIds !== undefined) {
      setCurrentDebtorTags({
        id: debtorPersonalInfo?.id,
        debtorTagIds:
          debtorTags?.filter((tag) => debtorPersonalInfo.debtorTagIds.includes(tag.id)) ?? [],
      });
    }
  }, [debtorPersonalInfo?.debtorTagIds, debtorPersonalInfo?.id, debtorTags]);

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const isSystem = currentDebtorTags?.debtorTagIds?.find((tag) => tag?.id === value)?.isSystem;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <StyledTag
        key={value}
        color={isSystem ? "red" : "blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </StyledTag>
    );
  };

  const onTagChange = async (selectedTag, options) => {
    const newDebtorTags = {
      id: debtorPersonalInfo?.id,
      debtorTagIds: options.map((option) => option.tagOption),
    };
    setCurrentDebtorTags(newDebtorTags);
    const debtorTagIds = [...(debtorPersonalInfo?.debtorTagIds ?? [])];
    const currentSelectedTagIds = newDebtorTags?.debtorTagIds?.map((tag) => tag.id);

    // Compare the current selected tags with the tags that are already saved in the backend field,
    // if they are the same, do not update.
    if (JSON.stringify(currentSelectedTagIds.sort()) === JSON.stringify(debtorTagIds.sort())) {
      return;
    }

    if (
      // not a default value
      newDebtorTags.id === null ||
      // or not the same id
      newDebtorTags.id !== debtorPersonalInfo?.id
    ) {
      return;
    }

    const result = await updateDebtorTags({
      debtorId: newDebtorTags.id,
      debtorTagIds: currentSelectedTagIds,
    });
    if ("data" in result) {
      message.success("Tags updated successfully!");
    } else {
      message.error("Unable to update tags.");
    }
  };

  return (
    <StyledCard>
      <h4>Debtor Tags</h4>
      <StyledSelect
        maxTagTextLength={20}
        popupMatchSelectWidth={false}
        mode="multiple"
        placeholder="Select Debtor Tags..."
        filterOption={filterOption}
        tagRender={tagRender}
        onChange={onTagChange}
        options={debtorTags?.map((tag) => ({
          value: tag.id,
          label: tag.name,
          tagOption: tag,
        }))}
        value={currentDebtorTags?.debtorTagIds?.map((tag) => tag?.id) ?? []}
      />
    </StyledCard>
  );
}
