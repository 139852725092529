import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Card,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  FloatButton,
  Popover,
  Progress,
  Result,
  Row,
  Statistic,
  Tabs,
} from "antd";
import config from "app/envConfig";
import { formatCurrency } from "common/utils";
import currency from "currency.js";
import { useFetchDebtorProfileQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import BankruptciesTable from "features/bankruptcies/bankruptciesTable";
import { useFetchCommunicationLimitsForDebtorQuery } from "features/communicationLimitsTable/communicationLimitsApi";
import { CommunicationsTable } from "features/communicationsTable";
import { DebtorAccountsTable } from "features/debtorAccountsTable";
import { useFetchDebtorAccountsQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import DebtorCreditReportsTable from "features/debtorCreditReportTable/debtorCreditReportTable";
import AddCostsTab from "features/debtorProfile/components/AddCostsTab";
import SystemLogTable from "features/debtorProfile/components/systemLogTable";
import { SIDER_ID } from "features/debtorSider/agencyPortal/debtorSider";
import DocumentsTable from "features/documentsTable/documentsTable";
import { GarnishmentsTable } from "features/garnishments";
import LegalInfo from "features/legal/legalInfo";
import PaymentCard from "features/payments";
import { resetMakeAPayment } from "features/payments/paymentsSlice";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import useFeatureFlags from "features/permissions/hooks/useFeatureFlags";
import { PaymentHistory, ScheduledPayments } from "features/scheduledPayments";
import { useAppearance } from "providers/appearanceProvider";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDocumentTitle } from "usehooks-ts";

const { Panel } = Collapse;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledStatistic = styled(Statistic)`
  margin-right: 36px;
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
  margin-top: 16px;
`;

const StyledCollapse = styled(Collapse)`
  margin-top: 16px;
  background-color: #fff;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    align-items: center !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

const StyledProgress = styled(Progress)`
  .ant-progress-inner span {
    display: none;
  }

  .ant-progress-inner:after {
    content: "${(props) =>
      `${
        // @ts-ignore
        props.$count
      }/${
        // @ts-ignore
        props.$total
      }`} calls";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11.5px;
  }
`;

const StyledCol = styled(Col)`
  margin-right: 16px;
`;

const StyledDiv = styled.div`
  margin-left: ${(props) =>
    `${
      // @ts-ignore
      props.$hidePanel ? 0 : 400
    }px`};
`;

function DebtorProfile() {
  const { debtorId } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const { data: isPaymentHistoryAuthorized } = useAuthorization(PERMISSIONS.PAYMENT__GET);
  const { data: isUpcomingPaymentsAuthorized } = useAuthorization(PERMISSIONS.PAYMENT__GET);
  const { data: isMakePaymentAuthorized } = useAuthorization(PERMISSIONS.PAYMENT__CREATE);
  const { data: isBankruptcyAuthorized } = useAuthorization(PERMISSIONS.DEBTOR_BANKRUPTCY__GET);
  const { data: canViewCommunicationLimit } = useAuthorization(
    PERMISSIONS.DEBTOR_PROFILE_COMM_LIMIT__CAN_VIEW,
  );
  const { data: isAdditionalCostAuthorized } = useAuthorization(
    PERMISSIONS.ACCOUNT__UPDATE_PAYMENT_DATA,
  );
  const { data: isCommunicationRuleAuthorized } = useAuthorization(
    PERMISSIONS.COMMUNICATION_RULE__GET,
  );
  const { data: isDebtorCreditReportAuthorized } = useAuthorization(
    PERMISSIONS.DEBTOR_CREDIT_REPORT__GET,
  );
  const { data: isSystemLogAuthorized } = useAuthorization(PERMISSIONS.SYSTEM_LOG__GET);
  const { appearance } = useAppearance();

  const {
    data: [hasComplianceFeature],
  } = useFeatureFlags(["compliance"]);

  const {
    data: debtorPersonalInfo,
    isLoading: isDebtorPersonalInfoLoading,
    isFetching: isDebtorPersonalInfoFetching,
    isError: isDebtorPersonalInfoError,
  } = useFetchDebtorProfileQuery({ debtorId });

  const {
    data: debtorAccounts,
    isLoading,
    isFetching,
  } = useFetchDebtorAccountsQuery(
    { debtorId },
    {
      skip:
        !debtorId ||
        isDebtorPersonalInfoLoading ||
        isDebtorPersonalInfoError ||
        isDebtorPersonalInfoFetching,
    },
  );

  const activeAccounts = useMemo(
    () =>
      debtorAccounts?.filter(
        (account) => !(account.isClosed || account.isPaidInFull || account.isSettled),
      ),
    [debtorAccounts],
  );

  // @ts-ignore
  const { data: communicationLimitRules } = useFetchCommunicationLimitsForDebtorQuery(debtorId, {
    skip:
      !isCommunicationRuleAuthorized ||
      !hasComplianceFeature ||
      !canViewCommunicationLimit ||
      isDebtorPersonalInfoLoading ||
      isDebtorPersonalInfoFetching ||
      isDebtorPersonalInfoError,
  });
  const filteredRules =
    communicationLimitRules?.filter((each) => each.communicationCount >= each.rule.maxAllowed) ??
    [];
  const isPaymentTabsShown =
    isPaymentHistoryAuthorized || isUpcomingPaymentsAuthorized || isMakePaymentAuthorized;

  const dispatch = useDispatch();

  const isDevMode = config.aktosEnv !== "prod";
  const prefix = isDevMode ? `[${config.aktosEnv}] ` : "";
  useDocumentTitle(
    debtorPersonalInfo ? `${prefix}${debtorPersonalInfo?.name} - Aktos` : `${prefix}Aktos`,
    {
      preserveTitleOnUnmount: false,
    },
  );

  // Reset "Make a Payment" progress on new Debtor
  useEffect(() => {
    dispatch(resetMakeAPayment());
  }, [dispatch]);

  const overViewTabs = [
    {
      label: "Accounts",
      key: "accounts",
      children: <DebtorAccountsTable debtorId={debtorId} />,
    },
    {
      label: "Lawsuits",
      key: "lawsuits",
      children: <LegalInfo debtorId={debtorId} />,
    },
    {
      label: "Documents",
      key: "documents",
      children: <DocumentsTable debtorId={debtorId} />,
    },
    isAdditionalCostAuthorized && {
      label: "Additional Costs",
      key: "additionalCosts",
      children: <AddCostsTab />,
    },
    isBankruptcyAuthorized && {
      label: "Bankruptcy",
      key: "bankruptcy",
      children: <BankruptciesTable debtorId={debtorId} />,
    },
    {
      label: "Garnishments",
      key: "garnishments",
      children: <GarnishmentsTable debtorId={debtorId} />,
    },
    isDebtorCreditReportAuthorized && {
      label: "Credit Report",
      key: "creditReport",
      children: <DebtorCreditReportsTable debtorId={debtorId} />,
    },
  ];

  const paymentTabs = [
    isMakePaymentAuthorized && {
      label: "Make a Payment",
      key: "paymentPlans",
      children: <PaymentCard />,
    },
    isUpcomingPaymentsAuthorized && {
      label: "Scheduled Payments",
      key: "scheduledPayments",
      children: <ScheduledPayments />,
    },
    isPaymentHistoryAuthorized && {
      label: "Payment History",
      key: "paymentHistory",
      children: <PaymentHistory />,
    },
  ];

  const balanceItems = [
    {
      key: "principal",
      label: "Principal",
      value: formatCurrency(
        activeAccounts?.reduce((acc, account) => acc.add(account.principalBalance), currency(0)),
      ),
    },
    {
      key: "interest",
      label: "Interest",
      value: formatCurrency(
        activeAccounts?.reduce((acc, account) => acc.add(account.interestBalance), currency(0)),
      ),
    },
    {
      key: "totalFees",
      label: "Fees",
      value: formatCurrency(
        activeAccounts?.reduce((acc, account) => acc.add(account.totalFeesBalance), currency(0)),
      ),
    },
    {
      key: "total",
      label: <strong>Total</strong>,
      value: (
        <strong>
          {formatCurrency(
            activeAccounts?.reduce((acc, account) => acc.add(account.totalBalance), currency(0)),
          )}
        </strong>
      ),
    },
  ];

  if (isDebtorPersonalInfoError) {
    return (
      <Result
        status="404"
        title="No debtor found"
        subTitle="Please check the debtor ID and try again"
      />
    );
  }

  return (
    <StyledDiv
      // @ts-ignore
      $hidePanel={appearance?.items?.[SIDER_ID]?.hidePanel}
    >
      <FloatButton.BackTop />
      <StyledCard loading={isLoading || isFetching}>
        <h3>Overview</h3>
        <Row align="middle">
          <StyledStatistic
            valueStyle={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
            title={
              <Row align="middle">
                <div>Total Account Balance</div>
                <Popover
                  content={
                    <Col>
                      <Descriptions bordered column={1} size="small">
                        {balanceItems?.map((item) => (
                          <Descriptions.Item key={item.key} label={item.label}>
                            {item.value}
                          </Descriptions.Item>
                        ))}
                      </Descriptions>
                    </Col>
                  }
                  title="Total Account Balance"
                  trigger="hover"
                  placement="right"
                >
                  <StyledQuestionCircleOutlined />
                </Popover>
              </Row>
            }
            value={formatCurrency(
              activeAccounts?.reduce((acc, account) => acc.add(account.totalBalance), currency(0)),
            )}
            precision={2}
          />
          <StyledStatistic
            valueStyle={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
            title="# Active Accounts"
            value={activeAccounts?.length ?? 0}
          />
        </Row>
      </StyledCard>
      <div>
        <StyledCollapse defaultActiveKey={[1]} bordered={false}>
          <StyledPanel header={<h3>Collection Records</h3>} key={1}>
            <Tabs items={overViewTabs} />
          </StyledPanel>
        </StyledCollapse>
        <StyledCollapse defaultActiveKey={[2]} bordered={false}>
          <StyledPanel header={<h3>Communications</h3>} key={2}>
            {communicationLimitRules?.length > 0 && (
              <Alert
                message={
                  filteredRules?.length > 0
                    ? "Warning: Communication Limit Reached"
                    : "Communication Limit Status"
                }
                description={
                  <Col>
                    <StyledRow>
                      {communicationLimitRules?.map((each) => (
                        // @ts-ignore
                        <StyledCol align="middle">
                          <div>{each.rule.name ?? `Rule ${each.rule.id}`}</div>
                          <StyledProgress
                            // @ts-ignore
                            $count={each.communicationCount}
                            $total={each.rule.maxAllowed}
                            size="small"
                            type="circle"
                            percent={(each.communicationCount / each.rule.maxAllowed) * 100}
                            status={
                              each.communicationCount >= each.rule.maxAllowed
                                ? "exception"
                                : "normal"
                            }
                          />
                        </StyledCol>
                      ))}
                    </StyledRow>
                    {filteredRules?.length > 0 && (
                      <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                        By checking this box, I acknowledge that I have obtained consumer consent.
                      </Checkbox>
                    )}
                  </Col>
                }
                type={filteredRules?.length > 0 ? "error" : "info"}
                showIcon
              />
            )}
            <CommunicationsTable
              debtorId={debtorId}
              hasConsumerConsent={filteredRules.length === 0 || isChecked}
            />
          </StyledPanel>
        </StyledCollapse>
        {isPaymentTabsShown && (
          <StyledCollapse defaultActiveKey={[3]} bordered={false}>
            <StyledPanel header={<h3>Payments</h3>} key={3}>
              <Tabs items={paymentTabs} />
            </StyledPanel>
          </StyledCollapse>
        )}
        {isSystemLogAuthorized && (
          <StyledCollapse bordered={false}>
            <StyledPanel header={<h3>System Log</h3>} key={4}>
              <SystemLogTable debtorId={debtorId} />
            </StyledPanel>
          </StyledCollapse>
        )}
      </div>
    </StyledDiv>
  );
}

export default DebtorProfile;
