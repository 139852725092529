import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "app/envConfig";
import { loadStateWithKey } from "common/redux/redux-utils";
import { camelToSnakeCase, deepRename, populateKey, snakeToCamelCase } from "common/utils";
import { v4 as uuidv4 } from "uuid";

const API_REDUCER_KEY = "api";

const AUTH_STORAGE_KEY = config.useAuth0 ? "rtk:auth0" : "rtk:auth";

function getBaseQuery() {
  return fetchBaseQuery({
    baseUrl: config.apiBaseUrl,
    prepareHeaders: (headers, api) => {
      // @ts-ignore
      const state = loadStateWithKey(AUTH_STORAGE_KEY);

      // If we have a token set in state, let's assume that we should be passing it.
      if (state?.accessToken) {
        headers.set("Authorization", `Bearer ${state.accessToken}`);
      } else {
        return null;
      }

      // Generate a UUID for each request for tracking purposes.
      headers.set("x-request-id", uuidv4());

      return headers;
    },
  });
}

export function transformResponse(response, customGetter) {
  return populateKey(
    deepRename(response.results ?? response.result, snakeToCamelCase),
    customGetter,
  );
}

export function transformCursorResponse(response, customGetter) {
  const { results, result, ...meta } = response;
  return {
    meta: deepRename(meta, snakeToCamelCase),
    result: deepRename(result, snakeToCamelCase),
    results: populateKey(deepRename(results, snakeToCamelCase), customGetter),
  };
}

export function transformRequest(request) {
  return deepRename(request, camelToSnakeCase);
}

/**
 * Recursively replaces empty arrays with null in a deeply nested object.
 *
 * @param {Object} obj - The object to process.
 * @returns {Object} - The modified object with empty arrays replaced by null.
 */
export function replaceEmptyArraysWithNulls(obj) {
  if (Array.isArray(obj) && obj.length === 0) {
    return null;
  }

  if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach((key) => {
      obj[key] = replaceEmptyArraysWithNulls(obj[key]);
    });
  }

  return obj;
}

export function transformMultipart(values, file) {
  const formData = new FormData();

  Object.keys(values).forEach((key) => {
    formData.append(camelToSnakeCase(key), values[key]);
  });

  if (file) {
    if (Array.isArray(file) && file.length > 0) {
      // Currently the back-end only supports 1 file upload.  We may need to support more
      // than that eventually.
      formData.append("file", file[0].originFileObj || file[0]);
    } else {
      formData.append("file", file.originFileObj || file);
    }
  }

  return formData;
}

const api = createApi({
  reducerPath: API_REDUCER_KEY,
  baseQuery: getBaseQuery(),
  endpoints: () => ({}),
  // 60 seconds TTL cache for the same query/data
  refetchOnMountOrArgChange: 60,
});

export default api;
