import { Form, Input } from "antd";
import { DATE_FORMAT } from "common/constants";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledValue = styled.span`
  flex: 1;
  overflow-wrap: anywhere;
`;

const StyledLabel = styled.span`
  text-transform: capitalize;
  color: grey;
  font-size: 12px;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const formatDate = (value) => {
  // If the value is a date, format it
  const date = moment(value);
  return date.isValid() ? date.format(DATE_FORMAT) : value;
};

export default function DisplayComponent(props) {
  const {
    isEditing,
    label,
    name,
    slice,
    children,
    placeholder,
    maxLength,
    isDate,
    outputFormatter,
  } = props;

  // Cleanup props
  const { isEditing: _, ...eligibleProps } = props;

  if (isEditing) {
    return (
      <Form.Item {...eligibleProps}>
        {children ?? <Input placeholder={placeholder} maxLength={maxLength} />}
      </Form.Item>
    );
  }

  if (slice?.[name]) {
    const value = outputFormatter ? outputFormatter(slice[name]) : slice[name];
    return (
      <StyledContainer>
        <StyledLabel>{label}</StyledLabel>
        <StyledValue>{isDate ? formatDate(value) : value}</StyledValue>
      </StyledContainer>
    );
  }

  if (slice?.[name[0]]?.[name[1]] !== undefined) {
    const value = outputFormatter
      ? outputFormatter(slice[name[0]][name[1]])
      : slice[name[0]][name[1]];
    return (
      <StyledContainer>
        <StyledLabel>{label}</StyledLabel>
        <StyledValue>{isDate ? formatDate(value) : value}</StyledValue>
      </StyledContainer>
    );
  }

  return null;
}
