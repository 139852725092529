import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { PageHeader } from "components/pageHeader";
import currency from "currency.js";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import SettlementRuleModal from "features/settlementAndPaymentRules/components/settlementRuleModal";
import {
  useDeleteSettlementRuleMutation,
  useFetchSettlementRulesQuery,
} from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

export default function SettlementRulesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: settlementRules, isLoading } = useFetchSettlementRulesQuery();
  const [selectedRule, setSelectedRule] = useState(null);
  const [deleteSettlementRule] = useDeleteSettlementRuleMutation();
  const {
    data: {
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__GET]: isSettlementPaymentRuleGetAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__CREATE]: isSettlementPaymentRuleCreateAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__UPDATE]: isSettlementPaymentRuleUpdateAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__DELETE]: isSettlementPaymentRuleDeleteAuthorized,
    },
  } = useAuthorizations();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Threshold",
      dataIndex: "thresholdPercentage",
      key: "thresholdPercentage",
      render: (threshold) => `${currency(threshold, { precision: 4 }).multiply(100).value}%`,
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isSettlementPaymentRuleUpdateAuthorized && !isSettlementPaymentRuleDeleteAuthorized,
      render: (record) => (
        <Space size="middle">
          {isSettlementPaymentRuleUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedRule(record);
                setModalVisible(true);
              }}
            />
          )}
          {isSettlementPaymentRuleDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this settlement rule?"
              onConfirm={async () => {
                const result = await deleteSettlementRule({ id: record.id });
                if ("data" in result) {
                  message.success("Settlement rule deleted successfully!");
                }
              }}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const onNewRule = async () => {
    setModalVisible(false);
  };

  return isSettlementPaymentRuleGetAuthorized ? (
    <>
      <StyledHeader>Settlement Rules</StyledHeader>
      <StyledRow align="middle">
        <span>Define settlement rules for accounts.</span>
        <Button onClick={() => setModalVisible(true)} icon={<PlusOutlined />} type="link">
          Add Rule
        </Button>
      </StyledRow>
      <Table
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={settlementRules}
      />
      {isModalVisible && (
        <SettlementRuleModal
          title="Add Settlement Rule"
          onOk={onNewRule}
          selectedRule={selectedRule}
          onCancel={() => {
            setModalVisible(false);
            setSelectedRule(null);
          }}
        />
      )}
    </>
  ) : null;
}
