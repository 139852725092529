import { Col, Form, Radio } from "antd";
import { useMemo } from "react";
import styled from "styled-components";

const StyledCol = styled(Col)`
  font-weight: 600;
  padding: 0 0 6px;
`;

const StyledDiv = styled.div`
  margin-bottom: 24px;
`;

/*
  * This is a custom input component that is used for tri-state handling.
  @param {string} initialValue - The initial value of the radio group.
  @param {string} includeName - The name of the include field.
  @param {string} excludeName - The name of the exclude field.
  @param {object} radioProps - The props to be passed to the radio group.
  @param {object} wrapperProps - The props to be passed to the wrapper div.
  @param {string} label - The label for the radio group.
*/
function IncludeExcludeInput(props) {
  const {
    initialValue = "Ignore",
    includeName,
    excludeName,
    radioProps,
    label,
    wrapperProps,
  } = props;
  const form = Form.useFormInstance();

  const defaultValue = useMemo(() => {
    const includeValue = form.getFieldValue(includeName);
    const excludeValue = form.getFieldValue(excludeName);

    if (includeValue) {
      return "Include";
    }
    if (excludeValue) {
      return "Exclude";
    }
    return initialValue;
  }, [form, includeName, excludeName, initialValue]);

  const onChange = (e) => {
    const { value: targetValue } = e.target;

    if (targetValue === "Include") {
      form.setFieldValue(includeName, true);
      form.setFieldValue(excludeName, null);
    }

    if (targetValue === "Exclude") {
      form.setFieldValue(includeName, null);
      form.setFieldValue(excludeName, true);
    }

    if (targetValue === "Ignore") {
      form.setFieldValue(includeName, null);
      form.setFieldValue(excludeName, null);
    }
  };

  const options = [
    { label: "Include", value: "Include" },
    { label: "Exclude", value: "Exclude" },
    { label: "Ignore", value: "Ignore" },
  ];

  return (
    <StyledDiv {...wrapperProps}>
      {label && <StyledCol>{label}</StyledCol>}
      <Radio.Group onChange={onChange} options={options} defaultValue={defaultValue}>
        {options.map((option) => (
          <Radio key={option.value} value={option.value} {...radioProps}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
      <Form.Item hidden name={includeName} />
      <Form.Item hidden name={excludeName} />
    </StyledDiv>
  );
}

export default IncludeExcludeInput;
