import { ForwardingEntities } from "features/forwardingEntities";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function ForwardingEntitiesPage() {
  return (
    <PageLayout>
      <Content>
        <ForwardingEntities />
      </Content>
    </PageLayout>
  );
}

export default ForwardingEntitiesPage;
