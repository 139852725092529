/* eslint-disable camelcase */
// 1. type: list, subtype: enum --> multi-select using choices
// 2. type: enum, subtype: null --> dropdown
// 3. type: bool, subtype: null --> checkbox
// 4. type: str/int/decimal --> input
// 5. type: date/datetime --> date/datetime picker
// 6. type: model.*, subtype: null --> API call + dropdown
// 7. type: list, subtype: model.* --> API call + multi-select
// 8. type: list, subtype: str/int/decimal --> multi-input
//
// (not needed yet)
// type: list, subtype: str/int/date/datetime/decimal --> multi-input
import { Tag } from "antd";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "common/constants";
import { useFetchWorkflowModelQuery } from "features/workflows/workflowAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  &:last-child {
    margin-inline-end: 0;
  }
`;

// components that takes an argument and displays an input
// arg - { type, subtype, name, is_required, display, choices }
// displayValue - value that needs to be interpreted, single value or array of values
export default function ArgDisplay({ type, subtype, choices, displayValue }) {
  // check if type is a model
  const isModel = type.startsWith("model.") || subtype?.startsWith("model.");
  // extract name of the model
  const modelName = type.startsWith("model.") ? type.split(".")[1] : subtype?.split(".")[1];
  // fetch search results
  const payload = { model: modelName, ids: [] };
  if (isModel && typeof displayValue === "object") {
    if (Array.isArray(displayValue)) {
      payload.ids = displayValue
        ?.filter((item) => !!item)
        .map((item) => {
          if (typeof item === "object" && "value" in item) {
            return item.value;
          }
          return item;
        });
    } else {
      payload.ids = [displayValue.value];
    }
  }
  if (isModel && Number.isInteger(displayValue)) {
    payload.ids = [displayValue];
  }
  // covers cases 6 and 7
  // We only search for models if we have previously saved fields.
  const hasModelSearch = payload.ids.length > 0 && isModel;
  const { data: listModelResults, isLoading } = useFetchWorkflowModelQuery(payload, {
    skip: !hasModelSearch,
  });

  // 1. type: list, subtype: enum --> multi-select using choices
  if (type === "list" && subtype === "enum") {
    if (Array.isArray(displayValue)) {
      // filter choices for displayValue
      const filtered = choices?.filter(([value]) => displayValue.includes(value)) ?? [];

      // get labels if found, else use value
      return (
        <>
          {filtered.map(([value, label]) => (
            <StyledTag color="blue">{label ?? value}</StyledTag>
          ))}
        </>
      );
    }

    // if displayValue is not an array, return it as is
    return displayValue;
  }

  // 2. type: enum, subtype: null --> dropdown
  if (type === "enum" && subtype === null) {
    // find appropriate label for displayValue
    const choice = choices?.find(([value]) => value === displayValue);

    // get label if found, else use displayValue
    return <StyledTag color="blue">{choice?.[1] ?? displayValue}</StyledTag>;
  }

  // 3. type: bool, subtype: null --> checkbox
  if (type === "bool" && subtype === null) {
    return <strong>{displayValue ? "True" : "False"}</strong>;
  }

  // 4. type: str/int/decimal --> input
  if (type === "str" || type === "int" || type === "decimal") {
    return <strong>{displayValue}</strong>;
  }

  // 5. type: date --> date picker
  if (type === "date" || type === "datetime") {
    return (
      <strong>
        {moment(displayValue).format(type === "date" ? DATE_FORMAT : DATE_TIME_FORMAT)}
      </strong>
    );
  }

  // 6. type: model, subtype: null --> API call + dropdown
  // 7. type: list, subtype: model --> API call + multi-select
  if (isModel) {
    if (isLoading) return "...";
    if (!Array.isArray(displayValue)) displayValue = [displayValue];

    // filter choices for displayValue
    /*
    The values here can come in 1 of 2 forms due to the DebounceSelect search feature.

    Example 1:
      [{
        "label": <name of model (String)>
        "value": <id of model (Integer)>
      }]
    
    Example 2:
      [<id of model (Integer)>]
    
    Example 1 must be changed into Example 2 for ArgDisplay and ArgInput to display the
    correct label.
    */
    const searchableDisplayValue = displayValue.map((item) => {
      if (typeof item === "object" && "value" in item) {
        return item.value;
      }

      return item;
    });

    const filtered =
      listModelResults?.filter((item) => {
        if (typeof item === "object" && "id" in item) {
          return searchableDisplayValue.includes(item.id);
        }

        return searchableDisplayValue.includes(item);
      }) ?? [];

    // get labels if found, else use value
    return (
      <>
        {filtered.map(({ id, name }) => (
          <StyledTag color="blue" key={name}>
            {name ?? id}
          </StyledTag>
        ))}
      </>
    );
  }

  // 8. type: list, subtype: str/int/decimal --> multi-input
  if (type === "list" && (subtype === "str" || subtype === "int" || subtype === "decimal")) {
    if (Array.isArray(displayValue)) {
      // join values with comma
      return (
        <>
          {displayValue.map((value) => (
            <StyledTag color="blue" key={value}>
              {value}
            </StyledTag>
          ))}
        </>
      );
    }

    // if displayValue is not an array, return it as is
    return displayValue;
  }

  return displayValue;
}
