import { isRejectedWithValue } from "@reduxjs/toolkit";
import { message } from "antd";
import { snakeToCamelCase } from "common/utils";

const DEFAULT_ERROR_MESSAGE = "Something went wrong";

// These errors are taken from the exceptions.py
const ERROR_CODES = {
  INTERNAL_SERVER_ERROR: "internal_server_error",
  VALIDATION_FAILED: "validation_failed",
  NOT_AUTHENTICATED: "not_authenticated",
  PERMISSION_DENIED: "permission_denied",
  NOT_FOUND: "not_found",
  CONFLICT: "conflict",
  THROTTLED: "throttled",
  PAYMENT_DECLINED: "payment_declined",
  PAYMENT_PROVIDER_ERROR: "payment_provider_error",
  PAYMENT_ERROR: "payment_error",
};

const HTTP_RESPONSE_CODES = {
  INTERNAL_SERVER_ERROR: 500,
  VALIDATION_FAILED: 400,
  NOT_AUTHENTICATED: 401,
  PERMISSION_DENIED: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  THROTTLED: 429,
  PAYMENT_DECLINED: 424,
  PAYMENT_PROVIDER_ERROR: 424,
};

export const queryErrorLogger = () => (next) => (action) => {
  const { meta, payload } = action;

  // Echo header that we bounce off backend
  const surpressError = meta?.baseQueryMeta?.response?.headers?.get("Aktos-Suppress-Error");

  // Ultimate way to check if error occured
  if (isRejectedWithValue(action) && !surpressError) {
    const errorCode = payload?.data?.error?.code;
    const responseCode = payload?.status;

    const userMessage =
      Object.values(ERROR_CODES).includes(errorCode) &&
      Object.values(HTTP_RESPONSE_CODES).includes(responseCode)
        ? payload?.data?.error?.user_message
        : DEFAULT_ERROR_MESSAGE;

    // Simply display a toast with a 10 second timeout
    message.error(userMessage, 10);
  }

  return next(action);
};

export const formatError = (error) => {
  const fieldErrors = error?.data?.error?.details?.field_errors;
  const errors = Object.keys(fieldErrors ?? {}).map((name) => ({
    name: snakeToCamelCase(name),
    errors: fieldErrors[name],
  }));
  return errors;
};
