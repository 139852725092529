import { Form, Select } from "antd";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useFetchFeePlanRuleSetsQuery } from "features/feePlans/feePlansAPI";
import { useFetchTaxPlanRuleGroupsQuery } from "features/taxPlans/taxPlansAPI";

const REMITTANCE_OPTIONS = [
  {
    label: "Gross",
    value: "gross",
  },
  {
    label: "Net",
    value: "net",
  },
];

const BILLING_OPTIONS = [
  {
    label: "Gross Accounts Receivable",
    value: "gross_accounts_receivable",
  },
  {
    label: "Net Accounts Receivable",
    value: "net_accounts_receivable",
  },
];

const CREDIT_OPTIONS = [
  {
    label: "Credit Apply",
    value: "credit_apply",
  },
  {
    label: "Credit Refund",
    value: "credit_refund",
  },
];

const PAYMENT_DELIVERY_METHOD_OPTIONS = [
  {
    label: "Checks",
    value: "checks",
  },
  {
    label: "ACH",
    value: "ach",
  },
];

const INVOICE_CONSOLIDATION_OPTIONS = [
  {
    label: "Single Invoice",
    value: "single",
  },
  {
    label: "Separate Invoices",
    value: "separate",
  },
];

export default function InvoicesStep() {
  const { data: feePlanRuleSets } = useFetchFeePlanRuleSetsQuery();
  const { data: taxPlanRuleGroups } = useFetchTaxPlanRuleGroupsQuery();
  return (
    <>
      <Form.Item
        name={["invoiceConfig", "feePlanAssignmentRulesetId"]}
        label="Fee Plan Rule Set"
        rules={[{ required: true, message: "Fee plan rule set is required" }]}
      >
        <Select
          options={feePlanRuleSets?.map((ruleSet) => ({ label: ruleSet.name, value: ruleSet.id }))}
        />
      </Form.Item>
      <Form.Item name={["invoiceConfig", "taxPlanRuleGroupId"]} label="Tax Plan Rule Group">
        <Select
          options={[
            {
              label: "Unassigned",
              value: null,
            },
            ...(taxPlanRuleGroups?.map((ruleGroup) => ({
              label: ruleGroup.name,
              value: ruleGroup.id,
            })) ?? []),
          ]}
        />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "principalRemit", "agency"]}
        label="Agency Principal Payment Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "interestRemit", "agency"]}
        label="Agency Interest Payment Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select
          options={REMITTANCE_OPTIONS.map((each) => ({ label: each.label, value: each.value }))}
        />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "feesRemit", "agency"]}
        label="Agency Fees Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "principalRemit", "creditor"]}
        label="Client Principal Payment Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "interestRemit", "creditor"]}
        label="Client Interest Payment Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "feesRemit", "creditor"]}
        label="Creditor Fees Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "adjustments"]}
        label="Invoice Adjustment Remit Type"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "billing"]}
        label="Billing - (Coming Soon)"
        tooltip={
          <>
            <p>
              Gross Accounts Receivable - Bill any amount due agency for each invoice separately.
            </p>
            <p>
              Net Accounts Receivable - Deduct any prior invoice amount due agency from next
              remittance.
            </p>
          </>
        }
        rules={[{ required: true, message: "Invoice billing type is required" }]}
      >
        <Select options={BILLING_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "paymentMethodType"]}
        label="Payment Method"
        tooltip={
          <>
            <p>ACH - Amount due Client to be paid in ACH payments.</p>
            <p>Checks - Amount due Client to be paid via printing checks.</p>
          </>
        }
        rules={[{ required: true, message: "Invoice payment method type is required" }]}
      >
        <Select options={PAYMENT_DELIVERY_METHOD_OPTIONS} />
      </Form.Item>

      <Form.Item
        name={["invoiceConfig", "credit"]}
        label="Credit - (Coming Soon)"
        tooltip={
          <>
            <p>Credit Apply - If agency owes client money, apply to next remittance.</p>
            <p>Credit Refund - If agency owes client money, refund the client each month.</p>
          </>
        }
        rules={[{ required: true, message: "Invoice credit type is required" }]}
      >
        <Select options={CREDIT_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "consolidation"]}
        label="Invoice Format Consolidation - (Coming Soon)"
        tooltip={
          <>
            <p>
              Single Invoice - Put principal, interest, fee payments and legal costs all on the same
              invoice.
            </p>
            <p>
              Separate Invoices - Put debt, interest, fee payments and legal costs each on separate
              invoices.
            </p>
          </>
        }
        rules={[{ required: true, message: "Invoice format is required" }]}
      >
        <Select options={INVOICE_CONSOLIDATION_OPTIONS} />
      </Form.Item>
      <Form.Item name={["invoiceConfig", "taxRate"]} label="Tax Rate">
        <AktPercentageInput />
      </Form.Item>
      <Form.Item name={["invoiceConfig", "taxRemit"]} label="Invoice Tax Remit Type">
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
    </>
  );
}
