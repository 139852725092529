import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tag } from "antd";

function ConsentTag({ isConsent, children }) {
  return (
    <Tag
      color={isConsent ? "green" : null}
      icon={isConsent ? <CheckOutlined /> : <CloseOutlined />}
    >
      {children}
    </Tag>
  );
}

export default ConsentTag;
