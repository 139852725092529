import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentMenuKey: [""],
  parentMenuKey: [""],
};

export const appMenuSlice = createSlice({
  name: "appMenu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const selectAppMenuSlice = (state) => state.appMenu;

export default appMenuSlice.reducer;
