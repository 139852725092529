import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Switch, Table, message } from "antd";
import { populateKey } from "common/utils";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import WorkflowEventForm from "features/workflows/components/workflowEventModal";
import { useFetchWorkflowQuery } from "features/workflows/workflowAPI";
import {
  useDeleteWorkflowEventMutation,
  useUpdateWorkflowEventMutation,
} from "features/workflows/workflowEventsAPI";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

export default function WorkflowEventsTable() {
  const { workflowId } = useParams();
  const [deleteWorkflowEvent] = useDeleteWorkflowEventMutation();
  const [updateWorkflowEvent] = useUpdateWorkflowEventMutation();
  const { data: currentWorkflow = {}, isLoading } = useFetchWorkflowQuery({
    orderBy: "-id",
    workflowId,
  });
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedWorkflowEvent, setSelectedWorkflowEvent] = useState(null);
  const {
    data: {
      [PERMISSIONS.WORKFLOW__GET]: isWorkflowGetAuthorized,
      [PERMISSIONS.WORKFLOW__CREATE]: isWorkflowCreateAuthorized,
      [PERMISSIONS.WORKFLOW__UPDATE]: isWorkflowUpdateAuthorized,
      [PERMISSIONS.WORKFLOW__DELETE]: isWorkflowDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (record) => {
    const result = await deleteWorkflowEvent({ workflowId, id: record.id });
    if ("data" in result) {
      message.success("Workflow event removed successfully!");
    }
    if ("error" in result) {
      message.error("Unable to remove workflow event");
    }
  };

  const handleToggleEnabled = async (value, record) => {
    await updateWorkflowEvent({ workflowId, id: record.id, isEnabled: value });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (_, record) => (
        <Switch
          checked={record.isEnabled}
          onChange={(value) => handleToggleEnabled(value, record)}
        />
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isWorkflowUpdateAuthorized && !isWorkflowDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isWorkflowUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedWorkflowEvent(record);
                setModalVisible(true);
              }}
            />
          )}
          {isWorkflowDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this workflow?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  const dataSource = useMemo(
    () => populateKey(currentWorkflow.events ?? []),
    [currentWorkflow.events],
  );

  return isWorkflowGetAuthorized ? (
    <>
      <StyledRow align="middle">
        <span>Configure workflow events to an existing workflow. </span>
        {isWorkflowCreateAuthorized && (
          <Button
            onClick={() => {
              setModalVisible(true);
            }}
            type="link"
            icon={<PlusOutlined />}
          >
            Add Workflow Event
          </Button>
        )}
      </StyledRow>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={dataSource}
      />
      {isModalVisible && (
        <WorkflowEventForm
          onOk={() => {
            setSelectedWorkflowEvent(null);
            setModalVisible(false);
          }}
          onCancel={() => {
            setSelectedWorkflowEvent(null);
            setModalVisible(false);
          }}
          selectedWorkflowEvent={selectedWorkflowEvent}
        />
      )}
    </>
  ) : null;
}
