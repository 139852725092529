import InvoiceDetailPage from "pages/agencyPortal/invoices/invoiceDetailPage";
import InvoiceListPage from "pages/agencyPortal/invoices/invoiceListPage";
import { Route, Routes } from "react-router-dom";

function InvoicesPage() {
  return (
    <Routes>
      <Route path="/" element={<InvoiceListPage />} />
      <Route path="/:invoiceId" element={<InvoiceDetailPage />} />
    </Routes>
  );
}

export default InvoicesPage;
