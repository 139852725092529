import { Table } from "antd";
import { populateKey } from "common/utils";

export default function TaxPlanRuleGroupEntry({ taxPlans, taxPlanRuleGroup, states }) {
  const columns = [
    {
      title: "Tax Plan Name",
      dataIndex: "taxPlan",
      key: "taxPlan",
      render: (index, rule) =>
        taxPlans?.find((taxPlan) => taxPlan.id === rule.taxPlanId)?.name ??
        `Tax Plan #${rule.taxPlanId}`,
    },
    {
      title: "Creditor States",
      render: (text, record) =>
        states
          .filter((state) => record.creditorStates?.includes(state.value))
          .map((state) => state.display)
          .join(", "),
      key: "creditorStates",
    },
    {
      title: "Debtor States",
      render: (text, record) =>
        states
          .filter((state) => record.debtorStates?.includes(state.value))
          .map((state) => state.display)
          .join(", "),
      key: "debtorStates",
    },
  ];
  return (
    <Table
      bordered
      columns={columns}
      dataSource={populateKey(taxPlanRuleGroup.rules)}
      pagination={false}
    />
  );
}
