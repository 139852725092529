import { PAYMENT_VIEW_SCREEN_ENUMS, selectPaymentsSlice } from "features/payments/paymentsSlice";
import { useSelector } from "react-redux";
import styled from "styled-components";

import SelectAccountsForPayment from "features/payments/wizardSteps/common/selectAccountsForPayment";
import SelectPaymentMethod from "features/payments/wizardSteps/common/selectPaymentMethod";
import SetupPaymentSchedule from "features/payments/wizardSteps/common/setupPaymentSchedule";
import SetupPaymentPlanConfiguration from "features/payments/wizardSteps/paymentPlan/setupPaymentPlanConfiguration";

const MarginTopPaymentContainer = styled.div`
  margin-top: 16px;
`;

function PaymentCard() {
  const paymentsSlice = useSelector(selectPaymentsSlice);

  if (paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment) {
    return <SelectAccountsForPayment />;
  }
  if (paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration) {
    return (
      <div>
        <MarginTopPaymentContainer>
          <SetupPaymentPlanConfiguration />
        </MarginTopPaymentContainer>
      </div>
    );
  }
  if (
    paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.OneTimePaymentSchedule ||
    paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentSchedule
  ) {
    return <SetupPaymentSchedule />;
  }
  if (paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentMethod) {
    return <SelectPaymentMethod />;
  }
}

export default PaymentCard;
