import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Col, Row, Space, Table, Tag, message } from "antd";
import { TruncatedText } from "components/truncatedText";
import DebtorMergeGroupRuleModal from "features/mergeGroups/components/debtorMergeGroupRuleModal";
import {
  firstPriorityFields,
  secondPriorityFields,
  thirdPriorityFields,
} from "features/mergeGroups/constants";
import {
  useDeleteDebtorMergeGroupRuleMutation,
  useFetchDebtorMergeGroupRuleQuery,
} from "features/mergeGroups/debtorMergeGroupRuleAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
`;

const StyledRow = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default function DebtorMergeGroupRuleTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: debtorMergeGroupRules, isLoading } = useFetchDebtorMergeGroupRuleQuery();
  const [activeRecord, setActiveRecord] = useState(null);
  const [deleteTyingRule] = useDeleteDebtorMergeGroupRuleMutation();
  const {
    data: {
      [PERMISSIONS.DEBTOR_MERGE_GROUP_RULE__CREATE]: canCreateMergeGroupRule,
      [PERMISSIONS.DEBTOR_MERGE_GROUP_RULE__GET]: canGetMergeGroupRule,
      [PERMISSIONS.DEBTOR_MERGE_GROUP_RULE__UPDATE]: canUpdateMergeGroupRule,
    },
  } = useAuthorizations();

  const handleDelete = async (record) => {
    const result = await deleteTyingRule({ id: record.id });
    if ("data" in result) {
      message.success("Tying rule removed successfully!");
    }
    if ("error" in result) {
      message.error("Unable to remove Tying rule");
    }
  };

  const onUpdateAccountClick = (record) => {
    setModalVisible(true);
    setActiveRecord(record);
  };

  const allFields = [...firstPriorityFields, ...secondPriorityFields, ...thirdPriorityFields];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Rule Summary",
      key: "summary",
      render: (text, record) => (
        <Col>
          <StyledRow>
            <Space size="small">
              <span>Identifier Match Params: </span>
              <span>
                {record.identifierMatchParams.map((param) => (
                  <Tag color="green" key={param}>
                    {allFields.find((field) => field.value === param)?.label ?? param}
                  </Tag>
                ))}
              </span>
            </Space>
          </StyledRow>
          <StyledRow>
            <Space size="small">
              <span>Identifier Disqualifying Params: </span>
              <span>
                {record.disqualifyingMatchParams.map((param) => (
                  <Tag color="red" key={param}>
                    {allFields.find((field) => field.value === param)?.label ?? param}
                  </Tag>
                ))}
              </span>
            </Space>
          </StyledRow>
          {Object.keys(record.partialMatchParamsScore ?? {}).length > 0 && (
            <StyledRow>
              <Space size="small">
                <span>Partial Match Params With Scores: </span>
                <span>
                  {(record.partialMatchParamsScore ?? []).map((score) => (
                    <Tag color="blue" key={`${score.criteria}-${score.weight}`}>
                      {allFields.find((field) => field.value === score.criteria)?.label ??
                        score.criteria}
                      : {score.weight}
                    </Tag>
                  ))}
                </span>
              </Space>
            </StyledRow>
          )}
        </Col>
      ),
    },
    {
      title: "Description",
      dataIndex: "notes",
      key: "notes",
      render: (notes) => <TruncatedText text={notes} maxLength={40} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {canUpdateMergeGroupRule && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onUpdateAccountClick(record)} />
            </AntDToolTip>
          )}
          {/*
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this Tying rule?"
            onConfirm={() => handleDelete(record)}
          >
            <DeleteOutlined key="delete-Tying-rule" />
          </Popconfirm>
      */}
        </Space>
      ),
      fixed: "right",
    },
  ];

  if (!canGetMergeGroupRule) return null;

  return (
    <>
      <h4>Debtor Merge Group Rule</h4>
      <Row align="middle">
        Debtor Merge Group Rules define how the accounts and debtors in a Debtor Merge Group Group
        are linked.
        {canCreateMergeGroupRule && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Debtor Merge Group Rule
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={debtorMergeGroupRules}
      />
      {isModalVisible && (
        <DebtorMergeGroupRuleModal
          title={activeRecord ? "Edit Debtor Merge Group Rule" : "Add Debtor Merge Group Rule"}
          onOk={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          debtorMergeGroupRule={activeRecord}
        />
      )}
    </>
  );
}
