import api, { transformRequest, transformResponse } from "services/api";

export const bankruptcies = api
  .enhanceEndpoints({
    addTagTypes: ["Bankruptcies"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtorBankruptcies: build.query({
        query: (payload) => `/api/v1/common/debtors/${payload.debtorId}/bankruptcies/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Bankruptcies", id })),
                { type: "Bankruptcies", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Bankruptcies', id: 'LIST' }` is invalidated
              [{ type: "Bankruptcies", id: "LIST" }],
      }),
      createDebtorBankruptcy: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/bankruptcies/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "Bankruptcies", id: "LIST" }],
      }),
      updateDebtorBankruptcy: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/bankruptcies/${payload.bankruptcyId}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "Bankruptcies", id: "LIST" }],
      }),
      deleteDebtorBankruptcy: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/bankruptcies/${payload.bankruptcyId}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "Bankruptcies", id: "LIST" }],
      }),
    }),

    overrideExisting: false,
  });

export const {
  useFetchDebtorBankruptciesQuery,
  useCreateDebtorBankruptcyMutation,
  useUpdateDebtorBankruptcyMutation,
  useDeleteDebtorBankruptcyMutation,
} = bankruptcies;
