import api, { transformRequest, transformResponse } from "services/api";

export const lawFirmsApi = api.enhanceEndpoints({ addTagTypes: ["LawFirms"] }).injectEndpoints({
  endpoints: (build) => ({
    getLawFirms: build.query({
      query: () => "/api/v1/core/law-firms/",
      transformResponse: (response) => transformResponse(response),
      providesTags: (results) =>
        results
          ? // successful query
            [
              ...results.map(({ id }) => ({ type: "LawFirms", id })),
              { type: "LawFirms", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'LawFirms', id: 'LIST' }` is invalidated
            [{ type: "LawFirms", id: "LIST" }],
    }),
    createLawFirm: build.mutation({
      query: (payload) => ({
        url: "/api/v1/core/law-firms/create/",
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all LawFirms-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "LawFirms", id: "LIST" }],
    }),
    updateLawFirm: build.mutation({
      query: (payload) => ({
        url: `/api/v1/core/law-firms/${payload.lawFirmId}/update/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all LawFirms-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "LawFirms", id: "LIST" }],
    }),
    deleteLawFirm: build.mutation({
      query: (payload) => ({
        url: `/api/v1/core/law-firms/${payload.lawFirmId}/delete/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      // Invalidates all LawFirms-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "LawFirms", id: "LIST" }],
    }),
  }),
});

export const {
  useGetLawFirmsQuery,
  useCreateLawFirmMutation,
  useUpdateLawFirmMutation,
  useDeleteLawFirmMutation,
} = lawFirmsApi;
