import { Divider, Form, Input, Modal, Radio, Segmented, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { useUserType } from "features/auth";
import { useUpdateAccountMutation } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ECOA_CODES = [
  { value: "1", label: "1 - Individual" },
  { value: "2", label: "2 - Joint Contractual Liability" },
  { value: "3", label: "3 - Authorized User" },
  { value: "5", label: "5 - Co-Maker or Guarantor" },
  { value: "7", label: "7 - Maker" },
  { value: "T", label: "T - Terminated" },
  { value: "X", label: "X - Deceased" },
  { value: "W", label: "W - Business/Commercial" },
  { value: "Z", label: "Z - Delete Consumer" },
];

export const BCII_CODES = [
  { value: "A", label: "A - Petition for Chapter 7 Bankruptcy" },
  { value: "B", label: "B - Petition for Chapter 11 Bankruptcy" },
  { value: "C", label: "C - Petition for Chapter 12 Bankruptcy" },
  { value: "D", label: "D - Petition for Chapter 13 Bankruptcy" },
  { value: "E", label: "E - Discharged through Bankruptcy Chapter 7" },
  { value: "F", label: "F - Discharged through Bankruptcy Chapter 11" },
  { value: "G", label: "G - Discharged through Bankruptcy Chapter 12" },
  { value: "H", label: "H - Discharged through Bankruptcy Chapter 13" },
  { value: "I", label: "I - Chapter 7 Bankruptcy Dismissed" },
  { value: "J", label: "J - Chapter 11 Bankruptcy Dismissed" },
  { value: "K", label: "K - Chapter 12 Bankruptcy Dismissed" },
  { value: "L", label: "L - Chapter 13 Bankruptcy Dismissed" },
  { value: "M", label: "M - Chapter 7 Bankruptcy Withdrawn" },
  { value: "N", label: "N - Chapter 11 Bankruptcy Withdrawn" },
  { value: "O", label: "O - Chapter 12 Bankruptcy Withdrawn" },
  { value: "P", label: "P - Chapter 13 Bankruptcy Withdrawn" },
  { value: "Q", label: "Q - Removes previously reported Bankruptcy Indicator" },
  { value: "R", label: "R - Chapter 7 Reaffirmation of Debt" },
  { value: "S", label: "S - Removes previously reported Reaffirmation of Debt" },
  { value: "T", label: "T - Credit Grantor Cannot Locate Consumer" },
  { value: "U", label: "U - Consumer Now Located" },
  { value: "V", label: "V - Chapter 7 Reaffirmation of Debt Rescinded" },
  { value: "Z", label: "Z - Bankruptcy (Undesignated Chapter)" },
  { value: "1A", label: "1A - Personal Receivership" },
  { value: "2A", label: "2A - Lease Assumption" },
];

const BSC_CODES = [
  { value: "B", label: "B - Payments Managed by Credit Counseling Service" },
  { value: "C", label: "C - Paid by Co-Maker" },
  { value: "M", label: "M - Account Closed at Credit Grantor's Request" },
  { value: "O", label: "O - Account Transferred to Another Lender" },
  { value: "S", label: "S - Special Handling" },
  { value: "V", label: "V - Adjustment Pending" },
  { value: "AB", label: "AB - Debt Being Paid Through Insurance" },
  { value: "AC", label: "AC - Paying Under a Partial Payment Agreement" },
  { value: "AH", label: "AH - Purchased by Another Lender" },
  { value: "AI", label: "AI - Recalled to Active Military Duty" },
  { value: "AJ", label: "AJ - Payroll Deduction" },
  { value: "AM", label: "AM - Account Payment Assured by Wage Garnishment" },
  { value: "AN", label: "AN - Account Acquired by RTC/FDIC" },
  { value: "AP", label: "AP - Credit Line Suspended" },
  { value: "AR", label: "AR - Contingent Liability" },
  { value: "AS", label: "AS - Account Closed Due to Refinance" },
  { value: "AT", label: "AT - Account Closed Due to Transfer" },
  { value: "AU", label: "AU - Account Paid in Full for Less than Full Balance" },
  { value: "AV", label: "AV - First Payment Never Received" },
  { value: "AW", label: "AW - Affected by Natural Disaster" },
  { value: "BA", label: "BA - Transferred to Recovery" },
  { value: "BL", label: "BL - Credit Card Lost or Stolen" },
  { value: "BP", label: "BP - Paid through Insurance" },
  { value: "BZ", label: "BZ - Judgment Granted" },
  { value: "CS", label: "CS - For Use by Child Support Agencies Only" },
];

export const BCCC_CODES = [
  { value: "XA", label: "XA - Account closed at consumer's request" },
  { value: "XB", label: "XB - Account information disputed by consumer" },
  { value: "XC", label: "XC - Completed investigation of FCRA" },
  { value: "XD", label: "XD - Closed at consumer's request & in dispute under FCRA" },
  { value: "XE", label: "XE - Closed at cons request, dispute completed, consumer disagrees" },
  { value: "XF", label: "XF - Account in dispute under Fair Credit Billing Act" },
  { value: "XG", label: "XG - FCBA Dispute resolved (consumer disagrees)" },
  { value: "XH", label: "XH - Previously in dispute (now resolved, reported by credit grantor)" },
  { value: "XR", label: "XR - Removes the most recently reported Compliance Condition Code" },
];

const StyledSegmented = styled(Segmented)`
  margin-bottom: 16px;
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function CreditBureauSettingsModal({ title, open, onOk, onCancel, account }) {
  const { isCreditorUserType } = useUserType();
  const { debtorId } = useParams();
  const [currentTab, setCurrentTab] = useState(debtorId);
  const [form] = useForm();
  const [isSkipCreditReporting, setIsSkipCreditReporting] = useState(false);
  const [isDeleteFromCreditReport, setIsDeleteFromCreditReport] = useState(false);
  const { data: backendConstants } = useFetchBackendConstantsQuery();
  const { data: isEditCreditBureauAuthorized } = useAuthorization(
    PERMISSIONS.ACCOUNT_CBR_CONFIG__UPDATE,
  );
  const [updateAccountDebt, { isLoading }] = useUpdateAccountMutation();

  const isDisabled =
    account?.legalStatus === "rolled_up" || !isEditCreditBureauAuthorized || isCreditorUserType;

  const onSubmit = async () => {
    const values = await form.validateFields();

    const result = await updateAccountDebt({
      ...values,
      accountId: account.id,
    });

    if ("data" in result) {
      message.success("Processing complete!");
      onOk();
    }

    if ("error" in result) {
      message.error("Failed to update credit bureau settings");
    }
  };

  const initialValues = {
    ...account,
    accountId: account?.id,
    isSkipCreditReporting: account?.isSkipCreditReporting ?? false,
    isDeleteFromCreditReport: account?.isDeleteFromCreditReport ?? false,
    complianceConditionCode: account?.complianceConditionCode || null,
  };

  return (
    <Modal
      width={600}
      closable={false}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      okText="Save"
      okButtonProps={{ disabled: isDisabled, loading: isLoading }}
      onCancel={onCancel}
    >
      <StyledForm
        disabled={isDisabled}
        layout="vertical"
        form={form}
        name="disputes"
        initialValues={initialValues}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="accountId" label="Account ID">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="creditReportingStatus"
          label="Status"
          tooltip={
            <>
              <p>U - Being Reported, Is Paying</p>
              <p>R - Being Reported, Is Not Paying</p>
              <p>{`C - Account Closed (e.g statute of limitations, medical debt < $500)`}</p>
              <p>N - Never Reported to Credit Bureau</p>
              <p>S - Account is Suppressed from Credit Reporting</p>
              <p>C - Will Be Closed (still reporting to Credit Bureau)</p>
            </>
          }
        >
          <Select
            filterOption={(input, option) =>
              // @ts-ignore
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            placeholder="Select one..."
            options={backendConstants?.creditReportingStatusOptions.map((o) => ({
              label: o.display,
              value: o.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="creditBureauNumber" label="Credit Bureau Number">
          <Input />
        </Form.Item>
        <Form.Item
          name="isSkipCreditReporting"
          label="Suppress from Credit Reporting?"
          tooltip={
            <p>If "Yes" is selected, this account will be suppressed from Credit Reporting.</p>
          }
        >
          <Radio.Group
            onChange={() => {
              setIsSkipCreditReporting(!isSkipCreditReporting);
            }}
            value={isSkipCreditReporting}
          >
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="isDeleteFromCreditReport"
          label="Delete from Credit Reporting?"
          tooltip={
            <p>
              If "Yes" is selected, this account which may have been previously reported will be
              credit reported as a 'DA' to signify a 'Delete Account'.
            </p>
          }
        >
          <Radio.Group
            onChange={() => {
              setIsDeleteFromCreditReport(!isDeleteFromCreditReport);
            }}
            value={isDeleteFromCreditReport}
          >
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Bureau Date"
          name="creditReportingDate"
          tooltip={
            initialValues.creditReportingDate ? (
              <p>
                The last credit reporting file from Aktos was created on{" "}
                {formatDate(initialValues.creditReportingDate)}
              </p>
            ) : (
              <p>This account has not been credit reported on yet</p>
            )
          }
        >
          <AktDatePicker placeholder="" disabled />
        </Form.Item>
        <Form.Item name="specialCommentCode" label="Bureau Special Comments">
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select an option"
            options={BSC_CODES}
          />
        </Form.Item>
        <Form.Item name="complianceConditionCode" label="Bureau Compliance Condition Code">
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select an option"
            options={[
              {
                label: "None",
                value: null,
              },
              ...BCCC_CODES,
            ]}
          />
        </Form.Item>
        <Form.Item label="Notes" name="creditBureauNotes">
          <Input.TextArea />
        </Form.Item>
        <Divider />
        <h4>Debtors</h4>
        <StyledSegmented
          options={account?.debtors?.map((debtor) => ({
            label: [`#${debtor.id},`, debtor?.firstName, debtor?.middleName, debtor?.lastName].join(
              " ",
            ),
            value: debtor.id.toString(),
          }))}
          value={currentTab}
          // @ts-ignore
          onChange={(value) => setCurrentTab(value)}
        />
        {account?.debtors?.map((debtor) => (
          <div hidden={debtor.id.toString() !== currentTab} key={debtor.id}>
            <Form.Item name={["ecoaMap", debtor.id.toString()]} label="ECOA">
              <Select
                placement="topRight"
                popupMatchSelectWidth={false}
                placeholder="Select one..."
                options={ECOA_CODES}
              />
            </Form.Item>
            <Form.Item
              name={["bciiMap", debtor.id.toString()]}
              label="BCII"
              tooltip="Bureau Consumer Information Indicator"
            >
              <Select
                popupMatchSelectWidth={false}
                placeholder="Select one..."
                options={BCII_CODES}
              />
            </Form.Item>
          </div>
        ))}
      </StyledForm>
    </Modal>
  );
}

export default CreditBureauSettingsModal;
