import api, { transformRequest, transformResponse } from "services/api";

export const udfApi = api
  .enhanceEndpoints({ addTagTypes: ["AccountUdfCustomField"] })
  .injectEndpoints({
    endpoints: (build) => ({
      addAccountUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/account-custom-fields/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "AccountUdfCustomField", id: "LIST" }],
      }),
      updateAccountUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/account-custom-fields/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "AccountUdfCustomField", id: "LIST" }],
      }),
      deleteAccountUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/account-custom-fields/${payload.id}/delete/`,
          method: "POST",
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "AccountUdfCustomField", id: "LIST" }],
      }),
    }),
  });

export const {
  useAddAccountUdfCustomFieldMutation,
  useUpdateAccountUdfCustomFieldMutation,
  useDeleteAccountUdfCustomFieldMutation,
} = udfApi;
