import { Button, Form } from "antd";
import { useFormWizard } from "components/formWizard/formWizardContext";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin: 0 8px;
`;

const StyledStepsAction = styled.div`
  margin-left: -8px;
`;

function ActionBar() {
  const { steps, current, onCancel, isLoading, onPreviousStep } = useFormWizard();

  return (
    <Form.Item>
      <StyledStepsAction>
        {current === 0 ? (
          <StyledButton onClick={onCancel} htmlType="button" {...steps[current]?.cancelButtonProps}>
            Cancel
          </StyledButton>
        ) : (
          <StyledButton
            onClick={onPreviousStep}
            htmlType="button"
            {...steps[current]?.previousButtonProps}
          >
            Previous
          </StyledButton>
        )}
        {current < steps.length - 1 ? (
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            {...steps[current]?.nextButtonProps}
          >
            Next
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            {...steps[current]?.doneButtonProps}
          >
            Done
          </Button>
        )}
      </StyledStepsAction>
    </Form.Item>
  );
}

export default ActionBar;
