import { Alert, Button, Col, Descriptions, Form, InputNumber, Select, Space, message } from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatCurrency } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { AktPercentageInput } from "components/aktPercentageInput";
import currency from "currency.js";
import { useApplyJudgmentMutation } from "features/legal/legalAPI";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
`;

const StyledSpace = styled(Space)`
  position: absolute;
  right: 24px;
  bottom: 20px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 8px;
`;

export function SimpleJudgmentForm({ lawsuit, onOk, onCancel }) {
  const { accountIds, debtorIds } = lawsuit;
  const [form] = Form.useForm();
  const entryDate = Form.useWatch(["judgment", "entryDate"], form);
  const { debtorId } = useParams();
  const [applyJudgment, { isLoading: isSaving }] = useApplyJudgmentMutation();

  useEffect(() => {
    if (entryDate && !form.isFieldTouched(["judgment", "expiryDate"])) {
      form.setFields([
        {
          name: ["judgment", "expiryDate"],
          value: moment(entryDate).add(10, "year").format(DATE_FORMAT),
          touched: false,
        },
      ]);
    }
  }, [entryDate, form]);

  const handleSave = async () => {
    const values = await form.getFieldsValue();

    const result = await applyJudgment({
      ...values,
      debtorId,
      lawsuitId: lawsuit.id,
      type: "simple",
    });
    if ("error" in result) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      message.error(result.error?.data?.error?.user_message);
      return;
    }

    message.success("Judgment applied successfully!");
    onOk();
  };

  const getAccountTotal = useCallback(
    (accountId) =>
      lawsuit?.accounts
        ?.filter((account) => account.id === accountId)
        .reduce(
          (acc, account) => acc.add(account.paymentCategories.totalBalance),
          currency(0, { precision: 4 }),
        ).value,
    [lawsuit?.accounts],
  );

  const initialValues = useMemo(
    () => ({
      judgment: {
        debtorIds,
        accountAllocations: accountIds.map((accountId, index) => ({
          accountId,
          judgmentPrincipal: getAccountTotal(accountId),
        })),
      },
    }),
    [debtorIds, accountIds, getAccountTotal],
  );

  const balanceItems = useMemo(
    () =>
      lawsuit?.accounts?.reduce(
        (acc, account) => ({
          ...acc,
          [account.id]: [
            {
              key: "principal",
              label: "Principal",
              value: formatCurrency(account.paymentCategories.principalBalance),
            },
            {
              key: "interest",
              label: `Interest (${currency(account?.interestRate, { precision: 4 }).multiply(
                100,
              )}%)`,
              value: formatCurrency(account.paymentCategories.interestBalance),
            },
            {
              key: "totalFees",
              label: "Fees",
              value: formatCurrency(account.paymentCategories.totalFeesBalance),
            },
            {
              key: "total",
              label: <strong>Total</strong>,
              value: <strong>{formatCurrency(account.paymentCategories.totalBalance)}</strong>,
            },
          ],
        }),
        {},
      ),
    [lawsuit?.accounts],
  );

  const totalBalanceContent = useCallback(
    (accountId) => (
      <Col>
        <Descriptions bordered column={1} size="small">
          {balanceItems[accountId].map((item) => (
            <Descriptions.Item
              key={item.key}
              label={item.label}
              contentStyle={{ whiteSpace: "nowrap" }}
            >
              {item.value}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Col>
    ),
    [balanceItems],
  );

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <StyledForm
      layout="vertical"
      onFinishFailed={onFinishFailed}
      form={form}
      onFinish={handleSave}
      initialValues={initialValues}
    >
      <Form.Item hidden name={["judgment", "debtorIds"]} />
      <Form.Item
        label="Entry Date"
        name={["judgment", "entryDate"]}
        rules={[{ required: true }]}
        tooltip="Judgment grant date."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Expiry Date"
        name={["judgment", "expiryDate"]}
        rules={[{ required: true }]}
        tooltip="Judgment expiry date."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Judgment Interest Calculation Date"
        name={["judgment", "interestStartDate"]}
        rules={[{ required: true }]}
        tooltip="Interest accrual begins on this day."
      >
        <AktDatePicker disabledDate={(current) => current && current > moment().endOf("day")} />
      </Form.Item>
      <Form.Item
        name={["judgment", "interestRate"]}
        label="Judgment Interest Rate"
        rules={[{ required: true }]}
        tooltip="Interest is accumulated as annual APR, not compound interest."
      >
        <AktPercentageInput />
      </Form.Item>
      {lawsuit?.accountIds.map((accountId, index) => (
        <>
          <Form.Item
            label="Account ID"
            name={["judgment", "accountAllocations", index, "accountId"]}
            hidden
          >
            <Select
              value={accountId}
              options={accountIds?.map((id) => ({
                value: id,
              }))}
            />
          </Form.Item>
          <Form.Item
            extra={
              <StyledAlert
                type="warning"
                showIcon
                message="This amount is an estimate that reflects the current interest balance rather than the balance at the judgment date"
              />
            }
            key={accountId}
            label={`Judgment Amount for Account ID: ${accountId}`}
            name={["judgment", "accountAllocations", index, "judgmentPrincipal"]}
            tooltip={{
              title: totalBalanceContent(accountId),
              color: "white",
            }}
          >
            <InputNumber prefix="$" step="0.01" />
          </Form.Item>
        </>
      ))}
      <StyledSpace>
        <Button onClick={onCancel}>Cancel</Button>
        <Button loading={isSaving} disabled={isSaving} onClick={form.submit} type="primary">
          Apply
        </Button>
      </StyledSpace>
    </StyledForm>
  );
}
