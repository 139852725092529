import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToWords, snakeToCamelCase, toTitleCase } from "common/utils";
import { DisplayComponent, DisplayPhoneNumber } from "components/formItems";
import { useUserType } from "features/auth";
import {
  useArchiveDebtorContactsMutation,
  useCreateDebtorContactsMutation,
  useUpdateDebtorContactsMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledTag = styled(Tag)`
  margin-right: 0;
  font-weight: normal;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledValue = styled.span`
  flex: 1;
  overflow-wrap: anywhere;
`;

const StyledLabel = styled.span`
  text-transform: capitalize;
  color: grey;
  font-size: 12px;
  flex: 1;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

export function DebtorContactInfoCard({
  debtorContact = undefined,
  isNew = undefined,
  onCreate = undefined,
  onCancel = undefined,
  title = undefined,
  profileType = "consumer",
}) {
  const { isAgencyUserType } = useUserType();
  const [form] = Form.useForm();
  const { debtorId } = useParams();
  const [isEditing, setIsEditing] = useState(isNew || false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const [updateDebtorContact, { isLoading: isUpdateLoading }] = useUpdateDebtorContactsMutation();
  const [createDebtorContact, { isLoading: isCreateLoading }] = useCreateDebtorContactsMutation();
  const [archiveDebtorContact, { isLoading: isDeleteLoading }] = useArchiveDebtorContactsMutation();
  const {
    data: {
      [PERMISSIONS.DEBTOR_CONTACT__CREATE]: isAddDebtorContactInfoAuthorized,
      [PERMISSIONS.DEBTOR_CONTACT__UPDATE]: isUpdateDebtorContactInfoAuthorized,
      [PERMISSIONS.DEBTOR_CONTACT__DELETE]: isDeleteDebtorContactInfoAuthorized,
    },
  } = useAuthorizations();
  const editableDataSourceTypeValues = constants?.editableDataSourceTypes?.map(
    ({ value }) => value,
  );

  const archiveContact = async () => {
    const result = await archiveDebtorContact({
      debtorId,
      contactId: debtorContact.id,
    });
    if ("data" in result) {
      message.success("Contact deleted successfully!");
    }
  };

  const toggleIsEditing = async () => {
    if (isEditing) {
      let values;
      try {
        values = await form.validateFields();
      } catch (e) {
        return null; // Form will handle error messages
      }

      const action = isNew
        ? createDebtorContact({
            debtorId,
            ...values,
          })
        : updateDebtorContact({
            debtorId,
            debtorContactId: debtorContact.id,
            ...values,
          });
      const result = await action;

      if ("data" in result) {
        message.success("Saved successfully!");
        if (isNew) onCreate();
      }

      if ("error" in result) {
        form.setFields(formatError(result.error));
        message.error("Could not update contact info");
        return;
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
      form.setFieldsValue(debtorContact);
    }
  };

  const onPrimaryCancel = () => {
    setIsEditing(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 14 },
    },
  };

  return (
    <StyledCard>
      <StyledHeader>
        <h4>
          <Space size="small">
            {isNew
              ? `${profileType === "consumer" ? "New Debtor Contact" : "New Contact Person"}`
              : title}
            {!isEditing && debtorContact.isDoNotContact && (
              <StyledTag color="warning">Do Not Contact</StyledTag>
            )}
          </Space>
        </h4>

        {!isEditing &&
          isAgencyUserType &&
          (isUpdateDebtorContactInfoAuthorized || isDeleteDebtorContactInfoAuthorized) && (
            <Row>
              {isUpdateDebtorContactInfoAuthorized && (
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  loading={isCreateLoading || isUpdateLoading}
                  onClick={toggleIsEditing}
                />
              )}
              {isDeleteDebtorContactInfoAuthorized && (
                <Popconfirm
                  placement="topRight"
                  okText="Yes"
                  title="Are you sure you want to delete this contact?"
                  onConfirm={archiveContact}
                >
                  <Button loading={isDeleteLoading} type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Row>
          )}
      </StyledHeader>
      <Form labelAlign="left" form={form} {...formItemLayout} initialValues={{ dataSource: "" }}>
        {isEditing ? (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="First Name"
              name="firstName"
              rules={[{ required: true }]}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Middle Name"
              name="middleName"
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Last Name"
              name="lastName"
              rules={[{ required: true }]}
            />
          </>
        ) : (
          <StyledContainer>
            <StyledLabel>
              <span>Contact Name</span>
              {debtorContact?.notes ? (
                <Tooltip title={debtorContact?.notes} trigger="hover">
                  <StyledInfoCircleOutlined />
                </Tooltip>
              ) : null}
            </StyledLabel>
            <StyledValue>
              {debtorContact.firstName} {debtorContact.middleName} {debtorContact.lastName}
            </StyledValue>
          </StyledContainer>
        )}
        <DisplayComponent isEditing={isEditing} slice={debtorContact} label="Email" name="email" />
        {profileType === "commercial" && (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Role"
              name="relationshipToDebtor"
              rules={[{ required: true }]}
              outputFormatter={(value) =>
                constants?.commercialDebtorContactRelationships?.find(
                  (item) => item.value === value,
                )?.display
              }
            >
              <Select
                options={constants?.commercialDebtorContactRelationships?.map(
                  ({ display, value }) => ({
                    value,
                    label: display,
                  }),
                )}
              />
            </DisplayComponent>
            <DisplayComponent isEditing={isEditing} slice={debtorContact} label="Fax" name="fax" />
          </>
        )}
        {profileType === "consumer" && (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Preferred Language"
              name="preferredLanguage"
            >
              <Select
                options={constants?.languages.map(({ display, value }) => ({
                  value,
                  label: display,
                }))}
              />
            </DisplayComponent>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Relationship"
              name="relationshipToDebtor"
              rules={[{ required: true }]}
              outputFormatter={(value) =>
                constants?.debtorContactRelationships?.find((item) => item.value === value)?.display
              }
            >
              {/* Agents shouldn't be able to create the DebtorContact as type Debtor/Self. Those
                are generated automatically on the backend and only 1 should exist. */}
              <Select
                options={constants?.debtorContactRelationships
                  .slice(1)
                  ?.map(({ display, value }) => ({
                    value,
                    label: display,
                  }))}
              />
            </DisplayComponent>
          </>
        )}
        {isEditing && (
          <Form.Item label="Do not contact" name="isDoNotContact" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        )}
        {isEditing ? (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Phone 1"
              name="phoneNumber1"
              placeholder="XXX-XXX-XXXX"
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Phone 1 type"
              name="phoneType1"
            >
              <Select
                options={constants?.phoneTypes.map(({ display, value }) => ({
                  value,
                  label: display,
                }))}
              />
            </DisplayComponent>
          </>
        ) : (
          !!debtorContact.phoneNumber1 && (
            <DisplayPhoneNumber
              number={debtorContact.phoneNumber1}
              type={`(${toTitleCase({ str: debtorContact.phoneType1, delimiter: "_" })})`}
              label="Phone 1"
              isValid={debtorContact.phoneType1 !== "invalid"}
            />
          )
        )}
        {isEditing ? (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Phone 2"
              name="phoneNumber2"
              placeholder="XXX-XXX-XXXX"
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Phone 2 type"
              name="phoneType2"
            >
              <Select
                options={constants?.phoneTypes.map(({ display, value }) => ({
                  value,
                  label: display,
                }))}
              />
            </DisplayComponent>
          </>
        ) : (
          !!debtorContact.phoneNumber2 && (
            <DisplayPhoneNumber
              number={debtorContact.phoneNumber2}
              type={`(${toTitleCase({ str: debtorContact.phoneType2, delimiter: "_" })})`}
              label="Phone 2"
              isValid={debtorContact.phoneType2 !== "invalid"}
            />
          )
        )}
        {/* Only allow setting of data source if no data source is set, or it is editable (i.e. present in the editableDataSourceTypes) */}

        <DisplayComponent isEditing={isEditing} slice={debtorContact} label="Notes" name="notes">
          <Input.TextArea placeholder="Add Notes" />
        </DisplayComponent>
        {!isEditing && (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Data Source"
              name="dataSource"
              outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={debtorContact}
              label="Created At"
              name="createdAt"
              outputFormatter={(value) => moment(value).format(DATE_TIME_FORMAT)}
            />
          </>
        )}
        {isEditing && (
          <>
            {(!debtorContact?.dataSource ||
              editableDataSourceTypeValues.includes(debtorContact?.dataSource)) && (
              <DisplayComponent
                isEditing={isEditing}
                slice={debtorContact}
                label="Data Source"
                name="dataSource"
                outputFormatter={(value) => camelToWords(value)}
              >
                <Select
                  options={constants?.editableDataSourceTypes?.map(({ display, value }) => ({
                    value,
                    label: display,
                  }))}
                />
              </DisplayComponent>
            )}
            <StyledButtonContainer>
              <Space>
                <Button onClick={isNew ? onCancel : onPrimaryCancel}>Cancel</Button>
                {isAddDebtorContactInfoAuthorized && isUpdateDebtorContactInfoAuthorized && (
                  <Button
                    loading={isCreateLoading || isUpdateLoading}
                    onClick={toggleIsEditing}
                    type="primary"
                  >
                    Save
                  </Button>
                )}
              </Space>
            </StyledButtonContainer>
          </>
        )}
      </Form>
    </StyledCard>
  );
}
