import { PriorityAccountRulesTable } from "features/priorityAccountRulesTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function PriorityAccountPage() {
  return (
    <PageLayout>
      <Content>
        <PriorityAccountRulesTable />
      </Content>
    </PageLayout>
  );
}

export default PriorityAccountPage;
