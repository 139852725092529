import api, { transformRequest, transformResponse } from "services/api";

export const userManagementApi = api
  .enhanceEndpoints({ addTagTypes: ["Roles", "Users"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchRoles: build.query({
        query: () => "/api/v1/core/groups/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [...results.map(({ id }) => ({ type: "Roles", id })), { type: "Roles", id: "LIST" }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Roles', id: 'LIST' }` is invalidated
              [{ type: "Roles", id: "LIST" }],
      }),
      createRole: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/groups/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Roles", id: "LIST" }],
      }),
      changeUserPassword: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/users/${payload.userId}/reset-password/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "Users", id: arg.userId }],
      }),
      updateRole: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/groups/${payload.roleId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Roles", id: arg.roleId },
          { type: "Roles", id: "LIST" },
        ],
      }),
      fetchUsers: build.query({
        query: () => "/api/v1/core/users/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [...results.map(({ id }) => ({ type: "Users", id })), { type: "Users", id: "LIST" }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Users', id: 'LIST' }` is invalidated
              [{ type: "Users", id: "LIST" }],
      }),
      createUser: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/users/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "Users", id: "LIST" }],
      }),
      updateUser: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/users/${payload.userId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Users", id: arg.userId },
          { type: "Users", id: "LIST" },
        ],
      }),
      fetchAllPermissions: build.query({
        query: () => "/api/v1/core/permissions/",
        transformResponse: (response) => transformResponse(response),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchUsersQuery,
  useFetchRolesQuery,
  useFetchAllPermissionsQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useChangeUserPasswordMutation,
} = userManagementApi;
