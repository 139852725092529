import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Row, message } from "antd";
import { resetStateAction } from "common/redux/resetStateAction";
import { useCreditorUserSignUpMutation } from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 300px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 12px;
  justify-content: center;
`;

function SignUp() {
  const { isWhiteLabeled, displayName, slug, signupProps } = useWhiteLabel();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [signUp, { isLoading }] = useCreditorUserSignUpMutation();
  const [form] = Form.useForm();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);
  const dispatch = useDispatch();

  // Reset state when user navigates to signup page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    setErrorMessage(null);
    values = signupProps.preTransform(values);
    const result = await signUp(values);

    if ("data" in result) {
      message.success("Successfully signed up!", 2);
      navigate("/login");
    }
    if ("error" in result) {
      // @ts-ignore
      setErrorMessage(result.error.data?.error?.user_message ?? "Failed to sign up");
    }
  };

  return (
    <>
      <StyledRow>
        <h3>Sign up for an {isWhiteLabeled ? displayName : "Aktos"} account</h3>
      </StyledRow>
      <StyledForm
        autoComplete="off"
        scrollToFirstError
        form={form}
        validateMessages={{ required: "This is a required field" }}
        className="sign-up-form"
        onFinish={onFinish}
      >
        <Form.Item
          name="creditorCode"
          hasFeedback
          rules={[{ required: true, message: "Please enter client code" }]}
        >
          <Input placeholder="Enter client code" data-testid="creditorCode" />
        </Form.Item>
        {isWhiteLabeled ? (
          <Form.Item name="agencySlug" hidden initialValue={slug} />
        ) : (
          <Form.Item
            name="agencySlug"
            hasFeedback
            rules={[{ required: true, message: "Please enter agency code" }]}
          >
            <Input placeholder="Enter agency code" data-testid="agencySlug" />
          </Form.Item>
        )}
        <Form.Item
          name="firstName"
          hasFeedback
          rules={[{ required: true, message: "Please enter first name" }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Enter first name" data-testid="firstName" />
        </Form.Item>
        <Form.Item
          name="lastName"
          hasFeedback
          rules={[{ required: true, message: "Please enter last name" }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Enter last name" data-testid="lastName" />
        </Form.Item>
        <Form.Item
          name="email"
          hasFeedback
          rules={[
            { type: "email", message: "Please enter valid email" },
            {
              required: true,
              message: "Please enter email",
            },
          ]}
        >
          <Input placeholder="Enter email" data-testid="email" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Enter your password" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="confirmPassword"
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords don't match"));
              },
            }),
          ]}
          dependencies={["password"]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Confirm your password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%", height: "50px" }}
            loading={isLoading}
          >
            Sign Up
          </Button>
        </Form.Item>
        {errorMessage ? (
          <Form.Item>
            <Alert message={errorMessage} type="error" />
          </Form.Item>
        ) : null}
        <Form.Item>
          <a href="/login">Already have an account? Log in</a>
        </Form.Item>
      </StyledForm>
    </>
  );
}

export default SignUp;
