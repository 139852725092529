import api, { transformRequest, transformResponse } from "services/api";

export const priorityAccountRuleApi = api
  .enhanceEndpoints({
    addTagTypes: ["AccountAssignmentRules"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAccountAssignmentRules: build.query({
        query: () => "/api/v1/core/account-assignment-rules/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "AccountAssignmentRules", id })),
                { type: "AccountAssignmentRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AccountAssignmentRules', id: 'LIST' }` is invalidated
              [{ type: "AccountAssignmentRules", id: "LIST" }],
      }),
      addAccountAssignmentRule: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/account-assignment-rules/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "AccountAssignmentRules", id: "LIST" }],
      }),
      updateAccountAssignmentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/account-assignment-rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "AccountAssignmentRules", id: "LIST" }],
      }),
      deleteAccountAssignmentRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/account-assignment-rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "AccountAssignmentRules", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useAddAccountAssignmentRuleMutation,
  useFetchAccountAssignmentRulesQuery,
  useDeleteAccountAssignmentRuleMutation,
  useUpdateAccountAssignmentRuleMutation,
} = priorityAccountRuleApi;
