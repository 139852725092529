import { Checkbox, Form, Input, InputNumber, Select } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  min-width: 180px;
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

function EditableCell({
  editing,
  dataIndex,
  title,
  valuePropName,
  inputType = "text",
  children,
  rules = [],
  options = [],
  onChange,
  inputProps,
  ...restProps
}) {
  const commonProps = {
    onChange,
    ...inputProps,
  };

  const inputNode = {
    number: (
      <InputNumber
        min={0}
        style={{ minWidth: 180 }}
        placeholder="Enter a number..."
        {...commonProps}
      />
    ),
    text: <Input placeholder="Enter text..." {...commonProps} />,
    textArea: <Input.TextArea placeholder="Enter text..." rows={3} {...commonProps} />,
    date: <AktDatePicker placeholder="Select date..." {...commonProps} />,
    select: (
      <StyledSelect
        popupMatchSelectWidth={false}
        placeholder="Select one..."
        options={options}
        {...commonProps}
      />
    ),
    checkbox: <Checkbox />,
  }[inputType];

  return (
    <td {...restProps} valign="top">
      {editing ? (
        <StyledFormItem name={dataIndex} rules={rules} valuePropName={valuePropName}>
          {inputNode}
        </StyledFormItem>
      ) : (
        children
      )}
    </td>
  );
}

export default EditableCell;
