import { PageHeader } from "components/pageHeader";
import { EventActions } from "features/events";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function EventsPage() {
  return (
    <PageLayout>
      <Content>
        <PageHeader>Events Configuration</PageHeader>
        <EventActions />
      </Content>
    </PageLayout>
  );
}

export default EventsPage;
