import api, { transformRequest, transformResponse } from "services/api";

export const legalApi = api
  .enhanceEndpoints({ addTagTypes: ["LegalCustomFields"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllLegalCustomFields: build.query({
        query: () => `/api/v1/core/legal-custom-fields/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "LegalCustomFields", id })),
                { type: "LegalCustomFields", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'LegalCustomFields', id: 'LIST' }` is invalidated
              [{ type: "LegalCustomFields", id: "LIST" }],
      }),
      addLegalCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/legal-custom-fields/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all LegalCustomFields-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "LegalCustomFields", id: "LIST" }],
      }),
      updateLegalCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/legal-custom-fields/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all LegalCustomFields-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "LegalCustomFields", id: "LIST" }],
      }),
      deleteLegalCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/legal-custom-fields/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "LegalCustomFields", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchAllLegalCustomFieldsQuery,
  useAddLegalCustomFieldMutation,
  useUpdateLegalCustomFieldMutation,
} = legalApi;
