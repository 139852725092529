import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Table } from "antd";
import { renderAddress } from "common/utils";
import { PageHeader } from "components/pageHeader";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import LawFirmModal from "features/lawFirmsTable/components/lawFirmModal";
import { useDeleteLawFirmMutation, useGetLawFirmsQuery } from "features/lawFirmsTable/lawFirmsAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

export default function LawFirmsTable() {
  const { data: lawFirms, isLoading } = useGetLawFirmsQuery();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedLawFirm, setSelectedLawFirm] = useState(null);
  const [deleteLawFirm] = useDeleteLawFirmMutation();
  const {
    data: {
      [PERMISSIONS.LAW_FIRM__GET]: isGetLawfirmAuthorized,
      [PERMISSIONS.LAW_FIRM__CREATE]: isCreateLawfirmAuthorized,
      [PERMISSIONS.LAW_FIRM__UPDATE]: isUpdateLawfirmAuthorized,
      [PERMISSIONS.LAW_FIRM__DELETE]: isDeleteLawfirmAuthorized,
    },
  } = useAuthorizations();

  const onNewLawFirm = () => {
    setModalVisible(false);
    setSelectedLawFirm(null);
  };

  const onCancelLawFirm = () => {
    setModalVisible(false);
    setSelectedLawFirm(null);
  };

  const columns = [
    {
      title: "Law Firm Code",
      dataIndex: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Address",
      render: (text, record) => renderAddress(record, constants?.states),
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isUpdateLawfirmAuthorized && !isDeleteLawfirmAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isUpdateLawfirmAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedLawFirm(record);
                setModalVisible(true);
              }}
            />
          )}
          {isDeleteLawfirmAuthorized && (
            <Popconfirm
              okText="Yes"
              placement="topLeft"
              title="Are you sure you want to delete this law firm?"
              onConfirm={() => deleteLawFirm({ lawFirmId: record.id })}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  return isGetLawfirmAuthorized ? (
    <Col>
      <PageHeader>Law Firms</PageHeader>
      <Row align="middle">
        <span>Configure law firms and relationships.</span>
        {isCreateLawfirmAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Law Firm
          </Button>
        )}
      </Row>
      {isModalVisible && (
        <LawFirmModal
          title={selectedLawFirm ? "Edit Law Firm" : "Add Law Firm"}
          onOk={onNewLawFirm}
          onCancel={onCancelLawFirm}
          selectedLawFirm={selectedLawFirm}
        />
      )}
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={lawFirms}
      />
    </Col>
  ) : null;
}
