const config = {
  apiBaseUrl: import.meta.env.REACT_APP_API_BASEURL,
  aktosEnv: import.meta.env.REACT_APP_AKTOS_ENV,
  useAuth0: !!Number(import.meta.env.REACT_APP_AUTH0_USE_AUTH0),
  auth0Domain: import.meta.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: import.meta.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
  sentryDsn: import.meta.env.REACT_APP_SENTRY_DSN,
};
export default config;
