import { DROMO_DATE_FORMAT } from "integrations/dromo/dromoFormats";

export default [
  {
    key: "client_reference_id",
    label: "Client Reference ID",
    type: "string",
    validators: [
      { validate: "require_with", fields: ["creditor_id"] },
      { validate: "require_without", fields: ["external_id"] },
    ],
    description:
      "The Client Reference ID for the account. Should be provided only if no external id is provided.",
  },
  {
    key: "creditor_id",
    label: "Client ID",
    type: "number",
    validators: [
      { validate: "require_with", fields: ["client_reference_id"] },
      { validate: "require_without", fields: ["external_id"] },
    ],
    description:
      "The Client ID for the account. Should be provided only if no external id is provided.",
  },
  {
    key: "account_id",
    label: "External Account ID",
    type: "string",
    validators: [{ validate: "require_without", fields: ["client_reference_id", "client_id"] }],
    description:
      "The External ID for the account. Should be provided only if both client reference id and creditor_id are not provided.",
  },
  {
    key: "transaction_date",
    label: "Transaction Date",
    type: DROMO_DATE_FORMAT,
    validators: [{ validate: "required" }],
  },
  {
    key: "transaction_amount",
    label: "Transaction Amount",
    type: ["number", "usd"],
    validators: [{ validate: "required" }],
  },
];
