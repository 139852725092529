import InvoicesTable from "features/invoices/components/invoiceList";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function InvoiceEditorPage() {
  return (
    <PageLayout>
      <Content>
        <InvoicesTable />
      </Content>
    </PageLayout>
  );
}

export default InvoiceEditorPage;
