import { Form, Input, Modal, Select, Tag } from "antd";
import { idToObjectMap } from "common/utils";
import { TableFormList } from "components/formList";
import currency from "currency.js";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import {
  useAddSettlementAndPaymentRuleMutation,
  useAddSettlementAndPaymentRuleSetMutation,
  useDeleteSettlementAndPaymentRuleMutation,
  useFetchPaymentRulesQuery,
  useFetchSettlementAndPaymentRulesQuery,
  useFetchSettlementRulesQuery,
  useUpdateSettlementAndPaymentRuleMutation,
  useUpdateSettlementAndPaymentRuleSetMutation,
} from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import { useMemo } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const StyledDiv = styled.div`
  margin-bottom: 12px;
`;

export default function AddSettlementAndPaymentRulesetModal({
  title,
  open,
  onOk,
  onCancel,
  selectedRuleSet,
  setSelectedRuleSet,
}) {
  const [form] = Form.useForm();
  const { data: paymentRules, isLoading: isPaymentRulesLoading } = useFetchPaymentRulesQuery();
  const { data: settlementRules, isLoading: isSettlementRulesLoading } =
    useFetchSettlementRulesQuery();
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const [addSettlementAndPaymentRule] = useAddSettlementAndPaymentRuleMutation();
  const [updateSettlementAndPaymentRule] = useUpdateSettlementAndPaymentRuleMutation();
  const [addSettlementAndPaymentRuleSet] = useAddSettlementAndPaymentRuleSetMutation();
  const [updateSettlementAndPaymentRuleSet] = useUpdateSettlementAndPaymentRuleSetMutation();
  const [deleteSettlementAndPaymentRule] = useDeleteSettlementAndPaymentRuleMutation();

  const initialValues = {
    ...selectedRuleSet,
    defaultSettlementRuleId: selectedRuleSet?.defaultSettlementRule?.id,
    defaultPaymentRuleId: selectedRuleSet?.defaultPaymentRule?.id,
  };

  const isNew = !selectedRuleSet;

  const { data: settlementAndPaymentRules, isLoading } = useFetchSettlementAndPaymentRulesQuery(
    {
      ruleSetId: selectedRuleSet?.id,
    },
    { skip: isNew },
  );

  const productOfferingsById = useMemo(() => {
    return idToObjectMap(productOfferings);
  }, [productOfferings]);

  const save = async () => {
    const fields = await form.validateFields();
    const action = isNew
      ? addSettlementAndPaymentRuleSet(fields)
      : updateSettlementAndPaymentRuleSet({ ...fields, id: selectedRuleSet.id });
    const result = await action;

    if (isNew && "data" in result) {
      setSelectedRuleSet(result.data);
    }
    return result;
  };

  const onSubmit = async () => {
    const result = await save();
    if ("data" in result) {
      onOk();
    }
  };

  // We run this hook when Add Payment And Settlement Rule is pressed
  const preAddSettlementAndPaymentRowHook = async () => {
    if (isNew) {
      const result = await save();
      if ("error" in result) {
        return false;
      }
    }
  };

  const columns = [
    {
      dataIndex: "paymentRuleId",
      key: "paymentRuleId",
      render: (index, rule) =>
        rule.paymentRuleId
          ? paymentRules?.find((paymentRule) => paymentRule.id === rule.paymentRuleId)?.name ??
            `Payment Rule #${rule.paymentRuleId}`
          : "-",
      editable: true,
      inputType: "select",
      title: "Payment Rule",
      options: [
        {
          label: "-------",
          value: null,
        },
        ...(paymentRules?.map((paymentRule) => ({
          label: paymentRule.name,
          value: paymentRule.id,
        })) ?? []),
      ],
      loading: isPaymentRulesLoading,
    },
    {
      dataIndex: "settlementRuleId",
      key: "settlementRuleId",
      render: (index, rule) =>
        rule.settlementRuleId
          ? settlementRules?.find((settlementRule) => settlementRule.id === rule.settlementRuleId)
              ?.name ?? `Settlement Plan #${rule.settlementRuleId}`
          : "-",
      editable: true,
      inputType: "select",
      title: "Settlement Plan",
      options: [
        {
          label: "-------",
          value: null,
        },
        ...(settlementRules?.map((settlementRule) => ({
          label: settlementRule.name,
          value: settlementRule.id,
        })) ?? []),
      ],
      loading: isSettlementRulesLoading,
    },
    {
      title: "Legal Status",
      dataIndex: "hasLawsuit",
      key: "hasLawsuit",
      editable: true,
      inputType: "select",
      options: [
        { label: "-------", value: null, key: "null" },
        { label: "Contains Legal", value: true, key: "yes" },
        { label: "No Legal", value: false, key: "no" },
      ],
      render: (index, rule) =>
        ({
          true: <Tag color="red">Contains Legal</Tag>,
          false: <Tag color="green">No Legal</Tag>,
          null: "-",
        }[rule.hasLawsuit]),
    },
    {
      title: "Product",
      dataIndex: "productOfferingId",
      key: "productOfferingId",
      editable: true,
      inputType: "select",
      options: productOfferings?.map((productOffering) => ({
        label: productOffering.name,
        value: productOffering.id,
      })),
      render: (index, rule) => productOfferingsById[rule.productOfferingId]?.name ?? "-",
    },
    {
      dataIndex: "minAccountBalance",
      key: "minAccountBalance",
      editable: true,
      inputType: "number",
      title: "Account Original Balance (From)",
      render: (amount) => (amount ? `$${currency(amount, { precision: 4 }).value}` : "-"),
    },
    {
      dataIndex: "maxAccountBalance",
      key: "maxAccountBalance",
      editable: true,
      inputType: "number",
      title: "Account Original Balance (To)",
      render: (amount) => (amount ? `$${currency(amount, { precision: 4 }).value}` : "-"),
    },
    {
      dataIndex: "minAccountAgeDays",
      key: "minAccountAgeDays",
      editable: true,
      required: false,
      inputType: "number",
      title: "Days Since Upload (From)",
    },
    {
      dataIndex: "maxAccountAgeDays",
      key: "maxAccountAgeDays",
      required: false,
      editable: true,
      inputType: "number",
      title: "Days Since Upload (To)",
    },
  ];

  return (
    <Modal
      width={1200}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="defaultPaymentRuleId"
          label="Default Payment Rule"
          tooltip="Default payment rule when no payment rules match"
        >
          <Select
            placeholder="Select Payment Rule"
            options={paymentRules?.map((paymentRule) => ({
              label: paymentRule.name,
              value: paymentRule.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="defaultSettlementRuleId"
          label="Default Settlement Rule"
          tooltip="Default settlement rule when no settlement rules match"
        >
          <Select
            placeholder="Select Settlement Plan"
            options={settlementRules?.map((settlementRule) => ({
              label: settlementRule.name,
              value: settlementRule.id,
            }))}
          />
        </Form.Item>
      </StyledForm>
      <h5>Payment And Settlement Rule</h5>
      <StyledDiv>
        Define payment and settlement rules that define which rules to use for each account criteria
        matched.
      </StyledDiv>
      <TableFormList
        addText="Add Rule"
        columns={columns}
        data={isNew ? [] : settlementAndPaymentRules}
        onDelete={(settlementAndPaymentRule) =>
          deleteSettlementAndPaymentRule({
            ruleSetId: selectedRuleSet.id,
            ...settlementAndPaymentRule,
          })
        }
        onSave={async (settlementAndPaymentRule) => {
          const action = settlementAndPaymentRule?.id
            ? updateSettlementAndPaymentRule
            : addSettlementAndPaymentRule;
          return action({
            ruleSetId: selectedRuleSet.id,
            ...settlementAndPaymentRule,
          });
        }}
        loading={isLoading}
        preAddRowHook={preAddSettlementAndPaymentRowHook}
      />
    </Modal>
  );
}
