import api, { transformRequest, transformResponse } from "services/api";

export const paymentsApi = api
  .enhanceEndpoints({
    addTagTypes: ["CreditorPortalPaymentIntents", "CreditorPortalDebtorAccounts"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createCreditorPortalLoggingPaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/payment-methods/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      createCreditorPortalPaymentIntents: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/payment-intents/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: () => [
          { type: "CreditorPortalPaymentIntents", id: "LIST" },
          { type: "CreditorPortalDebtorAccounts", id: "LIST" },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateCreditorPortalLoggingPaymentMethodMutation,
  useCreateCreditorPortalPaymentIntentsMutation,
} = paymentsApi;
