import { Form, Input, Modal, message } from "antd";
import { TableFormList } from "components/formList";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useAddTaxPlanRuleGroupMutation,
  useAddTaxPlanRuleMutation,
  useDeleteTaxPlanRuleMutation,
  useFetchTaxPlanRulesQuery,
  useFetchTaxPlansQuery,
  useUpdateTaxPlanRuleGroupMutation,
  useUpdateTaxPlanRuleMutation,
} from "features/taxPlans/taxPlansAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function AddTaxPlanRuleGroupModal({
  title,
  open,
  onOk,
  onCancel,
  selectedRuleGroup,
  setSelectedRuleGroup,
}) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: taxPlans } = useFetchTaxPlansQuery();
  const [addTaxPlanRule] = useAddTaxPlanRuleMutation();
  const [updateTaxPlanRule] = useUpdateTaxPlanRuleMutation();
  const [addTaxPlanRuleGroup] = useAddTaxPlanRuleGroupMutation();
  const [updateTaxPlanRuleGroup] = useUpdateTaxPlanRuleGroupMutation();
  const [deleteTaxPlanRule] = useDeleteTaxPlanRuleMutation();

  const initialValues = {
    ...selectedRuleGroup,
  };

  const isNew = !selectedRuleGroup;

  const { data: taxPlanRules } = useFetchTaxPlanRulesQuery(
    {
      ruleGroupId: selectedRuleGroup?.id,
    },
    { skip: isNew },
  );

  const save = async () => {
    const fields = await form.validateFields();
    const action = isNew
      ? addTaxPlanRuleGroup(fields)
      : updateTaxPlanRuleGroup({ ...fields, id: selectedRuleGroup.id });
    const result = await action;

    if ("data" in result) {
      const successMessage = isNew
        ? "Tax plan rule group added successfully!"
        : "Tax plan rule group updated successfully!";
      message.success(successMessage);
      onOk();
      if (isNew) {
        setSelectedRuleGroup(result.data);
      }
    }
    return result;
  };

  // We run this hook when Add Tax Plan Rule is pressed
  const preAddTaxPlanRuleRowHook = async () => {
    if (isNew) {
      const result = await save();
      if ("error" in result) {
        return false;
      }
    }
  };

  const columns = [
    {
      dataIndex: "taxPlanId",
      key: "taxPlanId",
      render: (index, rule) =>
        taxPlans?.find((taxPlan) => taxPlan.id === rule.taxPlanId)?.name ??
        `Tax Plan #${rule.taxPlanId}`,
      editable: true,
      inputType: "select",
      title: "Tax Plan",
      inputProps: {
        showSearch: true,
        filterOption,
      },
      options: taxPlans?.map((taxPlan) => ({ label: taxPlan.name, value: taxPlan.id })),
    },
    {
      dataIndex: "creditorStates",
      key: "creditorStates",
      render: (text, record) => {
        return constants?.states
          .filter((state) => record.creditorStates?.includes(state.value))
          .map((state) => state.display)
          .join(", ");
      },
      editable: true,
      inputType: "select",
      title: "Creditor States",
      inputProps: {
        mode: "multiple",
        placeholder: "Select state(s)...",
        showSearch: true,
        filterOption,
      },
      options: constants?.states.map((state) => ({
        label: state.display,
        value: state.value,
      })),
    },
    {
      dataIndex: "debtorStates",
      key: "debtorStates",
      render: (text, record) => {
        return constants?.states
          .filter((state) => record.debtorStates?.includes(state.value))
          .map((state) => state.display)
          .join(", ");
      },
      editable: true,
      inputType: "select",
      title: "Debtor States",
      inputProps: {
        mode: "multiple",
        placeholder: "Select state(s)...",
        showSearch: true,
        filterOption,
      },
      options: constants?.states.map((state) => ({
        label: state.display,
        value: state.value,
      })),
    },
  ];

  return (
    <Modal
      width={1200}
      maskClosable={false}
      title={title}
      open={open}
      onOk={save}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
      </StyledForm>
      <h5>Tax Plan Rules</h5>
      Define tax plan rules, which can apply independently on all invoice items in an invoice if all
      three criterias below are satisfied:
      <ul>
        <li>
          The list of applicable creditor states is not empty, where the address of the invoice's
          creditor belongs to one of these states
        </li>
        <li>The invoice item is a debtor payment</li>
        <li>
          The list of applicable debtor states is empty, which means the rule could apply regardless
          of debtor's address, or debtor's address belong to one of the states in the list
        </li>
      </ul>
      <br />
      <br />
      <TableFormList
        addText="Add Rule"
        columns={columns}
        data={isNew ? [] : taxPlanRules}
        onDelete={(taxPlanRule) =>
          deleteTaxPlanRule({
            ruleGroupId: selectedRuleGroup.id,
            ...taxPlanRule,
          })
        }
        onSave={async (taxPlanRule) => {
          const action = taxPlanRule?.id ? updateTaxPlanRule : addTaxPlanRule;
          return action({
            ruleGroupId: selectedRuleGroup.id,
            ...taxPlanRule,
          });
        }}
        preAddRowHook={preAddTaxPlanRuleRowHook}
      />
    </Modal>
  );
}
