import api, { transformCursorResponse, transformRequest, transformResponse } from "services/api";

export const organizationSettingsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "Creditors",
      "CreditorSummaries",
      "CreditorContacts",
      "CreditorAddresses",
      "CreditorTags",
      "CreditorGroup",
      "DebtorAccounts",
      "CreditorAssignedAccontCustomFields",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditor: build.query({
        query: (payload) => `/api/v1/core/creditors/${payload.creditorId}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [{ type: "Creditors", id: results.id }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Creditors', id: 'LIST' }` is invalidated
              [{ type: "Creditors", id: "LIST" }],
      }),
      searchCreditors: build.query({
        query: (payload) => `/api/v1/core/creditors/search/?query=${payload.query}`,
        transformResponse: (response) => transformResponse(response),
      }),
      fetchCreditors: build.query({
        query: (payload) => ({
          url: "/api/v1/core/creditors/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "Creditors", id })),
                { type: "Creditors", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Creditors', id: 'LIST' }` is invalidated
              [{ type: "Creditors", id: "LIST" }],
      }),
      fetchCreditorSummaries: build.query({
        query: (payload) => ({
          url: "/api/v1/core/creditors/summaries/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorSummaries", id })),
                { type: "CreditorSummaries", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorSummaries', id: 'LIST' }` is invalidated
              [{ type: "CreditorSummaries", id: "LIST" }],
      }),
      fetchCreditorContacts: build.query({
        query: (payload) => `/api/v1/core/creditors/${payload.creditorId}/contacts/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorContacts", id })),
                { type: "CreditorContacts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorContacts', id: 'LIST' }` is invalidated
              [{ type: "CreditorContacts", id: "LIST" }],
      }),
      fetchCreditorAddresses: build.query({
        query: (payload) => `/api/v1/core/creditors/${payload.creditorId}/addresses/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorAddresses", id })),
                { type: "CreditorAddresses", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorAddresses', id: 'LIST' }` is invalidated
              [{ type: "CreditorAddresses", id: "LIST" }],
      }),
      fetchCreditorTags: build.query({
        query: () => `/api/v1/core/creditor-tags/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorTags", id })),
                { type: "CreditorTags", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorTags', id: 'LIST' }` is invalidated
              [{ type: "CreditorTags", id: "LIST" }],
      }),
      createCreditor: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/creditors/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [
          { type: "Creditors", id: "LIST" },
          { type: "CreditorSummaries", id: "LIST" },
          { type: "CreditorTags", id: "LIST" },
          { type: "CreditorGroup", id: "LIST" },
        ],
      }),
      updateCreditor: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditors/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Creditors", id: arg.id },
          { type: "CreditorSummaries", id: arg.id },
          { type: "CreditorTags", id: "LIST" },
          { type: "CreditorGroup", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
          { type: "CreditorAssignedAccontCustomFields", id: arg.id },
        ],
      }),
      deleteCreditor: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditors/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [
          { type: "Creditors", id: "LIST" },
          { type: "CreditorSummaries", id: "LIST" },
        ],
      }),
      createCreditorAddress: build.mutation({
        query: ({ creditorId, address }) => ({
          url: `/api/v1/core/creditors/${creditorId}/addresses/create/`,
          method: "POST",
          body: transformRequest(address),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorAddresses", id: "LIST" }],
      }),
      deleteCreditorAddress: build.mutation({
        query: ({ creditorId, address }) => ({
          url: `/api/v1/core/creditors/${creditorId}/addresses/${address.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "CreditorAddresses", id: "LIST" }],
      }),
      updateCreditorAddress: build.mutation({
        query: ({ creditorId, address }) => ({
          url: `/api/v1/core/creditors/${creditorId}/addresses/${address.id}/update/`,
          method: "POST",
          body: transformRequest(address),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "CreditorAddresses", id: arg.address.id },
        ],
      }),
      createCreditorContact: build.mutation({
        query: ({ creditorId, contact }) => ({
          url: `/api/v1/core/creditors/${creditorId}/contacts/create/`,
          method: "POST",
          body: transformRequest(contact),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorContacts", id: "LIST" }],
      }),
      deleteCreditorContact: build.mutation({
        query: ({ creditorId, contact }) => ({
          url: `/api/v1/core/creditors/${creditorId}/contacts/${contact.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "CreditorContacts", id: "LIST" }],
      }),
      updateCreditorContact: build.mutation({
        query: ({ creditorId, contact }) => ({
          url: `/api/v1/core/creditors/${creditorId}/contacts/${contact.id}/update/`,
          method: "POST",
          body: transformRequest(contact),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "CreditorContacts", id: arg.contact.id }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchCreditorQuery,
  useFetchCreditorsQuery,
  useLazyFetchCreditorsQuery,
  useFetchCreditorSummariesQuery,
  useFetchCreditorContactsQuery,
  useFetchCreditorAddressesQuery,
  useFetchCreditorTagsQuery,
  useCreateCreditorMutation,
  useUpdateCreditorMutation,
  useCreateCreditorAddressMutation,
  useUpdateCreditorAddressMutation,
  useCreateCreditorContactMutation,
  useUpdateCreditorContactMutation,
  useDeleteCreditorAddressMutation,
  useDeleteCreditorContactMutation,
  useDeleteCreditorMutation,
  useLazySearchCreditorsQuery,
} = organizationSettingsApi;
