import { Button, Col, Form, Input, InputNumber, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToWords, snakeToCamelCase } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import {
  useCreateCreditorCostMutation,
  useFetchDebtorAccountsQuery,
  useUpdateAccountPaymentDataMutation,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import {
  useGetAccountPaymentSettingsQuery,
  useLazyCalculateBalanceFromScheduleQuery,
} from "features/payments/paymentsAPI";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

export default function AddCostsTab() {
  const { debtorId } = useParams();
  const [form] = useForm();
  const [selectedDebtorAccount, setSelectedDebtorAccount] = useState(null);
  const { data: debtorAccounts } = useFetchDebtorAccountsQuery({ debtorId });
  const [createCreditorCost] = useCreateCreditorCostMutation();
  const [updateAccountPaymentData] = useUpdateAccountPaymentDataMutation();
  const { data: accountPaymentSettings } = useGetAccountPaymentSettingsQuery(
    { accountIds: [selectedDebtorAccount?.id] },
    { skip: !selectedDebtorAccount?.id },
  );
  const [calculateBalanceFromSchedule, { isLoading: isCalculatingBalance }] =
    useLazyCalculateBalanceFromScheduleQuery();

  const categories = useMemo(
    () => accountPaymentSettings?.[0]?.paymentCategoryPriority.map(snakeToCamelCase) ?? [],
    [accountPaymentSettings],
  );

  useEffect(() => {
    // Reset the amounts when the selected account changes
    if (selectedDebtorAccount) {
      categories.forEach((category) => {
        const oldBalance = selectedDebtorAccount[`${category}Balance`] ?? 0;
        form.setFieldValue(["paymentCategoryOldBalances", category], oldBalance);
        form.setFieldValue(["paymentCategoryAdjustments", category], 0);
        form.setFieldValue(["paymentCategoryNewBalances", category], oldBalance);
      });
    }
  }, [categories, form, selectedDebtorAccount]);

  const onAccountSelect = (newAccountId) => {
    setSelectedDebtorAccount(debtorAccounts?.find((each) => each.id === newAccountId));
  };

  const onFinish = async () => {
    const formFields = await form.validateFields();
    // chargeValue derived from the form is strictly for UI purpose.
    // We need to remove it prior to sending the data to backend to prevent errors
    // since backend doesn't need the field and can't serialize it.
    const { chargeValue, accountId, notes, type, amount, effectiveDate, ...rest } = formFields;

    const action =
      chargeValue === "creditor"
        ? createCreditorCost({
            accountId,
            notes,
            type,
            amount,
            effectiveDate,
          })
        : updateAccountPaymentData({ accountId, notes, effectiveDate, ...rest });
    const result = await action;
    if ("data" in result) {
      const successMessage =
        chargeValue === "creditor"
          ? "Client cost added successfully!"
          : "Account balances updated successfully!";
      message.success(successMessage);
      setSelectedDebtorAccount(null);
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const onAdjustmentChange = (category) => (adjustment) => {
    const oldBalance = form.getFieldValue(["paymentCategoryOldBalances", category]);
    const newBalance = currency(oldBalance ?? 0, {
      precision: 4,
    }).add(adjustment).value;
    form.setFieldValue(["paymentCategoryNewBalances", category], newBalance);
  };

  const onDateChange = async (date) => {
    const result = await calculateBalanceFromSchedule({
      debtorId,
      intents: [
        {
          accountIds: [form.getFieldValue("accountId")],
          totalAmount: 0,
          scheduledDate: date,
        },
      ],
    });
    const balancesOnNewEffectiveDate = result?.data?.details[0];
    const balanceByCategory = balancesOnNewEffectiveDate?.accountBalanceHistory[0]?.balances;
    categories.forEach((category) => {
      const oldBalanceOnNewEffectiveDate = currency(balanceByCategory[category], {
        precision: 4,
      });
      const updatedNewBalance = oldBalanceOnNewEffectiveDate.add(
        form.getFieldValue(["paymentCategoryAdjustments", category]),
      ).value;
      form.setFieldValue(
        ["paymentCategoryOldBalances", category],
        oldBalanceOnNewEffectiveDate.value,
      );
      form.setFieldValue(["paymentCategoryNewBalances", category], updatedNewBalance);
    });
  };

  const disabledDate = (current) => {
    const chargeValue = form.getFieldValue("chargeValue");
    const endOfToday = moment().endOf("day");
    const firstOfMonth = moment().startOf("month");
    return (
      current &&
      (current > endOfToday ||
        (chargeValue === "creditor" && current < firstOfMonth) ||
        // More details on debtor adjustment effective date: https://github.com/Aktos-Inc/aktosbackend/pull/2023
        (chargeValue === "debtor" &&
          current < moment(selectedDebtorAccount?.lastInterestCalculationDate)))
    );
  };

  return (
    <StyledForm
      onFinish={onFinish}
      form={form}
      name="addCost"
      layout="vertical"
      initialValues={{
        chargeValue: "creditor",
        type: "fee",
        effectiveDate: moment().format(DATE_FORMAT),
      }}
    >
      <Form.Item name="chargeValue" label="Add Cost To">
        <Radio.Group>
          <Radio value="creditor">Client</Radio>
          <Radio value="debtor">Debtor</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.chargeValue !== currentValues.chargeValue
        }
      >
        {({ getFieldValue }) => {
          const chargeValue = getFieldValue("chargeValue");
          return (
            <>
              <Form.Item
                name="accountId"
                label="Account"
                rules={[{ required: true, message: "Select an account" }]}
              >
                <Select
                  options={debtorAccounts
                    ?.filter((account) => account.legalStatus !== "rolled_up")
                    .map((each) => ({
                      value: each.id,
                      label: `Account #${each.id} - $${
                        currency(each.totalBalance, { precision: 4 }).value
                      }`,
                    }))}
                  onChange={onAccountSelect}
                  placeholder="Select Account..."
                />
              </Form.Item>
              {chargeValue === "creditor" && (
                <>
                  <Form.Item name="type" label="Charge As">
                    <Radio.Group>
                      <Radio value="fee">Fee</Radio>
                      <Radio value="credit">Credit</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="amount" label="Balance">
                    <InputNumber prefix="$" controls={false} min={0} precision={2} />
                  </Form.Item>
                </>
              )}
              {chargeValue === "debtor" &&
                categories.map((category) => {
                  return (
                    <Form.Item label={camelToWords(category)} key={category}>
                      <Row gutter={16} align="middle">
                        <Col span={6}>
                          <Form.Item noStyle name={["paymentCategoryOldBalances", category]}>
                            <InputNumber disabled min={0} prefix="$" precision={2} />
                          </Form.Item>
                        </Col>
                        <Col span={1}>+</Col>
                        <Col span={6}>
                          <Form.Item noStyle name={["paymentCategoryAdjustments", category]}>
                            <InputNumber
                              precision={2}
                              prefix="$"
                              step="0.01"
                              onChange={onAdjustmentChange(category)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>=</Col>
                        <Col span={6}>
                          <Form.Item noStyle name={["paymentCategoryNewBalances", category]}>
                            <InputNumber disabled precision={2} prefix="$" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  );
                })}
              <Form.Item
                label="Effective Date"
                name="effectiveDate"
                rules={[{ required: true }]}
                tooltip={
                  chargeValue === "creditor"
                    ? ""
                    : "In order to avoid conflicts, the new adjustment cannot happen before existing adjustments and payments."
                }
              >
                <AktDatePicker disabledDate={disabledDate} onChange={onDateChange} />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
      <Form.Item name="notes" label="Notes">
        <Input.TextArea placeholder="Enter notes..." />
      </Form.Item>
      <Button htmlType="submit" type="primary">
        Submit
      </Button>
    </StyledForm>
  );
}
