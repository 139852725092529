import { combineReducers, configureStore } from "@reduxjs/toolkit";
import config from "app/envConfig";
import { queryErrorLogger } from "common/redux/middleware/queryErrorLogger";
import { unauthenticatedMiddleware } from "common/redux/middleware/unauthenticated";
import { RESET_STATE_ACTION_TYPE } from "common/redux/resetStateAction";
import accountsTableReducer from "features/accountsTable/accountsTableSlice";
import authReducer from "features/auth/authSlice";
import paymentsReducer from "features/payments/paymentsSlice";
import appMenuReducer from "layouts/agencyPortal/dashboard/menu/AppMenu/appMenuSlice";
import api from "services/api";

const reducers = combineReducers({
  auth: authReducer,
  appMenu: appMenuReducer,
  accountsTable: accountsTableReducer,
  payments: paymentsReducer,
  // rtk-query part
  [api.reducerPath]: api.reducer,
});

const LOCAL_AUTH_KEY_STORAGE = config.useAuth0 ? "rtk:auth0" : "rtk:auth";

export const rootReducer = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    // remove auth key
    localStorage.removeItem(LOCAL_AUTH_KEY_STORAGE);
    localStorage.removeItem("state:accountsTable");

    // then reset the state
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([unauthenticatedMiddleware, queryErrorLogger, api.middleware]),
    preloadedState,
  });
