import { Row, Table } from "antd";
import { formatDate } from "common/utils";
import styled from "styled-components";

const StyledRow = styled(Row)`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const FilterTitle = styled.div`
  flex: 1;
`;

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-bottom: 12px;
  margin-top: 12px;
`;

export default function DebtorsPreviewStep({ previewDebtors, previewDebtorsTotalCount }) {
  const columns = [
    {
      title: "Debtor ID",
      width: 150,
      fixed: "left",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => {
        return a.id > b.id;
      },
      onHeaderCell: () => ({
        onClick: null,
      }),
    },
    {
      width: 150,
      title: "External ID",
      render: (text, record) => record?.externalId ?? "-",
    },
    {
      width: 150,
      title: "Account IDs",
      render: (text, record) => record.accountIds?.join(", ") ?? "-",
    },
    {
      title: "Debtor Name",
      width: 250,
      dataIndex: "name",
      render: (text, record) => {
        return [record.firstName, record.middleName, record.lastName]
          .filter((name) => !!name)
          .join(" ");
      },
      key: "name",
    },
    {
      title: "Email",
      width: 200,
      dataIndex: "email",
      key: "email",
      render: (text, record) => record.email ?? "-",
    },
    {
      title: "SSN",
      width: 150,
      dataIndex: "ssn",
      key: "ssn",
      render: (text, record) => record.ssn ?? "-",
    },
    {
      title: "DOB",
      width: 150,
      dataIndex: "dob",
      key: "dob",
      render: (text) => (text ? formatDate(text) : "-"),
    },
    {
      width: 250,
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  return (
    <StyledRow>
      <FilterTitle>
        <strong>{previewDebtorsTotalCount} debtors</strong> match the selected criteria:{" "}
      </FilterTitle>
      <StyledTable
        bordered
        // @ts-ignore
        columns={columns}
        dataSource={previewDebtors}
        pagination={false}
        scroll={{ x: "max-content", y: previewDebtors.length > 0 ? 400 : null }}
      />
    </StyledRow>
  );
}
