import { DatePicker } from "antd";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "common/constants";
import moment from "moment-timezone";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { useCallback, useMemo } from "react";

const MomentInfusedDatePicker = DatePicker.generatePicker(momentGenerateConfig);

function AktDatePicker(props) {
  const { value, onChange, ...rest } = props;
  const { showTime, showSecond, format: formatProp } = rest;

  // Different formats for different picker types
  const format = formatProp ?? (showTime ? DATE_TIME_FORMAT : DATE_FORMAT);

  // We guess the format of the input, and if it's not valid, we try again without the format
  const bestGuessMoment = useCallback(
    (input) => {
      let momentObject = moment(input, format);
      if (!momentObject.isValid()) {
        momentObject = moment(input);
      }
      return momentObject;
    },
    [format],
  );

  const innerValue = useMemo(() => {
    if (value === undefined) return undefined;
    if (value === null) return null;

    const momentObject = moment.isMoment(value) ? value : bestGuessMoment(value);

    if (!showSecond) {
      // If we don't show seconds in the picker, we need to set the seconds to 0
      momentObject.set("second", 0);
    }

    return momentObject;
  }, [bestGuessMoment, showSecond, value]);

  const innerOnChange = useCallback(
    (input) => {
      if (input === null) return onChange(null);
      if (input === undefined) return onChange(undefined);

      // if input is not a moment object, we need to convert it to one
      const momentObject = moment.isMoment(input) ? input : bestGuessMoment(input);

      return onChange(momentObject.format(format));
    },
    [bestGuessMoment, format, onChange],
  );

  return (
    <MomentInfusedDatePicker
      {...rest}
      showTime={showTime ? { format: TIME_FORMAT, use12Hours: true } : false}
      value={innerValue}
      onChange={innerOnChange}
      format={format}
    />
  );
}

export default AktDatePicker;
