/*
 * This function converts a type to a Dromo type.
 * @param {string | [string, string | object]} toConvertType - The type to convert.
 * @returns {string | [string, string | object]} - The converted type.
 */
function convertToDromoType(toConvertType) {
  // Extract type from toConvertType
  const type = Array.isArray(toConvertType) ? toConvertType[0] : toConvertType;

  // Dromo has support for "date", "email", "number", "string", "datetime", "time", "select", "checkbox"
  // If type is supported, return original type
  if (
    ["date", "email", "number", "string", "datetime", "time", "select", "checkbox"].includes(type)
  ) {
    return toConvertType;
  }

  switch (type) {
    case "text":
      return "string";
    case "integer":
      return "number";
    case "decimal":
      return "number";
    case "date":
      return "date";
    case "boolean":
      return "select";
    default:
      throw new Error(`Invalid type: ${type}`);
  }
}

export function convertToDromoCustomField(field) {
  const customField = {
    ...field,
    type: convertToDromoType(field.type),
  };

  if (customField.isRequired) {
    customField.validators ??= [];
    customField.validators.push({ validate: "required" });
  }

  if (field.type === "boolean") {
    customField.selectOptions = [
      { label: "", value: "" },
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ];
  }

  return customField;
}
