import { useFetchMeQuery } from "features/auth/authAPI";
import { PERMISSIONS } from "features/permissions/constants";
import { hasPermission } from "features/permissions/hooks/useAuthorization";
import {
  useFetchFeaturesQuery,
  useFetchMyPermissionsSummaryQuery,
} from "features/permissions/permissionsAPI";
import { useEffect, useState } from "react";

/*
// usage example

import useAuthorizations from './useAuthorizations';

const PrivateComponent = () => {
  const { data: { [PERMISSIONS.AUTHORIZED]: isAuthorized } } = useAuthorizations();

  return isAuthorized ? (
    <h1>Admin Panel</h1>
  ) : (
    <p>You are not authorized to access this page.</p>
  );
};
*/

// Map of permissions to their status. All permissions are initially set to false.
const initialAuthMap = Object.fromEntries(
  Object.entries(PERMISSIONS).map(([_, permission]) => [permission, false]),
);

const useAuthorizations = () => {
  const [authMap, setAuthMap] = useState(initialAuthMap);
  const [isLoading, setIsLoading] = useState(true);
  const {
    data: permissionSummary,
    isLoading: isLoadingPermissionSummary,
    isSuccess: isPermissionSuccess,
    isError: isPermissionError,
  } = useFetchMyPermissionsSummaryQuery();

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isSuccess: isFeatureSuccess,
    isError: isFeatureError,
  } = useFetchFeaturesQuery();

  const {
    data: me,
    isLoading: isLoadingMe,
    isSuccess: isMeSuccess,
    isError: isMeError,
  } = useFetchMeQuery();

  useEffect(() => {
    if (isMeSuccess && isPermissionSuccess && isFeatureSuccess) {
      setAuthMap(
        Object.fromEntries(
          Object.entries(PERMISSIONS).map(([_, permission]) => [
            permission,
            hasPermission(permission, permissionSummary, features, me),
          ]),
        ),
      );
      setIsLoading(false);
    } else if (isPermissionError || isMeError || isFeatureError) {
      setAuthMap(initialAuthMap);
      setIsLoading(false);
    }
  }, [
    permissionSummary,
    me,
    isMeSuccess,
    isPermissionSuccess,
    isPermissionError,
    isMeError,
    isFeatureSuccess,
    isFeatureError,
    features,
  ]);

  return {
    data: authMap,
    isLoading: isLoadingPermissionSummary || isLoadingMe || isLoading || isLoadingFeatures,
    isError: isPermissionError || isMeError || isFeatureError,
  };
};

export default useAuthorizations;
