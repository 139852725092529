import { Form, Input, Modal, Select, message } from "antd";
import { FormWizard } from "components/formWizard";
import SelectList from "components/selectList/selectList";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  useAddCreditorGroupMutation,
  useUpdateCreditorGroupMutation,
} from "features/mergeGroups/debtorMergeGroupAPI";
import { useFetchDebtorMergeGroupRuleQuery } from "features/mergeGroups/debtorMergeGroupRuleAPI";
// import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";

export default function DebtorMergeGroupModal({ title, onOk, onCancel, creditorGroup }) {
  const [addCreditorGroup] = useAddCreditorGroupMutation();
  const [editCreditorGroup] = useUpdateCreditorGroupMutation();
  // If we are creating a new creditor group, only show creditors that are not already in a group.
  // For editing, show all creditors in that group and all creditors that are not in a group.
  const { data: creditors } = useFetchCreditorSummariesQuery({
    debtorMergeGroupIds: creditorGroup ? [creditorGroup.id, null] : [null],
  });
  const { data: debtorMergeGroupRules } = useFetchDebtorMergeGroupRuleQuery();

  const debtorMergeGroupRuleOptions = debtorMergeGroupRules?.map((rule) => ({
    label: `${rule.name}: ${rule.id}`,
    value: rule.id,
  }));

  const initialValues = {
    ...creditorGroup,
  };

  const onSubmitForm = async ({ step0, step1 }) => {
    try {
      const action = creditorGroup ? editCreditorGroup : addCreditorGroup;
      const result = await action({
        ...step0,
        ...step1,
      });
      if ("data" in result) {
        const successMessage = creditorGroup
          ? "Debtor merge group rule updated successfully!"
          : "Debtor merge group rule added successfully!";
        message.success(successMessage);
        onOk();
      }
    } catch (e) {
      message.error("Please make sure all required fields on all tabs are filled out.");
    }
  };

  const CreditorGroupSteps = [
    {
      title: "Basic Information",
      form: (
        <>
          <Form.Item name="id" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="groupName" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Enter Name..." />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input placeholder="Enter Notes..." />
          </Form.Item>
          <Form.Item name="ruleId" label="Debtor Merge Group" rules={[{ required: true }]}>
            <Select placeholder="Select Rule..." options={debtorMergeGroupRuleOptions} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Choose Clients",
      form: (
        <Form.Item name="creditorIds">
          <SelectList dataSource={creditors} entityName="creditor" />
        </Form.Item>
      ),
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width={800}
      open
    >
      <FormWizard
        canSkipSteps={!!creditorGroup}
        steps={CreditorGroupSteps}
        onCancel={onCancel}
        onDone={onSubmitForm}
        initialValues={initialValues}
      />
    </Modal>
  );
}
