import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";

function DocumentStep() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const [fileList, setFileList] = useState([]);

  return (
    <>
      <Form.Item label="Name" name="filename">
        <Input />
      </Form.Item>
      <Form.Item label="Type" name="type">
        <Select
          options={constants?.documentTypes
            ?.filter((x) => x.value !== "account_bulk_upload")
            ?.map((fileType) => ({
              label: fileType.display,
              value: fileType.value,
            }))}
        />
      </Form.Item>
      <Form.Item label="Notes" name="notes" initialValue="">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label="File"
        name="file"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
      >
        <Upload
          multiple={false}
          name="file"
          headers={{
            authorization: "authorization-text",
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status === "done") {
              message.success(`${info.file.name} file uploaded successfully`);
            } else if (status === "error") {
              message.error(`${info.file.name} file upload failed.`);
            }
          }}
          beforeUpload={(file) => {
            setFileList([...fileList, file]);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
    </>
  );
}

export default DocumentStep;
