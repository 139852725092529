import { DeleteOutlined, MergeCellsOutlined } from "@ant-design/icons";
import { Modal, Popconfirm, Row, Space, Table, Tooltip, message } from "antd";
import { usePostMergeDebtorsMutation } from "features/accountsTable/agencyPortal/accountsTableAPI";
import {
  useDeleteEligibleDebtorsForGroupMutation,
  useFetchEligibleDebtorsForGroupQuery,
} from "features/mergeGroups/debtorMergeGroupAPI";
import styled from "styled-components";

const StyledLinkContainer = styled.div`
  margin-right: 8px;
  display: inline-block;
`;

export default function MergeDebtorsModal({ title, onOk, onCancel, mergeGroup }) {
  const { data, isLoading: loadingEligibleDebtors } = useFetchEligibleDebtorsForGroupQuery({
    mergeGroupId: mergeGroup.id,
  });
  const [deleteEligibleDebtors, { isLoading: deleteLoading }] =
    useDeleteEligibleDebtorsForGroupMutation();
  const [mergeDebtors, { isLoading }] = usePostMergeDebtorsMutation();

  const onMerge = async (record) => {
    const result = await mergeDebtors({
      primaryDebtorId: record.parentDebtorId,
      secondaryDebtorIds: record.childrenDebtorIds,
    });
    if ("data" in result) {
      message.success(`Debtors merged successfully!`);
    }
    if ("error" in result) {
      message.error(`Failed to merge accounts. Please try again later.`);
    }
  };
  const columns = [
    {
      title: "Parent Debtor",
      dataIndex: "parentDebtorId",
      key: "parentDebtorId",
      render: (text, record) => {
        return (
          <StyledLinkContainer>
            <a target="_blank" href={`/debtors/${record.parentDebtorId}`} rel="noreferrer">
              {record.parentDebtor.name} ({record.parentDebtorId})
            </a>
          </StyledLinkContainer>
        );
      },
    },
    {
      title: "Children Debtors",
      dataIndex: "childrenDebtorIds",
      key: "childrenDebtorIds",
      render: (text, record) => {
        return (
          <Row>
            {record.childrenDebtors.map((codebtor) => (
              <StyledLinkContainer key={codebtor.id}>
                <a target="_blank" href={`/debtors/${codebtor.id}`} rel="noreferrer">
                  {codebtor.name} ({codebtor.id})
                </a>
              </StyledLinkContainer>
            ))}
          </Row>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip placement="bottom" title="Merge children into parent" key="merge">
            <MergeCellsOutlined key="merge" onClick={() => onMerge(record)} />
          </Tooltip>
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete?"
            onConfirm={() => deleteEligibleDebtors({ mergeEligibleDebtorsId: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        </Space>
      ),
      fixed: "right",
    },
  ];
  return (
    <Modal
      maskClosable={false}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width={1200}
      open
    >
      <Table
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={data}
        loading={isLoading || loadingEligibleDebtors || deleteLoading}
      />
    </Modal>
  );
}
