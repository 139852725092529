export const PERMISSIONS = {
  // # Accounts,
  ACCOUNT__GET: "account.get",
  ACCOUNT__GET_ALL: "account.get_all",
  ACCOUNT__CREATE: "account.create",
  ACCOUNT__DELETE: "account.delete",
  ACCOUNT__UPDATE_STATUS_CODE: "account.update_status_code",
  ACCOUNT__UPDATE_FOLLOW_UP_DATE: "account.update_follow_up_date",
  ACCOUNT__UPDATE_COLLECTOR: "account.update_collector",
  ACCOUNT__UPDATE_FORWARDING_ENTITY: "account.update_forwarding_entity",
  ACCOUNT__UPDATE_PAYMENT_DATA: "account.update_payment_data",
  ACCOUNT__UPDATE_GENERAL_INFO: "account.update_general_info",
  ACCOUNT__UPDATE_PRODUCT_OFFERING: "account.update_product_offering",
  ACCOUNT__BULK_UPLOAD: "account.bulk_upload",

  // # Account Balance History,
  ACCOUNT_BALANCE_HISTORY__GET: "account_balance_history.get",

  // # Account Bulk Actions,
  ACCOUNT_BULK_ACTION__GET: "account_bulk_action.get",
  ACCOUNT_BULK_ACTION__CREATE: "account_bulk_action.create",
  ACCOUNT_BULK_ACTION_ASYNC__NO_TIME_LIMIT__CREATE:
    "account_bulk_action_async.no_time_limit.create",
  ACCOUNT_BULK_ACTION_ASYNC__NO_TIME_LIMIT__UPDATE:
    "account_bulk_action_async.no_time_limit.update",

  // # Account-level credit bureau reporting configuration,
  ACCOUNT_CBR_CONFIG__GET: "account_cbr_config.get",
  ACCOUNT_CBR_CONFIG__UPDATE: "account_cbr_config.update",

  // # Account-level credit report history,
  ACCOUNT_CREDIT_REPORT_HISTORY__GET: "account_credit_report_history.get",

  // # Account Codebtors,
  ACCOUNT_CODEBTORS__GET: "account_codebtors.get",
  ACCOUNT_CODEBTORS__CREATE: "account_codebtors.create",
  ACCOUNT_CODEBTORS__DELETE: "account_codebtors.delete",

  // # Account Communication sequence,
  ACCOUNT_COMMUNICATION_SEQUENCE__GET: "account_communication_sequence.get",
  ACCOUNT_COMMUNICATION_SEQUENCE__TERMINATE: "account_communication_sequence.terminate",

  // # Account assignment rules,
  ACCOUNT_ASSIGNMENT_RULE__GET: "account_assignment_rule.get",
  ACCOUNT_ASSIGNMENT_RULE__CREATE: "account_assignment_rule.create",
  ACCOUNT_ASSIGNMENT_RULE__UPDATE: "account_assignment_rule.update",
  ACCOUNT_ASSIGNMENT_RULE__DELETE: "account_assignment_rule.delete",

  // # Account priority rules,
  ACCOUNT_PRIORITY_RULE__GET: "account_priority_rule.get",
  ACCOUNT_PRIORITY_RULE__CREATE: "account_priority_rule.create",
  ACCOUNT_PRIORITY_RULE__UPDATE: "account_priority_rule.update",
  ACCOUNT_PRIORITY_RULE__DELETE: "account_priority_rule.delete",

  // # Account user defined fields,
  ACCOUNT_USER_DEFINED_FIELD__GET: "account_user_defined_field.get",
  ACCOUNT_USER_DEFINED_FIELD__CREATE: "account_user_defined_field.create",
  ACCOUNT_USER_DEFINED_FIELD__UPDATE: "account_user_defined_field.update",
  ACCOUNT_USER_DEFINED_FIELD__DELETE: "account_user_defined_field.delete",

  // # Account tying,
  ACCOUNT_TYING__GET: "account_tying.get",
  ACCOUNT_TYING__CREATE: "account_tying.create",
  ACCOUNT_TYING__UPDATE: "account_tying.update",
  ACCOUNT_TYING__DELETE: "account_tying.delete",

  // # Agency configuration,
  AGENCY__GET: "agency.get",

  // # Agency-level credit bureau reporting configuration,
  AGENCY_CBR_CONFIG__GET: "agency_cbr_config.get",
  AGENCY_CBR_CONFIG__UPDATE: "agency_cbr_config.update",

  // # Debtor communications,
  COMMUNICATION__GET: "communication.get",
  COMMUNICATION__CREATE: "communication.create",
  COMMUNICATION__DELETE: "communication.delete",
  COMMUNICATION__GET_NOTES: "communication.get_notes",

  // # Communication Sequences,
  COMMUNICATION_SEQUENCE__GET: "communication_sequence.get",
  COMMUNICATION_SEQUENCE__CREATE: "communication_sequence.create",
  COMMUNICATION_SEQUENCE__UPDATE: "communication_sequence.update",

  // # Communication Rules,
  COMMUNICATION_RULE__GET: "compliance.comm_limit_rule.get",
  COMMUNICATION_RULE__CREATE: "compliance.comm_limit_rule.create",
  COMMUNICATION_RULE__UPDATE: "compliance.comm_limit_rule.update",
  COMMUNICATION_RULE__DELETE: "compliance.comm_limit_rule.delete",

  // # Courts of law,
  COURT_OF_LAW__GET: "court_of_law.get",
  COURT_OF_LAW__CREATE: "court_of_law.create",
  COURT_OF_LAW__UPDATE: "court_of_law.update",
  COURT_OF_LAW__DELETE: "court_of_law.delete",

  // # Costs,
  COST__CREATE: "cost.create",

  // # Creditors,
  CREDITOR__GET: "creditor.get",
  CREDITOR__CREATE: "creditor.create",
  CREDITOR__UPDATE: "creditor.update",
  CREDITOR__DELETE: "creditor.delete",

  // # Creditor user defined fields,
  CREDITOR_USER_DEFINED_FIELD__GET: "creditor_user_defined_field.get",
  CREDITOR_USER_DEFINED_FIELD__CREATE: "creditor_user_defined_field.create",
  CREDITOR_USER_DEFINED_FIELD__UPDATE: "creditor_user_defined_field.update",
  CREDITOR_USER_DEFINED_FIELD__DELETE: "creditor_user_defined_field.delete",

  // # Client Profile
  CLIENT_PROFILE__CAN_VIEW_CREDIT_REPORTING: "client_profile.can_view_credit_reporting",
  CLIENT_PROFILE__CAN_EDIT_CREDIT_REPORTING: "client_profile.can_edit_credit_reporting",
  CLIENT_PROFILE__CAN_VIEW_DOCUMENTS: "client_profile.can_view_documents",
  CLIENT_PROFILE__CAN_UPLOAD_DOCUMENTS: "client_profile.can_upload_documents",
  CLIENT_PROFILE__CAN_VIEW_CLIENT_COSTS: "client_profile.can_view_client_costs",
  CLIENT_PROFILE__CAN_VIEW_INVOICES: "client_profile.can_view_invoices",
  CLIENT_PROFILE__CAN_EDIT_INVOICES: "client_profile.can_edit_invoices",
  CLIENT_PROFILE__CAN_VIEW_PAYMENTS_CONFIGURATION: "client_profile.can_view_payments_configuration",
  CLIENT_PROFILE__CAN_EDIT_PAYMENTS_CONFIGURATION: "client_profile.can_edit_payments_configuration",

  // # Creditor tags,
  CREDITOR_TAG__GET: "creditor_tag.get",
  CREDITOR_TAG__CREATE: "creditor_tag.create",
  CREDITOR_TAG__UPDATE: "creditor_tag.update",
  CREDITOR_TAG__DELETE: "creditor_tag.delete",

  // # Credit reports (Metro2),
  CREDIT_REPORT__GET: "credit_report.get",
  CREDIT_REPORT__CREATE: "credit_report.create",
  CREDIT_REPORT__DELETE: "credit_report.delete",

  // # Credit report settings,
  CREDIT_REPORT_SETTINGS__GET: "credit_report_settings.get",
  CREDIT_REPORT_SETTINGS__UPDATE: "credit_report_settings.update",

  // # Data imports,
  DATA_IMPORT__GET: "data_import.get",
  DATA_IMPORT__CREATE: "data_import.create",

  // # Debtors,
  DEBTOR__GET: "debtor.get",
  DEBTOR__CREATE: "debtor.create",
  DEBTOR__UPDATE: "debtor.update",
  DEBTOR__DELETE: "debtor.delete",

  // # Text Sider buttons
  // # Is logged in user authorized to schedule texts, emails, letters?
  TEXT_MESSAGE__SEND: "text_message.send",
  EMAIL__SEND: "email.send",
  LETTER__SEND: "letter.send",

  // # Debtor addresses,
  DEBTOR_ADDRESS__GET: "debtor_address.get",
  DEBTOR_ADDRESS__CREATE: "debtor_address.create",
  DEBTOR_ADDRESS__UPDATE: "debtor_address.update",
  DEBTOR_ADDRESS__DELETE: "debtor_address.delete",

  // # Debtor bankruptcies,
  DEBTOR_BANKRUPTCY__GET: "debtor_bankruptcy.get",
  DEBTOR_BANKRUPTCY__CREATE: "debtor_bankruptcy.create",
  DEBTOR_BANKRUPTCY__UPDATE: "debtor_bankruptcy.update",
  DEBTOR_BANKRUPTCY__DELETE: "debtor_bankruptcy.delete",

  // # Garnishments,
  DEBTOR_GARNISHMENT__GET: "debtor_garnishment.get",
  DEBTOR_GARNISHMENT__CREATE: "debtor_garnishment.create",
  DEBTOR_GARNISHMENT__UPDATE: "debtor_garnishment.update",
  DEBTOR_GARNISHMENT__DELETE: "debtor_garnishment.delete",

  // # Debtor contacts,
  DEBTOR_CONTACT__GET: "debtor_contact.get",
  DEBTOR_CONTACT__CREATE: "debtor_contact.create",
  DEBTOR_CONTACT__UPDATE: "debtor_contact.update",
  DEBTOR_CONTACT__DELETE: "debtor_contact.delete",

  // # Debtor credit reports,
  DEBTOR_CREDIT_REPORT__GET: "debtor_credit_report.get",
  DEBTOR_CREDIT_REPORT__GENERATE: "debtor_credit_report.generate",

  // # Debtor employments,
  DEBTOR_EMPLOYMENT__GET: "debtor_employment.get",
  DEBTOR_EMPLOYMENT__CREATE: "debtor_employment.create",
  DEBTOR_EMPLOYMENT__UPDATE: "debtor_employment.update",
  DEBTOR_EMPLOYMENT__DELETE: "debtor_employment.delete",

  // # Debtor documents,
  DEBTOR_DOCUMENT__GET: "debtor_document.get",
  DEBTOR_DOCUMENT__CREATE: "debtor_document.create",
  DEBTOR_DOCUMENT__UPDATE: "debtor_document.update",
  DEBTOR_DOCUMENT__DELETE: "debtor_document.delete",

  // # Debtor emails,
  DEBTOR_EMAIL__GET: "debtor_email.get",
  DEBTOR_EMAIL__CREATE: "debtor_email.create",
  DEBTOR_EMAIL__UPDATE: "debtor_email.update",
  DEBTOR_EMAIL__DELETE: "debtor_email.delete",

  // Debtor merge group rule
  DEBTOR_MERGE_GROUP_RULE__GET: "debtor_merge_group_rule.get",
  DEBTOR_MERGE_GROUP_RULE__CREATE: "debtor_merge_group_rule.create",
  DEBTOR_MERGE_GROUP_RULE__UPDATE: "debtor_merge_group_rule.update",
  DEBTOR_MERGE_GROUP_RULE__DELETE: "debtor_merge_group_rule.delete",

  // Debtor merge group
  DEBTOR_MERGE_GROUP__GET: "debtor_merge_group.get",
  DEBTOR_MERGE_GROUP__CREATE: "debtor_merge_group.create",
  DEBTOR_MERGE_GROUP__UPDATE: "debtor_merge_group.update",
  DEBTOR_MERGE_GROUP__DELETE: "debtor_merge_group.delete",
  DEBTOR_MERGE_GROUP__MERGE: "debtor_merge_group.merge",

  // # Debtor notes,
  DEBTOR_NOTE__GET: "debtor_note.get",
  DEBTOR_NOTE__UPDATE: "debtor_note.update",

  // # Debtor personal info,
  DEBTOR_PERSONAL_INFO__GET: "debtor_personal_info.get",
  DEBTOR_PERSONAL_INFO__UPDATE: "debtor_personal_info.update",

  // # Debtor phone numbers,
  DEBTOR_PHONE_NUMBER__GET: "debtor_phone_number.get",
  DEBTOR_PHONE_NUMBER__CREATE: "debtor_phone_number.create",
  DEBTOR_PHONE_NUMBER__UPDATE: "debtor_phone_number.update",
  DEBTOR_PHONE_NUMBER__DELETE: "debtor_phone_number.delete",

  // # Debtor tags,
  DEBTOR_TAG__GET: "debtor_tag.get",
  DEBTOR_TAG__CREATE: "debtor_tag.create",
  DEBTOR_TAG__UPDATE: "debtor_tag.update",
  DEBTOR_TAG__DELETE: "debtor_tag.delete",

  // # Debtor user defined fields,
  DEBTOR_USER_DEFINED_FIELD__GET: "debtor_user_defined_field.get",
  DEBTOR_USER_DEFINED_FIELD__CREATE: "debtor_user_defined_field.create",
  DEBTOR_USER_DEFINED_FIELD__UPDATE: "debtor_user_defined_field.update",
  DEBTOR_USER_DEFINED_FIELD__DELETE: "debtor_user_defined_field.delete",

  // Debtor Profile permissions,
  DEBTOR_PROFILE_COMM_LIMIT__CAN_VIEW: "debtor_profile_comm_limit.can_view",

  // # Dispute,
  DISPUTE__GET: "dispute.get",
  DISPUTE__CREATE: "dispute.create",
  DISPUTE__UPDATE: "dispute.update",
  DISPUTE__DELETE: "dispute.delete",

  // # Dispute Category tags,
  DISPUTE_CATEGORY_TAG__GET: "dispute_category_tag.get",
  DISPUTE_CATEGORY_TAG__CREATE: "dispute_category_tag.create",
  DISPUTE_CATEGORY_TAG__UPDATE: "dispute_category_tag.update",
  DISPUTE_CATEGORY_TAG__DELETE: "dispute_category_tag.delete",

  // # Document center,
  DOCUMENT_CENTER__GET: "document_center.get",
  DOCUMENT_CENTER__GET__DOWNLOAD_LETTER_VENDOR__GET_EXPORT:
    "document_center.download_letter_vendor_export",

  DOCUMENT_CENTER__GET__DOWNLOAD_METRO2: "document_center.download_metro2",
  DOCUMENT_CENTER__GET__DOWNLOAD_CHECKS: "document_center.download_checks",
  DOCUMENT_CENTER__GET__DOWNLOAD_PUBLISHED_INVOICES: "document_center.download_published_invoices",

  // # UI-ONLY permissions that applies to the accounts table in worklist
  ACCOUNT_WORKLIST__MERGE_ACCOUNTS: "account_worklist.merge_accounts",
  ACCOUNT_WORKLIST__DOWNLOAD_LETTERS: "account_worklist.download_letters",
  ACCOUNT_WORKLIST__ASSIGN_COLLECTOR: "account_worklist.assign_collector",

  // # Fee plans,
  FEE_PLAN__GET: "fee_plan.get",
  FEE_PLAN__CREATE: "fee_plan.create",
  FEE_PLAN__UPDATE: "fee_plan.update",
  FEE_PLAN__DELETE: "fee_plan.delete",

  // # Tax plans,
  TAX_PLAN__GET: "tax_plan.get",
  TAX_PLAN__CREATE: "tax_plan.create",
  TAX_PLAN__UPDATE: "tax_plan.update",
  TAX_PLAN__DELETE: "tax_plan.delete",

  // # Fee plan rules.,
  FEE_PLAN_RULE__GET: "fee_plan_rule.get",
  FEE_PLAN_RULE__CREATE: "fee_plan_rule.create",
  FEE_PLAN_RULE__UPDATE: "fee_plan_rule.update",
  FEE_PLAN_RULE__DELETE: "fee_plan_rule.delete",

  // # Forwarding entities (e.g. law firms and forwarding collection agencies),
  FORWARDING_ENTITY__GET: "forwarding_entity.get",
  FORWARDING_ENTITY__CREATE: "forwarding_entity.create",
  FORWARDING_ENTITY__UPDATE: "forwarding_entity.update",
  FORWARDING_ENTITY__DELETE: "forwarding_entity.delete",

  // # User groups,
  GROUP__GET: "group.get",
  GROUP__CREATE: "group.create",
  GROUP__DELETE: "group.delete",
  GROUP__UPDATE: "group.update",
  GROUP__ADD_USER: "group.add_user",
  GROUP__REMOVE_USER: "group.remove_user",

  // # User group-level permissions,
  GROUP_PERMISSION__GET: "group_permission.get",
  GROUP_PERMISSION__GRANT: "group_permission.grant",
  GROUP_PERMISSION__REVOKE: "group_permission.revoke",

  // # Invoices,
  INVOICE__GET: "invoice.get",
  INVOICE__PUBLISH: "invoice.publish",
  INVOICE__DELETE: "invoice.delete",
  INVOICE__UPDATE: "invoice.update",
  INVOICE_DOWNLOAD: "invoice.download",

  // # Invoice checks,
  INVOICE_CHECK__GET: "invoice_check.get",
  INVOICE_CHECK__DOWNLOAD: "invoice_check.download",

  // # Law firms,
  LAW_FIRM__GET: "law_firm.get",
  LAW_FIRM__CREATE: "law_firm.create",
  LAW_FIRM__UPDATE: "law_firm.update",
  LAW_FIRM__DELETE: "law_firm.delete",

  // # Lawsuits and judgments,
  LAWSUIT__GET: "lawsuit.get",
  LAWSUIT__CREATE: "lawsuit.create",
  LAWSUIT__UPDATE: "lawsuit.update",
  LAWSUIT__DELETE: "lawsuit.delete",
  LAWSUIT__APPLY_JUDGEMENT: "lawsuit.apply_judgement",

  // # Legal,
  LEGAL__CREATE: "legal.create",
  LEGAL__GET_JUDGEMENT: "legal.get_judgement",
  LEGAL__DOWNLOAD_DOCUMENTS: "legal.download_documents",

  // # Legal custom fields,
  LEGAL_FIELD__GET: "legal_field.get",
  LEGAL_FIELD__CREATE: "legal_field.create",
  LEGAL_FIELD__UPDATE: "legal_field.update",
  LEGAL_FIELD__DELETE: "legal_field.delete",

  // # Letter templates,
  LETTER_TEMPLATE__GET: "letter_template.get",
  LETTER_TEMPLATE__CREATE: "letter_template.create",
  LETTER_TEMPLATE__UPDATE: "letter_template.update",
  LETTER_TEMPLATE__DELETE: "letter_template.delete",

  // # Letter vendor,
  LETTER_VENDOR__GET: "letter_vendor.get",

  // # Payments and history,
  PAYMENT__GET: "payment.get",
  PAYMENT__CREATE: "payment.create",
  PAYMENT__UPDATE: "payment.update",
  PAYMENT__DELETE: "payment.delete",
  PAYMENT__EXECUTE: "payment.execute",
  PAYMENT__VOID: "payment.void",
  PAYMENT__REVERSE: "payment.reverse",
  PAYMENT__SCHEDULE: "payment.schedule",

  // # Phone calls,
  PHONE__CALL_DEBTOR: "phone.call_debtor",
  PHONE__CALL_CREDITOR: "phone.call_creditor",

  // # Settlement Payment Rules
  SETTLEMENT_PAYMENT_RULE__GET: "settlement_payment_rule.get",
  SETTLEMENT_PAYMENT_RULE__CREATE: "settlement_payment_rule.create",
  SETTLEMENT_PAYMENT_RULE__UPDATE: "settlement_payment_rule.update",
  SETTLEMENT_PAYMENT_RULE__DELETE: "settlement_payment_rule.delete",

  // # System logs,
  SYSTEM_LOG__GET: "system_log.get",

  // # Skip tracing,
  SKIP_TRACE__GET: "skip_trace.get",
  SKIP_TRACE__CREATE: "skip_trace.create",
  SKIP_TRACE__START: "skip_trace.start",

  // # Users (e.g. admins, collectors),
  USER__GET: "user.get",
  USER__CREATE: "user.create",
  USER__DELETE: "user.delete",
  USER__UPDATE: "user.update",
  USER__MANAGE: "user.manage",

  // # User-level permissions,
  USER_PERMISSION__GRANT: "user_permission.grant",
  USER_PERMISSION__REVOKE: "user_permission.revoke",
  USER_PERMISSION__GET: "user_permission.get",

  // # Workflows,
  WORKFLOW__GET: "workflow.get",
  WORKFLOW__CREATE: "workflow.create",
  WORKFLOW__UPDATE: "workflow.update",
  WORKFLOW__DELETE: "workflow.delete",

  // # Product Offerings,
  PRODUCT_OFFERING__GET: "product_offering.get",
  PRODUCT_OFFERING__CREATE: "product_offering.create",
  PRODUCT_OFFERING__UPDATE: "product_offering.update",
  PRODUCT_OFFERING__DELETE: "product_offering.delete",

  // # Worklists
  WORKLIST__GET: "worklist.get",
  WORKLIST__CREATE: "worklist.create",
  WORKLIST__UPDATE: "worklist.update",
  WORKLIST__DELETE: "worklist.delete",

  EMPLOYER__GET: "employer.get",
  EMPLOYER__CREATE: "employer.create",
  EMPLOYER__UPDATE: "employer.update",
  EMPLOYER__DELETE: "employer.delete",

  // # Reports
  REPORT__GET: "report.get",
};

export const FEATURE_FLAGS = {
  ACCOUNT_BULK_ACTION_ASYNC: "account_bulk_action_async",
  ACCOUNT_BULK_ACTION_ASYNC__NO_TIME_LIMIT: "account_bulk_action_async.no_time_limit",
  COMPLIANCE: "compliance",
};
