import { snakeToCamelCase, toTitleCase } from "common/utils";

// param triggers - array of ["account.assigned", "debtor.address_created"]
// returns array of options
// [{
//   label: "Account",
//   options: [{
//     label: "Assigned",
//     value: "account.assigned"
//   }]
// }]
export const getTriggerOptions = (triggers = []) => {
  return triggers.reduce((acc, trigger) => {
    // split the trigger into modelId and triggerId
    const [modelId, triggerId] = trigger.split(".");

    // convert snake case to title case
    const modelName = toTitleCase({ str: modelId, delimiter: "_" });
    const triggerName = toTitleCase({ str: triggerId, delimiter: "_" });

    // find the existing model in the accumulator
    const existingModel = acc.find((model) => model.label === modelName);

    // if the model exists, add the trigger to the options
    if (existingModel) {
      existingModel.options.push({
        label: `${modelName}: ${triggerName}`,
        value: trigger,
      });
      return acc;
    }

    // if the model does not exist, add the model and trigger to the accumulator
    return [
      ...acc,
      {
        label: modelName,
        options: [
          {
            label: `${modelName}: ${triggerName}`,
            value: trigger,
          },
        ],
      },
    ];
  }, []);
};

// param trigger - "account.assigned"
// param collection - array of [{
//  description: "Account Balance in Between.",
//  display: "Account Balance in Between.",
//  func: "account.total_balance_in_between",
//  args: array of [{ type, subtype, name, is_required, display, choices }]
// }]
export const getCollectionOptions = (trigger, collection = [], allowedTriggersToModels = {}) => {
  // split the trigger into modelId and triggerId
  const [modelId] = trigger.split(".");

  const collectionOptionsMap = {};
  const allowedModels = allowedTriggersToModels[snakeToCamelCase(modelId)];

  collection
    .filter((condition) => {
      const [conditionModel] = condition.func.split(".");
      return allowedModels?.includes(conditionModel);
    })
    .forEach((condition) => {
      const [conditionModel] = condition.func.split(".");

      if (!(conditionModel in collectionOptionsMap)) {
        collectionOptionsMap[conditionModel] = [];
      }

      const { display, func } = condition;
      collectionOptionsMap[conditionModel].push({
        label: display,
        value: func,
      });
    }, []);

  return Object.keys(collectionOptionsMap)?.map((conditionModel) => {
    return {
      label: toTitleCase({ str: conditionModel, delimiter: "_" }),
      options: collectionOptionsMap[conditionModel],
    };
  });
};
