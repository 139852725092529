import { LawFirmsTable } from "features/lawFirmsTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function LawFirmsPage() {
  return (
    <PageLayout>
      <Content>
        <LawFirmsTable />
      </Content>
    </PageLayout>
  );
}

export default LawFirmsPage;
