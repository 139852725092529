import { Modal, message } from "antd";
import { extractNonEmptyFormEntries } from "common/utils";
import { FormWizard } from "components/formWizard";
import { useLazyFetchAccountsQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useCreateBulkActionMutation } from "features/bulkActions/bulkActionsAPI";
import { splitRangeDatePickerValue } from "features/bulkActions/utils";
import { AccountFiltersStep } from "features/bulkActions/wizardSteps/accountFiltersStep";
import AccountOperationsStep from "features/bulkActions/wizardSteps/accountOperationsStep";
import AccountsPreviewStep from "features/bulkActions/wizardSteps/accountsPreviewStep";
import FinishStep from "features/bulkActions/wizardSteps/finishStep";
import moment from "moment-timezone";
import { useState } from "react";

export function preTransformDateFilters(values) {
  if (values?.filters?.followUpDate) {
    [values.filters.followUpStartDate, values.filters.followUpEndDate] = splitRangeDatePickerValue(
      values.filters.followUpDate,
    );
  }

  if (values?.filters?.excludeFollowUpDate) {
    [values.filters.excludeFollowUpDateStart, values.filters.excludeFollowUpDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeFollowUpDate);
  }

  if (values?.filters?.uploadDate) {
    [values.filters.uploadDateStart, values.filters.uploadDateEnd] = splitRangeDatePickerValue(
      values.filters.uploadDate,
    );
  }

  if (values?.filters?.excludeUploadDate) {
    [values.filters.excludeUploadDateStart, values.filters.excludeUploadDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeUploadDate);
  }

  if (values?.filters?.includeDateOfService) {
    [values.filters.includeDateOfServiceStart, values.filters.includeDateOfServiceEnd] =
      splitRangeDatePickerValue(values.filters.includeDateOfService);
  }

  if (values?.filters?.excludeDateOfService) {
    [values.filters.excludeDateOfServiceStart, values.filters.excludeDateOfServiceEnd] =
      splitRangeDatePickerValue(values.filters.excludeDateOfService);
  }

  if (values?.filters?.includeTurnoverDate) {
    [values.filters.includeTurnoverDateStart, values.filters.includeTurnoverDateEnd] =
      splitRangeDatePickerValue(values.filters.includeTurnoverDate);
  }

  if (values?.filters?.excludeTurnoverDate) {
    [values.filters.excludeTurnoverDateStart, values.filters.excludeTurnoverDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeTurnoverDate);
  }

  if (values?.filters?.includeDateOfFirstDelinquency) {
    [
      values.filters.includeDateOfFirstDelinquencyStart,
      values.filters.includeDateOfFirstDelinquencyEnd,
    ] = splitRangeDatePickerValue(values.filters.includeDateOfFirstDelinquency);
  }

  if (values?.filters?.excludeDateOfFirstDelinquency) {
    [
      values.filters.excludeDateOfFirstDelinquencyStart,
      values.filters.excludeDateOfFirstDelinquencyEnd,
    ] = splitRangeDatePickerValue(values.filters.excludeDateOfFirstDelinquency);
  }

  if (values?.filters?.includeItemizationDate) {
    [values.filters.includeItemizationDateStart, values.filters.includeItemizationDateEnd] =
      splitRangeDatePickerValue(values.filters.includeItemizationDate);
  }

  if (values?.filters?.excludeItemizationDate) {
    [values.filters.excludeItemizationDateStart, values.filters.excludeItemizationDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeItemizationDate);
  }

  if (values?.filters?.includeChargeOffDate) {
    [values.filters.includeChargeOffDateStart, values.filters.includeChargeOffDateEnd] =
      splitRangeDatePickerValue(values.filters.includeChargeOffDate);
  }

  if (values?.filters?.excludeChargeOffDate) {
    [values.filters.excludeChargeOffDateStart, values.filters.excludeChargeOffDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeChargeOffDate);
  }

  if (values?.filters?.includeInterestStartDate) {
    [values.filters.includeInterestStartDateStart, values.filters.includeInterestStartDateEnd] =
      splitRangeDatePickerValue(values.filters.includeInterestStartDate);
  }

  if (values?.filters?.excludeInterestStartDate) {
    [values.filters.excludeInterestStartDateStart, values.filters.excludeInterestStartDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeInterestStartDate);
  }

  if (values?.filters?.includeInterestEndDate) {
    [values.filters.includeInterestEndDateStart, values.filters.includeInterestEndDateEnd] =
      splitRangeDatePickerValue(values.filters.includeInterestEndDate);
  }

  if (values?.filters?.excludeInterestEndDate) {
    [values.filters.excludeInterestEndDateStart, values.filters.excludeInterestEndDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeInterestEndDate);
  }

  if (values?.filters?.includeLastPaymentDate) {
    [values.filters.includeLastPaymentDateStart, values.filters.includeLastPaymentDateEnd] =
      splitRangeDatePickerValue(values.filters.includeLastPaymentDate);
  }

  if (values?.filters?.excludeLastPaymentDate) {
    [values.filters.excludeLastPaymentDateStart, values.filters.excludeLastPaymentDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeLastPaymentDate);
  }

  return values;
}

function AccountBulkActionsModal({ title, open: bulkActionRecord, onOk, onCancel }) {
  const [createBulkAction, { isLoading }] = useCreateBulkActionMutation();
  const [fetchAccounts, { data: accounts }] = useLazyFetchAccountsQuery();
  const [hideParentModal, setHideParentModal] = useState(false);

  const onFiltersDone = async (values) => {
    const hasAnyValueSet = Object.values(extractNonEmptyFormEntries(values.filters)).length > 0;

    if (!hasAnyValueSet) {
      return { error: { message: "Please select at least one filter." } };
    }

    // Split date range values into start and end dates
    const transformedValues = preTransformDateFilters(values);

    return fetchAccounts({
      ...transformedValues,
      includeResultCount: true, // Include the results count in the response
    });
  };

  const onOperationsDone = async (values, { step0 }) => {
    const hasAnyValueSet = Object.values(extractNonEmptyFormEntries(values.operations)).length > 0;

    if (!hasAnyValueSet) {
      return { error: { message: "Please select at least one operation." } };
    }

    let payload = {
      ...(await step0.validateFields()),
      ...values,
    };

    // Split date range values into start and end dates
    payload = preTransformDateFilters(payload);

    // Remove entries that contain all empty values
    payload.operations = extractNonEmptyFormEntries(payload.operations);

    const result = await createBulkAction(payload);
    if ("data" in result) {
      message.success(`Bulk action job created successfully!`);
    }
    if ("error" in result) {
      message.error("Failed to create bulk action job.");
    }
    return result;
  };

  const onDone = () => {
    onOk();
  };

  const steps = [
    /*
    {
      title: "Job Settings",
      form: <JobSettingsStep />,
      onNext: async (data) => onJobSettingsDone(data),
    },
    */
    {
      title: "Accounts",
      form: <AccountFiltersStep />,
      onNext: async (data) => onFiltersDone(data),
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
    {
      title: "Preview",
      form: (
        <AccountsPreviewStep
          previewAccounts={accounts?.results ?? []}
          previewAccountsTotalCount={accounts?.meta?.resultCount ?? 0}
        />
      ),
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
    {
      title: "Operations",
      form: <AccountOperationsStep setHideParentModal={setHideParentModal} />,
      onNext: onOperationsDone,
    },
    {
      title: "Finish",
      form: <FinishStep />,
      wrapperProps: {
        style: {
          margin: "0 auto",
        },
      },
      previousButtonProps: {
        style: {
          display: "none",
        },
      },
    },
    // {
    //   title: "Select Date",
    //   subTitle: "(Recurring Schedule Coming Soon)",
    //   form: (
    //     <StyledCard>
    //       <StyledHeader>Select a date and time to schedule the job:</StyledHeader>
    //       <Form.Item
    //         rules={[{ required: true, message: "This field is required." }]}
    //         name="oneOffScheduledAt"
    //       >
    //         <DatePicker disabledDate={disabledDate} showSecond={false} showTime />
    //       </Form.Item>
    //     </StyledCard>
    //   ),
    // },
    // {
    //   title: "Job Identifier",
    //   form: (
    //     <Form.Item rules={[{ required: true, message: "This field is required." }]} name="name">
    //       <StyledCard>
    //         <StyledHeader>Enter job name:</StyledHeader>
    //         <Input placeholder="Enter name..." />
    //       </StyledCard>
    //     </Form.Item>
    //   ),
    // },
  ];

  const initialValues = {
    type: "account",
    cadence: "one-off",
    schedule: "asap",
    every: {
      time: moment().format("hh:mm A"),
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={bulkActionRecord && !hideParentModal}
      onCancel={onCancel}
      confirmLoading={isLoading}
      footer={null}
      width={800}
    >
      <FormWizard steps={steps} onCancel={onCancel} onDone={onDone} initialValues={initialValues} />
    </Modal>
  );
}

export default AccountBulkActionsModal;
