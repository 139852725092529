import { ContainerOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { useFetchAgencyWorkflowStates } from "features/home/agencyPortal/homeAPI";
import {
  useArchiveCommunicationSequenceMutation,
  useFetchCommunicationSequencesQuery,
} from "features/sequencesTable/communicationSequenceAPI";
import AddSequenceModal from "features/sequencesTable/components/addSequenceModal";
import { useMemo, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function SequencesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [archiveCommunicationSequence] = useArchiveCommunicationSequenceMutation();
  const { data: communicationSequences, isLoading } = useFetchCommunicationSequencesQuery({
    orderBy: "-id",
  });
  const { data: defaultWorkflowStates = [], isLoading: isDefaultWorkflowStatesLoading } =
    useFetchAgencyWorkflowStates();

  const byId = useMemo(
    () =>
      defaultWorkflowStates.reduce((acc, state) => {
        acc[state.id] = state;
        return acc;
      }, {}),
    [defaultWorkflowStates],
  );

  const handleArchiveCommunicationSequence = async ({ sequenceId }) => {
    const result = await archiveCommunicationSequence({ sequenceId });

    if ("data" in result) {
      message.success("Communication sequence archived successfully!");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Stop Conditions",
      dataIndex: "stopWorkflowStateIds",
      key: "stopWorkflowStateIds",
      render: (record) =>
        isDefaultWorkflowStatesLoading
          ? "..."
          : record
              .filter((workflowId) => byId[workflowId])
              .map((workflowId) => byId[workflowId].name)
              .join(", "),
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) =>
        !record.isArchived && (
          <Space size="middle">
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedSequence(record);
                setModalVisible(true);
              }}
            />
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to archive this communication sequence?"
              description="This will terminate any scheduled communication task in this communication sequence."
              onConfirm={() => handleArchiveCommunicationSequence({ sequenceId: record.id })}
            >
              <ContainerOutlined key="archive" />
            </Popconfirm>
          </Space>
        ),
    },
  ];

  return (
    <>
      <Row align="middle">
        <span>Create sequences that will be applied to different accounts. </span>
        <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
          Add Sequence
        </Button>
      </Row>
      <StyledTable
        loading={isLoading || isDefaultWorkflowStatesLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={communicationSequences}
      />
      <AddSequenceModal
        title="Add Communication Sequence"
        selectedSequence={selectedSequence}
        setSelectedSequence={setSelectedSequence}
        open={isModalVisible}
        onOk={() => {
          setModalVisible(false);
          setSelectedSequence(null);
        }}
        onCancel={() => {
          setModalVisible(false);
          setSelectedSequence(null);
        }}
      />
    </>
  );
}
