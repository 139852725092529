import { useState } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  cursor: pointer;
`;

export default function TriggerModal({
  modal: ModalComponent,
  children,
  isDisabled = false,
  containerProps = {},
  ...otherProps
}) {
  const [open, setOpen] = useState(false);
  const onCancel = () => setOpen(false);
  const onOk = () => setOpen(false);
  const onClick = () => setOpen(true);

  return (
    <>
      {isDisabled ? (
        children
      ) : (
        <StyledDiv onClick={onClick} {...containerProps}>
          {children}
        </StyledDiv>
      )}
      <ModalComponent open={open} onOk={onOk} onCancel={onCancel} {...otherProps} />
    </>
  );
}
