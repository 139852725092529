import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Skeleton, Switch } from "antd";
import { capitalizeFirstLetter } from "common/utils";
import { pageSizeSelectorOptions } from "components/cursorTable/components/cursorPagination";
import { DragAndDropTable } from "components/dragAndDropTable";
import { SORT_ORDER } from "features/appearance/constants";
import { useState } from "react";
import styled from "styled-components";

const StyledHeader = styled.h4`
  margin: 0;
  line-height: 2.25;
  font-weight: normal;
`;

const StyledCrossed = styled.span`
  text-decoration: line-through;
  color: #999;
  line-height: 2.25;
  margin-left: 12px;
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
  margin-left: 12px;
`;

const StyledRow = styled(Row)`
  margin-top: 12px;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-select {
    margin-left: 0;
  }
`;

export default function AppearanceManager({
  isLoading,
  dataSource,
  setDataSource,
  mode,
  paginationSelector,
}) {
  const [editKey, setEditKey] = useState(null);

  const onCancel = (record) => {
    const index = dataSource.findIndex((item) => item.key === record.key);
    const newDataSource = [...dataSource];
    if (newDataSource[index].newTitle === "") {
      newDataSource[index].newTitle = null;
    }
    setDataSource(newDataSource);
    setEditKey(null);
  };

  const columns = [
    {
      title: "Visible",
      dataIndex: "visible",
      render: (visible, record) => {
        return (
          <Switch
            checked={visible}
            onChange={(checked) => {
              const index = dataSource.findIndex((item) => item.key === record.key);
              const newDataSource = [...dataSource];
              newDataSource[index].visible = checked;
              setDataSource(newDataSource);
            }}
          />
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "100%",
      render: (title, record) => {
        if (editKey === record.key) {
          return (
            <Input
              value={record.newTitle ?? record.originalTitle}
              onChange={(e) => {
                const index = dataSource.findIndex((item) => item.key === record.key);
                const newDataSource = [...dataSource];
                newDataSource[index].newTitle = e.target.value;
                setDataSource(newDataSource);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onCancel(record);
                }
              }}
            />
          );
        }
        return record.newTitle ? (
          <Row>
            <StyledHeader>{record.newTitle}</StyledHeader>
            <StyledCrossed>{record.originalTitle}</StyledCrossed>
          </Row>
        ) : (
          record.originalTitle
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        if (editKey === record.key) {
          return (
            <Button
              loading={isLoading}
              type="link"
              icon={<CheckOutlined />}
              onClick={() => onCancel(record)}
            />
          );
        }
        return (
          <Button
            loading={isLoading}
            type="link"
            icon={<EditOutlined />}
            onClick={() => setEditKey(record.key)}
          />
        );
      },
    },
  ];

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <DragAndDropTable
        columns={columns}
        dataSource={
          dataSource
            ? dataSource?.map((source) => ({
                key: source.key,
                title: source.title,
                visible: source.visible,
                newTitle: source.newTitle,
                originalTitle: source.originalTitle,
              }))
            : []
        }
        setDataSource={setDataSource}
        pagination={false}
      />
      {mode === "table" && (
        <>
          <StyledRow gutter={16}>
            <Col span={12}>
              <StyledFormItem name="sortColumn" label="Sort Column">
                <StyledSelect
                  placeholder="Select one..."
                  options={dataSource
                    ?.filter((source) => {
                      return !!source.sorter;
                    })
                    .map((source) => ({
                      label: source.title,
                      value: source.key,
                    }))}
                />
              </StyledFormItem>
            </Col>
            <Col span={12}>
              <StyledFormItem name="sortOrder" label="Sort Order">
                <StyledSelect
                  placeholder="Select one..."
                  options={Object.values(SORT_ORDER).map((order) => ({
                    label: capitalizeFirstLetter(order),
                    value: order,
                  }))}
                />
              </StyledFormItem>
            </Col>
          </StyledRow>
          {paginationSelector && (
            <StyledRow gutter={16}>
              <Col span={12}>
                <StyledFormItem name="pageSize" label="Page Size">
                  <StyledSelect placeholder="Select one..." options={pageSizeSelectorOptions} />
                </StyledFormItem>
              </Col>
            </StyledRow>
          )}
        </>
      )}
    </>
  );
}
