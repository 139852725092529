import { Layout, Menu } from "antd";
import { PageHeader } from "components/pageHeader";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledLeftPane = styled.div`
  position: fixed;
`;

const StyledMenu = styled(Menu)`
  width: 255px;
  height: 100%;
  border-right: 0;
  background: transparent;
  overflow: auto;
  z-index: 10;
  @media only screen and (max-width: 470px) {
    display: none;
  }
  &&& {
    padding-inline: 0;
  }
  & .ant-menu-item-selected {
    background-color: transparent;
    color: #1677ff;
  }
  & .ant-menu-item:hover, .ant-menu-submenu-title:hover {
    background-color: transparent !important;
    color: #4096ff !important;
  }
    
  }
`;

const StyledSider = styled(Layout.Sider)`
  padding: 12px;
  overflow: scroll;
  left: 0px;
  top: 115px;
  bottom: 0px;
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  &&& {
    position: fixed;
    background: transparent;
  }
`;

function SettingsTabs({ items }) {
  const location = useLocation();
  // converts current location into array, i.e. ['settings', 'user-settings']
  const pathway = location.pathname.split("/").filter(Boolean); // filter(Boolean) is used to remove empty strings

  // needs to be tested
  const selectedKeys = [pathway[1] ?? items[0].key];

  return (
    <>
      <StyledLeftPane>
        <PageHeader>Settings</PageHeader>
      </StyledLeftPane>
      <StyledSider width={256} theme="light">
        <StyledMenu
          selectedKeys={selectedKeys}
          defaultOpenKeys={items.map((item) => item.key)}
          mode="inline"
          theme="light"
          // selectedKeys={parentMenuKey.concat(currentMenuKey)}
          // TODO: Can eventually add functionality to "Open current submenu only"
          // See https://ant.design/components/menu/#components-menu-demo-sider-current
          // onOpenChange={onOpenChange}
          //
          // HACK: For now, we will have the admin and accounts menus be always open
          // openKeys={parentMenuKey.concat(currentMenuKey).concat(["admin", "accounts"])}
          // defaultSelectedKeys={["admin", "accounts"]}
          // defaultOpenKeys={["admin", "accounts"]}
          items={items}
        />
      </StyledSider>
    </>
  );
}

export default SettingsTabs;
