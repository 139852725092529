import api, { transformRequest, transformResponse } from "services/api";

export const udfApi = api
  .enhanceEndpoints({ addTagTypes: ["CreditorUdfCustomField"] })
  .injectEndpoints({
    endpoints: (build) => ({
      addCreditorUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditor-custom-fields/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorUdfCustomField", id: "LIST" }],
      }),
      fetchAllCreditorUdfCustomFields: build.query({
        query: () => `/api/v1/core/creditor-custom-fields/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorUdfCustomField", id })),
                { type: "CreditorUdfCustomField", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorUdfCustomField', id: 'LIST' }` is invalidated
              [{ type: "CreditorUdfCustomField", id: "LIST" }],
      }),
      updateCreditorUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditor-custom-fields/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorUdfCustomField", id: "LIST" }],
      }),
      deleteCreditorUdfCustomField: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditor-custom-fields/${payload.id}/delete/`,
          method: "POST",
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorUdfCustomField", id: "LIST" }],
      }),
    }),
  });

export const {
  useAddCreditorUdfCustomFieldMutation,
  useFetchAllCreditorUdfCustomFieldsQuery,
  useUpdateCreditorUdfCustomFieldMutation,
  useDeleteCreditorUdfCustomFieldMutation,
} = udfApi;
