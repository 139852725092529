import { Alert, Form } from "antd";
import { useFormWizard } from "components/formWizard/formWizardContext";

function Error() {
  const { error } = useFormWizard();

  return error ? (
    <Form.Item>
      <Alert message={error.message ?? error.error} type="error" />
    </Form.Item>
  ) : null;
}

export default Error;
