import { Select, Tag } from "antd";
import { useEffect, useState } from "react";
import { EditableCardComponent } from "components/editableCardComponent";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useFetchSettlementAndPaymentRuleSetsQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";

export default function CreditorPaymentConfigurationCard({ currentCreditor, onSave }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: settlementAndPaymentRuleSets } = useFetchSettlementAndPaymentRuleSetsQuery();
  const categories =
    constants?.paymentCategories?.invoiceable.map((category) => ({
      value: category.value,
      label: category.display,
    })) ?? [];
  const [selectedAgencyCategoriesPriority, setSelectedAgencyCategoriesPriority] = useState(
    Object.fromEntries(categories.map((key) => [key, null])),
  );
  const [selectedCreditorCategoriesPriority, setSelectedCreditorCategoriesPriority] = useState(
    Object.fromEntries(categories.map((key) => [key, null])),
  );
  const [
    selectedForwardingEntityCategoriesPriority,
    setSelectedForwardingEntityCategoriesPriority,
  ] = useState(Object.fromEntries(categories.map((key) => [key, null])));
  const { data: isClientProfileCanEditPaymentsConfigurationAuthorized } = useAuthorization(
    PERMISSIONS.CLIENT_PROFILE__CAN_EDIT_PAYMENTS_CONFIGURATION,
  );

  const updateSelectedCategoriesPriority = (values, setCategoriesPriority) => {
    const priorities = values?.reduce((acc, categoryValue, index) => {
      return { ...acc, [categoryValue]: index + 1 };
    }, {});
    setCategoriesPriority(priorities);
  };

  useEffect(() => {
    const agencyValues = currentCreditor?.paymentConfig.paymentCategoryPriority ?? [];
    const creditorValues = currentCreditor?.paymentConfig.creditorPaymentCategoryPriority ?? [];
    const forwardingEntityValues =
      currentCreditor?.paymentConfig.forwardingEntityPaymentCategoryPriority ?? [];
    updateSelectedCategoriesPriority(agencyValues, setSelectedAgencyCategoriesPriority);
    updateSelectedCategoriesPriority(creditorValues, setSelectedCreditorCategoriesPriority);
    updateSelectedCategoriesPriority(
      forwardingEntityValues,
      setSelectedForwardingEntityCategoriesPriority,
    );
  }, [currentCreditor, setSelectedAgencyCategoriesPriority]);

  const tagRender = (props, categoriesPriority) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {categoriesPriority[value]}. {label}
      </Tag>
    );
  };

  const handleAgencyChange = (values) => {
    const priorities = values.reduce((acc, categoryValue, index) => {
      return { ...acc, [categoryValue]: index + 1 };
    }, {});
    setSelectedAgencyCategoriesPriority(priorities);
  };

  const handleCreditorChange = (values) => {
    const priorities = values.reduce((acc, categoryValue, index) => {
      return { ...acc, [categoryValue]: index + 1 };
    }, {});
    setSelectedCreditorCategoriesPriority(priorities);
  };

  const handleForwardingEntityChange = (values) => {
    const priorities = values.reduce((acc, categoryValue, index) => {
      return { ...acc, [categoryValue]: index + 1 };
    }, {});
    setSelectedForwardingEntityCategoriesPriority(priorities);
  };

  const rows = [
    {
      label: "Payment Category Allocation Priority (Paid to Agency)",
      name: ["paymentConfig", "paymentCategoryPriority"],
      formElement: (
        <Select
          placeholder="Select and Order Categories..."
          tagRender={(props) => tagRender(props, selectedAgencyCategoriesPriority)}
          mode="multiple"
          options={categories}
          onChange={handleAgencyChange}
        />
      ),
      formItemProps: {
        tooltip:
          "Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the Legal fees first, then Principal, and Interest last?).",
        rules: [{ required: true, message: "This field is required." }],
      },
      render: (value, record) => {
        const paymentCategoryPriorities = record?.paymentConfig?.paymentCategoryPriority;
        return paymentCategoryPriorities?.map((categoryValue) => {
          const category = categories.find((x) => x.value === categoryValue);
          return (
            <Tag>
              {selectedAgencyCategoriesPriority[categoryValue]}. {category?.label}
            </Tag>
          );
        });
      },
    },
    {
      label: "Payment Category Allocation Priority (Paid to Creditor)",
      name: ["paymentConfig", "creditorPaymentCategoryPriority"],
      formElement: (
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select and Order Categories..."
          tagRender={(props) => tagRender(props, selectedCreditorCategoriesPriority)}
          mode="multiple"
          options={categories.filter((category) =>
            Object.keys(selectedAgencyCategoriesPriority).includes(category.value),
          )}
          onChange={handleCreditorChange}
        />
      ),
      formItemProps: {
        tooltip:
          "Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the Legal fees first, then Principal, and Interest last?).",
        rules: [{ required: true, message: "This field is required." }],
      },
      render: (value, record) => {
        const paymentCategoryPriorities = record?.paymentConfig?.creditorPaymentCategoryPriority;
        return paymentCategoryPriorities?.map((categoryValue) => {
          const category = categories.find((x) => x.value === categoryValue);
          return (
            <Tag>
              {selectedCreditorCategoriesPriority[categoryValue]}. {category?.label}
            </Tag>
          );
        });
      },
    },
    {
      label: "Payment Category Allocation Priority (Paid to Forwarding Entity)",
      name: ["paymentConfig", "forwardingEntityPaymentCategoryPriority"],
      formElement: (
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select and Order Categories..."
          tagRender={(props) => tagRender(props, selectedForwardingEntityCategoriesPriority)}
          mode="multiple"
          options={categories.filter((category) =>
            Object.keys(selectedAgencyCategoriesPriority).includes(category.value),
          )}
          onChange={handleForwardingEntityChange}
        />
      ),
      formItemProps: {
        tooltip:
          "Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the Legal fees first, then Principal, and Interest last?).",
        rules: [{ required: true, message: "This field is required." }],
      },
      render: (value, record) => {
        const paymentCategoryPriorities =
          record?.paymentConfig?.forwardingEntityPaymentCategoryPriority;
        return paymentCategoryPriorities?.map((categoryValue) => {
          const category = categories.find((x) => x.value === categoryValue);
          return (
            <Tag>
              {selectedForwardingEntityCategoriesPriority[categoryValue]}. {category?.label}
            </Tag>
          );
        });
      },
    },
    {
      label: "Payment Ruleset",
      name: ["paymentConfig", "settlementPaymentRulesetId"],
      formElement: (
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select payment ruleset..."
          options={settlementAndPaymentRuleSets?.map((ruleSet) => ({
            value: ruleSet.id,
            label: ruleSet.name,
          }))}
        />
      ),
      formItemProps: {
        tooltip: "Select payment ruleset.",
      },
      render: (value, record) => {
        const ruleSet = settlementAndPaymentRuleSets?.find(
          (x) => x.id === record?.paymentConfig?.settlementPaymentRulesetId,
        );
        return ruleSet?.name ?? record?.paymentConfig?.settlementPaymentRulesetId;
      },
    },
  ];

  return (
    <EditableCardComponent
      hasEditPermission={isClientProfileCanEditPaymentsConfigurationAuthorized}
      title="Payments Configuration"
      record={currentCreditor}
      rows={rows}
      onSave={onSave}
    />
  );
}
