export const firstPriorityFields = [
  {
    value: "drivers_license",
    label: "Driver's License",
  },
  {
    value: "passport",
    label: "Passport",
  },
  {
    value: "ssn",
    label: "SSN",
  },
];

export const secondPriorityFields = [
  {
    value: "date_of_birth",
    label: "Date of Birth",
  },
  {
    value: "drivers_license",
    label: "Driver's License",
  },
  {
    value: "passport",
    label: "Passport",
  },
  {
    value: "ssn",
    label: "SSN",
  },
];

export const thirdPriorityFields = [
  // {
  //   value: "address",
  //   label: "Address",
  // },
  {
    value: "date_of_birth",
    label: "Date of Birth",
  },
  {
    value: "full_name",
    label: "Full Name",
  },
  {
    value: "phone",
    label: "Phone",
  },
  {
    value: "email",
    label: "Email",
  },
];
