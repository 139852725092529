import { Modal, Radio, Form, Space } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useGenerateDebtorCreditReportMutation } from "features/debtorCreditReportTable/debtorCreditReportAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

function GenerateDebtorCreditReportModal({ debtorId, title, open, onOk, onCancel }) {
  const [generateDebtorCreditReport] = useGenerateDebtorCreditReportMutation();
  const { data: constants } = useFetchBackendConstantsQuery();

  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const result = await generateDebtorCreditReport({ ...values, debtorId });
      if ("data" in result) {
        form.resetFields();
        onOk(); // Close the modal using the parent callback
      }
      if ("error" in result) {
        form.setFields(formatError(result.error));
      }
    } catch (error) {
      // No-op
    }
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={handleOk} onCancel={onCancel}>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        initialValues={{ creditBureauType: "experian" }}
      >
        <Form.Item name="creditBureauType" label="Select a credit bureau">
          <Radio.Group>
            <Space direction="vertical">
              {constants?.creditBureauTypes?.map((creditBureau) => (
                <Radio value={creditBureau.value} disabled={creditBureau.value !== "experian"}>
                  {creditBureau.display}
                  {creditBureau.value !== "experian" && " (Coming soon)"}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default GenerateDebtorCreditReportModal;
