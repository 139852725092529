import { Table, Tag } from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatCurrency, formatPercentage } from "common/utils";
import { useFetchJudgmentsQuery } from "features/legal/legalAPI";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";

export function JudgmentsTable({ lawsuit }) {
  const { debtorId } = useParams();
  const { data: judgments, isLoading: isJudgmentsLoading } = useFetchJudgmentsQuery(
    { debtorId, lawsuitId: lawsuit.id },
    { skip: !lawsuit.id },
  );

  const columns = [
    {
      title: "Judgment ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
    },
    {
      title: "Debtors",
      dataIndex: "debtorIds",
      key: "debtorIds",
      render: (value) =>
        value?.map((id) => {
          const currentDebtors = lawsuit?.debtors.filter((debtor) => debtor.id === id) ?? [];
          return currentDebtors.map((currentDebtor) => (
            <Tag key={currentDebtor.id} color="blue">
              {currentDebtor.id} - {currentDebtor.name}
            </Tag>
          ));
        }) ?? "-",
    },
    {
      title: "Entry Date",
      dataIndex: "entryDate",
      key: "entryDate",
      render: (value) => moment(value).format(DATE_FORMAT) ?? "-",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (value) => moment(value).format(DATE_FORMAT) ?? "-",
    },
    {
      title: "Judgment Interest Calculation Date",
      dataIndex: "interestStartDate",
      key: "interestStartDate",
      render: (value) => moment(value).format(DATE_FORMAT) ?? "-",
    },
    {
      title: "Judgment Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      render: (value) => formatPercentage(value) ?? "-",
    },
    {
      title: "Judgment Amount",
      dataIndex: "judgmentPrincipal",
      key: "judgmentPrincipal",
      render: (value) => formatCurrency(value) ?? "-",
    },
  ];

  return (
    <Table
      scroll={{ x: "max-content" }}
      columns={columns}
      bordered
      dataSource={judgments}
      loading={isJudgmentsLoading}
    />
  );
}
