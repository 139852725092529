import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import { renderAddress } from "common/utils";
import { PageHeader } from "components/pageHeader";
import AddCourtOfLawModal from "features/courtsOfLawTable/components/addCourtOfLawModal";
import { useFetchCourtsOfLawQuery } from "features/courtsOfLawTable/courtsOfLawAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

export default function CourtsOfLawTable() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const [modalContent, setModalContent] = useState(null);
  const { data: courtsOfLaw, isLoading } = useFetchCourtsOfLawQuery();

  const onNewCourt = async () => {
    setModalContent(null);
  };

  const columns = [
    {
      title: "Court Code",
      dataIndex: "code",
    },
    {
      title: "Court Type",
      dataIndex: "type",
    },
    {
      title: "Court Name",
      dataIndex: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Division",
      dataIndex: "division",
    },
    {
      title: "Address",
      render: (text, record) => renderAddress(record, constants?.states),
    },
    {
      title: "County",
      dataIndex: "county",
    },
    {
      title: "District",
      dataIndex: "district",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "URL",
      dataIndex: "url",
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
  ];

  return (
    <Col>
      <PageHeader>Courts of Law</PageHeader>
      <Row align="middle">
        <span>Configure courts of law and their locations.</span>
        <Button
          onClick={() =>
            setModalContent(
              <AddCourtOfLawModal
                title="Add court of law"
                open
                onOk={onNewCourt}
                onCancel={() => setModalContent(null)}
              />,
            )
          }
          type="link"
          icon={<PlusOutlined />}
        >
          Add Court of Law
        </Button>
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={courtsOfLaw}
      />
      {modalContent}
    </Col>
  );
}
