import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Row, Space, Table } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { capitalizeFirstLetter } from "common/utils";
import { TruncatedText } from "components/truncatedText";
import RoleModal from "features/userManagementTable/components/roleModal";
import {
  useFetchAllPermissionsQuery,
  useFetchRolesQuery,
} from "features/userManagementTable/userManagementAPI";

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

export default function RolesTable() {
  const { data: permissions } = useFetchAllPermissionsQuery();
  const { data: roles } = useFetchRolesQuery();

  const [isModalVisible, setModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const isNew = !activeRecord;

  const handleEdit = (record) => {
    setActiveRecord(record);
    setModalVisible(true);
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Account Type",
      dataIndex: "userType",
      key: "userType",
      render: (userType) => capitalizeFirstLetter(userType),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => <TruncatedText text={description} maxLength={40} />,
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (value) => {
        const permissionNames = permissions
          ?.filter((x) => value?.includes(x.value))
          .map((x) => x.display);
        const permissionText = permissionNames?.join(", ");
        return <TruncatedText text={permissionText} maxLength={40} />;
      },
      width: 400,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <AntDToolTip placement="bottom" title="Edit" key="edit">
            <EditOutlined key="edit" onClick={() => handleEdit(record)} />
          </AntDToolTip>
        </Space>
      ),
      fixed: "right",
    },
  ];

  const onNewRoleOk = async () => {
    setModalVisible(false);
    setActiveRecord(null);
  };

  return (
    <>
      <StyledRow align="middle">
        <span>Create, manage, or set roles and their permissions.</span>
        <Button onClick={() => setModalVisible(true)} icon={<PlusOutlined />} type="link">
          Add Role
        </Button>
      </StyledRow>
      <Table
        loading={false}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={roles}
      />

      {isModalVisible && (
        <RoleModal
          title={isNew ? "Add Role" : "Edit Role"}
          selectedRole={activeRecord}
          open={isModalVisible}
          onOk={onNewRoleOk}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
        />
      )}
    </>
  );
}
