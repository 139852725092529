import { useAuth0 } from "@auth0/auth0-react";
import { selectIsAccessTokenStoredInRtk, updateAccessToken } from "features/auth/authSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

function ProtectedRoute() {
  const dispatch = useDispatch();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);
  const { isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  // isAuthenticated is unreliable here for unknown reasons
  // so we use isLoggedIn which checks rtk for the accessToken
  const { getAccessTokenSilently } = useAuth0();

  const makeGetAccessTokenRequest = useCallback(async () => {
    if (!isAuthenticated) {
      // If Auth0 has not returned the access token yet, don't bother trying to store anything in RTK.
      return;
    }

    const accessToken = await getAccessTokenSilently();
    dispatch(updateAccessToken(accessToken));
  }, [dispatch, getAccessTokenSilently, isAuthenticated]);

  // We fetch the access token, if not there, just go to login. No concept of refresh token with Auth0.
  useEffect(() => {
    makeGetAccessTokenRequest();
  }, [makeGetAccessTokenRequest]);

  if (!isLoading && !isAuthenticated) {
    // If the user is NOT authenticating AND NOT already authenticated with Auth0, force re-login.
    navigate("/login", {
      state: {
        returnTo: window.location.pathname,
      },
    });
  }

  if (isLoading || !isAccessTokenStoredInRtk) {
    // We don't want to show the page until we have the access token in RTK.
    return;
  }

  return <Outlet />;
}

export default ProtectedRoute;
