import { AccountAssignmentRulesTable } from "features/accountAssignmentRulesTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function AccountAssignmentPage() {
  return (
    <PageLayout>
      <Content>
        <AccountAssignmentRulesTable />
      </Content>
    </PageLayout>
  );
}

export default AccountAssignmentPage;
