import api, { transformRequest, transformResponse } from "services/api";

export const communicationLimitsApi = api
  .enhanceEndpoints({
    addTagTypes: ["CommunicationLimits", "CommunicationLimitsByDebtor", "DebtorSystemLogs"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCommunicationLimits: build.query({
        query: () => `api/v1/core/compliance/communication-limit-rules/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CommunicationLimits", id })),
                { type: "CommunicationLimits", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CommunicationLimits', id: 'LIST' }` is invalidated
              [{ type: "CommunicationLimits", id: "LIST" }],
      }),
      fetchCommunicationLimitsForDebtor: build.query({
        query: (debtorId) => `api/v1/core/debtors/${debtorId}/compliance/communication-limits/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result, error, arg) => [{ type: "CommunicationLimitsByDebtor", id: arg }],
      }),
      createCommunicationLimit: build.mutation({
        query: (payload) => ({
          url: `api/v1/core/compliance/communication-limit-rules/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [
          { type: "CommunicationLimits", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      updateCommunicationLimit: build.mutation({
        query: (payload) => ({
          url: `api/v1/core/compliance/communication-limit-rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [
          { type: "CommunicationLimits", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      deleteCommunicationLimit: build.mutation({
        query: (payload) => ({
          url: `api/v1/core/compliance/communication-limit-rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [
          { type: "CommunicationLimits", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateCommunicationLimitMutation,
  useFetchCommunicationLimitsQuery,
  useUpdateCommunicationLimitMutation,
  useDeleteCommunicationLimitMutation,
  useFetchCommunicationLimitsForDebtorQuery,
} = communicationLimitsApi;
