export const groupPermissionOptions = (permissions) => {
  return permissions?.reduce((result, permission) => {
    const model = permission?.value?.split(".")[0];
    result[model] = result[model] || [];
    result[model].push(permission);

    return result;
  }, {});
};

export const groupExistingPermissions = (permissions, allGroupKeys) => {
  // Group existing permissions by group keys provided to ensure that all group keys are present in the object
  return allGroupKeys?.reduce((acc, groupKey) => {
    acc[groupKey] = permissions?.filter((x) => x.split(".")[0] === groupKey);
    return acc;
  }, {});
};
