import { ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { toTitleCase } from "common/utils";
import styled from "styled-components";

const StyledValue = styled.span`
  flex: 1;
`;

const StyledLabel = styled.span`
  text-transform: capitalize;
  color: grey;
  font-size: 12px;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: red;
  margin-right: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

export default function DisplayPhoneNumber(props) {
  const { number, notes, type, label, isValid } = props;
  return (
    <StyledContainer>
      {isValid ? (
        <>
          <StyledLabel>
            {toTitleCase({ str: type, delimiter: "_" })}
            {notes ? (
              <Tooltip title={notes} trigger="hover">
                <StyledInfoCircleOutlined />
              </Tooltip>
            ) : null}
          </StyledLabel>
          <StyledValue>{number}</StyledValue>
        </>
      ) : (
        <>
          <Tooltip title="This phone number is invalid">
            <StyledExclamationCircleOutlined />
          </Tooltip>
          <StyledValue>{number}</StyledValue>
        </>
      )}
    </StyledContainer>
  );
}
