import { Button, Card, Col, Divider, message, Row, Tabs, Tag } from "antd";
import AddressesStep from "features/addCreditorWizard/wizardSteps/addressesStep";
import CreditorContactsStep from "features/addCreditorWizard/wizardSteps/contactsStep";
import CreditorBasicInfoCard from "features/creditorProfile/components/creditorBasicInfoCard";
import CreditorCostsTable from "features/creditorProfile/components/creditorCostsTable";
import CreditorCreditReportingCard from "features/creditorProfile/components/creditorCreditReportingCard";
import CreditorInvoicesTable from "features/creditorProfile/components/creditorInvoicesTable";
import { useUpdateCreditorMutation } from "features/creditorProfile/creditorProfileAPI";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import CreditorDocumentsTable from "./components/creditorDocumentsTable";
import CreditorInvoiceSettingsCard from "./components/creditorInvoiceSettingsCard";
import CreditorPaymentConfigurationCard from "./components/creditorPaymentConfigurationCard";

const StyledCol = styled(Col)`
  margin-left: 224px;
  margin-bottom: 16px;
`;

const StyledContainer = styled(Row)`
  margin-bottom: 16px;
`;

const StyledCreditorName = styled.h2`
  margin-bottom: 0;
`;

const StyledValue = styled.span`
  font-size: 12px;
  color: gray;
`;

const StyledHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 36px;
  text-transform: uppercase;
`;

const StyledRow = styled(Row)`
  display: flex;
`;

const StyledVertDivider = styled(Divider)`
  background-color: #000;
`;

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 28px;
  left: 0;
  background-color: #f9f3f3;
`;

const StyledCard = styled(Card)`
  flex: 1;
  margin-bottom: 16px;
  .ant-card-body {
    padding: 16px;
  }
`;

const StyledButtonCol = styled(Col)`
  display: flex;
  align-self: center;
`;

function CreditorProfile() {
  const { creditorId } = useParams();
  const { data: currentCreditor, isLoading } = useFetchCreditorQuery(
    { creditorId },
    { skip: !creditorId },
  );
  const [updateCreditor] = useUpdateCreditorMutation();
  const {
    data: {
      [PERMISSIONS.CLIENT_PROFILE__CAN_VIEW_CREDIT_REPORTING]: canViewClientProfileCreditReporting,
      [PERMISSIONS.CLIENT_PROFILE__CAN_VIEW_DOCUMENTS]: canViewClientProfileDocuments,
      [PERMISSIONS.CLIENT_PROFILE__CAN_VIEW_CLIENT_COSTS]: canViewClientProfileClientCosts,
      [PERMISSIONS.CLIENT_PROFILE__CAN_VIEW_INVOICES]: canViewClientProfileInvoices,
      [PERMISSIONS.CLIENT_PROFILE__CAN_VIEW_PAYMENTS_CONFIGURATION]:
        canViewClientProfilePaymentsConfiguration,
    },
  } = useAuthorizations();

  const handleSave = async (creditor) => {
    const result = await updateCreditor({ ...creditor, id: currentCreditor?.id });
    if ("data" in result) {
      message.success("Creditor updated successfully!");
    }
    if ("error" in result) {
      message.error("Unable to update Creditor");
    }
    return result;
  };

  const tabItems = [
    {
      label: "Configuration Overview",
      key: "overview",
      children: (
        <Col>
          <StyledContainer>
            <CreditorBasicInfoCard currentCreditor={currentCreditor} onSave={handleSave} />
          </StyledContainer>
          <StyledCard>
            <StyledHeader>Addresses</StyledHeader>
            <StyledDivider />
            <AddressesStep creditorId={currentCreditor?.id} />
          </StyledCard>
          <StyledCard>
            <StyledHeader>Client Contacts</StyledHeader>
            <StyledDivider />
            <CreditorContactsStep creditorId={currentCreditor?.id} />
          </StyledCard>
        </Col>
      ),
    },
    canViewClientProfileCreditReporting && {
      label: "Credit Reporting",
      key: "reporting",
      children: (
        <StyledContainer>
          <CreditorCreditReportingCard currentCreditor={currentCreditor} onSave={handleSave} />
        </StyledContainer>
      ),
    },
    canViewClientProfileDocuments && {
      label: "Documents",
      key: "documents",
      children: (
        <StyledContainer>
          <CreditorDocumentsTable currentCreditor={currentCreditor} />
        </StyledContainer>
      ),
    },
    canViewClientProfileClientCosts && {
      label: "Client Costs",
      key: "costs",
      children: (
        <StyledContainer>
          <StyledCard>
            <StyledRow>
              <StyledHeader>Client Costs</StyledHeader>
              <StyledDivider />
            </StyledRow>
            <CreditorCostsTable currentCreditor={currentCreditor} />
          </StyledCard>
        </StyledContainer>
      ),
    },
    canViewClientProfileInvoices && {
      label: "Invoices",
      key: "invoices",
      children: (
        <>
          <StyledContainer>
            <CreditorInvoiceSettingsCard currentCreditor={currentCreditor} onSave={handleSave} />
          </StyledContainer>
          <StyledCard>
            <StyledHeader>Invoices</StyledHeader>
            <StyledDivider />
            <CreditorInvoicesTable currentCreditor={currentCreditor} />
          </StyledCard>
        </>
      ),
    },
    canViewClientProfilePaymentsConfiguration && {
      label: "Payments Configuration",
      key: "configuration",
      children: (
        <StyledContainer>
          <CreditorPaymentConfigurationCard currentCreditor={currentCreditor} onSave={handleSave} />
        </StyledContainer>
      ),
    },
  ];

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Row justify="space-between">
        <StyledCol id="overview">
          <StyledCreditorName>{currentCreditor?.name}</StyledCreditorName>
          <Col flex="auto">
            <StyledValue>Client ID: {currentCreditor?.id}</StyledValue>
            <StyledVertDivider type="vertical" />
            <StyledValue>Client Code: {currentCreditor?.code}</StyledValue>
            <StyledVertDivider type="vertical" />
            <StyledValue>
              Status:{" "}
              <Tag color={currentCreditor?.status === "active" ? "green" : "red"}>
                {currentCreditor?.status?.toUpperCase()}
              </Tag>
            </StyledValue>
          </Col>
        </StyledCol>
        <StyledButtonCol>
          <Link
            to="/accounts"
            state={{
              creditorFilter: currentCreditor?.id,
            }}
          >
            <Button>View accounts</Button>
          </Link>
        </StyledButtonCol>
      </Row>
      <Tabs items={tabItems} tabPosition="left" />
    </>
  );
}

export default CreditorProfile;
