import { Form, Input, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
} from "features/workflows/workflowAPI";
import { useFetchWorkflowStatesQuery } from "features/workflows/workflowStatesAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

export default function WorkflowModal({
  onOk,
  onCancel,
  open,
  title = "Add Workflow",
  selectedWorkflow,
}) {
  const [form] = useForm();
  const [createWorkflow] = useCreateWorkflowMutation();
  const [updateWorkflow] = useUpdateWorkflowMutation();
  const { data: workflowStates = [], isLoading: isWorkflowStatesLoading } =
    useFetchWorkflowStatesQuery();

  const onSubmit = async () => {
    const values = await form.validateFields();

    const result = selectedWorkflow ? await updateWorkflow(values) : await createWorkflow(values);
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success("Workflow added successfully!");
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onCancel}>
      <StyledForm form={form} layout="vertical" initialValues={selectedWorkflow}>
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Enter workflow name" }]}
        >
          <Input placeholder="Enter Workflow Name..." />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description..." />
        </Form.Item>
        <Form.Item
          name="initialStateId"
          label="Initial Workflow State"
          rules={[{ required: true, message: "Please select an initial state" }]}
        >
          <Select
            placeholder="Select default workflow state..."
            loading={isWorkflowStatesLoading}
            options={workflowStates?.map((state) => ({
              label: `${state.code} - ${state.name}`,
              value: state.id,
            }))}
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
