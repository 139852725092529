// Functional component that shows antd modal with metabase iframe in it, the url is fetched using useFetchMetabaseReportDetailsQuery hook
import { Modal, Skeleton } from "antd";
import { IFrame } from "components/iFrame";
import { useUserType } from "features/auth";
import { useFetchMetabaseReportDetailsQuery as useFetchAgencyMetabaseReportDetailsQuery } from "features/metabase/agencyPortal/metabaseAPI";
import { useFetchCreditorMetabaseReportDetailsQuery } from "features/metabase/creditorPortal/metabaseAPI";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  height: 75vh;
`;

const StyledIFrame = styled(IFrame)`
  width: 100%;
  height: 75vh;
  border: 0;
`;

export default function MetabaseModal({ reportId, ...props }) {
  const { isAgencyUserType, isCreditorUserType } = useUserType();

  const {
    data: creditorReportDetails,
    isLoading: isCreditorReportsLoading,
    isError: isCreditorReportsError,
  } = useFetchCreditorMetabaseReportDetailsQuery(
    { id: reportId },
    {
      skip: !isCreditorUserType,
    },
  );
  const {
    data: agencyReportDetails,
    isLoading: isAgencyReportsLoading,
    isError: isAgencyReportsError,
  } = useFetchAgencyMetabaseReportDetailsQuery(
    { id: reportId },
    {
      skip: !isAgencyUserType,
    },
  );

  const isLoading = isCreditorReportsLoading || isAgencyReportsLoading;
  const isError = isCreditorReportsError || isAgencyReportsError;
  const reportDetails = isCreditorUserType ? creditorReportDetails : agencyReportDetails;

  return (
    <StyledModal {...props} footer={null} width="95%">
      {isLoading && <Skeleton active />}
      {isError && <div>Something went wrong</div>}
      {reportDetails && (
        <StyledIFrame title={reportId} src={`${reportDetails.url}#bordered=false`} />
      )}
    </StyledModal>
  );
}
