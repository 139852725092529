import { EllipsisOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Select, Space } from "antd";
import styled from "styled-components";

const INITIAL_PAGE_SIZE = 100;

const StyledButton = styled(Button)`
  min-width: 32px;
  height: 32px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 7px;
  padding-right: 7px;
  &:focus {
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
  }
`;

const NoOutlineButton = styled(Button)`
  border: none;
  background: transparent !important;
  padding-left: 2px;
  padding-right: 2px;
`;

const ActiveButton = styled(StyledButton)`
  color: #40a9ff;
  border-color: #40a9ff;
`;

const StyledSelect = styled(Select)``;

export const pageSizeSelectorOptions = [
  { label: "10 / page", value: 10 },
  { label: "20 / page", value: 20 },
  { label: "50 / page", value: 50 },
  { label: "100 / page", value: 100 },
  { label: "1000 / page", value: 1000 },
];

export default function CursorPagination({
  onChange,
  pageSize,
  prevToken,
  nextToken,
  virtualPage,
  loading,
}) {
  const handleNextPage = () => {
    onChange({ prevToken: null, nextToken, pageSize, virtualPage: virtualPage + 1 });
  };

  const handlePrevPage = () => {
    onChange({ prevToken, nextToken: null, pageSize, virtualPage: virtualPage - 1 });
  };

  const handleFirstPage = () => {
    onChange({ prevToken: null, nextToken: null, pageSize, virtualPage: 1 });
  };

  const handlePageSizeChange = (newPageSize) => {
    onChange({ prevToken: null, nextToken: null, pageSize: newPageSize, virtualPage: 1 });
  };

  /* Show first page if current page is above 2 */
  const showFirstPage = virtualPage > 2;

  /* For pages above 3, show 1 ... page */
  const showFirstSpread = virtualPage > 3;

  /* Show first page if current page is above 1 */
  const showPreviousPage = virtualPage > 1;

  /* Show second spread if next page exists */
  const showSecondSpread = !!nextToken;

  /* Show first page if next page exists */
  const showNextPage = !!nextToken;

  return (
    <Space direction="horizontal">
      {/** Left arrow if needed */}
      <StyledButton tabIndex={-1} onClick={handlePrevPage} disabled={!showPreviousPage || loading}>
        <LeftOutlined />
      </StyledButton>
      {/** Show first page */}
      {showFirstPage && (
        <StyledButton tabIndex={-1} onClick={handleFirstPage} disabled={loading}>
          1
        </StyledButton>
      )}
      {/** Show first spread if needed */}
      {showFirstSpread && (
        <NoOutlineButton tabIndex={-1} disabled={loading}>
          <EllipsisOutlined />
        </NoOutlineButton>
      )}
      {/** Show previous page if needed */}
      {showPreviousPage && (
        <StyledButton tabIndex={-1} onClick={handlePrevPage} disabled={loading}>
          {virtualPage - 1}
        </StyledButton>
      )}
      {virtualPage > 0 && (
        <ActiveButton tabIndex={-1} disabled={loading}>
          {virtualPage}
        </ActiveButton>
      )}
      {/** Show next page if needed */}
      {showNextPage && (
        <StyledButton tabIndex={-1} onClick={handleNextPage} disabled={loading}>
          {virtualPage + 1}
        </StyledButton>
      )}
      {/** Show second spread if needed */}
      {showSecondSpread && (
        <NoOutlineButton tabIndex={-1} disabled={loading}>
          <EllipsisOutlined />
        </NoOutlineButton>
      )}
      {/** Right arrow if needed */}
      <StyledButton tabIndex={-1} onClick={handleNextPage} disabled={!showNextPage || loading}>
        <RightOutlined />
      </StyledButton>
      <StyledSelect
        defaultValue={INITIAL_PAGE_SIZE}
        options={pageSizeSelectorOptions}
        value={pageSize}
        onChange={handlePageSizeChange}
        disabled={loading}
      />
    </Space>
  );
}
