export default [
  {
    key: "id",
    label: "Account ID",
    type: "number",
    validators: [
      { validate: "require_without_all", fields: ["external_id", "client_reference_id"] },
    ],
  },
  {
    key: "external_id",
    label: "External Account ID",
    type: "string",
    validators: [{ validate: "require_without_all", fields: ["id", "client_reference_id"] }],
  },
  {
    key: "client_reference_id",
    label: "Client Reference ID",
    type: "string",
    validators: [{ validate: "require_without_all", fields: ["id", "external_id"] }],
  },
];
