import api, { transformResponse } from "services/api";

export const smsTemplatesApi = api
  .enhanceEndpoints({ addTagTypes: ["SmsTemplates"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchSmsTemplates: build.query({
        query: () => "/api/v1/core/sms-templates/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "SmsTemplates", id })),
                { type: "SmsTemplates", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'SmsTemplates', id: 'LIST' }` is invalidated
              [{ type: "SmsTemplates", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const { useFetchSmsTemplatesQuery } = smsTemplatesApi;
