import { Card, Form, Select } from "antd";
import {
  useFetchCreditorSummariesQuery,
  useLazyFetchCreditorsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 400px;
`;

export default function AccountUploadParamsForm({
  onSubmit,
  isFetching,
  isMultipleClientsAccountUploadType,
}) {
  const [form] = Form.useForm();
  const [
    fetchCreditors,
    {
      data: creditorsResponse,
      isLoading: isCreditorDetailsLoading,
      isFetching: isCreditorDetailsFetching,
    },
  ] = useLazyFetchCreditorsQuery();
  const { data: creditors, isLoading } = useFetchCreditorSummariesQuery();
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: productOfferings } = useFetchProductOfferingsQuery();

  const selectedCreditor = creditorsResponse?.results?.[0];

  const onCreditorIdChange = async (creditorId) => {
    form.setFieldsValue({ cbrClassCode: null, productOfferingId: null });
    fetchCreditors({ filters: { creditorIds: [creditorId] } });

    // If the account upload type is for multiple clients, we only need the creditorId.
    // (No need for cbrClassCode and productOfferingId). So we can submit the form once the creditor is selected.
    if (isMultipleClientsAccountUploadType) {
      const validatedFields = await form.validateFields();
      onSubmit(validatedFields);
    }
  };

  const onDependantSelectChange = async () => {
    const validatedFields = await form.validateFields();
    onSubmit(validatedFields);
  };

  return (
    <Card>
      <StyledForm layout="vertical" form={form} disabled={isFetching}>
        <Form.Item
          label="Client"
          name="creditorId"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={creditors?.map((creditor) => ({
              value: creditor.id,
              label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
            }))}
            onChange={onCreditorIdChange}
            loading={isLoading}
          />
        </Form.Item>
        {selectedCreditor && !isMultipleClientsAccountUploadType && (
          <>
            <Form.Item
              name="cbrClassCode"
              label="CBR Class Code"
              rules={[{ required: true, message: "Please select the CBR Class Code" }]}
            >
              <Select
                placeholder="Select the CBR Class Code"
                options={constants?.cbrClassCodes
                  .filter((dt) => {
                    return selectedCreditor.cbrClassCodes.includes(dt.value);
                  })
                  .map((cbrClassCodeOption) => ({
                    value: cbrClassCodeOption.value,
                    label: cbrClassCodeOption.display,
                  }))}
                onSelect={onDependantSelectChange}
                loading={isCreditorDetailsLoading || isCreditorDetailsFetching}
              />
            </Form.Item>
            <Form.Item
              name="productOfferingId"
              label="Product"
              rules={[{ required: true, message: "Please select a product" }]}
            >
              <Select
                placeholder="Select a product"
                options={productOfferings
                  .filter((product) =>
                    (selectedCreditor?.productOfferingIds ?? []).includes(product.id),
                  )
                  .map((product) => ({
                    value: product.id,
                    label: product.name,
                  }))}
                onSelect={onDependantSelectChange}
                loading={isCreditorDetailsLoading || isCreditorDetailsFetching}
              />
            </Form.Item>
          </>
        )}
      </StyledForm>
    </Card>
  );
}
