import { useFormWizard } from "components/formWizard/formWizardContext";
import { Steps as AntdSteps } from "antd";

function Steps() {
  const { steps, current, onStepChange, canSkipSteps } = useFormWizard();

  // We need to remove onNext as it will cause errors
  const filteredSteps = steps.map((step) => {
    const { onNext: _, ...rest } = step;
    return rest;
  });

  if (canSkipSteps) {
    return (
      <AntdSteps size="small" items={filteredSteps} onChange={onStepChange} current={current} />
    );
  }

  return <AntdSteps size="small" items={filteredSteps} current={current} />;
}

export default Steps;
