import { Form, Input, InputNumber, Modal, Radio, message } from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { formatCurrency } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { usePostPaymentMutation } from "features/invoices/invoiceAPI";
import moment from "moment-timezone";
import { useMemo } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function LogPaymentModal({ title, open, onCancel, selectedRows, onOk }) {
  const [form] = Form.useForm();
  const invoice = selectedRows?.[0];
  const [postPayment, { isLoading }] = usePostPaymentMutation();
  const payinFullAmount = useMemo(
    () => currency(invoice?.summaryTotalAmountDueToAgency).subtract(invoice?.amountCreditorPaid),
    [invoice?.summaryTotalAmountDueToAgency, invoice?.amountCreditorPaid],
  );

  const onSubmit = async () => {
    const payment = await form.validateFields();
    payment.isPartialPayment = payment?.paymentIntentType === "partial";
    if (!payment.isPartialPayment) {
      payment.amount = payinFullAmount.value;
    }
    const result = await postPayment({ invoiceId: invoice?.id, payload: payment });
    if ("data" in result) {
      message.success("Payment has been posted");
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <StyledForm
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item
          name="paymentIntentType"
          initialValue={payinFullAmount.value <= 0 ? "partial" : "full"}
        >
          <Radio.Group>
            <Radio value="partial">Partial Payment</Radio>
            <Radio disabled={payinFullAmount.value <= 0} value="full">
              {" "}
              {/* TODO Akif: Add a property remainingAmountDueToAgency on the BE to replace chained calculations  */}
              Pay in Full: {formatCurrency(payinFullAmount)}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <div>
              {getFieldValue("paymentIntentType") === "partial" && (
                <Form.Item
                  name="amount"
                  key="amount"
                  label="Partial Amount"
                  initialValue={0}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: (_, value) => {
                        if (value === 0) {
                          return Promise.reject(
                            new Error("The entered amount must be greater than 0"),
                          );
                        }
                        return Promise.resolve(true);
                      },
                    },
                  ]}
                >
                  <InputNumber prefix="$" controls={false} precision={2} min={0} />
                </Form.Item>
              )}
            </div>
          )}
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="effectiveDate"
          label="Effective Date"
          initialValue={moment().format(DATE_FORMAT)}
        >
          <AktDatePicker />
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default LogPaymentModal;
