import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Select, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CREDITOR_DOCUMENT_TYPE_OPTIONS } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useCreateCreditorDocumentMutation } from "features/creditorProfile/creditorProfileAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function UploadCreditorDocumentModal({ creditorId, title, open, onOk, onCancel }) {
  const [uploadDocument] = useCreateCreditorDocumentMutation();
  const [fileList, setFileList] = useState([]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const result = await uploadDocument({ ...values, creditorId });
      if ("data" in result) {
        form.resetFields();
        onOk(); // Close the modal using the parent callback
      }
      if ("error" in result) {
        form.setFields(formatError(result.error));
      }
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  // See https://ant.design/components/form/ `validateMessages` to see how to customize error
  // messages.
  const validateMessages = {
    required: "This is a required field",
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={handleOk} onCancel={onCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Name"
          name="filename"
          rules={[{ required: true }]}
          tooltip="This is a required field"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true }]}
          tooltip="This is a required field"
        >
          <Select
            options={CREDITOR_DOCUMENT_TYPE_OPTIONS.map((fileType) => ({
              label: fileType.label,
              value: fileType.value,
            }))}
          />
        </Form.Item>
        <Form.Item label="Notes" name="notes" initialValue="">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Upload"
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true }]}
        >
          <Upload
            multiple={false}
            name="file"
            headers={{
              authorization: "authorization-text",
            }}
            onChange={(info) => {
              const { status } = info.file;
              if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            beforeUpload={(file) => {
              setFileList([...fileList, file]);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default UploadCreditorDocumentModal;
