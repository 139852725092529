import { Button, Form, message, Select, Skeleton, Space } from "antd";
import { formatCurrency, snakeToCamelCase } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import AccrualAmountSelect from "features/debtorProfile/components/accrualAmountSelect";
import {
  useApplyAdvancedJudgmentMutation,
  useFetchLawsuitAllocationsQuery,
} from "features/legal/legalAPI";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  padding-bottom: 36px;
`;

const StyledSpace = styled(Space)`
  position: absolute;
  right: 24px;
  bottom: 20px;
`;

const DEFAULT_PAYMENT_CATEGORIES = [
  "principal",
  "interest",
  "legalFees",
  "checkFees",
  "collectionFees",
  "miscFees",
  "attorneyFees",
  "trebleDamages",
  "handlingFees",
  "courtFees",
  "serviceFees",
  "lienFees",
];

export function AdvancedJudgmentForm({ lawsuit, onOk, onCancel }) {
  const [form] = Form.useForm();
  const { accounts, debtors } = lawsuit;
  const { debtorId } = useParams();
  const [applyAdvancedJudgment, { isLoading: isSaving }] = useApplyAdvancedJudgmentMutation();
  const {
    data: lawsuitAllocations = [],
    isLoading: isLawsuitAllocationsLoading,
    isFetching: isLawsuitAllocationsFetching,
  } = useFetchLawsuitAllocationsQuery({ debtorId, lawsuitId: lawsuit.id }, { skip: !lawsuit.id });

  const { data: selectedCreditor } = useFetchCreditorQuery(
    { creditorId: lawsuit?.creditorId },
    { skip: !lawsuit?.creditorId },
  );

  // Priority list comes in a snake case format, so we need to convert it to camel case
  const paymentCategoryPriority =
    selectedCreditor?.paymentConfig?.paymentCategoryPriority?.map(snakeToCamelCase) ??
    DEFAULT_PAYMENT_CATEGORIES;

  // Deduplicate available account ids and build options for select
  const allocationsWithoutJudgments = lawsuitAllocations.filter((lawsuitAllocation) => {
    return !lawsuitAllocation.judgmentDate;
  });

  const allAccountIds = allocationsWithoutJudgments.map((pair) => pair.originalAccountId);
  const accountIds = Array.from(new Set(allAccountIds));
  const accountIdOptions = accountIds.map((accountId) => {
    const account = lawsuit?.accounts.find((acc) => acc.id === accountId);
    return {
      label: `ID: ${accountId} - (${formatCurrency(account?.paymentCategories?.totalBalance)})`,
      value: accountId,
    };
  });

  const handleSave = async () => {
    const judgmentValues = await form.getFieldsValue();

    const result = await applyAdvancedJudgment({
      ...judgmentValues,
      debtorId,
      lawsuitId: lawsuit.id,
      type: "advanced",
    });
    if ("error" in result) {
      // @ts-ignore
      return;
    }

    message.success("Judgment applied successfully!");
    onOk();
  };

  if (allocationsWithoutJudgments.length === 0) {
    return null;
  }

  if (isLawsuitAllocationsLoading || isLawsuitAllocationsFetching || isSaving) {
    return <Skeleton active />;
  }

  return (
    <StyledForm layout="vertical" form={form} onFinish={handleSave}>
      <Form.Item
        label="Account"
        name={["judgment", "accountAllocations", 0, "accountId"]}
        rules={[{ required: true }]}
      >
        <Select options={accountIdOptions} placeholder="Select Account..." />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.judgment?.accountAllocations?.[0]?.accountId !==
          currentValues.judgment?.accountAllocations?.[0]?.accountId
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const selectedAccount = getFieldValue(["judgment", "accountAllocations", 0, "accountId"]);

          form.setFieldValue(["judgment", "debtorIds"], []);

          const debtorOptions = allocationsWithoutJudgments
            .filter((pair) => pair.originalAccountId.toString() === selectedAccount?.toString())
            .map((pair) => ({
              label: `${pair.debtorId} - ${
                debtors.find((debtor) => debtor.id === pair.debtorId)?.name
              }`,
              value: pair.debtorId,
            }));

          return (
            <Form.Item
              label="Debtor IDs"
              name={["judgment", "debtorIds"]}
              rules={[{ required: true }]}
            >
              <Select
                options={debtorOptions}
                mode="multiple"
                disabled={!selectedAccount}
                placeholder="Debtor IDs"
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        label="Entry Date"
        name={["judgment", "entryDate"]}
        rules={[{ required: true }]}
        tooltip="Judgment grant date."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Expiry Date"
        name={["judgment", "expiryDate"]}
        rules={[{ required: true }]}
        tooltip="Judgment expiry date."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Judgment Interest Calculation Date"
        name={["judgment", "interestStartDate"]}
        rules={[{ required: true }]}
        tooltip="Interest accrual begins on this day."
      >
        <AktDatePicker disabledDate={(current) => current && current > moment().endOf("day")} />
      </Form.Item>
      <Form.Item
        name={["judgment", "interestRate"]}
        label="Judgment Interest Rate"
        rules={[{ required: true }]}
        tooltip="Interest is accumulated as annual APR, not compound interest."
      >
        <AktPercentageInput />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.judgment?.accountAllocations?.[0]?.accountId !==
            currentValues.judgment?.accountAllocations?.[0]?.accountId ||
          prevValues.judgment?.debtorIds !== currentValues.judgment?.debtorIds
        }
      >
        {({ getFieldValue }) => {
          const selectedAccount = getFieldValue(["judgment", "accountAllocations", 0, "accountId"]);
          if (!selectedAccount) return null;

          const account = accounts?.find(
            (acc) => acc.id.toString() === selectedAccount?.toString(),
          );
          if (!account) return null;

          const debtorIds = getFieldValue(["judgment", "debtorIds"]);
          if (!debtorIds?.length) return null;

          const paymentCategories = paymentCategoryPriority.reduce((acc, category) => {
            acc[category] = account?.paymentCategories[category];
            form.setFieldValue(
              ["judgment", "accountAllocations", 0, "paymentCategories", category],
              acc[category],
            );
            return acc;
          }, {});
          return <AccrualAmountSelect paymentCategories={paymentCategories} />;
        }}
      </Form.Item>
      <StyledSpace>
        <Button onClick={onCancel}>Cancel</Button>
        <Button loading={isSaving} disabled={isSaving} onClick={form.submit} type="primary">
          Apply
        </Button>
      </StyledSpace>
    </StyledForm>
  );
}
