import api, { transformRequest, transformResponse } from "services/api";

export const taxPlanApi = api
  .enhanceEndpoints({
    addTagTypes: ["TaxPlan", "TaxPlanRuleGroup", "TaxPlanRule"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchTaxPlans: build.query({
        query: () => "api/v1/core/tax-plans/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "TaxPlan", id })),
                { type: "TaxPlan", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'TaxPlans', id: 'LIST' }` is invalidated
              [{ type: "TaxPlan", id: "LIST" }],
      }),
      addTaxPlan: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/tax-plans/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "TaxPlan", id: "LIST" }],
      }),
      updateTaxPlan: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plans/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "TaxPlan", id: arg.id }],
      }),
      deleteTaxPlan: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plans/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "TaxPlan", id: "LIST" }],
      }),

      fetchTaxPlanRuleGroups: build.query({
        query: () => "api/v1/core/tax-plan-rule-groups/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "TaxPlanRuleGroup", id })),
                { type: "TaxPlanRuleGroup", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'TaxPlanRuleGroup', id: 'LIST' }` is invalidated
              [{ type: "TaxPlanRuleGroup", id: "LIST" }],
      }),
      fetchTaxPlanRuleGroup: build.query({
        query: (payload) => `api/v1/core/tax-plan-rule-groups/${payload.id}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) => [{ type: "TaxPlanRuleGroup", id: result.id }],
      }),
      addTaxPlanRuleGroup: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/tax-plan-rule-groups/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "TaxPlanRuleGroup", id: "LIST" }],
      }),
      updateTaxPlanRuleGroup: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plan-rule-groups/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "TaxPlanRuleGroup", id: arg.id }],
      }),
      deleteTaxPlanRuleGroup: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plan-rule-groups/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "TaxPlanRuleGroup", id: "LIST" }],
      }),

      fetchTaxPlanRules: build.query({
        query: (payload) => `api/v1/core/tax-plan-rule-groups/${payload.ruleGroupId}/rules/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "TaxPlanRule", id })),
                { type: "TaxPlanRule", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'TaxPlanRules', id: 'LIST' }` is invalidated
              [{ type: "TaxPlanRule", id: "LIST" }],
      }),
      addTaxPlanRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plan-rule-groups/${payload.ruleGroupId}/rules/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "TaxPlanRule", id: "LIST" }],
      }),
      updateTaxPlanRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plan-rule-groups/${payload.ruleGroupId}/rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "TaxPlanRule", id: arg.id }],
      }),
      deleteTaxPlanRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/tax-plan-rule-groups/${payload.ruleGroupId}/rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "TaxPlanRule", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useFetchTaxPlansQuery,
  useFetchTaxPlanRuleGroupQuery,
  useFetchTaxPlanRuleGroupsQuery,
  useFetchTaxPlanRulesQuery,
  useAddTaxPlanMutation,
  useAddTaxPlanRuleGroupMutation,
  useAddTaxPlanRuleMutation,
  useUpdateTaxPlanMutation,
  useUpdateTaxPlanRuleGroupMutation,
  useUpdateTaxPlanRuleMutation,
  useDeleteTaxPlanMutation,
  useDeleteTaxPlanRuleGroupMutation,
  useDeleteTaxPlanRuleMutation,
} = taxPlanApi;
