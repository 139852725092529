import { Auth0Provider } from "@auth0/auth0-react";
import config from "app/envConfig";

function LoginProvider({ children }) {
  if (!config.useAuth0) {
    return children;
  }

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/accounts`,
        audience: config.auth0Audience,
        scope: "openid profile email",
      }}
    >
      {children}
    </Auth0Provider>
  );
}

export default LoginProvider;
