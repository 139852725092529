import { Button, Modal } from "antd";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import styled from "styled-components";

const StyledSpan = styled.span`
  margin: 0px 6px;
`;

const StyledDiv = styled.div`
  margin-bottom: 16px;
`;

export default function SelectAddAccountsModeModal({ title, open, onOk, onCancel }) {
  const { data: isAccountBulkActionCreateAuthorized } = useAuthorization(
    PERMISSIONS.ACCOUNT_BULK_ACTION__CREATE,
  );

  const modalFooter = [
    <Button key="cancel" onClick={onCancel}>
      Cancel
    </Button>,
  ];

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={modalFooter}
      width={600}
    >
      <>
        <StyledDiv>What type of bulk action?</StyledDiv>
        {isAccountBulkActionCreateAuthorized && (
          <>
            <Button type="primary" onClick={() => onOk(false)}>
              Account
            </Button>
            <StyledSpan>or</StyledSpan>
            <Button onClick={() => onOk(true)}>Debtor</Button>
          </>
        )}
      </>
    </Modal>
  );
}
