import { Modal, Spin, message } from "antd";
import { snakeToCamelCase } from "common/utils";
import { FormWizard } from "components/formWizard";
import {
  useAddLawsuitMutation,
  useFetchLawsuitsQuery,
  useSubmitLegalCustomFieldValuesMutation,
  useUpdateLawsuitMutation,
} from "features/legal/legalAPI";
import AgencyAttorneyStep from "features/legal/wizardSteps/attorneyStep";
import CourtStep from "features/legal/wizardSteps/courtStep";
import LawsuitDateStep from "features/legal/wizardSteps/lawsuitDateStep";
import LegalCustomFieldsStep from "features/legal/wizardSteps/legalCustomFieldsStep";
import { useFetchAllLegalCustomFieldsQuery } from "features/userDefinedFields/legalCustomFieldsAPI";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

function AddLawsuitModal({ open, lawsuit, onOk, onCancel }) {
  const { debtorId } = useParams();
  const [updateLawsuit] = useUpdateLawsuitMutation();
  const [createLawsuit] = useAddLawsuitMutation();
  const [submitLegalCustomFieldValues] = useSubmitLegalCustomFieldValuesMutation();
  const { data: legalCustomFields, isLoading: customFieldsLoading } =
    useFetchAllLegalCustomFieldsQuery();
  const { data: lawsuits, isLoading: lawsuitsLoading } = useFetchLawsuitsQuery({ debtorId });
  const [createdLawSuitId, setCreatedLawsuitId] = useState(null);

  const OnDoneLegalStep = async (data) => {
    const dataCopy = { ...data };
    if (lawsuit?.id || createdLawSuitId) {
      // Do update
      const result = await updateLawsuit({
        ...dataCopy,
        debtorId,
        lawsuitId: lawsuit?.id ?? createdLawSuitId,
      });
      return result;
    }

    // Do create
    const result = await createLawsuit({ ...dataCopy, debtorId });
    if ("data" in result) {
      setCreatedLawsuitId(result.data.id);
    }
    return result;
  };

  const onDoneLegalCustomFieldsStep = async (data) => {
    const result = await submitLegalCustomFieldValues({
      debtorId,
      lawsuitId: lawsuit?.id ?? createdLawSuitId,
      ...data.letterVariables,
    });
    if ("error" in result) {
      return false;
    }
    if ("data" in result) {
      message.success("Lawsuit saved!");
    }
    setCreatedLawsuitId(null);
  };

  const legalSteps = [
    {
      title: "Basic Lawsuit Information",
      form: <AgencyAttorneyStep lawsuitId={lawsuit?.id || createdLawSuitId} />,
      onNext: async (data) => OnDoneLegalStep(data),
    },
    {
      title: "Lawsuit Dates",
      form: <LawsuitDateStep lawsuitId={lawsuit?.id || createdLawSuitId} />,
      onNext: async (data) => OnDoneLegalStep(data),
    },
    {
      title: "Court Information",
      form: <CourtStep />,
      onNext: async (data) => OnDoneLegalStep(data),
    },
    {
      title: "Legal Custom Fields",
      form: <LegalCustomFieldsStep />,
      onNext: async (data) => onDoneLegalCustomFieldsStep(data),
    },
  ];

  const initialValues = useMemo(() => {
    if (lawsuit?.id || createdLawSuitId) {
      const selectedLawsuit = lawsuits?.find((x) => x.id === lawsuit?.id);
      if (selectedLawsuit) {
        const initialLegalCustomFields = {};
        legalCustomFields?.forEach((field) => {
          const slug = snakeToCamelCase(field.slug);
          initialLegalCustomFields[slug] = selectedLawsuit.customFieldValues[slug];
        });

        return {
          attorney: selectedLawsuit,
          court: selectedLawsuit,
          letterVariables: initialLegalCustomFields,
        };
      }
    } else {
      return null;
    }
  }, [lawsuit, createdLawSuitId, lawsuits, legalCustomFields]);

  const isInitialValuesDepsLoading = lawsuitsLoading || customFieldsLoading;

  const onSubmit = async () => {
    await onOk();
  };

  const onModalCancel = async () => {
    await onCancel();
  };

  return open ? (
    <Modal
      maskClosable={false}
      title={lawsuit?.id ? "Edit Lawsuit" : "Add Lawsuit"}
      open={open}
      onOk={onSubmit}
      onCancel={onModalCancel}
      width={1000}
      footer={null}
    >
      <Spin spinning={isInitialValuesDepsLoading}>
        <FormWizard
          // passing in different `initialValues` after first render of a form will not have any effect.
          // so we trigger a re-render by changing the `key` depending on the loading state of the dependencies.
          key={isInitialValuesDepsLoading}
          canSkipSteps={!!lawsuit}
          steps={legalSteps}
          onCancel={onCancel}
          onDone={onOk}
          initialValues={initialValues}
        />
      </Spin>
    </Modal>
  ) : null;
}
export default AddLawsuitModal;
