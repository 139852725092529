import { Input, Form, Modal, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  useAddDisputeCategoryTagMutation,
  useUpdateDisputeCategoryTagMutation,
} from "features/tags/tagsAPI";

export default function AddDisputeCategoryTagModal({
  title,
  open,
  onOk,
  onCancel,
  disputeCategoryTag,
}) {
  const [form] = Form.useForm();
  const [addDisputeCategoryTag] = useAddDisputeCategoryTagMutation();
  const [updateDisputeCategoryTag] = useUpdateDisputeCategoryTagMutation();

  const initialValues = {
    ...disputeCategoryTag,
  };

  const onSubmit = async () => {
    const action = disputeCategoryTag ? updateDisputeCategoryTag : addDisputeCategoryTag;
    const formData = await form.validateFields();

    const result = await action({ ...formData });
    if ("data" in result) {
      const successMessage = disputeCategoryTag
        ? "Dispute Category tag updated successfully."
        : "Dispute Category tag added successfully.";
      message.success(successMessage);
      onOk();
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 14 },
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      okText="Submit"
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form
        form={form}
        {...formItemLayout}
        labelAlign="left"
        initialValues={initialValues}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="notes" label="Notes / Description">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
