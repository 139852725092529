import api, { transformRequest, transformResponse } from "services/api";

export const productOfferingsApi = api
  .enhanceEndpoints({ addTagTypes: ["ProductOfferings"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchProductOfferings: build.query({
        query: () => "/api/v1/core/product-offerings/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "ProductOfferings", id })),
                { type: "ProductOfferings", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ProductOfferings', id: 'LIST' }` is invalidated
              [{ type: "ProductOfferings", id: "LIST" }],
      }),
      fetchCreditorPortalProductOfferings: build.query({
        query: () => ({
          url: "/api/v1/creditor/product-offerings/",
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "ProductOfferings", id })),
                { type: "ProductOfferings", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ProductOfferings', id: 'LIST' }` is invalidated
              [{ type: "ProductOfferings", id: "LIST" }],
      }),
      addProductOffering: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/product-offerings/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all ProductOfferings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "ProductOfferings", id: "LIST" }],
      }),
      updateProductOffering: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/product-offerings/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all ProductOfferings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "ProductOfferings", id: "LIST" }],
      }),
      deleteProductOffering: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/product-offerings/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all ProductOfferings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: (result, error, arg) => [
          { type: "ProductOfferings", id: arg.id },
          { type: "ProductOfferings", id: "LIST" },
        ],
      }),
    }),
  });

export const {
  useFetchProductOfferingsQuery,
  useFetchCreditorPortalProductOfferingsQuery,
  useAddProductOfferingMutation,
  useUpdateProductOfferingMutation,
  useDeleteProductOfferingMutation,
} = productOfferingsApi;
