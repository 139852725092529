import { FileTextOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Table, Tooltip, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import Dragger from "antd/lib/upload/Dragger";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useCreateLetterTemplateMutation,
  useFetchLetterTemplateVariablesQuery,
} from "features/letterTemplates/letterTemplatesAPI";
import { useRef, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

const StyledDragger = styled(Dragger)`
  margin: 12px 0;
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const StyledFileTextOutlined = styled(FileTextOutlined)`
  cursor: pointer;
  color: #1890ff;
  margin-left: 6px;
`;

const initialFileList = [];

export default function AddLetterTemplateModal({
  onOk,
  onCancel,
  open,
  getColumnSearchProps,
  title = "Add Letter Template",
}) {
  const [form] = useForm();
  const [isTemplateVariablesModalOpen, setIsTemplateVariablesModalOpen] = useState(false);
  const [fileList, setFileList] = useState(initialFileList);
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createLetterTemplate] = useCreateLetterTemplateMutation();
  const { data: letterTemplateVariables } = useFetchLetterTemplateVariablesQuery();
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    const result = await createLetterTemplate(values);
    if ("data" in result) {
      await onOk();
      form.resetFields();
      setFileList(initialFileList);
      return message.success("Letter template added successfully!");
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const draggerProps = {
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    accept: ".doc,.docx",
  };

  const normFile = ({ file }) => {
    return file.originFileObj ?? file;
  };

  const handleCancelTemplateVariablesList = () => {
    setIsTemplateVariablesModalOpen(false);
  };
  return (
    <>
      <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onCancel}>
        <StyledForm layout="vertical" form={form} name="addCost">
          <Form.Item
            name="filename"
            label="File Name"
            rules={[{ required: true, message: "Missing file name" }]}
          >
            <Input placeholder="Enter File Name..." />
          </Form.Item>
          <Form.Item
            name="type"
            label={
              <Tooltip title="See Template Variables">
                Document Type
                <StyledFileTextOutlined onClick={() => setIsTemplateVariablesModalOpen(true)} />
              </Tooltip>
            }
            rules={[{ required: true, message: "Missing document type" }]}
          >
            <Select
              placeholder="Please select one"
              options={constants?.documentTypes.map((documentType) => ({
                label: documentType.display,
                value: documentType.value,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="file"
            rules={[{ required: true, message: "Missing file" }]}
            getValueFromEvent={normFile}
          >
            <StyledDragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </StyledDragger>
          </Form.Item>
        </StyledForm>
      </Modal>
      {isTemplateVariablesModalOpen && (
        <Modal
          title="Template Variables"
          open={isTemplateVariablesModalOpen}
          footer={null}
          onCancel={handleCancelTemplateVariablesList}
        >
          <StyledTable
            bordered
            dataSource={letterTemplateVariables}
            columns={[
              // @ts-ignore
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
                ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
              },
            ]}
          />
        </Modal>
      )}
    </>
  );
}
