import { DeleteOutlined, FileTextOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Table, Tooltip } from "antd";
import { useRef, useState } from "react";
import styled from "styled-components";

import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import AddLetterTemplateModal from "features/letterTemplates/components/addLetterTemplateModal";
import {
  useDeleteLetterTemplateMutation,
  useFetchLetterTemplatesQuery,
} from "features/letterTemplates/letterTemplatesAPI";
import { getColumnSearchProps } from "common/antd-utils";

const StyledTable = styled(Table)`
  cursor: pointer;
`;

const StyledFileTextOutlined = styled(FileTextOutlined)`
  cursor: pointer;
  color: #1890ff;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)``;

function LetterTemplates() {
  const [showModal, setShowModal] = useState(false);
  const { data: letterTemplates, isLoading } = useFetchLetterTemplatesQuery();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [deleteLetterTemplate] = useDeleteLetterTemplateMutation();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Template Name",
      dataIndex: "filename",
      key: "filename",
      ...getColumnSearchProps("filename", searchInput, handleSearch, handleReset),
    },
    {
      title: "Document Type",
      dataIndex: "type",
      key: "type",
      filters: constants?.documentTypes.map((documentType) => ({
        text: documentType.display,
        value: documentType.value,
      })),
      onFilter: (value, record) => record.type.startsWith(value),
      filterSearch: true,
      render: (text, record) =>
        constants?.documentTypes.find((documentType) => record.type === documentType.value)
          ?.display,
    },
    {
      title: "Uploaded File Name",
      dataIndex: "originalFilename",
      key: "originalFilename",
      render: (text, record) => {
        return (
          <a href={`${record.file}`} target="_blank" rel="noreferrer">
            {record.originalFilename}
          </a>
        );
      },
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Tooltip placement="bottom" title="Delete Template">
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this template?"
              onCancel={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onConfirm={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteLetterTemplate({ id: record.id });
              }}
            >
              <StyledDeleteOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Row align="middle">
        <h4>Letter Templates</h4>
        <Button onClick={() => setShowModal(true)} icon={<PlusOutlined />} type="link">
          Add Letter Template
        </Button>
      </Row>
      {/* @ts-ignore */}
      <StyledTable bordered loading={isLoading} columns={columns} dataSource={letterTemplates} />
      <AddLetterTemplateModal
        getColumnSearchProps={getColumnSearchProps}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      />
    </>
  );
}

export default LetterTemplates;
