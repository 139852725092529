import { downloadFile } from "common/utils";
import api, { transformMultipart, transformRequest, transformResponse } from "services/api";

export const letterTemplatesApi = api
  .enhanceEndpoints({ addTagTypes: ["LetterTemplates", "InterpolatedLetters"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchInterpolatedLetters: build.query({
        query: () => "/api/v1/core/letter-templates/interpolated-letters/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "InterpolatedLetters", id })),
                { type: "InterpolatedLetters", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'InterpolatedLetters', id: 'LIST' }` is invalidated
              [{ type: "InterpolatedLetters", id: "LIST" }],
      }),
      fetchLetterTemplates: build.query({
        query: () => "/api/v1/core/letter-templates/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "LetterTemplates", id })),
                { type: "LetterTemplates", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'LetterTemplates', id: 'LIST' }` is invalidated
              [
                { type: "LetterTemplates", id: "LIST" },
                { type: "InterpolatedLetters", id: "LIST" },
              ],
      }),
      fetchLetterTemplateVariables: build.query({
        query: () => "/api/v1/core/templates/variables/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "LetterTemplateVariables", id })),
                { type: "LetterTemplateVariables", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'LetterTemplateVariables', id: 'LIST' }` is invalidated
              [{ type: "LetterTemplateVariables", id: "LIST" }],
      }),
      createLetterTemplate: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/letter-templates/create/",
          method: "POST",
          body: transformMultipart(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all LetterTemplates-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [{ type: "LetterTemplates", id: "LIST" }],
      }),
      // On the BE it's archiving the letter template due to associations with InterpolatedLetters. On the FE we act as if it's deleted
      deleteLetterTemplate: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/letter-templates/${payload.id}/archive/`,
          method: "POST",
        }),
        // Invalidates all LetterTemplates-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [{ type: "LetterTemplates", id: "LIST" }],
      }),
      scheduleLetterSend: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/schedule-letter-send/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all InterpolatedLetters-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [{ type: "InterpolatedLetters", id: "LIST" }],
      }),
      downloadLetters: build.query({
        query: (payload) => ({
          url: `/api/v1/core/letter-templates/download/`,
          method: "POST",
          body: transformRequest(payload),
          responseHandler: (response) => response.blob(),
        }),
        transformResponse: downloadFile,
      }),
      downloadLegalLetters: build.query({
        query: ({ debtorId, lawsuitId, data }) => ({
          url: `/api/v1/core/debtors/${debtorId}/lawsuits/${lawsuitId}/download-letters/`,
          method: "POST",
          body: transformRequest(data),
          responseHandler: (response) => response.blob(),
        }),
        transformResponse: downloadFile,
      }),
    }),
    overrideExisting: false,
  });

export const {
  useDeleteLetterTemplateMutation,
  useFetchLetterTemplatesQuery,
  useFetchLetterTemplateVariablesQuery,
  useCreateLetterTemplateMutation,
  useScheduleLetterSendMutation,
  useFetchInterpolatedLettersQuery,
  useLazyDownloadLettersQuery,
  useLazyDownloadLegalLettersQuery,
} = letterTemplatesApi;
