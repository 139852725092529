import { useFetchMeQuery } from "features/auth/authAPI";
import { hasFeatureFlag } from "features/permissions/hooks/useFeatureFlag";
import { useFetchFeaturesQuery } from "features/permissions/permissionsAPI";
import { useEffect, useState } from "react";

/*
// usage example

import useFeatureFlag from 'features/permissions/useFeatureFlag';

const PrivateComponent = () => {
  const { data: [hasBulkActionAsyncFeature, hasBulkActionAsyncFeatureBulk] } = useFeatureFlags(["account_bulk_action_async", "account_bulk_action_async__bulk"]);

  return hasBulkActionAsyncFeature ? (
    <h1>Account Bulk Action Async</h1>
  ) : (
    <p>403</p>
  );
};
*/

const useFeatureFlag = (featureKeys = []) => {
  const [hasFeatures, setHasFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isSuccess: isFeatureSuccess,
    isError: isFeatureError,
  } = useFetchFeaturesQuery();

  const {
    data: me,
    isLoading: isLoadingMe,
    isSuccess: isMeSuccess,
    isError: isMeError,
  } = useFetchMeQuery();

  useEffect(() => {
    if (isMeSuccess && isFeatureSuccess) {
      const isFeaturesEnabled = featureKeys.map((featureKey) =>
        hasFeatureFlag(featureKey, features),
      );

      // Set the state based on your feature check
      setHasFeatures(isFeaturesEnabled);
      setIsLoading(false);
    } else if (isMeError || isFeatureError) {
      setHasFeatures([]);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, isMeSuccess, isMeError, isFeatureSuccess, isFeatureError, features]);

  return {
    data: hasFeatures,
    isLoading: isLoadingMe || isLoading || isLoadingFeatures,
    isError: isMeError || isFeatureError,
  };
};

export default useFeatureFlag;
