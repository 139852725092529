import api, { transformRequest, transformResponse } from "services/api";

export const udfApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "AccountUdfCustomField",
      "AccountUdfCustomFieldDromoSchema",
      "CreditorAssignedAccontCustomFields",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllAccountUdfCustomFields: build.query({
        query: () => `/api/v1/common/account-custom-fields/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "AccountUdfCustomField", id })),
                { type: "AccountUdfCustomField", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AccountUdfCustomField', id: 'LIST' }` is invalidated
              [{ type: "AccountUdfCustomField", id: "LIST" }],
      }),
      fetchAllAccountUdfCustomFieldsDromoSchema: build.query({
        query: () => `/api/v1/common/account-custom-fields/dromo-schema/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [{ type: "AccountUdfCustomFieldDromoSchema", id: "LIST" }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AccountUdfCustomFieldDromoSchema', id: 'LIST' }` is invalidated
              [{ type: "AccountUdfCustomFieldDromoSchema", id: "LIST" }],
      }),
      fetchAllAccountUdfCustomFieldsAssignments: build.query({
        query: (payload) => ({
          url: `/api/v1/common/account-custom-fields/assignments/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (_results, _error, payload) => {
          return [
            { type: "AccountUdfCustomField", id: "LIST" },
            ...(payload?.creditorIds ?? []).map((id) => ({
              // This result needs to be invalidated when a creditor is updated (assigned different custom fields)
              type: "CreditorAssignedAccontCustomFields",
              id,
            })),
          ];
        },
      }),
    }),
  });

export const {
  useFetchAllAccountUdfCustomFieldsQuery,
  useFetchAllAccountUdfCustomFieldsDromoSchemaQuery,
  useFetchAllAccountUdfCustomFieldsAssignmentsQuery,
} = udfApi;
