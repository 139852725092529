import { PageHeader } from "components/pageHeader";
import { DataImport } from "features/dataImport";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

export default function DataImportPage() {
  return (
    <PageLayout>
      <PageHeader>Data Import</PageHeader>
      <Content>
        <DataImport />
      </Content>
    </PageLayout>
  );
}
