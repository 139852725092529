import { Popconfirm, Table, Tabs, Tag, message } from "antd";
import currency from "currency.js";
import { useCallback, useMemo } from "react";
import styled from "styled-components";

import { DeleteOutlined } from "@ant-design/icons";
import { formatCurrency, formatDate } from "common/utils";
import {
  useDeleteInvoicePaymentMutation,
  useFetchInvoicePaymentsQuery,
  useFetchInvoicesQuery,
} from "features/invoices/invoiceAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";

const StyledTag = styled(Tag)`
  margin: 4px 4px;
  text-transform: uppercase;
`;

const StyledTable = styled(Table)`
  white-space: pre;
  cursor: pointer;
  .invoice-noclick {
    pointer-events: none;
  }

  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }
`;

export default function CreditorInvoiceTables({ currentCreditor }) {
  const { data: isInvoiceGetAuthorized } = useAuthorization(PERMISSIONS.INVOICE__GET);

  const items = [
    {
      label: "Invoices",
      key: "invoices",
      children: <MainCreditorInvoiceTable currentCreditor={currentCreditor} />,
      hidden: !isInvoiceGetAuthorized,
    },
    {
      label: "Invoice Payment Logs",
      key: "invoicePaymentLogs",
      children: <CreditorInvoicePaymentLogsTable currentCreditor={currentCreditor} />,
    },
  ].filter((item) => !item.hidden);

  return <Tabs items={items} defaultActiveKey="invoices" />;
}

function MainCreditorInvoiceTable({ currentCreditor }) {
  const creditorId = currentCreditor?.id;
  const { data: { results: invoices } = { results: [] }, isLoading } = useFetchInvoicesQuery({
    creditorId,
  });
  const { data: isInvoiceGetAuthorized } = useAuthorization(PERMISSIONS.INVOICE__GET);

  const gotoViewInvoiceDetails = (record) => {
    const url = `/invoices/${record.id}`;

    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const columns = useMemo(
    () => [
      {
        title: "Invoice ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Invoice Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.name || `Invoice ${record.id}`,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <>
            {record.status?.toLowerCase() === "published" && (
              <StyledTag color="yellow">published</StyledTag>
            )}
            {record.status?.toLowerCase() === "delivered" && (
              <StyledTag color="yellow">delivered</StyledTag>
            )}
            {record.status?.toLowerCase() === "draft" && <StyledTag>draft</StyledTag>}
            {record.status?.toLowerCase() === "paid_in_full" && (
              <StyledTag color="green">Paid</StyledTag>
            )}
          </>
        ),
        filters: [
          {
            text: "Delivered",
            value: "delivered",
          },
          {
            text: "Draft",
            value: "draft",
          },
          {
            text: "Published",
            value: "published",
          },
          {
            text: "Paid in Full",
            value: "paid_in_full",
          },
        ],
        onFilter: (value, record) => record.status?.indexOf(value) === 0,
      },
      {
        title: "Client Name",
        dataIndex: "creditorId",
        key: "creditorId",
        render: () => currentCreditor?.name,
      },
      {
        title: "Client Code",
        dataIndex: "creditorId",
        key: "creditorId",
        render: () => currentCreditor?.code || "-",
      },
      {
        title: "Date Range",
        dataIndex: "startDate",
        key: "startDate",
        render: (text, record) => (
          <>
            {formatDate(record.startDate)} - {formatDate(record.endDate)}
          </>
        ),
        filters: invoices
          ?.map((each) => ({
            text: `${formatDate(each.startDate)} - ${formatDate(each.endDate)}`,
            value: `${formatDate(each.startDate)} - ${formatDate(each.endDate)}`,
          }))
          .filter((current, index, array) => {
            // remove duplicate date ranges
            return (
              index ===
              array.findIndex((item) => item.text === current.text && item.value === current.value)
            );
          }),
        onFilter: (value, record) =>
          value.toLowerCase() ===
          `${formatDate(record.startDate)} - ${formatDate(record.endDate)}`.toLowerCase(),
      },
      {
        title: "Paid ($)",
        dataIndex: "amountCreditorPaid",
        key: "amountCreditorPaid",
        render: (text, record) => formatCurrency(record.amountCreditorPaid),
      },
      {
        title: "Remaining Balance ($)",
        key: "totalAmountCreditorOwes",
        render: (text, record) =>
          currency(record.summaryTotalAmountDueToAgency, { precision: 2 })
            .subtract(record.amountCreditorPaid)
            .format(),
      },
      {
        title: `Amount ${currentCreditor?.name ?? "Client"}\nOwes Us ($)`,
        align: "center",
        dataIndex: "summaryTotalAmountDueToAgency",
        key: "summaryTotalAmountDueToAgency",
        render: (text, record) => formatCurrency(record.summaryTotalAmountDueToAgency),
      },
      {
        title: `Amount We\nOwe\n${currentCreditor?.name ?? "Client"} ($)`,
        align: "center",
        dataIndex: "summaryTotalAmountDueToCreditor",
        key: "summaryTotalAmountDueToCreditor",
        render: (text, record) => formatCurrency(record.summaryTotalAmountDueToCreditor),
      },
      {
        title: "Payment Type",
        align: "center",
        dataIndex: "creditorPaymentMethodType",
        key: "creditorPaymentMethodType",
      },
      {
        title: "ACH / Check Number",
        dataIndex: "creditorPaymentReferenceNumber",
        key: "creditorPaymentReferenceNumber",
      },
      {
        title: "Notes",
        className: "invoice-noclick",
        dataIndex: "notes",
        key: "notes",
      },
    ],
    [invoices, currentCreditor],
  );

  return isInvoiceGetAuthorized ? (
    <StyledTable
      pagination={{ pageSize: 100 }}
      loading={isLoading}
      bordered
      scroll={{ x: "max-content" }}
      // @ts-ignore
      columns={columns}
      dataSource={invoices}
      onRow={(record) => ({
        onClick: (e) => {
          // allow users to select text so it does not trigger a click into the row
          const selection = window.getSelection().toString();
          // do not navigate if the user is selecting text or if the bulk action in progress
          if (selection.length <= 0 && !isLoading) {
            gotoViewInvoiceDetails(record);
          }
        },
      })}
    />
  ) : null;
}

function CreditorInvoicePaymentLogsTable({ currentCreditor }) {
  const creditorId = currentCreditor?.id;
  const { data: invoices, isLoading } = useFetchInvoicePaymentsQuery({ creditorId });
  const [handleDeleteInvoicePayment] = useDeleteInvoicePaymentMutation();
  const { data: isDebtorTagDeleteAuthorized } = useAuthorization(PERMISSIONS.INVOICE__DELETE);

  const handleDelete = useCallback(
    async (record) => {
      const result = await handleDeleteInvoicePayment({ id: record.id });
      if ("data" in result) {
        message.success("Invoice payment removed successfully!");
      }
    },
    [handleDeleteInvoicePayment],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: "Effective Date",
          dataIndex: "effectiveDate",
          key: "effectiveDate",
          render: (text, record) => formatDate(record.effectiveDate),
        },
        {
          title: "Invoice Name",
          dataIndex: "invoiceName",
          key: "invoiceName",
          render: (text, record) => record.name || `Invoice ${record.id}`,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (text, record) => formatCurrency(record.amount),
        },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "notes",
          render: (text, record) => record.notes,
        },
        {
          title: "Actions",
          fixed: "right",
          dataIndex: "action",
          key: "action",
          hidden: !isDebtorTagDeleteAuthorized,
          render: (_, record) => (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this invoice payment?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          ),
        },
      ].filter((column) => !column.hidden),
    [handleDelete, isDebtorTagDeleteAuthorized],
  );

  return (
    <StyledTable
      pagination={{ pageSize: 100 }}
      loading={isLoading}
      bordered
      scroll={{ x: "max-content" }}
      // @ts-ignore
      columns={columns}
      dataSource={invoices}
    />
  );
}
