import { CourtsOfLawTable } from "features/courtsOfLawTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function LawFirmsPage() {
  return (
    <PageLayout>
      <Content>
        <CourtsOfLawTable />
      </Content>
    </PageLayout>
  );
}

export default LawFirmsPage;
