import { useFetchMeQuery } from "features/auth/authAPI";
import { FEATURE_FLAGS } from "features/permissions/constants";
import { hasFeatureFlag } from "features/permissions/hooks/useFeatureFlag";
import {
  useFetchFeaturesQuery,
  useFetchMyPermissionsSummaryQuery,
} from "features/permissions/permissionsAPI";
import { useEffect, useState } from "react";

/*
// usage example

import useAuthorization from './useAuthorization';

const PrivateComponent = () => {
  const { data: isAuthorized } = useAuthorization('payment.create');

  return isAuthorized ? (
    <h1>Admin Panel</h1>
  ) : (
    <p>You are not authorized to access this page.</p>
  );
};
*/

/**
 * Checks if a user has a specific permission.
 *
 * @param {string} permission - The permission to check.
 * @param {Object} permissionSummary - An object that maps permissions to their status ("*" if granted).
 * @param {Array} features - An array of feature objects. Each feature object should have a 'name' property (string) and an 'isEnabled' property (boolean).
 * @param {Object} me - The user object. Should have an 'isAdmin' property (boolean) that indicates if the user is an admin.
 *
 * @returns {boolean} Returns true if the user has the specified permission, false otherwise. If the user is an admin, the function always returns true.
 *
 * @example
 * const permission = 'account.no_limit.create';
 * const permissionSummary = { 'account.no_limit.create': "*" };
 * const features = [{ name: 'account', isEnabled: true }, { name: 'account.no_limit', isEnabled: true }];
 * const me = { isAdmin: false, ...otherProps };
 * console.log(hasPermission(permission, permissionSummary, features, me)); // true
 */
export const hasPermission = (permission, permissionSummary, features, me) => {
  // admin has all permissions
  if (me.isAdmin) {
    return true;
  }

  // check if permission has been granted in the summary
  if (!permissionSummary[permission]) {
    return false;
  }

  // get the substring of the permission until the last dot, ie `account.no_limit.create` => `account.no_limit`
  const featureName = permission.substring(0, permission.lastIndexOf("."));

  // if it's not an additional feature, we don't need to check for feature presence
  if (!Object.values(FEATURE_FLAGS).includes(featureName)) {
    return true;
  }

  // check if the feature is enabled
  return hasFeatureFlag(featureName, features);
};

const useAuthorization = (permission) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    data: permissionSummary,
    isLoading: isLoadingPermissionSummary,
    isSuccess: isPermissionSuccess,
    isError: isPermissionError,
  } = useFetchMyPermissionsSummaryQuery();

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isSuccess: isFeatureSuccess,
    isError: isFeatureError,
  } = useFetchFeaturesQuery();

  const {
    data: me,
    isLoading: isLoadingMe,
    isSuccess: isMeSuccess,
    isError: isMeError,
  } = useFetchMeQuery();

  useEffect(() => {
    if (isMeSuccess && permission && isPermissionSuccess && isFeatureSuccess) {
      // Set the state based on your permission check
      setIsAuthorized(hasPermission(permission, permissionSummary, features, me));
      setIsLoading(false);
    } else if (isPermissionError || isMeError || isFeatureError) {
      setIsAuthorized(false);
      setIsLoading(false);
    }
  }, [
    permissionSummary,
    permission,
    me,
    isMeSuccess,
    isPermissionSuccess,
    isPermissionError,
    isMeError,
    isFeatureSuccess,
    isFeatureError,
    features,
  ]);

  return {
    data: isAuthorized,
    isLoading: isLoadingPermissionSummary || isLoadingMe || isLoading || isLoadingFeatures,
    isError: isPermissionError || isMeError || isFeatureError,
  };
};

export default useAuthorization;
