import { AutoComplete, Col, Input, Row, Space, message } from "antd";
import { useLazySearchDebtorsQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { collectorFullName } from "features/home/utils";
import { useState } from "react";
import styled from "styled-components";

const StyledSpace = styled(Space.Compact)`
  width: 100%;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

function DebtorSelector({ onSelectDebtor, selectedDebtors = [] }) {
  const [inputValue, setInputValue] = useState("");
  const [searchDebtors, { data: debtorSearchResults }] = useLazySearchDebtorsQuery();

  const selectedDebtorIds = selectedDebtors.map((debtor) => debtor.id) ?? [];

  const handleOnAdd = (id) => {
    try {
      const debtor = debtorSearchResults?.find((d) => d.id === id);
      onSelectDebtor(debtor);
      setInputValue("");
    } catch (error) {
      message.error("Failed to add debtor");
    }
  };

  const onSearch = (query = "") => {
    if (query.trim().length > 0) {
      const payload = { query, includeArchivedOrNoAccountDebtors: true };
      searchDebtors(payload);
    }
  };

  const renderItem = (debtor) => ({
    value: debtor.id,
    label: (
      <Row>
        <Space>
          <Col>{collectorFullName(debtor)}</Col>
          <Col>
            Debtor ID: {debtor.id}, Account IDs: [{debtor.accountIds.join(", ")}]
          </Col>
        </Space>
      </Row>
    ),
    disabled: selectedDebtorIds.includes(debtor.id),
  });

  const options = debtorSearchResults?.map(renderItem) ?? [];

  return (
    <StyledSpace>
      <StyledAutoComplete
        popupMatchSelectWidth={false}
        options={options}
        onSelect={handleOnAdd}
        onSearch={onSearch}
        allowClear
        value={inputValue}
        onChange={setInputValue}
      >
        <Input
          size="large"
          title="Search: Enter debtor ID, SSN, name, or account #..."
          placeholder="Search: Enter debtor ID, SSN, name, or account #..."
        />
      </StyledAutoComplete>
    </StyledSpace>
  );
}

export default DebtorSelector;
