import { PageHeader } from "components/pageHeader";
import { MetabaseReportsTable } from "features/metabase";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function ReportsPage() {
  return (
    <PageLayout>
      <Content>
        <PageHeader>Reports</PageHeader>
        <MetabaseReportsTable />
      </Content>
    </PageLayout>
  );
}

export default ReportsPage;
