import { Layout } from "antd";
import styled from "styled-components";

function Content({ children }) {
  return <StyledContent>{children}</StyledContent>;
}

const StyledContent = styled(Layout.Content)`
  background: #f0f2f5;
`;

export default Content;
