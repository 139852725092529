import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Select, Space, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToWords, snakeToCamelCase } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { DisplayComponent } from "components/formItems";
import { useUserType } from "features/auth";
import {
  useCreateDebtorEmploymentsMutation,
  useDeleteDebtorEmploymentMutation,
  useUpdateDebtorEmploymentsMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Assign to undefined to make it think it's optional
export function DebtorEmploymentInfoCard({
  employment = undefined,
  isNew = undefined,
  onCreate = undefined,
  onCancel = undefined,
  title = undefined,
}) {
  const { isAgencyUserType } = useUserType();
  const [form] = Form.useForm();
  const { debtorId } = useParams();
  const [isEditing, setIsEditing] = useState(isNew || false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createDebtorEmployment, { isLoading: isCreateLoading }] =
    useCreateDebtorEmploymentsMutation();
  const [updateDebtorEmployment, { isLoading: isUpdateLoading }] =
    useUpdateDebtorEmploymentsMutation();
  const [deleteDebtorEmployment, { isLoading: isDeleteLoading }] =
    useDeleteDebtorEmploymentMutation();

  const {
    data: {
      [PERMISSIONS.DEBTOR_EMPLOYMENT__CREATE]: isAddDebtorEmploymentAuthorized,
      [PERMISSIONS.DEBTOR_EMPLOYMENT__UPDATE]: isUpdateDebtorEmploymentAuthorized,
      [PERMISSIONS.DEBTOR_EMPLOYMENT__DELETE]: isDeleteDebtorEmploymentAuthorized,
    },
  } = useAuthorizations();

  const editableDataSourceTypeValues = constants?.editableDataSourceTypes?.map(
    ({ value }) => value,
  );

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const deleteEmployment = async () => {
    const result = await deleteDebtorEmployment({ debtorId, employmentId: employment.id });
    if ("data" in result) {
      message.success("Employment deleted successfully!");
    }
  };

  const toggleIsEditing = async () => {
    if (isEditing) {
      let values;
      try {
        values = await form.validateFields();
      } catch (e) {
        return null; // Form will handle error messages
      }

      const action = isNew
        ? createDebtorEmployment({
            debtorId,
            ...values,
          })
        : updateDebtorEmployment({
            debtorId,
            employmentId: employment.id,
            ...values,
          });
      const result = await action;

      if ("data" in result) {
        message.success("Saved successfully!");
        if (isNew) onCreate();
      }

      if ("error" in result) {
        form.setFields(formatError(result.error));
        message.error("Could not update employment");
        return;
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
      form.setFieldsValue(employment);
    }
  };

  const onPrimaryCancel = () => {
    setIsEditing(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 12 },
    },
  };

  return (
    <StyledCard>
      <StyledHeader>
        <h4>{isNew ? "New Employment" : title}</h4>
        {!isEditing &&
          isAgencyUserType &&
          (isUpdateDebtorEmploymentAuthorized || isDeleteDebtorEmploymentAuthorized) && (
            <Row>
              {isUpdateDebtorEmploymentAuthorized && (
                <Button type="link" icon={<EditOutlined />} onClick={toggleIsEditing} />
              )}
              {isDeleteDebtorEmploymentAuthorized && (
                <Button
                  loading={isDeleteLoading}
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={deleteEmployment}
                />
              )}
            </Row>
          )}
      </StyledHeader>

      <Form labelAlign="left" form={form} {...formItemLayout} initialValues={{ dataSource: "" }}>
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Company Name"
          name="companyName"
          rules={[{ required: true, message: "Name is required" }]}
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Job Title"
          name="jobTitle"
          rules={[{ required: true, message: "Job Title is required" }]}
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Salary"
          name="salary"
          rules={[{ required: true, message: "Salary is required" }]}
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Address Line 1"
          name="companyAddress1"
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Address Line 2"
          name="companyAddress2"
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="City"
          name="companyAddressCity"
          rules={[{ pattern: /^[^\d]*$/, message: "Numbers not allowed here" }]}
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="State"
          name="companyAddressState"
          rules={[{ required: true, message: "State is required" }]}
        >
          <Select
            showSearch
            filterOption={filterOption}
            options={constants?.states.map(({ display, value }) => ({
              value,
              label: display,
            }))}
          />
        </DisplayComponent>
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Zip Code"
          name="companyAddressZipCode"
          rules={[{ pattern: /^\d{5}(-\d{4})?$/, message: "Must be a valid zip code" }]}
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Company Phone Number"
          name="companyPhoneNumber"
          placeholder="XXX-XXX-XXXX"
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Employment Start Date"
          name="startDate"
          rules={[{ required: true, message: "Start Date is required" }]}
          isDate
        >
          <AktDatePicker />
        </DisplayComponent>
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Employment End Date"
          name="endDate"
          isDate
        >
          <AktDatePicker />
        </DisplayComponent>
        <DisplayComponent
          isEditing={isEditing}
          slice={employment}
          label="Employment Type"
          name="employmentType"
        >
          <Select
            options={constants?.employmentTypes.map(({ display, value }) => ({
              value,
              label: display,
            }))}
          />
        </DisplayComponent>
        {isEditing && (
          <>
            <DisplayComponent isEditing={isEditing} slice={employment} label="Notes" name="notes">
              <Input.TextArea placeholder="Add Notes" />
            </DisplayComponent>
            {/* Only allow setting of data source if no data source is set, or it is editable (i.e. present in the editableDataSourceTypes) */}
            {(!employment?.dataSource ||
              editableDataSourceTypeValues.includes(employment?.dataSource)) && (
              <DisplayComponent
                isEditing={isEditing}
                slice={employment}
                label="Data Source"
                name="dataSource"
                outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
              >
                <Select
                  options={constants?.editableDataSourceTypes?.map(({ display, value }) => ({
                    value,
                    label: display,
                  }))}
                />
              </DisplayComponent>
            )}
            <StyledButtonContainer>
              <Space>
                <Button onClick={isNew ? onCancel : onPrimaryCancel}>Cancel</Button>
                {(isAddDebtorEmploymentAuthorized || isUpdateDebtorEmploymentAuthorized) && (
                  <Button
                    loading={isCreateLoading || isUpdateLoading}
                    onClick={toggleIsEditing}
                    type="primary"
                  >
                    Save
                  </Button>
                )}
              </Space>
            </StyledButtonContainer>
          </>
        )}
        {!isEditing && (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={employment}
              label="Data Source"
              name="dataSource"
              outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={employment}
              label="Created At"
              name="createdAt"
              outputFormatter={(value) => moment(value).format(DATE_TIME_FORMAT)}
            />
          </>
        )}
      </Form>
    </StyledCard>
  );
}
