import PaymentRulesTable from "./components/paymentRulesTable";
import SettlementAndPaymentRulesets from "./components/settlementAndPaymentRulesets";
import SettlementRulesTable from "./components/settlementRulesTable";

export default function SettlementAndPaymentRules() {
  return (
    <>
      <PaymentRulesTable />
      <SettlementRulesTable />
      <SettlementAndPaymentRulesets />
    </>
  );
}
