import { DROMO_DATE_FORMAT } from "integrations/dromo/dromoFormats";

export default [
  {
    key: "debtor_id",
    label: "External Debtor ID",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "ssn",
    label: "Debtor SSN",
    type: "string",
  },
  {
    key: "dob",
    label: "Debtor Date Of Birth",
    type: DROMO_DATE_FORMAT,
  },
];
