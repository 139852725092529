import { Form, Select } from "antd";
import { useFetchDebtorAccountsQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useGetLawFirmsQuery } from "features/lawFirmsTable/lawFirmsAPI";
import { useFetchEligibleDebtorsQuery, useFetchLawsuitsQuery } from "features/legal/legalAPI";
import { useParams } from "react-router-dom";

export default function AttorneyStep({ lawsuitId }) {
  const { debtorId } = useParams();
  const { data: lawFirms } = useGetLawFirmsQuery();
  const { data: lawsuits } = useFetchLawsuitsQuery({ debtorId });
  const { data: debtorAccounts } = useFetchDebtorAccountsQuery({ debtorId });
  const { data: eligibleDebtors } = useFetchEligibleDebtorsQuery({ debtorId });
  const selectedLawsuit = lawsuits?.find((lawsuit) => lawsuit.id === lawsuitId);

  // Select unique accounts that are not part of an active lawsuit.
  // const eligibleDebtorAccounts = debtorAccounts.filter((acct) => {
  //   return !acct.lawsuitId;
  // });

  // const eligibleDebtors = {};
  // const accountIdToDebtorIds = {};
  // eligibleDebtorAccounts.forEach((acct) => {
  //   acct.debtors.forEach((debtor) => {
  //     eligibleDebtors[debtor.id] = debtor;
  //     accountIdToDebtorIds[acct.id] = accountIdToDebtorIds[acct.id] || [];
  //     accountIdToDebtorIds[acct.id].push(debtor.id);
  //   });
  // });

  if (!lawsuitId) {
    return (
      <>
        <Form.Item
          label="Debtors"
          name={["debtorIds"]}
          rules={[{ required: true, message: "Select at least one debtor" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select Debtors..."
            options={eligibleDebtors?.map((each) => {
              return {
                label: each.name,
                value: each.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues.debtorIds !== currentValues.debtorIds;
          }}
        >
          {({ getFieldValue, setFieldValue }) => {
            const currentAccountIds = getFieldValue(["accountIds"]);
            const selectedDebtorIds = getFieldValue(["debtorIds"]);
            if (!selectedDebtorIds?.length) {
              return null;
            }

            // Get all account ids associated with the selected debtors
            const eligibleAccountIds = new Set();
            selectedDebtorIds?.forEach((id) => {
              const accountIds = eligibleDebtors.find((debtor) => debtor.id === id)?.accountIds;
              accountIds?.forEach((accountId) => eligibleAccountIds.add(accountId));
            });

            // Remove previously selected accounts that are not associated with the currently selected debtors
            currentAccountIds?.forEach((id) => {
              if (!eligibleAccountIds.has(id)) {
                setFieldValue(
                  ["accountIds"],
                  currentAccountIds.filter((acctId) => acctId !== id),
                );
              }
            });

            return (
              <Form.Item
                name={["accountIds"]}
                label="Accounts"
                rules={[{ required: true, message: "Select at least one account" }]}
              >
                <Select
                  popupMatchSelectWidth={false}
                  mode="multiple"
                  options={debtorAccounts
                    .filter((acct) => {
                      return (
                        eligibleAccountIds.has(acct.id) &&
                        (acct.legalStatus === "" || acct.legalStatus === "not_litigated")
                      );
                    })
                    .map((acct) => ({
                      label: `Account #${acct.id}`,
                      value: acct.id,
                    }))}
                  placeholder="Select Accounts..."
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name={["attorney", "lawFirmId"]}
          label="Law Firm/Attorney"
          rules={[{ required: true, message: "Please select one" }]}
        >
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select a law firm or attorney..."
            options={lawFirms?.map((lawFirm) => ({ label: lawFirm.name, value: lawFirm.id }))}
          />
        </Form.Item>
      </>
    );
  }

  return (
    <>
      <Form.Item label="Accounts">{selectedLawsuit?.accountIds?.join(", ")}</Form.Item>
      <Form.Item label="Debtors">{selectedLawsuit?.debtorIds?.join(", ")}</Form.Item>
      <Form.Item name={["attorney", "lawFirmId"]} label="Law Firm/Attorney">
        <Select
          placeholder="Select a law firm or attorney..."
          options={lawFirms?.map((lawFirm) => ({ label: lawFirm.name, value: lawFirm.id }))}
        />
      </Form.Item>
    </>
  );
}
