import { Form, Input, Modal, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useAddDebtorTagMutation, useUpdateDebtorTagMutation } from "features/tags/tagsAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function AddDebtorTagModal({ title, open, onOk, onCancel, debtorTag }) {
  const [form] = Form.useForm();
  const [addDebtorTag] = useAddDebtorTagMutation();
  const [updateDebtorTag] = useUpdateDebtorTagMutation();

  const initialValues = {
    ...debtorTag,
  };

  const onSubmit = async () => {
    const action = debtorTag ? updateDebtorTag : addDebtorTag;
    const formData = await form.validateFields();

    const result = await action({ ...formData });
    if ("data" in result) {
      const successMessage = debtorTag
        ? "Debtor tag updated successfully."
        : "Debtor tag added successfully.";
      message.success(successMessage);
      onOk();
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      okText="Submit"
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        labelAlign="left"
        initialValues={initialValues}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="notes" label="Notes / Description">
          <Input />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
