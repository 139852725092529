import api, { transformResponse } from "services/api";

export const priorityAccountRuleApi = api
  .enhanceEndpoints({
    addTagTypes: ["CreditorMetaBaseReports", "CreditorMetaBaseReportDetails"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorMetabaseReports: build.query({
        query: () => "/api/v1/creditor/reports/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorMetaBaseReports", id })),
                { type: "CreditorMetaBaseReports", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorMetaBaseReports', id: 'LIST' }` is invalidated
              [{ type: "CreditorMetaBaseReports", id: "LIST" }],
      }),
      fetchCreditorMetabaseReportDetails: build.query({
        query: (payload) => `/api/v1/creditor/reports/${payload.id}`,
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "CreditorMetaBaseReportDetails", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const { useFetchCreditorMetabaseReportDetailsQuery, useFetchCreditorMetabaseReportsQuery } =
  priorityAccountRuleApi;
