import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Modal, Space } from "antd";
import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function PerformSearchModal({ title, open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const initialValues = {};
  const [searchAction, setSearchAction] = useState("");

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onOk} onCancel={onCancel}>
      <StyledForm layout="vertical" form={form} initialValues={initialValues}>
        <Form.Item label="Select an action to perform">
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  label: "Skip Trace",
                  key: "Skip Trace",
                },
                {
                  type: "divider",
                },
                {
                  label: "Pull Credit Report",
                  key: "Pull Credit Report",
                },
                {
                  type: "divider",
                },
                {
                  label: "Lookup Attorney",
                  key: "Lookup Attorney",
                },
              ],
              onClick: ({ key }) => setSearchAction(key),
            }}
          >
            <Button>
              <Space>
                {searchAction || "Choose an action..."}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default PerformSearchModal;
