import { Modal, Button } from "antd";

function NotesModal({ title, open, onClose, notesText }) {
  return (
    <Modal
      footer={[
        <Button type="primary" key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      maskClosable={false}
      title={title}
      open={open}
      onCancel={onClose}
      onOk={onClose}
    >
      {notesText}
    </Modal>
  );
}

export default NotesModal;
