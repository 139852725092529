import { Form } from "antd";
import { snakeToCamelCase, udfToComponentMap } from "common/utils";
import { useUserType } from "features/auth";
import { useFetchAllCreditorUdfCustomFieldsQuery } from "features/userDefinedFields/agencyPortal/creditorUserDefinedFieldsAPI";

export default function CreditorCustomFields() {
  const { isAgencyUserType } = useUserType();

  const { data: creditorCustomFields } = useFetchAllCreditorUdfCustomFieldsQuery(
    {},
    { skip: !isAgencyUserType },
  );

  return (creditorCustomFields ?? []).map((field) => (
    <Form.Item
      key={field.id}
      label={field.name}
      name={["customFields", `${snakeToCamelCase(field.slug)}`]}
      rules={[{ required: field.isRequired }]}
    >
      {udfToComponentMap[field.type]}
    </Form.Item>
  ));
}
