import { Form, Modal, Radio, Select, message } from "antd";

import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useDownloadInvoicesMutation } from "features/invoices/invoiceAPI";

import { useFetchLetterTemplatesQuery } from "features/letterTemplates/letterTemplatesAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function DownloadInvoiceModal({ open, onOk, onCancel, invoiceIds = [] }) {
  const [form] = useForm();
  const { data: letterTemplates, isLoading: isLoadingLetterTemplates } =
    useFetchLetterTemplatesQuery();
  const [downloadInvoices, { isLoading: isDownloadingInvoices }] = useDownloadInvoicesMutation();

  const onSubmit = async () => {
    const values = await form.validateFields();
    const { letterTemplateId, useCombinedDocument, renderFormat } = values;

    const result = await downloadInvoices({
      letterTemplateId,
      invoiceIds,
      useCombinedDocument,
      renderFormat,
    });

    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success("Downloading...");
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      maskClosable={false}
      title="Download Invoices"
      open={open}
      confirmLoading={isDownloadingInvoices}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm layout="vertical" form={form} disabled={isDownloadingInvoices}>
        <Form.Item
          label="File Format"
          name="renderFormat"
          rules={[{ required: true, message: "This field is required" }]}
          initialValue="pdf"
        >
          <Select
            placeholder="Select one.."
            options={[
              {
                label: "PDF",
                value: "pdf",
              },
              {
                label: "Docx",
                value: "docx",
              },
              {
                label: "Xlsx",
                value: "xlsx",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.renderFormat !== currentValues.renderFormat
          }
        >
          {({ getFieldValue }) => {
            const format = getFieldValue("renderFormat");
            return (
              <>
                {(format === "pdf" || format === "docx") && (
                  <Form.Item
                    label="Letter Template"
                    name="letterTemplateId"
                    rules={[{ required: true, message: "Select letter template" }]}
                  >
                    <Select
                      loading={isLoadingLetterTemplates}
                      placeholder="Select Letter Template..."
                      disabled={isDownloadingInvoices}
                      options={letterTemplates
                        ?.filter((template) => template.type === "invoice")
                        .map((template) => ({
                          value: template.id,
                          label: template.filename,
                        }))}
                    />
                  </Form.Item>
                )}
                <Form.Item name="useCombinedDocument" initialValue={false} label="Output As">
                  <Radio.Group>
                    {format === "pdf" && <Radio value>Combined Invoices</Radio>}
                    <Radio value={false}>Separate Invoices</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default DownloadInvoiceModal;
