import WorkflowProfile from "features/workflows/components/workflowProfile";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function WorkflowProfilePage() {
  return (
    <PageLayout>
      <Content>
        <WorkflowProfile />
      </Content>
    </PageLayout>
  );
}

export default WorkflowProfilePage;
