import api, { transformRequest, transformResponse } from "services/api";

export const appearanceApi = api
  .enhanceEndpoints({
    addTagTypes: ["Appearances", "Roles", "Me"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAppearances: build.query({
        query: () => `/api/v1/common/appearances/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Appearances", id })),
                { type: "Appearances", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Appearances', id: 'LIST' }` is invalidated
              [{ type: "Appearances", id: "LIST" }],
      }),
      createAppearance: build.mutation({
        query: (appearance) => ({
          url: `/api/v1/core/appearances/create/`,
          method: "POST",
          body: transformRequest(appearance),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [
          { type: "Appearances", id: "LIST" },
          { type: "Roles", id: "LIST" },
          { type: "Me" },
        ],
      }),
      deleteAppearance: build.mutation({
        query: (appearance) => ({
          url: `/api/v1/core/appearances/${appearance.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [
          { type: "Appearances", id: "LIST" },
          { type: "Roles", id: "LIST" },
          { type: "Me" },
        ],
      }),
      updateAppearance: build.mutation({
        query: (appearance) => ({
          url: `/api/v1/core/appearances/${appearance.id}/update/`,
          method: "POST",
          body: transformRequest(appearance),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Appearances", id: arg.id },
          { type: "Appearances", id: "LIST" },
          { type: "Me" },
        ],
      }),
    }),
  });

export const {
  useFetchAppearancesQuery,
  useCreateAppearanceMutation,
  useUpdateAppearanceMutation,
  useDeleteAppearanceMutation,
} = appearanceApi;
