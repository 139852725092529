import { Card, Tabs } from "antd";
import styled from "styled-components";

import { PageHeader } from "components/pageHeader";
import UsersTable from "features/userManagementTable/components/usersTable";
import RolesTable from "features/userManagementTable/components/rolesTable";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

export default function UserManagementTable() {
  const tabs = [
    {
      label: "Users",
      key: "users",
      children: <UsersTable />,
    },
    {
      label: "Roles",
      key: "roles",
      children: <RolesTable />,
    },
  ];

  return (
    <>
      <StyledHeader>User Management</StyledHeader>
      <Card>
        <Tabs items={tabs} />
      </Card>
    </>
  );
}
