import { Button, Modal, Skeleton } from "antd";
import { AdvancedJudgmentForm } from "features/legal/components/advancedJudgmentForm";
import { SimpleJudgmentForm } from "features/legal/components/simpleJudgmentForm";
import { useFetchJudgmentsQuery, useFetchLawsuitAllocationsQuery } from "features/legal/legalAPI";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { JudgmentsTable } from "./judgmentsTable";

const StyledModal = styled(Modal)`
  max-width: 800px;
`;

function AddJudgmentModal({ lawsuit, onOk, onCancel }) {
  const { debtorId } = useParams();
  const [isSimpleForm, setIsSimpleForm] = useState(true);
  const { data: lawsuitAllocations = [], isLoading: isLawsuitAllocationsLoading } =
    useFetchLawsuitAllocationsQuery({ debtorId, lawsuitId: lawsuit.id }, { skip: !lawsuit.id });

  const { data: judgments, isLoading: isJudgmentsLoading } = useFetchJudgmentsQuery(
    { debtorId, lawsuitId: lawsuit.id },
    { skip: !lawsuit.id },
  );

  const onModalCancel = () => {
    onCancel();
  };

  // Set of flags to render different parts of the modal
  const isJudgmentsExist = judgments?.length > 0;
  const isAwaitingJudgments = lawsuitAllocations.some((allocation) => !allocation.judgmentDate);
  const showSimpleForm =
    !isJudgmentsLoading && !isJudgmentsExist && !isLawsuitAllocationsLoading && isSimpleForm;
  const showAdvancedForm =
    (!isJudgmentsLoading && !isLawsuitAllocationsLoading && isJudgmentsExist) ||
    (!isJudgmentsExist && !isLawsuitAllocationsLoading && !isSimpleForm);
  const showFormSwitch = !isJudgmentsLoading && !isJudgmentsExist;

  return (
    <StyledModal
      maskClosable={false}
      title="Apply Judgment"
      onCancel={onModalCancel}
      open
      width="95%"
      footer={null}
    >
      <>
        {isJudgmentsLoading && <Skeleton active />}
        {isJudgmentsExist && <JudgmentsTable lawsuit={lawsuit} />}
        {isAwaitingJudgments && showSimpleForm && (
          <SimpleJudgmentForm lawsuit={lawsuit} onOk={onOk} onCancel={onCancel} />
        )}
        {isAwaitingJudgments && showAdvancedForm && (
          <AdvancedJudgmentForm lawsuit={lawsuit} onOk={onOk} onCancel={onCancel} />
        )}
        {isAwaitingJudgments && showFormSwitch && (
          <Button type="link" onClick={() => setIsSimpleForm((prev) => !prev)}>
            {isSimpleForm ? "Show Advanced" : "Hide Advanced"}
          </Button>
        )}
      </>
    </StyledModal>
  );
}
export default AddJudgmentModal;
