import ChangePassword from "features/auth/components/changePasswordForm";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";
import Content from "layouts/agencyPortal/dashboard/content";

function ChangePasswordPage() {
  return (
    <PageLayout>
      <Content>
        <ChangePassword />
      </Content>
    </PageLayout>
  );
}

export default ChangePasswordPage;
