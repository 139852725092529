import api, { transformRequest, transformResponse } from "services/api";

export const workflowEventsApi = api
  .enhanceEndpoints({ addTagTypes: ["WorkflowsEvents", "Workflows", "WorkflowDetails"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchWorkflowEvents: build.query({
        query: (payload) => ({
          url: `api/v1/core/workflows/${payload.workflowId}/events/`,
          method: "GET",
          params: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "WorkflowsEvents", id })),
                { type: "WorkflowsEvents", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'WorkflowsEvents', id: 'LIST' }` is invalidated
              [{ type: "WorkflowsEvents", id: "LIST" }],
      }),
      addWorkflowEvent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflows/${payload.workflowId}/events/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "WorkflowsEvents", id: "LIST" },
          { type: "Workflows", id: arg.workflowId },
          { type: "WorkflowDetails", id: arg.workflowId },
        ],
      }),
      updateWorkflowEvent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflows/${payload.workflowId}/events/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "WorkflowsEvents", id: arg.id },
          { type: "Workflows", id: arg.workflowId },
          { type: "WorkflowDetails", id: arg.workflowId },
        ],
      }),
      deleteWorkflowEvent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflows/${payload.workflowId}/events/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "WorkflowsEvents", id: "LIST" },
          { type: "Workflows", id: arg.workflowId },
          { type: "WorkflowDetails", id: arg.workflowId },
        ],
      }),
    }),
  });

export const {
  useAddWorkflowEventMutation,
  useUpdateWorkflowEventMutation,
  useDeleteWorkflowEventMutation,
  useFetchWorkflowEventsQuery,
} = workflowEventsApi;
