import { PlusOutlined } from "@ant-design/icons";
import { Button, Row, Table } from "antd";
import { PageHeader } from "components/pageHeader";
import PriorityAccountRuleModal from "features/priorityAccountRulesTable/components/priorityAccountRuleModal";
import {
  useAddPriorityAccountRuleMutation,
  useFetchPriorityAccountRulesQuery,
} from "features/priorityAccountRulesTable/priorityAccountRulesAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const columns = [
  {
    title: "Debt Types",
    render: (text, record) => record.debtTypes?.map((debtType) => debtType.display)?.join(", "),
    key: "debtTypes",
  },
  {
    title: "States",
    render: (text, record) => record.debtorStates?.map((state) => state.display)?.join(", "),
    key: "states",
  },
  {
    title: "Alphabetical",
    children: [
      {
        title: "From",
        dataIndex: "minLastnameAlphabetical",
        key: "minLastnameAlphabetical",
      },
      {
        title: "To",
        dataIndex: "maxLastnameAlphabetical",
        key: "maxLastnameAlphabetical",
      },
    ],
  },
  {
    title: "Account Balance",
    children: [
      {
        title: "From",
        dataIndex: "minDebtBalance",
        key: "minDebtBalance",
      },
      {
        title: "To",
        dataIndex: "maxDebtBalance",
        key: "maxDebtBalance",
      },
    ],
  },
  {
    title: "Days Since Upload",
    children: [
      {
        title: "From",
        dataIndex: "minAccountAgeMonths",
        key: "minAccountAgeMonths",
      },
      {
        title: "To",
        dataIndex: "maxAccountAgeMonths",
        key: "maxAccountAgeMonths",
      },
    ],
  },
  {
    title: "Clients",
    render: (text, record) => record.creditorNames?.map((creditorName) => creditorName)?.join(", "),
    key: "creditors",
  },
  {
    title: "Language",
    render: (text, record) => record.languages?.map((language) => language.display)?.join(", "),
    key: "language",
  },
];

export default function PriorityAccountRulesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: priorityAccountRules, isLoading } = useFetchPriorityAccountRulesQuery();
  const [addPriorityAccount] = useAddPriorityAccountRuleMutation();

  const onNewRule = async (newRule) => {
    const result = await addPriorityAccount({ rule: newRule });
    if ("data" in result) {
      setModalVisible(false);
    }
  };

  return (
    <>
      <StyledHeader>Priority Accounts</StyledHeader>
      <StyledRow align="middle">
        <span>Define which accounts get priority and which do not.</span>
        <Button onClick={() => setModalVisible(true)} icon={<PlusOutlined />} type="link">
          Add Rule
        </Button>
      </StyledRow>
      <Table
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={priorityAccountRules}
      />
      <PriorityAccountRuleModal
        title="Add Rule"
        open={isModalVisible}
        onOk={onNewRule}
        onCancel={() => setModalVisible(false)}
      />
    </>
  );
}
