import { Modal, message } from "antd";
import { TableFormList } from "components/formList";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useAddLegalFeesMutation,
  useFetchEligibleDebtorsQuery,
  useFetchLegalFeesQuery,
} from "features/legal/legalAPI";

import { useParams } from "react-router-dom";

function AddLegalFeesModal({ lawsuit, title, onCancel }) {
  const { debtorId } = useParams();
  const [addLegalFees] = useAddLegalFeesMutation();
  const { data: legalFees } = useFetchLegalFeesQuery({ lawsuitId: lawsuit.id, debtorId });
  const { data: eligibleDebtors } = useFetchEligibleDebtorsQuery({ debtorId });
  const { data: constants } = useFetchBackendConstantsQuery();

  const onSave = async (values) => {
    const result = await addLegalFees({ debtorId, lawsuitId: lawsuit.id, ...values });
    if ("data" in result) {
      message.success("Legal fee added successfully!");
    }
    return { data: true };
  };
  const columns = [
    {
      title: "Debtors",
      dataIndex: "debtorIds",
      editable: true,
      inputType: "select",
      options: eligibleDebtors?.map((debtor) => ({
        label: `${debtor.id} - ${debtor.name}`,
        value: debtor.id,
      })),
      inputProps: { mode: "multiple" },
      rules: [{ required: true, message: "Please select a debtor" }],
      render: (text) => text?.join(", "),
    },
    {
      title: "Fee Type",
      dataIndex: "feeType",
      editable: true,
      inputType: "select",
      options: constants?.feeCategories?.map((type) => ({
        label: type.display,
        value: type.value,
      })),
      rules: [{ required: true, message: "Please select a fee type" }],
      render: (text) => constants?.feeCategories.find((type) => type.value === text)?.display,
    },
    {
      title: "Amount",
      editable: true,
      dataIndex: "feeAmount",
      inputProps: { min: 0 },
      inputType: "number",
      rules: [{ required: true, message: "Please enter an amount" }],
      render: (text) => `$${text}`,
    },
  ];

  return (
    <Modal
      footer={null}
      width={800}
      maskClosable={false}
      title={title}
      open={lawsuit}
      onCancel={onCancel}
    >
      <TableFormList
        disableDelete
        disableEdit
        addText="Add Fee"
        disableEditaddText="Legal Fee"
        columns={columns}
        data={legalFees}
        onSave={onSave}
      />
    </Modal>
  );
}

export default AddLegalFeesModal;
