import api, { transformRequest, transformResponse } from "services/api";

export const tagsApi = api
  .enhanceEndpoints({ addTagTypes: ["DebtorTags", "CreditorTags", "DisputeCategoryTags"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtorTags: build.query({
        query: () => "/api/v1/core/debtor-tags/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorTags", id })),
                { type: "DebtorTags", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorTags', id: 'LIST' }` is invalidated
              [{ type: "DebtorTags", id: "LIST" }],
      }),
      fetchCreditorTags: build.query({
        query: () => "/api/v1/core/creditor-tags/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query

              [
                ...results.map(({ id }) => ({ type: "CreditorTags", id })),
                { type: "CreditorTags", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorTags', id: 'LIST' }` is invalidated
              [{ type: "CreditorTags", id: "LIST" }],
      }),
      fetchDisputeCategoryTags: build.query({
        query: (creditorId) => ({
          url: `/api/v1/core/dispute-category-tags/`,
          method: "GET",
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query

              [
                ...results.map(({ id }) => ({ type: "DisputeCategoryTags", id })),
                { type: "DisputeCategoryTags", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DisputeCategoryTags', id: 'LIST' }` is invalidated
              [{ type: "DisputeCategoryTags", id: "LIST" }],
      }),
      addDebtorTag: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/debtor-tags/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorTags", id: "LIST" }],
      }),
      addCreditorTag: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/creditor-tags/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CreditorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CreditorTags", id: "LIST" }],
      }),
      addDisputeCategoryTag: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/dispute-category-tags/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DisputeCategoryTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DisputeCategoryTags", id: "LIST" }],
      }),
      updateDebtorTag: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-tags/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorTags", id: "LIST" }],
      }),
      updateCreditorTag: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditor-tags/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CreditorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CreditorTags", id: "LIST" }],
      }),
      updateDisputeCategoryTag: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/dispute-category-tags/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DisputeCategoryTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DisputeCategoryTags", id: "LIST" }],
      }),
      deleteDebtorTag: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtor-tags/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all DebtorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorTags", id: "LIST" }],
      }),
      deleteCreditorTag: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditor-tags/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all CreditorTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CreditorTags", id: "LIST" }],
      }),
      deleteDisputeCategoryTag: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/dispute-category-tags/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        // Invalidates all DisputeCategoryTags-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DisputeCategoryTags", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchDebtorTagsQuery,
  useFetchCreditorTagsQuery,
  useFetchDisputeCategoryTagsQuery,
  useAddDebtorTagMutation,
  useAddCreditorTagMutation,
  useAddDisputeCategoryTagMutation,
  useUpdateDebtorTagMutation,
  useUpdateCreditorTagMutation,
  useUpdateDisputeCategoryTagMutation,
  useDeleteDebtorTagMutation,
  useDeleteCreditorTagMutation,
  useDeleteDisputeCategoryTagMutation,
} = tagsApi;
