import api, { transformRequest, transformResponse } from "services/api";

export const feePlanApi = api
  .enhanceEndpoints({
    addTagTypes: ["FeePlans", "FeePlanRuleSet", "FeePlanRules"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchFeePlans: build.query({
        query: () => "api/v1/core/fee-plans/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "FeePlans", id })),
                { type: "FeePlans", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'FeePlans', id: 'LIST' }` is invalidated
              [{ type: "FeePlans", id: "LIST" }],
      }),
      addFeePlan: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/fee-plans/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FeePlans", id: "LIST" }],
      }),
      updateFeePlan: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plans/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "FeePlans", id: arg.id }],
      }),
      deleteFeePlan: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plans/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "FeePlans", id: "LIST" }],
      }),

      fetchFeePlanRuleSets: build.query({
        query: () => "api/v1/core/fee-plan-assignment-rulesets/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "FeePlanRuleSet", id })),
                { type: "FeePlanRuleSet", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'FeePlanRuleSet', id: 'LIST' }` is invalidated
              [{ type: "FeePlanRuleSet", id: "LIST" }],
      }),
      fetchFeePlanRuleSet: build.query({
        query: (payload) => `api/v1/core/fee-plan-assignment-rulesets/${payload.id}/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) => [{ type: "FeePlanRuleSet", id: result.id }],
      }),
      addFeePlanRuleSet: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/fee-plan-assignment-rulesets/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FeePlanRuleSet", id: "LIST" }],
      }),
      updateFeePlanRuleSet: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plan-assignment-rulesets/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "FeePlanRuleSet", id: arg.id }],
      }),
      deleteFeePlanRuleSet: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plan-assignment-rulesets/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "FeePlanRuleSet", id: "LIST" }],
      }),

      fetchFeePlanRules: build.query({
        query: (payload) => `api/v1/core/fee-plan-assignment-rulesets/${payload.ruleSetId}/rules/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "FeePlanRules", id })),
                { type: "FeePlanRules", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'FeePlanRules', id: 'LIST' }` is invalidated
              [{ type: "FeePlanRules", id: "LIST" }],
      }),
      addFeePlanRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plan-assignment-rulesets/${payload.ruleSetId}/rules/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "FeePlanRules", id: "LIST" }],
      }),
      updateFeePlanRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plan-assignment-rulesets/${payload.ruleSetId}/rules/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "FeePlanRules", id: arg.id }],
      }),
      deleteFeePlanRule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/fee-plan-assignment-rulesets/${payload.ruleSetId}/rules/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "FeePlanRules", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useAddFeePlanMutation,
  useFetchFeePlansQuery,
  useAddFeePlanRuleMutation,
  useAddFeePlanRuleSetMutation,
  useDeleteFeePlanMutation,
  useDeleteFeePlanRuleMutation,
  useDeleteFeePlanRuleSetMutation,
  useFetchFeePlanRuleSetQuery,
  useFetchFeePlanRuleSetsQuery,
  useFetchFeePlanRulesQuery,
  useUpdateFeePlanMutation,
  useUpdateFeePlanRuleMutation,
  useUpdateFeePlanRuleSetMutation,
} = feePlanApi;
