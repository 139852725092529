import { Form, Input, InputNumber, Modal, Select } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  useAddAccountAssignmentRuleMutation,
  useUpdateAccountAssignmentRuleMutation,
} from "features/accountAssignmentRulesTable/accountAssignmentRulesAPI";

import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  collectorsWithRolesSelector,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export default function AccountAssignmentRuleModal({ record, open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });

  const initialValues = {
    ...record,
    creditorIds: record?.creditors?.map((creditor) => creditor.id),
    assigneeId: record?.assignee.id,
  };

  const isExistingRule = !!record;

  const title = isExistingRule ? "Update Rule" : "Add Rule";

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const [addAccountAssignmentRule] = useAddAccountAssignmentRuleMutation();
  const [updateAccountAssignmentRule] = useUpdateAccountAssignmentRuleMutation();

  const onSubmit = async () => {
    const rule = await form.validateFields();

    const result = isExistingRule
      ? await updateAccountAssignmentRule(rule)
      : await addAccountAssignmentRule(rule);
    if ("data" in result) {
      await onOk();
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const onModalCancel = async () => {
    form.resetFields();
    await onCancel();
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onModalCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="assigneeId" label="Collector" rules={[{ required: true }]}>
          <Select
            showSearch
            filterOption={filterOption}
            options={collectors?.map((c) => ({
              label: collectorFullName(c),
              value: c.id,
            }))}
          />
        </Form.Item>
        <h5>Account Match Criteria</h5>
        Accounts that match the criteria will be assigned to the collector.
        <br />
        <br />
        <Form.Item name="cbrClassCodes" label="CBR Class Codes">
          <Select
            mode="multiple"
            filterOption={filterOption}
            options={constants?.cbrClassCodes.map((d) => ({
              label: d.display,
              value: d.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="debtorStates" label="States">
          <Select
            mode="multiple"
            filterOption={filterOption}
            options={constants?.states.map((state) => ({
              value: state.value,
              label: state.display,
            }))}
          />
        </Form.Item>
        <Form.Item name="minLastnameAlphabetical" label="Alphabetical (From)">
          <StyledInput maxLength={1} />
        </Form.Item>
        <Form.Item name="maxLastnameAlphabetical" label="Alphabetical (To)">
          <StyledInput maxLength={1} />
        </Form.Item>
        <Form.Item name="minDebtBalance" label="Account Balance (From)">
          <StyledInputNumber min="0" prefix="$" step="0.01" />
        </Form.Item>
        <Form.Item name="maxDebtBalance" label="Account Balance (To)">
          <StyledInputNumber min="0" prefix="$" step="0.01" />
        </Form.Item>
        <Form.Item name="minAccountAgeMonths" label="Days Since Upload (From)">
          <StyledInputNumber />
        </Form.Item>
        <Form.Item name="maxAccountAgeMonths" label="Days Since Upload (To)">
          <StyledInputNumber />
        </Form.Item>
        <Form.Item name="creditorIds" label="Clients">
          <Select
            mode="multiple"
            filterOption={filterOption}
            options={creditors?.map((creditor) => ({
              label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
              value: creditor.id,
            }))}
          />
        </Form.Item>
        <Form.Item name="languages" label="Languages">
          <Select
            mode="multiple"
            options={constants?.languages.map((l) => ({
              label: l.display,
              value: l.value,
            }))}
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
