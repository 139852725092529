import { Form, Input, InputNumber, message, Modal, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useAddAdjustmentMutation } from "features/invoices/invoiceAPI";

function AddAdjustmentModal({ open, title, onCancel, onOk, invoice }) {
  const [form] = useForm();
  const [addAdjustment] = useAddAdjustmentMutation();

  const onSubmit = async () => {
    const value = await form.validateFields();
    const result = await addAdjustment({ payload: value, invoiceId: invoice.id });

    if ("data" in result) {
      message.success("Adjustment added successfully!");
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const initialValues = {
    isDebitOrCredit: "credit",
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onCancel}>
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <Form.Item name="isDebitOrCredit" required>
          <Radio.Group>
            <Radio value="credit">Credit</Radio>
            <Radio value="fees">Fees</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.isDebitOrCredit !== currentValues.isDebitOrCredit
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("isDebitOrCredit") === "credit" ? (
              <Form.Item
                name="adjustmentCredit"
                label="Amount"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <InputNumber prefix="$" min="0" />
              </Form.Item>
            ) : (
              <Form.Item
                name="adjustmentFee"
                label="Amount"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <InputNumber prefix="$" min="0" />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input placeholder="Add Notes" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddAdjustmentModal;
