export default [
  {
    key: "account_id",
    label: "External Account ID",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "score",
    label: "Account Score",
    type: "number",
  },
];
