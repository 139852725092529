import api, { transformResponse } from "services/api";

export const udfApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "CreditorPortalDebtorUdfCustomField",
      "CreditorPortalDebtorUdfCustomFieldDromoSchema",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllCreditorPortalDebtorUdfCustomFields: build.query({
        query: () => `/api/v1/creditor/debtor-custom-fields/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalDebtorUdfCustomField", id })),
                { type: "CreditorPortalDebtorUdfCustomField", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorUdfCustomField', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorUdfCustomField", id: "LIST" }],
        invalidatesTags: [{ type: "CreditorPortalDebtorUdfCustomFieldDromoSchema", id: "LIST" }],
      }),
      fetchAllCreditorPortalDebtorUdfCustomFieldsDromoSchema: build.query({
        query: () => `/api/v1/creditor/debtor-custom-fields/dromo-schema/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [{ type: "CreditorPortalDebtorUdfCustomFieldDromoSchema", id: "LIST" }]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalDebtorUdfCustomFieldDromoSchema', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalDebtorUdfCustomFieldDromoSchema", id: "LIST" }],
      }),
    }),
  });

export const {
  useFetchAllCreditorPortalDebtorUdfCustomFieldsQuery,
  useFetchAllCreditorPortalDebtorUdfCustomFieldsDromoSchemaQuery,
} = udfApi;
