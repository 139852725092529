import { PlusOutlined } from "@ant-design/icons";
import { Form, Button } from "antd";
import AddEventModal from "features/events/components/addEventModal";

import { useState } from "react";

function EventActions() {
  const [form] = Form.useForm();
  const [isEventsModalVisible, setIsEventsModalVisible] = useState(false);

  // const dispatch = useDispatch();
  // const isIdle = useSelector(selectIsIdle);
  // useEffect(() => {
  //   if (isIdle) {
  //   }
  // }, [dispatch, isIdle]);

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        form.resetFields();
        setIsEventsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsEventsModalVisible(false);
  };

  return (
    <>
      <Button onClick={() => setIsEventsModalVisible(true)} icon={<PlusOutlined />}>
        Add Event
      </Button>
      <AddEventModal open={isEventsModalVisible} onOk={handleOk} onCancel={handleCancel} />
    </>
  );
}

export default EventActions;
