import { TableFormList } from "components/formList";
import {
  useCreateDebtorAddressesMutation,
  useDeleteDebtorAddressesMutation,
  useFetchDebtorAddressesQuery,
  useUpdateDebtorAddressesMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useMemo } from "react";

export default function AddressesStep({
  debtorId = undefined,
  dataSource = undefined,
  handleDelete = undefined,
  handleSave = undefined,
}) {
  const isControlled = !!dataSource && !!handleDelete;

  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: debtorAddresses, isLoading } = useFetchDebtorAddressesQuery(
    {
      debtorId,
    },
    { skip: !debtorId },
  );
  const [updateDebtorAddress] = useUpdateDebtorAddressesMutation();
  const [deleteDebtorAddress] = useDeleteDebtorAddressesMutation();
  const [createDebtorAddress] = useCreateDebtorAddressesMutation();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const addressTypeOptions = useMemo(() => {
    return constants?.addressTypes
      ?.filter((type) => type.value !== "home")
      .map(({ value, display }) => ({ value, label: display }));
  }, [constants?.addressTypes]);

  const columns = [
    {
      dataIndex: "isPrimary",
      editable: true,
      inputType: "checkbox",
      title: "Primary",
      valuePropName: "checked",
      render: (value) => (value ? "Yes" : "-"),
    },
    {
      dataIndex: "type",
      editable: true,
      inputType: "select",
      title: "Type",
      options: addressTypeOptions,
    },
    {
      dataIndex: "address1",
      editable: true,
      inputType: "text",
      title: "Address 1",
      rules: [
        {
          required: true,
          message: "Enter Address 1",
        },
      ],
    },
    {
      dataIndex: "address2",
      editable: true,
      inputType: "text",
      title: "Address 2",
    },
    {
      dataIndex: "city",
      editable: true,
      inputType: "text",
      title: "City",
      rules: [
        {
          required: true,
          message: "Enter city",
        },
      ],
    },
    {
      dataIndex: "state",
      editable: true,
      inputType: "select",
      rules: [
        {
          required: true,
          message: "Select state",
        },
      ],
      options: constants?.states.map((state) => ({
        label: state.display,
        value: state.value,
      })),
      inputProps: {
        filterOption,
        showSearch: true,
      },
      title: "State",
    },
    {
      dataIndex: "zipCode",
      editable: true,
      inputType: "text",
      rules: [
        {
          required: true,
          pattern: /^\d{5}(-\d{4})?$/,
          message: "Must be a valid zip code",
        },
      ],
      title: "Zip Code",
    },
    {
      dataIndex: "notes",
      editable: true,
      inputType: "text",
      title: "Notes",
    },
  ];

  const data = isControlled ? dataSource : debtorAddresses;

  return (
    <TableFormList
      addText="Add Address"
      loading={isLoading}
      defaultValues={{ type: "mail", isPrimary: data.length === 0 }}
      columns={columns}
      data={data}
      onDelete={(address) =>
        isControlled
          ? handleDelete(address)
          : deleteDebtorAddress({
              debtorId,
              address,
            })
      }
      onSave={(address) => {
        if (isControlled) return handleSave(address);

        const action = address.id ? updateDebtorAddress : createDebtorAddress;
        return action({
          debtorId,
          address,
        });
      }}
    />
  );
}
