import { Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  useFetchLetterTemplatesQuery,
  useLazyDownloadLegalLettersQuery,
} from "features/letterTemplates/letterTemplatesAPI";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function LegalLettersModal({ title, open: lawsuit, onOk, onCancel }) {
  const { debtorId } = useParams();
  const [form] = useForm();
  const { data: letterTemplates, isLoading } = useFetchLetterTemplatesQuery();
  const [downloadLegalLetters] = useLazyDownloadLegalLettersQuery();

  const onSubmit = async () => {
    const values = await form.validateFields();
    await downloadLegalLetters({
      debtorId,
      lawsuitId: lawsuit.id,
      data: values,
    });
    await onOk();
    form.resetFields();
    return message.success("Letters successfully downloaded");
  };

  return (
    <Modal maskClosable={false} title={title} open={lawsuit} onOk={onSubmit} onCancel={onCancel}>
      <StyledForm layout="vertical" form={form}>
        <Form.Item
          label="Letter Template"
          name="letterTemplateIds"
          rules={[{ required: true, message: "Select letter template" }]}
        >
          <Select disabled={isLoading} mode="multiple" placeholder="Select templates">
            {(letterTemplates ?? [])
              .filter((template) => template.type === "legal_filings")
              .map((letterTemplate) => (
                <Select.Option value={letterTemplate.id} key={letterTemplate.id}>
                  {letterTemplate.filename}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default LegalLettersModal;
