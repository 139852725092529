import { EditOutlined, MergeCellsOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Row, Space, Switch, Table, message } from "antd";
import CreditorGroupModal from "features/mergeGroups/components/debtorMergeGroupModal";
import MergeDebtorsModal from "features/mergeGroups/components/mergeDebtorsModal";
import {
  useDeleteCreditorGroupMutation,
  useFetchCreditorGroupQuery,
  useUpdateCreditorGroupMutation,
} from "features/mergeGroups/debtorMergeGroupAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
`;

export default function DebtorMergeGroupTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isMergeDebtorModalVisible, setMergeDebtorModalVisible] = useState(false);
  const { data: creditorGroups, isLoading } = useFetchCreditorGroupQuery();
  const [activeRecord, setActiveRecord] = useState(null);
  const [deleteTyingRule] = useDeleteCreditorGroupMutation();
  const [editDebtorMergeGroup] = useUpdateCreditorGroupMutation();
  const {
    data: {
      [PERMISSIONS.DEBTOR_MERGE_GROUP__CREATE]: canCreateMergeGroup,
      [PERMISSIONS.DEBTOR_MERGE_GROUP__GET]: canGetMergeGroup,
      [PERMISSIONS.DEBTOR_MERGE_GROUP__UPDATE]: canUpdateMergeGroup,
      [PERMISSIONS.DEBTOR_MERGE_GROUP__MERGE]: canMerge,
    },
  } = useAuthorizations();

  const onSetAutoMerge = async (record, isAutoMergeEnabled) => {
    await editDebtorMergeGroup({ ...record, isAutoMergeEnabled });
    const response = await editDebtorMergeGroup({ ...record, isAutoMergeEnabled });
    if ("error" in response) {
      message.error("Failed to set auto merge for this group");
    }
  };

  // const handleDelete = async (record) => {
  //   const result = await deleteTyingRule({ id: record.id });
  //   if ("data" in result) {
  //     message.success("Creditor Debtor Merge Group removed successfully!");
  //   }
  //   if ("error" in result) {
  //     message.error("Unable to remove Debtor Merge Group");
  //   }
  // };

  const onUpdateAccountClick = (record) => {
    setModalVisible(true);
    setActiveRecord(record);
  };

  const onMergeDebtorsClick = (record) => {
    setMergeDebtorModalVisible(true);
    setActiveRecord(record);
  };

  const columns = [
    {
      title: "Auto Merge",
      dataIndex: "autoMerge",
      key: "autoMerge",
      render: (text, record) => {
        return (
          <AntDToolTip title="Auto Merge" key="autoMerge">
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              disabled={!canUpdateMergeGroup}
              checked={record?.isAutoMergeEnabled}
              onClick={(checked, event) => onSetAutoMerge(record, checked)}
            />
          </AntDToolTip>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "groupName",
      key: "groupName",
    },
    {
      title: "Rule ID",
      dataIndex: "ruleId",
      key: "ruleId",
      render: (text, record) => {
        return `${record.ruleId}`;
      },
    },
    {
      title: "Notes",
      key: "notes",
      render: (text, record) => {
        return `${record.notes} (${record.creditorIds.length} creditors in the group)`;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {canUpdateMergeGroup && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onUpdateAccountClick(record)} />
            </AntDToolTip>
          )}
          {canMerge && (
            <AntDToolTip placement="bottom" title="Merge debtors in this group" key="merge">
              {/* <Badge size="small" count={10} offset={[10, 0]} overflowCount={5}> */}
              <MergeCellsOutlined key="merge" onClick={() => onMergeDebtorsClick(record)} />
              {/* </Badge> */}
            </AntDToolTip>
          )}
          {/* 
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this Creditor Account Tying Group?"
            onConfirm={() => handleDelete(record)}
          >
            <DeleteOutlined key="delete-creditor-group" />
          </Popconfirm>
          */}
        </Space>
      ),
      fixed: "right",
    },
  ];

  if (!canGetMergeGroup) return null;

  return (
    <>
      <h4>Debtor Merge Groups</h4>
      <Row align="middle">
        <span>
          Define a group of Creditors whose accounts and debtors can be tied together determined by
          Debtor Merge Rules.
        </span>
        {canCreateMergeGroup && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Debtor Merge Group
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={creditorGroups}
      />
      {isModalVisible && (
        <CreditorGroupModal
          title={activeRecord ? "Edit Debtor Merge Group" : "Add Debtor Merge Group"}
          onOk={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          creditorGroup={activeRecord}
        />
      )}
      {isMergeDebtorModalVisible && (
        <MergeDebtorsModal
          mergeGroup={activeRecord}
          title={`Group ${activeRecord.groupName} (ID: ${activeRecord.id})`}
          onOk={() => {
            setMergeDebtorModalVisible(false);
            setActiveRecord(null);
          }}
          onCancel={() => {
            setMergeDebtorModalVisible(false);
            setActiveRecord(null);
          }}
        />
      )}
    </>
  );
}
