/* eslint-disable camelcase */
// 1. type: list, subtype: enum --> multi-select using choices
// 2. type: enum, subtype: null --> dropdown
// 3. type: bool, subtype: null --> checkbox
// 4. type: str/int/decimal --> input
// 5. type: date/datetime --> date/datetime picker
// 6. type: model.*, subtype: null --> API call + dropdown
// 7. type: list, subtype: model.* --> API call + multi-select
// 8. type: list, subtype: str/int/decimal --> multi-input
//
// (not needed yet)
// type: list, subtype: str/int/date/datetime/decimal --> multi-input

import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, InputNumber, Select, Spin, Tooltip } from "antd";
import { debounce } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import {
  useFetchWorkflowModelQuery,
  useLazyFetchWorkflowModelsWithSearchQuery,
} from "features/workflows/workflowAPI";
import { cloneElement, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.66);
`;

function DebounceSelect({ options, fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value) => {
      setFetching(true);
      await fetchOptions(value);
      setFetching(false);
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <StyledSelect
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : "No results found"}
      options={options}
      {...props}
    />
  );
}

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

// converts underlying input with specificed type
// type === "str" || type === "int" || type === "decimal"
const TypeConverter = ({ children, value = undefined, onChange = undefined, type }) => {
  let internalOnChange = onChange;

  if (type === "int" || type === "decimal") {
    internalOnChange = (newValue) => {
      // if newValue is undefined or null, don't change anything
      if (newValue === undefined || newValue === null) {
        onChange(undefined);
      } else {
        // define converter function
        const converterFunction = type === "int" ? (v) => parseInt(v, 10) : (v) => parseFloat(v);

        // convert newValue to the correct type
        const convertedValue = Array.isArray(newValue)
          ? newValue.map(converterFunction)
          : converterFunction(newValue);
        onChange(convertedValue);
      }
    };
  }

  return cloneElement(children, { value, onChange: internalOnChange });
};

// components that takes an argument and displays an input
// arg - { type, subtype, name, isRequired, display, choices }
export default function ArgInput({ type, subtype, name, isRequired, display, choices, ...rest }) {
  const [searchOptions, setSearchOptions] = useState([]);

  // extract name of the model
  const modelName = type.startsWith("model.") ? type.split(".")[1] : subtype?.split(".")[1];

  // covers cases 6 and 7
  // We only search for models if we have previously saved fields.
  const hasModelSearch = type.startsWith("model.") || subtype?.startsWith("model.");
  const {
    data: listModelsResults = [],
    isLoading: isFetchWorkflowModelsLoading,
    isFetching: isFetchingWorkflowModelsLoading,
  } = useFetchWorkflowModelQuery(
    { model: modelName },
    {
      skip: !hasModelSearch,
    },
  );

  useEffect(() => {
    if (!isFetchWorkflowModelsLoading && !isFetchingWorkflowModelsLoading) {
      setSearchOptions(
        listModelsResults.map((result) => ({
          label: result.name,
          value: result.id,
        })),
      );
    }
  }, [listModelsResults, isFetchWorkflowModelsLoading, isFetchingWorkflowModelsLoading]);

  const [searchWorkflowModel, { isLoading: isSearchTextLoading }] =
    useLazyFetchWorkflowModelsWithSearchQuery();

  // 1. type: list, subtype: enum --> multi-select using choices
  if (type === "list" && subtype === "enum") {
    // convert array of choices into options
    const options =
      choices?.map(([value, label]) => ({
        label,
        value,
      })) ?? [];

    if (!isRequired) {
      options.unshift({
        label: "None",
        value: null,
      });
    }

    return (
      <StyledFormItem
        name={name}
        label={display}
        rules={[{ required: isRequired, message: "This field is required." }]}
      >
        <StyledSelect
          filterOption={filterOption}
          mode="multiple"
          options={options}
          maxTagCount="responsive"
          {...rest}
        />
      </StyledFormItem>
    );
  }

  // 2. type: enum, subtype: null --> dropdown
  if (type === "enum" && subtype === null) {
    // convert array of choices into options
    const options =
      choices?.map(([value, label]) => ({
        label,
        value,
      })) ?? [];

    if (!isRequired) {
      options.unshift({
        label: "None",
        value: null,
      });
    }

    return (
      <StyledFormItem
        name={name}
        label={display}
        rules={[{ required: isRequired, message: "This field is required." }]}
      >
        <StyledSelect filterOption={filterOption} showSearch options={options} {...rest} />
      </StyledFormItem>
    );
  }

  // 3. type: bool, subtype: null --> checkbox
  if (type === "bool" && subtype === null) {
    return (
      <StyledFormItem
        name={name}
        label={display}
        rules={[{ required: isRequired, message: "This field is required." }]}
        valuePropName="checked"
      >
        <Checkbox {...rest} />
      </StyledFormItem>
    );
  }

  // 4. type: str/int/decimal --> input
  if (type === "str" || type === "int" || type === "decimal") {
    return (
      <StyledFormItem
        name={name}
        label={display}
        rules={[{ required: isRequired, message: "This field is required." }]}
      >
        {type === "decimal" && <InputNumber step={0.01} {...rest} />}
        {type === "int" && <InputNumber step={1} {...rest} />}
        {type === "str" && <Input {...rest} />}
      </StyledFormItem>
    );
  }

  // 5. type: date/datetime --> date/datetime picker
  if (type === "date" || type === "datetime") {
    return (
      <StyledFormItem
        name={name}
        label={display}
        rules={[{ required: isRequired, message: "This field is required." }]}
      >
        <AktDatePicker showTime={type === "datetime"} {...rest} />
      </StyledFormItem>
    );
  }

  // 6. type: model, subtype: null --> API call + dropdown
  // 7. type: list, subtype: model --> API call + multi-select
  if (
    (type.startsWith("model") && subtype === null) ||
    (type === "list" && subtype?.startsWith("model"))
  ) {
    if (type === "list" && subtype?.startsWith("model")) {
      rest.mode = "multiple";
    } else {
      // This is required when searching you can only select a single item
      rest.showSearch = true;
    }

    if (isFetchWorkflowModelsLoading) return <>...</>;

    // convert array of listModelsResults into options
    return (
      <StyledFormItem
        name={name}
        label={display}
        rules={[{ required: isRequired, message: "This field is required." }]}
      >
        <DebounceSelect
          fetchOptions={async (searchInput) => {
            if (searchInput === "") {
              setSearchOptions([]);
              return;
            }
            const { data: workflowModelData = [] } = await searchWorkflowModel({
              model: modelName,
              prefix: searchInput,
            });
            setSearchOptions(
              workflowModelData.map((result) => ({
                label: result.name,
                value: result.id,
              })),
            );
          }}
          maxTagCount="responsive"
          options={searchOptions}
          allowClear
          {...rest}
          loading={isSearchTextLoading}
        />
      </StyledFormItem>
    );
  }

  // 8. type: list, subtype: str --> multi-input
  if (type === "list" && (subtype === "str" || subtype === "int" || subtype === "decimal")) {
    return (
      <StyledFormItem
        name={name}
        label={
          <>
            {display}
            <StyledTooltip title="Comma-separated list">
              <InfoCircleOutlined />
            </StyledTooltip>
          </>
        }
        rules={[{ required: isRequired, message: "This field is required." }]}
      >
        <TypeConverter type={subtype}>
          <StyledSelect
            mode="tags"
            tokenSeparators={[",", ";"]}
            {...rest}
            suffixIcon={null}
            open={false}
          />
        </TypeConverter>
      </StyledFormItem>
    );
  }

  return null;
}
