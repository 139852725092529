import api, { transformRequest, transformResponse } from "services/api";

export const accountsApi = api
  .enhanceEndpoints({ addTagTypes: ["CreditorPortalAccounts", "CreditorPortalDebtorProfile"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getCreditorPortalDebtorCommunicationHistory: build.query({
        query: (payload) => `/api/v1/creditor/debtors/${payload.debtorId}/communications/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorPortalAccounts", id })),
                { type: "CreditorPortalAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Accounts', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalAccounts", id: "LIST" }],
      }),
      postCreditorPortalDebtorCommunicationHistory: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/debtors/${payload.debtorId}/communications/create/`,
          method: "POST",
          body: transformRequest(payload.communication),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Account-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created letter template could show up in any lists.
        invalidatesTags: [{ type: "CreditorPortalAccounts", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetCreditorPortalDebtorCommunicationHistoryQuery,
  usePostCreditorPortalDebtorCommunicationHistoryMutation,
} = accountsApi;
