import { Form, Input, Modal, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { snakeToCamelCase } from "common/utils";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useAddTaxPlanMutation, useUpdateTaxPlanMutation } from "features/taxPlans/taxPlansAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function EditTaxPlanModal({ title, open, onOk, onCancel, taxPlan }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [addTaxPlan] = useAddTaxPlanMutation();
  const [editTaxPlan] = useUpdateTaxPlanMutation();

  const onSubmit = async () => {
    const newRule = await form.validateFields();
    const action = taxPlan ? editTaxPlan : addTaxPlan;
    const result = await action(newRule);
    if ("data" in result) {
      const successMessage = taxPlan
        ? "Tax plan updated successfully!"
        : "Tax plan added successfully!";
      message.success(successMessage);
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const initialValues = {
    ...taxPlan,
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onCancel}>
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="name"
          label="Plan Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
        <h5>Percent Contingency</h5>
        The percentage take for each payment category.
        <br />
        <br />
        {constants?.paymentCategories?.invoiceable.map((category) => (
          <Form.Item
            key={category.display}
            name={snakeToCamelCase(category.value)}
            label={category.display}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <AktPercentageInput />
          </Form.Item>
        ))}
      </StyledForm>
    </Modal>
  );
}
