import { Modal, Button, Skeleton } from "antd";
import styled from "styled-components";
import DOMPurify from "dompurify";

const StyledContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  height: 430px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-top: 10px;
  border-radius: 3px;
`;

const StyledContentPreview = styled.div`
  margin: 0 auto;
  background-color: #ffffff;
  padding: 0px;
`;

function PreviewEmailModal({ open, onCancel, data, isLoading }) {
  // The API is prepared for returning multiple emails templates, one for each
  // account selected in the form. For now, we only pass 1 account ID to the API
  // and the Preview modal has support for only 1 email template. For that reason,
  // we only get the first item of the data array.
  const previewData = data?.[0];
  const sanitizedHtml = DOMPurify.sanitize(previewData?.htmlContent);
  return (
    <Modal
      title="Email Preview"
      open={open}
      onCancel={onCancel}
      width={1300} // Set the width to be twice as wide
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
      ]}
    >
      {/* Display the fetched preview data */}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <p>
            <b>Subject:</b> {previewData?.subject}
          </p>
          <StyledContainer>
            <StyledContentPreview>
              <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
            </StyledContentPreview>
          </StyledContainer>
        </div>
      )}
    </Modal>
  );
}

export default PreviewEmailModal;
