import { Button } from "antd";
import { TriggerModal } from "components/triggerModal";
import styled from "styled-components";
import { useState } from "react";

const StyledContainer = styled.div`
  max-width: 350px;
  max-height: 350px;
  padding: 24px 24px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const StyledDownloadButton = styled(Button)`
  width: 150px;
  margin-top: 12px;
`;

const StyledHeader = styled.h3`
  margin-top: 0;
`;

function DownloadCard({ title, description, modal = undefined, onClick = undefined }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onClick();
    } finally {
      setIsSubmitting(false);
    }
  };

  const determineButtonText = () => {
    if (isSubmitting) {
      return "Generating...";
    }
    return "Generate";
  };

  return (
    <StyledContainer>
      <StyledHeader>{title}</StyledHeader>
      <span>{description}</span>
      {modal ? (
        <TriggerModal modal={modal}>
          <StyledDownloadButton type="primary">{determineButtonText()}</StyledDownloadButton>
        </TriggerModal>
      ) : (
        <StyledDownloadButton type="primary" onClick={onSubmit} loading={isSubmitting}>
          {determineButtonText()}
        </StyledDownloadButton>
      )}
    </StyledContainer>
  );
}

export default DownloadCard;
