import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Row, Space, Table } from "antd";
import { booleanToYesNo, populateKey } from "common/utils";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useDeleteAccountUdfCustomFieldMutation } from "features/userDefinedFields/agencyPortal/accountUserDefinedFieldsAPI";
import { useFetchAllAccountUdfCustomFieldsQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import {
  useDeleteCreditorUdfCustomFieldMutation,
  useFetchAllCreditorUdfCustomFieldsQuery,
} from "features/userDefinedFields/agencyPortal/creditorUserDefinedFieldsAPI";
import AddUserDefinedFieldModal from "features/userDefinedFields/components/addUserDefinedFieldModal";
import {
  useDeleteDebtorUdfCustomFieldMutation,
  useFetchAllDebtorUdfCustomFieldsQuery,
} from "features/userDefinedFields/agencyPortal/debtorUserDefinedFieldsAPI";
import { useFetchAllLegalCustomFieldsQuery } from "features/userDefinedFields/legalCustomFieldsAPI";
import { useMemo, useState } from "react";
import styled from "styled-components";

const StyledRow = styled(Row)``;

const StyledHeader = styled.h4`
  text-transform: capitalize;
`;

export default function UserDefinedFieldsTable({ forType }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: accountUdfCustomFields } = useFetchAllAccountUdfCustomFieldsQuery(
    {},
    { skip: forType !== "account" },
  );
  const { data: creditorUdfCustomFields } = useFetchAllCreditorUdfCustomFieldsQuery(
    {},
    { skip: forType !== "client" },
  );
  const { data: debtorUdfCustomFields } = useFetchAllDebtorUdfCustomFieldsQuery(
    {},
    { skip: forType !== "debtor" },
  );
  const { data: legalCustomFields } = useFetchAllLegalCustomFieldsQuery(
    {},
    { skip: forType !== "legal" },
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedUdf, setSelectedUdf] = useState(undefined);
  const [handleDeleteAccountUserDefinedField] = useDeleteAccountUdfCustomFieldMutation();
  const [handleDeleteDebtorUserDefinedField] = useDeleteDebtorUdfCustomFieldMutation();

  const udfCustomFields =
    {
      account: accountUdfCustomFields,
      client: creditorUdfCustomFields,
      debtor: debtorUdfCustomFields,
      legal: legalCustomFields,
    }[forType] ?? [];

  const handleDeleteUserDefinedField = {
    account: handleDeleteAccountUserDefinedField,
    client: useDeleteCreditorUdfCustomFieldMutation,
    debtor: handleDeleteDebtorUserDefinedField,
  }[forType];

  const onModalCancel = () => {
    setSelectedUdf(null);
    setModalVisible(false);
  };

  const onModalSubmit = () => {
    setSelectedUdf(null);
    setModalVisible(false);
  };

  const byCustomFieldTypeMap = useMemo(() => {
    return (constants?.customFieldTypes ?? []).reduce((acc, type) => {
      acc[type.value] = type;
      return acc;
    }, {});
  }, [constants]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text) => byCustomFieldTypeMap[text]?.display,
      key: "type",
    },
    {
      title: "Is Required?",
      dataIndex: "isRequired",
      key: "isRequired",
      render: (text) => booleanToYesNo(text),
    },
    {
      title: "Is Important?",
      dataIndex: "isImportant",
      key: "isImportant",
      render: (text) => booleanToYesNo(text),
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            key="edit"
            onClick={() => {
              setSelectedUdf(record);
              setModalVisible(true);
            }}
          />
          {/*
          // Temporary disabled due to lack of the endpoint
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this communication method?"
            onConfirm={() => handleDeleteUserDefinedField({ id: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
           */}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  const addLinkCaption =
    {
      account: "Add Account Field",
      debtor: "Add Debtor Field",
      client: "Add Client Field",
      legal: "Add Legal Field",
    }[forType] ?? "Add User Defined Field";

  return (
    <>
      <StyledRow align="middle">
        <StyledHeader>{forType?.toLowerCase()}</StyledHeader>
        <Button onClick={() => setModalVisible(true)} icon={<PlusOutlined />} type="link">
          {addLinkCaption}
        </Button>
      </StyledRow>
      <Table
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={populateKey(udfCustomFields)}
      />
      {isModalVisible && (
        <AddUserDefinedFieldModal
          onSubmit={onModalSubmit}
          onCancel={onModalCancel}
          userDefinedField={selectedUdf}
          forType={forType}
        />
      )}
    </>
  );
}
