import { PageHeader } from "components/pageHeader";
import { BulkActions } from "features/bulkActions";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function BulkActionsPage() {
  return (
    <PageLayout>
      <PageHeader>Bulk Action Jobs</PageHeader>
      <Content>
        <BulkActions />
      </Content>
    </PageLayout>
  );
}

export default BulkActionsPage;
