import api, { transformRequest, transformResponse } from "services/api";

export const backupDates = api
  .enhanceEndpoints({
    addTagTypes: ["BackupDates"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllBackupDates: build.query({
        query: (payload) => `/api/v1/common/backup-dates/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "BackupDates", id })),
                { type: "BackupDates", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'BackupDates', id: 'LIST' }` is invalidated
              [{ type: "BackupDates", id: "LIST" }],
      }),
      updateBackupDate: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/backup-dates/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "BackupDates", id: "LIST" }],
      }),
    }),

    overrideExisting: false,
  });

export const { useFetchAllBackupDatesQuery, useUpdateBackupDateMutation } = backupDates;
