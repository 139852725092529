import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table } from "antd";
import { capitalizeFirstLetter } from "common/utils";
import { PageHeader } from "components/pageHeader";
import {
  useDeleteAccountAssignmentRuleMutation,
  useFetchAccountAssignmentRulesQuery,
} from "features/accountAssignmentRulesTable/accountAssignmentRulesAPI";
import AccountAssignmentRuleModal from "features/accountAssignmentRulesTable/components/accountAssignmentRuleModal";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions/constants";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

export default function AccountAssignmentRulesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const { data: accountAssignmentRules, isLoading } = useFetchAccountAssignmentRulesQuery();
  const { data: constants } = useFetchBackendConstantsQuery();

  const [deleteAccountAssignmentRule] = useDeleteAccountAssignmentRuleMutation();

  const {
    data: {
      [PERMISSIONS.ACCOUNT_ASSIGNMENT_RULE__CREATE]: isAccountAssignmentRuleCreateAuthorized,
      [PERMISSIONS.ACCOUNT_ASSIGNMENT_RULE__UPDATE]: isAccountAssignmentRuleUpdateAuthorized,
      [PERMISSIONS.ACCOUNT_ASSIGNMENT_RULE__DELETE]: isAccountAssignmentRuleDeleteAuthorized,
    },
  } = useAuthorizations();

  const onAddNewRule = async () => {
    setActiveRecord(null);
    setModalVisible(true);
  };

  const onCloseModal = async () => {
    setActiveRecord(null);
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      render: (text, record) => {
        return record.assignee?.name ?? "-";
      },
    },
    {
      title: "CBR Class Codes",
      render: (text, record) => {
        const cbrClassCodes = constants?.cbrClassCodes.filter((cbrClassCode) => {
          return record.cbrClassCodes?.find(
            (existingCbrClassCode) => cbrClassCode.value === existingCbrClassCode,
          );
        });
        return cbrClassCodes?.map((cbrClassCode) => cbrClassCode.display)?.join(", ") ?? "-";
      },
      key: "cbrClassCode",
    },
    {
      title: "States",
      render: (text, record) => record.debtorStates?.join(", ") ?? "-",
      key: "state",
    },
    {
      title: "Alphabetical",
      children: [
        {
          title: "From",
          dataIndex: "minLastnameAlphabetical",
          key: "minLastnameAlphabetical",
        },
        {
          title: "To",
          dataIndex: "maxLastnameAlphabetical",
          key: "maxLastnameAlphabetical",
        },
      ],
    },
    {
      title: "Account Balance",
      children: [
        {
          title: "From",
          dataIndex: "minDebtBalance",
          key: "minDebtBalance",
        },
        {
          title: "To",
          dataIndex: "maxDebtBalance",
          key: "maxDebtBalance",
        },
      ],
    },
    {
      title: "Days Since Upload",
      children: [
        {
          title: "From",
          dataIndex: "minAccountAgeMonths",
          key: "minAccountAgeMonths",
        },
        {
          title: "To",
          dataIndex: "maxAccountAgeMonths",
          key: "maxAccountAgeMonths",
        },
      ],
    },
    {
      title: "Clients",
      render: (text, record) =>
        record.creditors?.map((creditor) => <p key={creditor.name}>{creditor.name}</p>) ?? "-",
      key: "creditors",
    },
    {
      title: "Language",
      render: (text, record) => record.languages?.map(capitalizeFirstLetter)?.join(", ") ?? "-",
      key: "language",
    },
    {
      title: "Actions",
      fixed: "right",
      key: "action",
      hidden: !isAccountAssignmentRuleUpdateAuthorized && !isAccountAssignmentRuleDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isAccountAssignmentRuleUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setActiveRecord(record);
                setModalVisible(true);
              }}
            />
          )}
          {isAccountAssignmentRuleDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this account assignment rule?"
              onConfirm={() => {
                deleteAccountAssignmentRule({ id: record.id });
              }}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  return (
    <>
      <StyledHeader>Account Assignment</StyledHeader>
      <StyledRow align="middle">
        <span>Define rules for how accounts get assigned.</span>
        {isAccountAssignmentRuleCreateAuthorized && (
          <Button onClick={onAddNewRule} icon={<PlusOutlined />} type="link">
            Add Rule
          </Button>
        )}
      </StyledRow>
      <Table
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={accountAssignmentRules}
      />
      {isModalVisible && (
        <AccountAssignmentRuleModal
          record={activeRecord}
          open={isModalVisible}
          onOk={onCloseModal}
          onCancel={onCloseModal}
        />
      )}
    </>
  );
}
