import { ArrowRightOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Collapse, Popconfirm, Row, Space, Table, Tag } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { camelToWords, capitalizeFirstLetter, idToObjectMap, snakeToCamelCase } from "common/utils";
import { useFetchMeQuery } from "features/auth/authAPI";
import {
  useDeleteBulkActionMutation,
  useFetchAllBulkActionsQuery,
  useFetchBulkActionHistoryQuery,
} from "features/bulkActions/bulkActionsAPI";
import AccountBulkActionsModal from "features/bulkActions/components/accountBulkActionsModal";
import DebtorBulkActionsModal from "features/bulkActions/components/debtorBulkActionsModal";
import SelectBulkActionModeModal from "features/bulkActions/components/selectBulkActionModeModal";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchCollectorsQuery } from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

const StyledTag = styled(Tag)`
  margin: 4px 4px;
`;

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background-color: #f5f5f5;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    align-items: center !important;
    padding-bottom: 0 !important;
    padding-left: 8px !important;
  }
`;

export default function BulkActions() {
  const [isAccountBulkActionsModalVisible, setAccountBulkActionsModalVisible] = useState(false);
  const [isDebtorBulkActionsModalVisible, setDebtorBulkActionsModalVisible] = useState(false);
  const [isSelectBulkActionModeModalVisible, setSelectBulkActionModeModalVisible] = useState(false);
  const { data: bulkActionsList, isLoading: isBulkActionsLoading } = useFetchAllBulkActionsQuery();
  const { data: bulkActionsHistoryData, isLoading: isBulkActionsHistoryLoading } =
    useFetchBulkActionHistoryQuery();
  const [deleteBulkAction] = useDeleteBulkActionMutation();
  const { data: collectors } = useFetchCollectorsQuery();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: me } = useFetchMeQuery();
  const {
    data: {
      [PERMISSIONS.ACCOUNT_BULK_ACTION__GET]: isAccountBulkActionGetAuthorized,
      [PERMISSIONS.ACCOUNT_BULK_ACTION__CREATE]: isAccountBulkActionCreateAuthorized,
    },
  } = useAuthorizations();

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors);
  }, [creditors]);

  const onApplyDebtorBulkActionsModalOk = () => {
    setDebtorBulkActionsModalVisible(false);
  };

  const onApplyDebtorBulkActionsModalCancel = () => {
    setDebtorBulkActionsModalVisible(false);
  };

  const onApplyAccountBulkActionsModalOk = () => {
    setAccountBulkActionsModalVisible(false);
  };

  const onApplyAccountBulkActionsModalCancel = () => {
    setAccountBulkActionsModalVisible(false);
  };

  const onSelectBulkActionModeOk = async (isDebtorMode) => {
    setSelectBulkActionModeModalVisible(false);

    if (isDebtorMode) {
      setDebtorBulkActionsModalVisible(true);
    } else {
      setAccountBulkActionsModalVisible(true);
    }
  };

  const onSelectBulkActionModeCancel = () => {
    setSelectBulkActionModeModalVisible(false);
  };

  const isInternalUser = me?.isAdmin; // Only internal users can see IDs

  const columns = [
    {
      title: "Job ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Operations",
      dataIndex: "operations",
      key: "operations",
      render: (_, record) =>
        Object.keys(record.operations).map((each) => <StyledTag>{camelToWords(each)}</StyledTag>),
    },
    // {
    //   title: "Scheduled Run Date",
    //   dataIndex: "oneOffScheduledAt",
    //   key: "oneOffScheduledAt",
    //   render: (oneOffScheduledAt) =>
    //     oneOffScheduledAt ? moment(oneOffScheduledAt).format(DATE_TIME_FORMAT) : "-",
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format(DATE_TIME_FORMAT),
    },
    {
      title: "Account Criteria",
      width: 600,
      dataIndex: "criteria",
      key: "criteria",
      render: (_, record) => {
        return Object.keys(record.filters).map((filter) => {
          if (filter === "assigneeIds") {
            const collectorNames = record.filters[filter].map((collectorId) => {
              if (collectorId === null) return "Unassigned";
              return collectorFullName(collectors?.find((x) => x.id === collectorId));
            });
            return (
              <StyledTag>
                <b>Assignees: </b>
                {collectorNames.join(", ")}
              </StyledTag>
            );
          }
          if (filter === "debtorStates") {
            return (
              <StyledTag>
                <b>Debtor States: </b>
                {record.filters[filter].join(", ")}
              </StyledTag>
            );
          }
          if (filter === "creditorIds") {
            const creditorNames = record.filters[filter].map((creditorId) => {
              const creditor = creditorIdToCreditorMap[creditorId];
              return creditor?.code ? `${creditor?.name} (${creditor?.code})` : creditor?.name;
            });
            return (
              <StyledTag>
                <b>Creditors: </b>
                {creditorNames.join(", ")}
              </StyledTag>
            );
          }
          if (
            [
              "excludeStatusCodes",
              "includeStatusCodes",
              "collectionDebtTypes",
              "debtorTagIds",
              "excludeDebtorTagIds",
              "tagIds",
              "excludeTagIds",
            ].includes(filter)
          ) {
            return (
              <StyledTag>
                <b>{camelToWords(filter)}: </b>
                {record.filters[filter].join(", ")}
              </StyledTag>
            );
          }
          if (filter.startsWith("is") || filter.startsWith("has")) {
            return <StyledTag>{camelToWords(filter)}</StyledTag>;
          }
          return (
            <StyledTag>
              <b>{camelToWords(filter)}: </b>
              {record.filters[filter]}
            </StyledTag>
          );
        });
      },
    },
    {
      title: "Actions",
      fixed: "right",
      align: "center",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        // TODO: Replace with actual logic
        const isDebtorLevelBulkAction = record.actionType === "debtor";

        return (
          <Space size="middle">
            {/* <EditOutlined
            key="edit"
            onClick={() => {
              setAccountBulkActionsModalVisible(record);
            }}
          /> */}
            <Space>
              {isDebtorLevelBulkAction && (
                <>
                  <Button type="default" icon={<DownloadOutlined />} onClick={() => null}>
                    Download Preview
                  </Button>
                  <Button type="primary" onClick={() => null}>
                    Approve
                  </Button>
                </>
              )}
              <Popconfirm
                placement="topLeft"
                okText="Yes"
                title="Are you sure you want to delete this job?"
                onConfirm={() => deleteBulkAction({ actionId: record.id })}
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>
            </Space>
          </Space>
        );
      },
    },
  ];

  const historyColumns = [
    {
      title: "Job ID",
      dataIndex: "actionId",
      key: "actionId",
      hidden: !isInternalUser,
    },
    {
      title: "Task ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Job Type",
      dataIndex: "actionType",
      key: "actionType",
      render: (actionType) => capitalizeFirstLetter(actionType),
    },
    {
      title: "Filter Criteria",
      width: 600,
      dataIndex: "criteria",
      key: "criteria",
      render: (_, record) => {
        return Object.keys(record.filters).map((filter) => {
          if (filter === "assigneeIds") {
            const collectorNames = record.filters[filter].map((collectorId) => {
              if (collectorId === null) return "Unassigned";
              return collectorFullName(collectors?.find((x) => x.id === collectorId));
            });
            return (
              <StyledTag>
                <b>Assignees: </b>
                {collectorNames.join(", ")}
              </StyledTag>
            );
          }
          if (filter === "debtorStates") {
            return (
              <StyledTag>
                <b>Debtor States: </b>
                {record.filters[filter].join(", ")}
              </StyledTag>
            );
          }
          if (filter === "creditorIds") {
            const creditorNames = record.filters[filter].map((creditorId) => {
              const creditor = creditorIdToCreditorMap[creditorId];
              return creditor?.code ? `${creditor?.name} (${creditor?.code})` : creditor?.name;
            });
            return (
              <StyledTag>
                <b>Creditors: </b>
                {creditorNames.join(", ")}
              </StyledTag>
            );
          }
          if (
            [
              "excludeStatusCodes",
              "includeStatusCodes",
              "collectionDebtTypes",
              "tagIds",
              "excludeTagIds",
              "debtorTagIds",
              "excludeDebtorTagIds",
            ].includes(filter)
          ) {
            return (
              <StyledTag>
                <b>{camelToWords(filter)}: </b>
                {record.filters[filter].join(", ")}
              </StyledTag>
            );
          }
          if (filter.startsWith("is") || filter.startsWith("has")) {
            return <StyledTag>{camelToWords(filter)}</StyledTag>;
          }
          return (
            <StyledTag>
              <b>{camelToWords(filter)}: </b>
              {record.filters[filter]}
            </StyledTag>
          );
        });
      },
    },

    {
      title: "Operations",
      dataIndex: "operations",
      key: "operations",
      render: (_, record) => {
        return record.operations
          ? Object.keys(record.operations).map((each) => (
              <StyledTag>{camelToWords(each)}</StyledTag>
            ))
          : "-";
      },
    },
    {
      title: "# of Records Affected",
      dataIndex: "recordIds",
      key: "recordIds",
      render: (_, record) => {
        if (record.actionType === "debtor") {
          return record.debtorIds.length;
        }
        if (record.actionType === "account") {
          return record.accountIds.length;
        }
        return "-";
      },
    },
    {
      title: "Executed At",
      dataIndex: "executedAt",
      key: "executedAt",
      render: (executedAt) => (executedAt ? moment(executedAt).format(DATE_TIME_FORMAT) : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "success" || status === "failed") {
          return (
            <StyledTag color={status === "success" ? "green" : "red"}>
              {capitalizeFirstLetter(status)}
            </StyledTag>
          );
        }
        return (
          <StyledTag>{capitalizeFirstLetter(camelToWords(snakeToCamelCase(status)))}</StyledTag>
        );
      },
    },
  ].filter((column) => !column.hidden);

  return isAccountBulkActionGetAuthorized ? (
    <>
      <Row align="middle">
        <span>Apply or schedule actions on a specified list of accounts. </span>
        {isAccountBulkActionCreateAuthorized && (
          <Button
            onClick={() => setSelectBulkActionModeModalVisible(true)}
            type="link"
            icon={<ArrowRightOutlined />}
          >
            Configure New Job
          </Button>
        )}
      </Row>
      <h3>Pending Jobs</h3>
      <StyledTable
        scroll={{ x: "max-content" }}
        loading={isBulkActionsLoading}
        bordered
        // @ts-ignore
        columns={columns}
        dataSource={bulkActionsList}
      />
      <StyledCollapse defaultActiveKey="history" bordered={false}>
        <StyledPanel header={<h3>Job History</h3>} key="history">
          <Table
            scroll={{ x: "max-content" }}
            loading={isBulkActionsHistoryLoading}
            columns={historyColumns}
            bordered
            dataSource={bulkActionsHistoryData}
          />
        </StyledPanel>
      </StyledCollapse>
      <SelectBulkActionModeModal
        title="Configure New Job"
        open={isSelectBulkActionModeModalVisible}
        onOk={onSelectBulkActionModeOk}
        onCancel={onSelectBulkActionModeCancel}
      />
      {isAccountBulkActionsModalVisible && (
        <AccountBulkActionsModal
          title="Account Bulk Actions"
          open={isAccountBulkActionsModalVisible}
          onOk={onApplyAccountBulkActionsModalOk}
          onCancel={onApplyAccountBulkActionsModalCancel}
        />
      )}
      {isDebtorBulkActionsModalVisible && (
        <DebtorBulkActionsModal
          title="Debtor Bulk Actions"
          open={isDebtorBulkActionsModalVisible}
          onOk={onApplyDebtorBulkActionsModalOk}
          onCancel={onApplyDebtorBulkActionsModalCancel}
        />
      )}
    </>
  ) : null;
}
