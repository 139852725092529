import WorklistTable from "features/workList/worklistTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function WorklistPage() {
  return (
    <PageLayout>
      <Content>
        <WorklistTable />
      </Content>
    </PageLayout>
  );
}

export default WorklistPage;
