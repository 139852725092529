import { Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select } from "antd";
import {
  DEFAULT_LOGIN_REDIRECT_SCREEN,
  DEFAULT_LOGIN_REDIRECT_SCREEN_OPTIONS,
} from "common/constants";
import { toTitleCase } from "common/utils";
import {
  useCreateRoleMutation,
  useFetchAllPermissionsQuery,
  useUpdateRoleMutation,
} from "features/userManagementTable/userManagementAPI";
import {
  groupExistingPermissions,
  groupPermissionOptions,
} from "features/userManagementTable/utils";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const StyledCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
  margin-left: 20px;
  margin-top: 5px;
  & label {
    flex: 1;
  }

  & span:nth-child(2) {
    white-space: pre;
  }
`;

const StyledPermissionsHeader = styled.h5`
  font-size: 14px;
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
`;

const FormFieldsContainer = styled.div`
  max-width: 400px;
`;

export default function RoleModal({ title, open, onOk, onCancel, selectedRole }) {
  const [form] = Form.useForm();
  const [userType, setUserType] = useState("agency");
  const { data: allPermissions } = useFetchAllPermissionsQuery();
  const [createRole] = useCreateRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const roleUserType = selectedRole?.userType || userType;
  const permissions =
    roleUserType === "creditor" ? allPermissions.filter((x) => x.isCreditor) : allPermissions;

  const permissionOptionsGroup = useMemo(() => {
    return permissions ? groupPermissionOptions(permissions) : {};
  }, [permissions]);

  const onCheckAllChange = (event, groupKey, allOptions) => {
    if (event.target.checked) {
      form.setFieldValue(["permissions", groupKey], allOptions);
    } else {
      form.setFieldValue(["permissions", groupKey], []);
    }
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    const result = !selectedRole
      ? await createRole({
          ...values,
          permissions: Object.values(values.permissions).flat(),
        })
      : await updateRole({
          roleId: selectedRole?.id,
          ...values,
          permissions: Object.values(values.permissions).flat(),
        });
    if ("data" in result) {
      const action = selectedRole ? "updated" : "created";
      message.success(`Role ${action} successfully!`);
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      const action = selectedRole ? "update" : "create";
      message.error(`Failed to ${action} role.`);
    }
  };
  const onModalCancel = async () => {
    onCancel();
  };

  const initialValues = {
    userType,
    name: "",
    description: "",
    defaultLoginRedirectScreen:
      selectedRole?.defaultLoginRedirectScreen || DEFAULT_LOGIN_REDIRECT_SCREEN,
    permissions: Object.keys(permissionOptionsGroup)?.reduce((acc, groupKey) => {
      acc[groupKey] = [];
      return acc;
    }, {}),
  };

  useEffect(() => {
    if (selectedRole) {
      form.setFieldsValue({
        ...selectedRole,
        permissions: groupExistingPermissions(
          selectedRole.permissions,
          Object.keys(permissionOptionsGroup),
        ),
      });
    } else {
      form.resetFields();
    }
  }, [form, selectedRole, permissionOptionsGroup]);

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onModalCancel}
      width={800}
    >
      <StyledForm
        initialValues={initialValues}
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
      >
        <FormFieldsContainer>
          <Form.Item name="userType" label="User Type" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  label: "Agency",
                  value: "agency",
                },
                {
                  label: "Client",
                  value: "creditor",
                },
              ]}
              onChange={(value) => setUserType(value)}
            />
          </Form.Item>
          <Form.Item name="name" label="Role Name" rules={[{ required: true }]}>
            <Input placeholder="Enter Name..." />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Enter Description..." />
          </Form.Item>
          <Form.Item name="defaultLoginRedirectScreen" label="Default Loading Redirect Screen">
            <Select options={DEFAULT_LOGIN_REDIRECT_SCREEN_OPTIONS} />
          </Form.Item>
        </FormFieldsContainer>
        <StyledPermissionsHeader>Permissions</StyledPermissionsHeader>
        <Divider />
        {Object.entries(permissionOptionsGroup).map(([groupKey, options]) => {
          return (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.permissions?.[groupKey] !== currentValues.permissions?.[groupKey]
              }
            >
              {({ getFieldValue }) => {
                const allOptions = options.map((x) => x.value);
                const checkedList = getFieldValue(["permissions", groupKey]);
                const isCheckedAll = checkedList?.length === options.length;
                const isIndeterminate =
                  checkedList?.length > 0 && checkedList?.length < options.length;

                return (
                  <Col key={groupKey}>
                    <Row>
                      <Checkbox
                        indeterminate={isIndeterminate}
                        onChange={(event) => onCheckAllChange(event, groupKey, allOptions)}
                        checked={isCheckedAll}
                      >
                        {toTitleCase({ str: groupKey, delimiter: "_" })}
                      </Checkbox>
                    </Row>
                    <Form.Item name={["permissions", groupKey]}>
                      <StyledCheckboxGroup>
                        <Row>
                          {options.map((option) => (
                            <Col key={option.value} span={12}>
                              <Checkbox value={option.value}>{option.display}</Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </StyledCheckboxGroup>
                    </Form.Item>
                    <Divider />
                  </Col>
                );
              }}
            </Form.Item>
          );
        })}
      </StyledForm>
    </Modal>
  );
}
