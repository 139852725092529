export default [
  {
    key: "account_id",
    label: "External Account ID",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "debtor_id",
    label: "External Debtor ID",
    type: "string",
  },
  {
    key: "call_notes",
    label: "Call Notes",
    type: "string",
  },
  {
    key: "call_result",
    label: "Call Result",
    type: "string",
  },
  {
    key: "call_time",
    label: "Call Time",
    type: "datetime",
    validators: [{ validate: "required" }],
  },
];
