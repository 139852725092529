import { DisputeManagementTable } from "features/disputeManagementTable";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";

function DisputeManagementPage() {
  return (
    <PageLayout>
      <Content>
        <DisputeManagementTable />
      </Content>
    </PageLayout>
  );
}

export default DisputeManagementPage;
