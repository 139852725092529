import api, { transformRequest, transformResponse } from "services/api";

export const worklistApi = api.enhanceEndpoints({ addTagTypes: ["Worklists"] }).injectEndpoints({
  endpoints: (build) => ({
    fetchWorklists: build.query({
      query: (payload) => ({
        url: `/api/v1/agency/worklists/`,
        body: transformRequest(payload),
        method: "POST",
      }),
      transformResponse: (response) => transformResponse(response),
      providesTags: (results) =>
        results
          ? // successful query
            [
              ...results.map(({ id }) => ({ type: "Worklists", id })),
              { type: "Worklists", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Worklists', id: 'LIST' }` is invalidated
            [{ type: "Worklists", id: "LIST" }],
    }),
    updateWorklist: build.mutation({
      query: (payload) => ({
        url: `/api/v1/agency/worklists/${payload.id}/update/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all Worklists-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Worklists", id: "LIST" }],
    }),
    deleteWorklist: build.mutation({
      query: (payload) => ({
        url: `/api/v1/agency/worklists/${payload.id}/delete/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      // Invalidates all Worklists-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Worklists", id: "LIST" }],
    }),
    createWorklist: build.mutation({
      query: (payload) => ({
        url: `/api/v1/agency/worklists/create/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all Worklists-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Worklists", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateWorklistMutation,
  useFetchWorklistsQuery,
  useUpdateWorklistMutation,
  useDeleteWorklistMutation,
} = worklistApi;
