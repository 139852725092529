import { Layout } from "antd";
import Header from "layouts/agencyPortal/dashboard/header";
import styled from "styled-components";

const StyledContent = styled(Layout.Content)`
  padding: 8px 24px;
  min-height: 280px;
`;

const StyledLayout = styled(Layout)`
  height: 100vh;
  position: relative;
`;

const StyledInnerLayout = styled(Layout)`
  top: 64px;
  position: absolute;
  width: 100%;
  min-height: calc(100vh - 64px);
  overflow: hidden;
`;

function PageLayout({ children }) {
  return (
    <StyledLayout>
      <Header />
      <StyledInnerLayout>
        <StyledContent>{children}</StyledContent>
      </StyledInnerLayout>
    </StyledLayout>
  );
}

export default PageLayout;
