import { Button, Form, Input, Modal, Select, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { getCustomFieldsItems, udfToComponentMap } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useUserType } from "features/auth";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useUpdateAccountMutation } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchAllAccountUdfCustomFieldsQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useMemo } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function UpdateAccountsModal({ title, onCancel, onOk, account }) {
  const [form] = Form.useForm();
  const [updateAccountDebt, { isLoading }] = useUpdateAccountMutation();
  const { data: currentCreditor } = useFetchCreditorQuery(
    { creditorId: account?.creditorId },
    { skip: !account?.id },
  );
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const { data: accountCustomFields } = useFetchAllAccountUdfCustomFieldsQuery();
  const initialValues = {
    ...account,
    accountId: account?.id,
  };
  const { data: isChangeProductOfferingAuthorized } = useAuthorization(
    PERMISSIONS.ACCOUNT__UPDATE_PRODUCT_OFFERING,
  );
  const { isCreditorUserType } = useUserType();
  const onSubmit = async () => {
    const values = await form.validateFields();
    const result = await updateAccountDebt({ ...values, accountId: account.id });

    if ("data" in result) {
      message.success("Processing complete!");
      form.resetFields();
      onOk();
    }

    if ("error" in result) {
      message.error("Failed to update account");
      form.setFields(formatError(result.error));
    }
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const isChangeProductDisabled = isCreditorUserType || !isChangeProductOfferingAuthorized;

  const modalFooter = [
    <Button key="back" onClick={onCancel}>
      Back
    </Button>,
    <Button key="submit" type="primary" onClick={onSubmit} loading={isLoading}>
      Update
    </Button>,
  ];

  const customFieldItems = useMemo(
    () =>
      getCustomFieldsItems(account.customFields, accountCustomFields, {
        includeAll: true,
      }),
    [account, accountCustomFields],
  );

  return (
    <Modal
      maskClosable={false}
      title={title}
      open
      onOk={onSubmit}
      onCancel={onCancel}
      footer={modalFooter}
      width={700}
    >
      <StyledForm
        form={form}
        layout="vertical"
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <Form.Item name="accountId" label="Account ID">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="originalCreditorName"
          label="Original Creditor Name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="currentCreditorName"
          label="Current Creditor Name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="productOfferingId"
          label="Product"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={
              productOfferings?.map((offering) => ({
                label: offering.name,
                value: offering.id,
              })) ?? []
            }
            disabled={isChangeProductDisabled}
          />
        </Form.Item>
        <Form.Item
          label="Client Reference ID"
          name="clientReferenceId"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date of Service"
          name="dateOfService"
          tooltip="The date the service was performed."
        >
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Turnover Date" name="turnoverDate">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Itemization Date" name="itemizationDate" rules={[{ required: true }]}>
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Date of First Delinquency" name="dateOfFirstDelinquency">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Charge-off Date" name="chargeOffDate">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Interest Rate" name="interestRate">
          <AktPercentageInput />
        </Form.Item>
        <Form.Item label="Interest Start Date" name="interestStartDate">
          <AktDatePicker />
        </Form.Item>
        <Form.Item label="Interest End Date" name="interestEndDate">
          <AktDatePicker />
        </Form.Item>
        {customFieldItems.map(({ field, slug }) => (
          <Form.Item
            key={field.id}
            label={field.name}
            name={["customFields", slug]}
            rules={[{ required: field.isRequired }]}
          >
            {udfToComponentMap[field.type]}
          </Form.Item>
        ))}
      </StyledForm>
    </Modal>
  );
}

export default UpdateAccountsModal;
