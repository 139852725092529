import { DeleteOutlined } from "@ant-design/icons";
import { message, Popconfirm, Space, Table } from "antd";
import {
  useDeleteCreditorCostMutation,
  useFetchCreditorCostsQuery,
} from "features/creditorProfile/creditorProfileAPI";

import { useFetchCollectorsQuery } from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";

export default function CreditorCostsTable({ currentCreditor }) {
  const creditorId = currentCreditor?.id;
  const { data: creditorCosts } = useFetchCreditorCostsQuery(
    {
      creditorId,
    },
    {
      skip: !creditorId,
    },
  );
  const [deleteCreditorCost] = useDeleteCreditorCostMutation();
  const { data: collectors } = useFetchCollectorsQuery();

  const handleDeleteCreditorCost = async ({ creditorCostId }) => {
    const result = await deleteCreditorCost({ creditorCostId });
    if ("data" in result) {
      message.success("Client cost deleted successfully!");
    }
  };

  const creditorCostColumns = [
    {
      title: "Account ID",
      key: "accountId",
      dataIndex: "accountId",
    },
    {
      title: "Effective Date",
      key: "effectiveDate",
      dataIndex: "effectiveDate",
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Collector",
      dataIndex: "collector",
      key: "collector",
      render: (_, record) => {
        const collector = collectors?.find((c) => c.id === record.createdById);
        return collectorFullName(collector);
      },
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this client cost?"
            onConfirm={() => handleDeleteCreditorCost({ creditorCostId: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      // @ts-ignore
      columns={creditorCostColumns}
      bordered
      scroll={{ x: "max-content" }}
      dataSource={creditorCosts}
      pagination={false}
    />
  );
}
