import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Table, message } from "antd";
import { renderAddress } from "common/utils";
import { PageHeader } from "components/pageHeader";
import EmployerModal from "features/employersTable/components/employerModal";
import {
  useDeleteEmployerMutation,
  useGetEmployersQuery,
} from "features/employersTable/employersAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

const StyledInfoTitle = styled.div`
  color: grey;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

export default function EmployersTable() {
  const { data: employers, isLoading } = useGetEmployersQuery();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [deleteEmployer] = useDeleteEmployerMutation();
  const {
    data: {
      [PERMISSIONS.EMPLOYER__GET]: isGetEmployerAuthorized,
      [PERMISSIONS.EMPLOYER__CREATE]: isCreateEmployerAuthorized,
      [PERMISSIONS.EMPLOYER__UPDATE]: isUpdateEmployerAuthorized,
      [PERMISSIONS.EMPLOYER__DELETE]: isDeleteEmployerAuthorized,
    },
  } = useAuthorizations();

  const onNewEmployer = () => {
    setModalVisible(false);
    setSelectedEmployer(null);
  };

  const onCancelEmployer = () => {
    setModalVisible(false);
    setSelectedEmployer(null);
  };

  const handleDelete = async (record) => {
    const result = await deleteEmployer({ employerId: record.id });

    if ("data" in result) {
      message.success("Employer deleted successfully.");
    }
  };

  const columns = [
    {
      title: "Employer Code",
      dataIndex: "code",
      key: "code",
      render: (text) => text || "-",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text || "-",
    },
    {
      title: "EIN",
      dataIndex: "ein",
      key: "ein",
      render: (text) => text || "-",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      render: (text) => text || "-",
    },
    {
      title: "Address",
      key: "address",
      render: (text, record) => renderAddress(record?.address, constants?.states),
    },
    {
      title: "Contact",
      key: "contact",
      render: (text, record) => {
        if (!record.contact) return "-";

        return (
          <Col>
            <Row align="middle">
              <StyledInfoTitle>Name:</StyledInfoTitle>
              <StyledInfoValue>{record.contact.name ?? "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Title:</StyledInfoTitle>
              <StyledInfoValue>{record.contact.title ?? "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Work Phone:</StyledInfoTitle>
              <StyledInfoValue>{record.contact.workPhone ?? "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Cell Phone:</StyledInfoTitle>
              <StyledInfoValue>{record.contact.cellPhone ?? "-"}</StyledInfoValue>
            </Row>
          </Col>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isUpdateEmployerAuthorized && !isDeleteEmployerAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isUpdateEmployerAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedEmployer(record);
                setModalVisible(true);
              }}
            />
          )}
          {isDeleteEmployerAuthorized && (
            <Popconfirm
              okText="Yes"
              placement="topLeft"
              title="Are you sure you want to delete this employer?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  return isGetEmployerAuthorized ? (
    <Col>
      <PageHeader>Employers</PageHeader>
      <Row align="middle">
        <span>Configure employers and relationships.</span>
        {isCreateEmployerAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Employer
          </Button>
        )}
      </Row>
      {isModalVisible && (
        <EmployerModal
          title={selectedEmployer ? "Edit Employer" : "Add Employer"}
          onOk={onNewEmployer}
          onCancel={onCancelEmployer}
          selectedEmployer={selectedEmployer}
        />
      )}
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={employers}
      />
    </Col>
  ) : null;
}
