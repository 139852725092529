import { PageHeader } from "components/pageHeader";
import DataImport from "features/dataImport/creditorPortal/dataImport";
import Content from "layouts/creditorPortal/dashboard/content";
import PageLayout from "layouts/creditorPortal/dashboard/pageLayout";

export default function DataImportPage() {
  return (
    <PageLayout>
      <PageHeader>Data Import</PageHeader>
      <Content>
        <DataImport />
      </Content>
    </PageLayout>
  );
}
