import { Form, Input, message, Modal, Select } from "antd";
import {
  DEFAULT_LOGIN_REDIRECT_SCREEN,
  DEFAULT_LOGIN_REDIRECT_SCREEN_OPTIONS,
} from "common/constants";
import {
  useCreateUserMutation,
  useFetchRolesQuery,
  useUpdateUserMutation,
} from "features/userManagementTable/userManagementAPI";
import { useEffect } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

export default function UserModal({ title, open, onOk, onCancel, selectedUser }) {
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [form] = Form.useForm();
  const { data: roles } = useFetchRolesQuery();

  const initialValues = {
    type: "agency",
    firstName: selectedUser?.firstName,
    lastName: selectedUser?.lastName,
    email: selectedUser?.email,
    phoneNumber: selectedUser?.phoneNumber,
    phoneExtension: selectedUser?.phoneExtension,
    defaultLoginRedirectScreen:
      selectedUser?.defaultLoginRedirectScreen || DEFAULT_LOGIN_REDIRECT_SCREEN,
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    const action = selectedUser !== null ? updateUser : createUser;
    const result = await action({
      userId: selectedUser?.id,
      ...values,
    });
    if ("data" in result) {
      message.success(`User ${selectedUser ? "updated" : "created"} successfully.`);
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      message.error(`Failed to ${selectedUser ? "update" : "create"} user.`);
    }
  };
  const onModalCancel = async () => {
    onCancel();
  };

  useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue(selectedUser);
    } else {
      form.resetFields();
    }
  }, [form, selectedUser]);

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onModalCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item name="type" label="Account Type">
          <Select
            disabled={!!selectedUser}
            options={[
              {
                label: "Agency",
                value: "agency",
              },
              {
                label: "Client",
                value: "creditor",
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true }]}>
          <Input placeholder="XXX-XXX-XXXX" />
        </Form.Item>
        <Form.Item name="phoneExtension" label="Phone Extension">
          <Input />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) => {
            const userType = getFieldValue("type");

            return (
              <Form.Item name="groupIds" label="Roles">
                <Select
                  popupMatchSelectWidth={false}
                  allowClear
                  mode="multiple"
                  options={roles
                    ?.filter((x) => x.userType === userType)
                    .map((x) => ({ label: x.name, value: x.id }))}
                  placeholder="Select roles"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="defaultLoginRedirectScreen" label="Default Login Redirect Screen">
          <Select options={DEFAULT_LOGIN_REDIRECT_SCREEN_OPTIONS} />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
