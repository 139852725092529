import React, { useCallback, useMemo, useState } from "react";

// @ts-ignore
const FormWizardContext = React.createContext();
FormWizardContext.displayName = "FormWizardContext";

function FormWizardProvider(props) {
  const { steps, onCancel, onDone, initialValues, canSkipSteps } = props;
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const onStepChange = useCallback((value) => {
    setError(null);
    setCurrent(value);
  }, []);

  const onNextStep = useCallback(() => {
    setCurrent((prev) => prev + 1);
  }, []);

  const onPreviousStep = useCallback(() => {
    setCurrent((prev) => prev - 1);
  }, []);

  const value = useMemo(
    () => ({
      canSkipSteps,
      initialValues,
      onCancel,
      current,
      steps,
      onStepChange,
      onNextStep,
      onDone,
      isLoading,
      setIsLoading,
      error,
      setError,
      onPreviousStep,
    }),
    [
      canSkipSteps,
      initialValues,
      onCancel,
      current,
      steps,
      onStepChange,
      onNextStep,
      onDone,
      isLoading,
      error,
      onPreviousStep,
    ],
  );

  return <FormWizardContext.Provider value={value} {...props} />;
}

function useFormWizard() {
  const context = React.useContext(FormWizardContext);
  if (context === undefined) {
    throw new Error(`useFormWizard must be used within a FormWizardProvider`);
  }
  return context;
}

export { FormWizardProvider, useFormWizard };
