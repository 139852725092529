import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Tooltip as AntDToolTip,
  Button,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import AddDisputeCategoryTagModal from "features/tags/components/addDisputeCategoryTagModal";
import {
  useDeleteDisputeCategoryTagMutation,
  useFetchDisputeCategoryTagsQuery,
} from "features/tags/tagsAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
`;

export default function DisputeCategoryTagTable() {
  const { data: DisputeCategoryTags, isLoading } = useFetchDisputeCategoryTagsQuery();
  const [deleteDisputeCategoryTag] = useDeleteDisputeCategoryTagMutation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const {
    data: {
      [PERMISSIONS.DISPUTE_CATEGORY_TAG__GET]: isDisputeCategoryTagGetAuthorized,
      [PERMISSIONS.DISPUTE_CATEGORY_TAG__CREATE]: isDisputeCategoryTagCreateAuthorized,
      [PERMISSIONS.DISPUTE_CATEGORY_TAG__UPDATE]: isDisputeCategoryTagUpdateAuthorized,
      [PERMISSIONS.DISPUTE_CATEGORY_TAG__DELETE]: isDisputeCategoryTagDeleteAuthorized,
    },
  } = useAuthorizations();

  const onNewDisputeCategoryTag = async () => {
    setModalVisible(false);
  };

  const handleDelete = async (record) => {
    const result = await deleteDisputeCategoryTag({ id: record.id });
    if ("data" in result) {
      message.success("Deleted Dispute Category tag successfully.");
    }
    if ("error" in result) {
      message.error("Unable to delete Dispute Category tag.");
    }
  };

  const onEdit = (record) => {
    setModalVisible(true);
    setActiveRecord(record);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space size="middle">
          {text}
          {record.isSystem && <Tag color="red">SYSTEM</Tag>}
        </Space>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isDisputeCategoryTagUpdateAuthorized && !isDisputeCategoryTagDeleteAuthorized,
      render: (text, record) => (
        <Space size="middle">
          {isDisputeCategoryTagUpdateAuthorized && !record.isSystem && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onEdit(record)} />
            </AntDToolTip>
          )}
          {isDisputeCategoryTagDeleteAuthorized && !record.isSystem && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this dispute category tag?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete-DisputeCategory-tag" />
            </Popconfirm>
          )}
        </Space>
      ),
      fixed: "right",
    },
  ].filter((column) => !column.hidden);

  return isDisputeCategoryTagGetAuthorized ? (
    <Col>
      <Row align="middle">
        <h4>Dispute Categories</h4>
        {isDisputeCategoryTagCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Dispute Category Tag
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={DisputeCategoryTags}
      />
      {isModalVisible && (
        <AddDisputeCategoryTagModal
          title={activeRecord ? "Edit Dispute Category Tag" : "Add Dispute Category Tag"}
          open
          onOk={onNewDisputeCategoryTag}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          disputeCategoryTag={activeRecord}
        />
      )}
    </Col>
  ) : null;
}
