import { ArrowRightOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { useGetDebtorCreditReportsQuery } from "features/debtorCreditReportTable/debtorCreditReportAPI";
import { useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import GenerateDebtorCreditReportModal from "features/debtorCreditReportTable/generateDebtorCreditReportModal";
import ViewDebtorCreditReportModal from "features/debtorCreditReportTable/viewDebtorCreditReportModal";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";

const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

const StyledTable = styled(Table)`
  & .ant-table-cell:not(th) {
    padding: 0px 8px !important;
  }
  cursor: "pointer";
`;

const StyledZoomInOutlined = styled(ZoomInOutlined)`
  color: gray;
  cursor: pointer;
`;

function DebtorCreditReportsTable({ debtorId }) {
  const { data: debtorCreditReports, isLoading: isDebtorCreditReportsLoading } =
    useGetDebtorCreditReportsQuery({ debtorId });

  const { data: isDebtorCreditReportGenerateAuthorized } = useAuthorization(
    PERMISSIONS.DEBTOR_CREDIT_REPORT__GENERATE,
  );
  const { data: constants } = useFetchBackendConstantsQuery();
  const [debtorCreditReport, setDebtorCreditReport] = useState(null);

  const [isGenerateDebtorCreditReportModalVisible, setGenerateDebtorCreditReportModalVisible] =
    useState(false);

  const onGenerateDebtorCreditReportOk = () => {
    setGenerateDebtorCreditReportModalVisible(false);
  };

  const onGenerateDebtorCreditReportCancel = () => {
    setGenerateDebtorCreditReportModalVisible(false);
  };

  const onViewDebtorCreditReportClose = () => {
    setViewDebtorCreditReportDataModalVisible(false);
    setDebtorCreditReport(null);
  };

  const [isViewDebtorCreditReportDataModalVisible, setViewDebtorCreditReportDataModalVisible] =
    useState(false);

  const columns = [
    {
      title: "Generation Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, _) => moment(text).format(DATE_TIME_FORMAT),
    },
    {
      title: "Credit Bureau",
      dataIndex: "creditBureauType",
      key: "creditBureauType",
      render: (text, _) =>
        constants?.creditBureauTypes?.find((type) => type.value === text)?.display,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Button
            type="link"
            title="View Data"
            icon={<StyledZoomInOutlined />}
            onClick={() => {
              setDebtorCreditReport(record);
              setViewDebtorCreditReportDataModalVisible(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      {isDebtorCreditReportGenerateAuthorized && (
        <StyledButton
          type="link"
          onClick={() => setGenerateDebtorCreditReportModalVisible(true)}
          icon={<ArrowRightOutlined />}
        >
          Generate Credit Report
        </StyledButton>
      )}
      <StyledTable
        bordered
        loading={isDebtorCreditReportsLoading}
        // @ts-ignore
        columns={columns}
        dataSource={debtorCreditReports}
        scroll={{ x: "max-content" }}
      />
      <GenerateDebtorCreditReportModal
        debtorId={debtorId}
        title="Generate Credit Report"
        open={isGenerateDebtorCreditReportModalVisible}
        onOk={onGenerateDebtorCreditReportOk}
        onCancel={onGenerateDebtorCreditReportCancel}
      />
      <ViewDebtorCreditReportModal
        title="View Details"
        open={isViewDebtorCreditReportDataModalVisible}
        record={debtorCreditReport}
        onOk={onViewDebtorCreditReportClose}
        onCancel={onViewDebtorCreditReportClose}
      />
    </>
  );
}

export default DebtorCreditReportsTable;
