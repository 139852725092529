import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table } from "antd";
import { getColumnSearchProps } from "common/antd-utils";
import AddFeePlanRulesetModal from "features/feePlans/components/addFeePlanRulesetModal";
import { FeePlanRulesTable } from "features/feePlans/components/feePlanRulesetTable";
import {
  useDeleteFeePlanRuleSetMutation,
  useFetchFeePlanRuleSetsQuery,
  useFetchFeePlansQuery,
} from "features/feePlans/feePlansAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useCallback, useRef, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function FeePlanRuleset() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRuleSet, setSelectedRuleSet] = useState(null);
  const [deleteFeePlanRuleSet] = useDeleteFeePlanRuleSetMutation();
  const { data: feePlanRuleSets, isLoading } = useFetchFeePlanRuleSetsQuery();
  const { data: feePlans } = useFetchFeePlansQuery();
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const {
    data: {
      [PERMISSIONS.FEE_PLAN_RULE__GET]: isFeePlanRuleGetAuthorized,
      [PERMISSIONS.FEE_PLAN_RULE__CREATE]: isFeePlanRuleCreateAuthorized,
      [PERMISSIONS.FEE_PLAN_RULE__UPDATE]: isFeePlanRuleUpdateAuthorized,
      [PERMISSIONS.FEE_PLAN_RULE__DELETE]: isFeePlanRuleDeleteAuthorized,
    },
  } = useAuthorizations();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
    },
    {
      title: "Default Fee Plan",
      dataIndex: "defaultFeePlan",
      key: "defaultFeePlan",
      render: (index, ruleSet) =>
        ruleSet.defaultFeePlan &&
        (ruleSet.defaultFeePlan.name ?? `Fee Plan #${ruleSet.defaultFeePlan.id}`),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isFeePlanRuleUpdateAuthorized && !isFeePlanRuleDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isFeePlanRuleUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedRuleSet(record);
                setModalVisible(true);
              }}
            />
          )}
          {isFeePlanRuleDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this fee plan rule set?"
              onConfirm={() => deleteFeePlanRuleSet({ id: record.id })}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  const onNewRuleOk = () => {
    setModalVisible(false);
    setSelectedRuleSet(null);
  };

  const TableWithPlans = useCallback(
    // bundle feePlans and productOfferings with FeePlanRulesTable
    (props) => (
      <FeePlanRulesTable {...props} feePlans={feePlans} productOfferings={productOfferings} />
    ),
    [feePlans, productOfferings],
  );

  return isFeePlanRuleGetAuthorized ? (
    <>
      <h4>Fee Plan Rule Sets</h4>
      <Row align="middle">
        <span>Configure a group of fee plans and the account criteria for which each apply.</span>
        {isFeePlanRuleCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Fee Plan Rule Set
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        expandable={{
          expandedRowRender: TableWithPlans,
          // @ts-ignore
          rowExpandable: (record) => record.rules?.length > 0,
        }}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={feePlanRuleSets}
      />
      {/* The isModalVisible forces the modal to rerender itself and the form inside when toggling the modal's visibility
          This forced rerendering is needed for the form to use the latest initialValues */}
      {isModalVisible && (
        <AddFeePlanRulesetModal
          title="Add Fee Plan Rule Set"
          selectedRuleSet={selectedRuleSet}
          setSelectedRuleSet={setSelectedRuleSet}
          open
          onOk={onNewRuleOk}
          onCancel={() => {
            setModalVisible(false);
            setSelectedRuleSet(null);
          }}
        />
      )}
    </>
  ) : null;
}
