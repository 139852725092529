import { Col, Row, Table, Tag, Tooltip } from "antd";
import { capitalizeFirstLetter, formatCurrency, formatDate, idToObjectMap } from "common/utils";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchCollectorsQuery } from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useMemo } from "react";
import styled from "styled-components";

const StyledRow = styled(Row)`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const FilterTitle = styled.div`
  flex: 1;
`;

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const StyledInfoTitle = styled.div`
  color: grey;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

export default function AccountsPreviewStep({ previewAccounts, previewAccountsTotalCount }) {
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: collectors } = useFetchCollectorsQuery();

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors);
  }, [creditors]);

  const columns = [
    {
      title: "Account ID",
      width: 150,
      fixed: "left",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => {
        return a.id > b.id;
      },
      onHeaderCell: () => ({
        onClick: null,
      }),
      render: (text, record) => {
        if (record.legalStatus?.toLowerCase() === "judgment") {
          return (
            <>
              <Tag color="red">JUDGMENT</Tag>
              <span>{text}</span>
            </>
          );
        }
        return <span>{text}</span>;
      },
    },
    {
      title: "Client Reference ID",
      dataIndex: "clientReferenceId",
      key: "clientReferenceId",
      render: (text, record) => record?.clientReferenceId || "-",
      width: 175,
    },
    {
      title: "External IDs",
      render: (text, record) => (
        <Col>
          <Row align="middle">
            <StyledInfoTitle>Debtor ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.debtorExternalId ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Account ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.externalId ?? "-"}</StyledInfoValue>
          </Row>
        </Col>
      ),
    },
    {
      title: "Follow-Up Date",
      width: 150,
      dataIndex: "followUpDate",
      render: (text, record) => formatDate(record.followUpDate),
      key: "followUpDate",
    },
    {
      title: "Debtor ID",
      width: 150,
      dataIndex: "debtorId",
      key: "debtorId",
    },
    {
      title: "Debtor Name",
      width: 150,
      dataIndex: "debtorName",
      render: (text, record) => {
        if (record.isNew && record.isPriority) {
          return (
            <>
              <Tag color="red">NEW</Tag>
              <Tag color="green">PRIORITY</Tag>
              <span>{text}</span>
            </>
          );
        }
        if (record.isNew) {
          return (
            <>
              <Tag color="red">NEW</Tag>
              <span>{text}</span>
            </>
          );
        }
        if (record.isPriority) {
          return (
            <>
              <Tag color="green">PRIORITY</Tag>
              <span>{text}</span>
            </>
          );
        }
        return <span>{text}</span>;
      },
      key: "debtorName",
    },
    {
      title: "Collector Name",
      width: 150,
      render: (text, record) =>
        collectorFullName(collectors?.find((x) => x.id === record.assigneeId)),
      key: "collector",
    },
    {
      title: "Status",
      width: 150,
      dataIndex: "statusName",
      render: (text, record) => {
        if (!text || !record.statusCode) {
          return "None";
        }
        return `${record.statusCode} - ${text}`;
      },
      key: "statusName",
    },
    {
      title: "Current Balance",
      width: 150,
      children: [
        {
          title: "Original Amount",
          dataIndex: "originalTotal",
          render: (text) => formatCurrency(text),
        },
        {
          title: "Principal",
          width: 150,
          dataIndex: "principalBalance",
          key: "principalBalance",
          render: (text) => formatCurrency(text),
        },
        {
          title: "Interest",
          width: 150,
          dataIndex: "interestBalance",
          key: "interestBalance",
          render: (text) => formatCurrency(text),
        },
        {
          title: "Total Fees",
          width: 150,
          dataIndex: "totalFeesBalance",
          key: "totalFeesBalance",
          render: (text) => formatCurrency(text),
        },
        {
          title: "Account Balance",
          width: 150,
          dataIndex: "totalBalance",
          key: "totalBalance",
          render: (text) => formatCurrency(text),
        },
      ],
    },
    {
      title: "Address",
      dataIndex: "debtorAddress",
      key: "debtorAddress",
    },
    {
      title: "Turnover Date",
      width: 150,
      dataIndex: "turnoverDate",
      key: "turnoverDate",
      render: (text) => formatDate(text),
    },
    {
      title: <Tooltip title="The date the account was uploaded onto Aktos">Upload Date</Tooltip>,
      width: 150,
      dataIndex: "uploadDate",
      render: (text, record) => formatDate(record.uploadDate),
      key: "uploadDate",
    },
    {
      title: "Employment",
      width: 150,
      dataIndex: "debtorEmployer",
      render: (text, record) => record.debtorEmployer || "Unemployed",
      key: "debtorEmployer",
    },
    {
      title: "Client",
      render: (text, record) => creditorIdToCreditorMap[record.creditorId]?.name,
      key: "client",
    },
    {
      title: "Debt Type",
      width: 150,
      render: (text, _) => capitalizeFirstLetter(text),
      key: "collectionDebtType",
      dataIndex: "collectionDebtType",
    },
    {
      title: "Last Payment Date",
      width: 150,
      dataIndex: "lastPaymentDate",
      render: (text, record) => formatDate(record.lastPaymentDate),
      key: "lastPaymentDate",
    },
    {
      title: "Judgment Date",
      width: 150,
      dataIndex: "judgmentDate",
      render: (text, record) => formatDate(record.judgmentDate),
      key: "judgmentDate",
    },
    {
      title: "Close Date",
      width: 150,
      dataIndex: "closeDate",
      render: (text, record) => formatDate(record.closeDate),
      key: "closeDate",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  return (
    <StyledRow>
      <FilterTitle>
        <strong>{previewAccountsTotalCount} accounts</strong> match the selected criteria:{" "}
      </FilterTitle>
      <StyledTable
        bordered
        // @ts-ignore
        columns={columns}
        dataSource={previewAccounts}
        pagination={false}
        scroll={{ x: "max-content", y: previewAccounts.length > 0 ? 400 : null }}
      />
    </StyledRow>
  );
}
