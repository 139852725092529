import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 32px;
  margin-bottom: 16px;
`;

function ConsumerDebtorTable({ dataSource, handleDelete, isLoading, readOnly = false }) {
  const { debtorId } = useParams();
  const navigate = useNavigate();

  const debtorColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => record.id ?? "-",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        record.name ? (
          <a target="_blank" href={`/debtors/${record.id}`} rel="noreferrer">
            {record.name}
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      hidden: readOnly,
      render: (text, record, index) =>
        // Can't delete primary debtor
        (!debtorId || record?.id?.toString() !== debtorId) && (
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this debtor?"
            onConfirm={() => handleDelete(record, index)}
          >
            <DeleteOutlined key="delete-debtor" />
          </Popconfirm>
        ),
    },
  ].filter((column) => !column.hidden);

  return (
    <StyledTable
      loading={isLoading}
      dataSource={dataSource}
      // @ts-ignore
      columns={debtorColumns}
      bordered
      scroll={{ x: "max-content" }}
      pagination={false}
    />
  );
}

export default ConsumerDebtorTable;
