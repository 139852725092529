import { Button, Form, message, Select } from "antd";
import { formatCurrency } from "common/utils";
import { getPaymentIntents } from "features/payments/paymentDefaults";
import {
  useCreatePaymentIntentsMutation,
  useGetPaymentMethodOptionsQuery,
} from "features/payments/paymentsAPI";
import { selectPaymentsSlice } from "features/payments/paymentsSlice";
import { useSelector } from "react-redux";

import { useUserType } from "features/auth";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function SelectExistingPaymentMethodForm({ onOk, onCancel }) {
  const { isAgencyUserType } = useUserType();
  const { debtorId } = useParams();
  const [form] = Form.useForm();
  const [createPaymentIntents, { isLoading: confirmPaymentLoading }] =
    useCreatePaymentIntentsMutation();
  const { data: paymentOptions, isLoading: isPaymentMethodOptionsLoading } =
    useGetPaymentMethodOptionsQuery(
      {
        debtorId,
      },
      { skip: !isAgencyUserType },
    );
  const paymentsSlice = useSelector(selectPaymentsSlice);

  const onExistingPaymentMethodFinish = async (values) => {
    const { existingPaymentMethodOptionId } = values;
    try {
      const intents = getPaymentIntents({
        paymentMethodId: existingPaymentMethodOptionId,
        paymentsSchedule: paymentsSlice.paymentsSchedule,
        isPaymentPlan: paymentsSlice.isPaymentPlan,
      });
      const result = await createPaymentIntents({
        paymentMethodId: existingPaymentMethodOptionId,
        paymentIntentType: paymentsSlice.paymentIntentType,
        debtorId,
        intents,
      });
      if ("data" in result) {
        onOk();
      }
    } catch (e) {
      message.error("Failed to process the payment. Please refresh and try again.");
    }
  };

  return (
    <StyledForm
      form={form}
      layout="vertical"
      validateMessages={{ required: "This is a required field" }}
      onFinish={onExistingPaymentMethodFinish}
    >
      <Form.Item
        name="existingPaymentMethodOptionId"
        label="Payment Method:"
        initialValue={
          paymentOptions?.length > 0 ? paymentOptions[paymentOptions.length - 1].id : ""
        }
        rules={[{ required: true, message: "Payment Method is required" }]}
      >
        <Select
          loading={isPaymentMethodOptionsLoading}
          options={paymentOptions?.map((paymentMethod) => ({
            value: paymentMethod.id,
            label:
              paymentMethod.type === "process_card"
                ? `${paymentMethod.firstName} ${paymentMethod.lastName} - card ending in ${paymentMethod.cardLast4Digits}`
                : `${paymentMethod.bankAccountName} - ACH ending in ${paymentMethod.bankAccountLast4Digits}`,
          }))}
        />
      </Form.Item>
      <Form.Item>
        <Button disabled={confirmPaymentLoading} onClick={onCancel}>
          Back
        </Button>
        <Button
          disabled={confirmPaymentLoading}
          loading={confirmPaymentLoading}
          style={{ marginLeft: 8 }}
          type="primary"
          htmlType="submit"
        >
          {paymentsSlice.isPaymentPlan && "Setup Payment Plan"}
          {!paymentsSlice.downPayment &&
            !paymentsSlice.isPaymentPlan &&
            `Confirm Payment (${formatCurrency(paymentsSlice.totalAmount)})`}
        </Button>
      </Form.Item>
    </StyledForm>
  );
}

export default SelectExistingPaymentMethodForm;
