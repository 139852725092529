import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Table, Tag, message } from "antd";
import { camelToWords, renderAddress, snakeToCamelCase } from "common/utils";
import { useGetEmployersQuery } from "features/employersTable/employersAPI";
import AddGarnishmentModal from "features/garnishments/addGarnishmentModal";
import {
  useDeleteDebtorGarnishmentMutation,
  useFetchDebtorGarnishmentsQuery,
} from "features/garnishments/garnishmentsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

const StyledInfoTitle = styled.div`
  color: grey;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

function GarnishmentsTable({ debtorId }) {
  const [isGarnishmentModalVisible, setGarnishmentModalVisible] = useState(false);
  const [deleteGarnishment] = useDeleteDebtorGarnishmentMutation();
  const [activeRecord, setActiveRecord] = useState(null);
  const { data: garnishments, isLoading } = useFetchDebtorGarnishmentsQuery({ debtorId });
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: employers = [], isLoading: isEmployersLoading } = useGetEmployersQuery();

  const {
    data: {
      [PERMISSIONS.DEBTOR_GARNISHMENT__GET]: isGarnishmentGetAuthorized,
      [PERMISSIONS.DEBTOR_GARNISHMENT__CREATE]: isGarnishmentCreateAuthorized,
      //  [PERMISSIONS.DEBTOR_GARNISHMENT__UPDATE]: isGarnishmentUpdateAuthorized,
      [PERMISSIONS.DEBTOR_GARNISHMENT__DELETE]: isGarnishmentDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (payload) => {
    const result = await deleteGarnishment(payload);
    if ("data" in result) {
      message.success("Garnishment deleted successfully!");
    }
  };

  const onGarnishmentOk = () => {
    setActiveRecord(null);
    setGarnishmentModalVisible(false);
  };

  const onGarnishmentCancel = () => {
    setActiveRecord(null);
    setGarnishmentModalVisible(false);
  };

  /*
  const onUpdateGarnishment = (record) => {
    setGarnishmentModalVisible(true);
    setActiveRecord(record);
  };
  */

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => camelToWords(snakeToCamelCase(text)) || "-",
    },
    {
      title: "Employer",
      dataIndex: "employerId",
      key: "employer",
      render: (employerId, record) => {
        const currentEmployer = employers.find((employer) => employer.id === employerId);
        if (currentEmployer) {
          return (
            <Tag>
              {currentEmployer.id} - {currentEmployer.name}
            </Tag>
          );
        }

        if (record.employerId || record.employerName) {
          return (
            <Tag>
              {record.employerId && `${record.employerId} - `}
              {record.employerName ? record.employerName : "Unknown"}
            </Tag>
          );
        }

        return "-";
      },
    },
    {
      title: "Employer Address",
      dataIndex: "employerAddress",
      key: "employerAddress",
      render: (text, record) => {
        if (record.employerId) {
          const currentEmployer = employers.find((employer) => employer.id === record.employerId);
          return renderAddress(currentEmployer?.address, constants?.states) || "-";
        }

        return (
          renderAddress(
            {
              address1: record.employerAddress1,
              address2: record.employerAddress2,
              city: record.employerAddressCity,
              state: record.employerAddressState,
              zipCode: record.employerAddresszipCode,
            },
            constants?.states,
          ) || "-"
        );
      },
    },
    {
      title: "Employer Phone",
      dataIndex: "employerPhoneNumber",
      key: "employerPhoneNumber",
      render: (_, record) => {
        if (record.employerId) {
          const currentEmployer = employers.find((employer) => employer.id === record.employerId);
          return (
            <Col>
              <Row align="middle">
                <StyledInfoTitle>Work:</StyledInfoTitle>
                <StyledInfoValue>{currentEmployer?.contact?.workPhone ?? "-"}</StyledInfoValue>
              </Row>
              <Row align="middle">
                <StyledInfoTitle>Cell:</StyledInfoTitle>
                <StyledInfoValue>{currentEmployer?.contact?.cellPhone ?? "-"}</StyledInfoValue>
              </Row>
            </Col>
          );
        }

        if (!record.employerWorkPhoneNumber && !record.employerCellPhoneNumber) {
          return "-";
        }

        return (
          <Col>
            <Row align="middle">
              <StyledInfoTitle>Work:</StyledInfoTitle>
              <StyledInfoValue>{record?.employerWorkPhoneNumber ?? "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Cell:</StyledInfoTitle>
              <StyledInfoValue>{record?.employerCellPhoneNumber ?? "-"}</StyledInfoValue>
            </Row>
          </Col>
        );
      },
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (text) => text || "-",
    },
    {
      title: "Bank Routing Number",
      dataIndex: "bankRoutingNumber",
      key: "bankRoutingNumber",
      render: (text) => text || "-",
    },
    {
      title: "Bank Account Number",
      dataIndex: "bankAccountNumber",
      key: "bankAccountNumber",
      render: (text) => text || "-",
    },
    {
      title: "Bank Address",
      dataIndex: "bankAddress",
      key: "bankAddress",
      render: (text, record) =>
        renderAddress(
          {
            address1: record.bankAddress1,
            address2: record.bankAddress2,
            city: record.bankAddressCity,
            state: record.bankAddressState,
            zipCode: record.bankAddresszipCode,
          },
          constants?.states,
        ) || "-",
    },
    {
      title: "Bank Phone Number",
      dataIndex: "bankPhoneNumber",
      key: "bankPhoneNumber",
      render: (text) => text || "-",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => text || "-",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      hidden: !isGarnishmentDeleteAuthorized, // && !isGarnishmentUpdateAuthorized,
      render: (text, record) => {
        return (
          <Space size="middle">
            {/*
            isGarnishmentUpdateAuthorized && (
              <Tooltip placement="bottom" title="Edit" key="edit">
                <EditOutlined key="edit" onClick={() => onUpdateGarnishment(record)} />
              </Tooltip>
            )
            */}
            {isGarnishmentDeleteAuthorized && (
              <Popconfirm
                placement="topLeft"
                okText="Yes"
                title="Are you sure you want to delete this record?"
                onConfirm={() => handleDelete({ debtorId, bankruptcyId: record.id })}
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ].filter((column) => !column.hidden);

  return isGarnishmentGetAuthorized ? (
    <>
      {isGarnishmentCreateAuthorized && (
        <StyledButton
          type="link"
          onClick={() => setGarnishmentModalVisible(true)}
          icon={<PlusOutlined />}
        >
          Add Garnishment
        </StyledButton>
      )}
      <Table
        bordered
        loading={isLoading || isEmployersLoading}
        style={{ cursor: "pointer" }}
        // @ts-ignore
        columns={columns}
        dataSource={garnishments}
        scroll={{ x: "max-content" }}
      />
      {isGarnishmentModalVisible && (
        <AddGarnishmentModal
          garnishmentRecord={activeRecord}
          debtorId={debtorId}
          title="Add Garnishment"
          open={isGarnishmentModalVisible}
          onOk={onGarnishmentOk}
          onCancel={onGarnishmentCancel}
        />
      )}
    </>
  ) : null;
}

export default GarnishmentsTable;
