import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import config from "app/envConfig";
import { LoginProvider } from "providers";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import "./index.css";

Sentry.init({
  environment: config.aktosEnv,
  dsn: config.sentryDsn,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
  // Trace 100% of the transactions in production.
  tracesSampleRate: config.aktosEnv === "prod" ? 1.0 : 1.0,
  tracePropagationTargets: ["localhost", config.apiBaseUrl],

  // Capture Replay for 10% of all sessions, and 100% of sessions with errors
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.configureScope((scope) => {
  // Tag all events coming out of this app as 'frontend'.
  // This tag helps us differentiate frontend events from backend events.
  scope.setTag("service", "frontend");
});

const container = document.getElementById("root");
const root = createRoot(container);

const theme = {
  token: {
    colorPrimary: "#1677ff",
    colorLink: "#0098ed",
  },
};

root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <LoginProvider>
        <App />
      </LoginProvider>
    </ConfigProvider>
  </React.StrictMode>,
);
