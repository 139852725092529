import api, { transformRequest, transformResponse } from "services/api";

export const employersApi = api.enhanceEndpoints({ addTagTypes: ["Employers"] }).injectEndpoints({
  endpoints: (build) => ({
    getEmployers: build.query({
      query: () => "/api/v1/common/employers/",
      transformResponse: (response) => transformResponse(response),
      providesTags: (results) =>
        results
          ? // successful query
            [
              ...results.map(({ id }) => ({ type: "Employers", id })),
              { type: "Employers", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Employers', id: 'LIST' }` is invalidated
            [{ type: "Employers", id: "LIST" }],
    }),
    createEmployer: build.mutation({
      query: (payload) => ({
        url: "/api/v1/core/employers/create/",
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all Employers-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Employers", id: "LIST" }],
    }),
    updateEmployer: build.mutation({
      query: (payload) => ({
        url: `/api/v1/core/employers/${payload.id}/update/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all Employers-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Employers", id: "LIST" }],
    }),
    deleteEmployer: build.mutation({
      query: (payload) => ({
        url: `/api/v1/core/employers/${payload.employerId}/delete/`,
        method: "POST",
        body: transformRequest(payload),
        headers: { "aktos-suppress-error": "true" },
      }),
      // Invalidates all Employers-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Employers", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEmployersQuery,
  useCreateEmployerMutation,
  useUpdateEmployerMutation,
  useDeleteEmployerMutation,
} = employersApi;
