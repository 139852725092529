import { Card, Tabs } from "antd";
import { CommunicationMethodsTable } from "features/communicationMethodsTable";
import { SequencesTable } from "features/sequencesTable";

function communicationSequencesTabs() {
  const items = [
    {
      label: "Communication Sequences",
      key: "communicationSequences",
      children: <SequencesTable />,
    },
    {
      label: "Communication Methods",
      key: "communicationMethods",
      children: <CommunicationMethodsTable />,
    },
  ];

  return (
    <Card>
      <Tabs items={items} defaultActiveKey="sequences" />
    </Card>
  );
}

export default communicationSequencesTabs;
