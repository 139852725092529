import { Form } from "antd";
import Content from "components/formWizard/components/Content";
import Steps from "components/formWizard/components/Steps";
import { FormWizardProvider, useFormWizard } from "components/formWizard/formWizardContext";

function FormWizard() {
  const { steps, current, onNextStep, setIsLoading, onDone, setError, canSkipSteps } =
    useFormWizard();

  const onFormFinish = async (formName, { forms }) => {
    const formInstance = forms[formName];
    const formNames = Object.keys(forms);
    const lastStepFormName = formNames[formNames.length - 1];

    setIsLoading(true);
    setError(null);
    try {
      // Validate currently submitted form
      const formValues = await formInstance.validateFields();
      // If onNext prop is supplied, send to callback
      if (formValues && steps[current].onNext) {
        const result = await steps[current].onNext(formValues, forms);
        if (result?.error) {
          throw new Error(result.error.data?.error?.message ?? result.error.message);
        }
      }
      if (formName === lastStepFormName) {
        // Firstly, fetch all values from all forms
        const allValues = await Promise.all(formNames.map((fn) => forms[fn].getFieldsValue()));
        // Secondly, build a map of <formName, formValues>
        const allValuesMap = formNames.reduce(
          (acc, fn, index) => ({ ...acc, [fn]: allValues[index] }),
          {},
        );
        // Finally, send them to the onDone callback
        const result = await onDone(allValuesMap);
        if (result) {
          formNames.forEach((fn) => forms[fn].resetFields());
        }
      } else {
        // Switch to the next step
        await onNextStep();
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Steps />
      <Content />
    </Form.Provider>
  );
}

function WithProvider(props) {
  return (
    <FormWizardProvider {...props}>
      <FormWizard />
    </FormWizardProvider>
  );
}

export default WithProvider;
