import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Popconfirm, Row, Table, message } from "antd";
import config from "app/envConfig";
import { capitalizeFirstLetter } from "common/utils";
import {
  useDeleteCreditorDocumentMutation,
  useFetchCreditorDocumentsQuery,
} from "features/creditorProfile/creditorProfileAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";
import UploadCreditorDocumentModal from "./uploadCreditorDocumentModal";

const StyledDocumentButton = styled(Button)`
  margin-bottom: 12px;
`;

const StyledHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 36px;
  text-transform: uppercase;
`;

const StyledRow = styled(Row)`
  display: flex;
`;

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 28px;
  left: 0;
  background-color: #f9f3f3;
`;

const StyledCard = styled(Card)`
  flex: 1;
  .ant-card-body {
    padding: 16px;
  }
`;

function CreditorDocumentsTable({ currentCreditor }) {
  const creditorId = currentCreditor?.id;
  const [isUploadDocumentModalVisible, setUploadDocumentModalVisible] = useState(false);
  const { data: documents, isLoading } = useFetchCreditorDocumentsQuery(
    {
      creditorId,
    },
    { skip: !creditorId },
  );
  const [deleteDocument] = useDeleteCreditorDocumentMutation();
  const {
    data: {
      [PERMISSIONS.DEBTOR_DOCUMENT__GET]: isDebtorDocumentGetAuthorized,
      [PERMISSIONS.DEBTOR_DOCUMENT__CREATE]: isDebtorDocumentCreateAuthorized,
      [PERMISSIONS.DEBTOR_DOCUMENT__DELETE]: isDebtorDocumentDeleteAuthorized,
      [PERMISSIONS.CLIENT_PROFILE__CAN_UPLOAD_DOCUMENTS]:
        isClientProfileCanUploadDocumentsAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (payload) => {
    const result = await deleteDocument(payload);
    if ("data" in result) {
      message.success("Document removed successfully!");
    }
  };

  const onUploadDocumentOk = () => {
    setUploadDocumentModalVisible(false);
  };

  const onUploadDocumentCancel = () => {
    setUploadDocumentModalVisible(false);
  };

  const columns = [
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => new Date(record.createdAt).toLocaleDateString(),
      sorter: {
        compare: (a, b) => {
          const dateA = a.createdAt;
          const dateB = b.createdAt;
          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        const tokens = text.split("_").map((val) => {
          return capitalizeFirstLetter(val);
        });
        const prettifiedText = tokens.join(" ");
        return <div>{prettifiedText}</div>;
      },
      sorter: (a, b) => {
        return a.type.toLowerCase() > b.type.toLowerCase();
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
      render: (text, record) => {
        return record.file ? (
          <a target="_blank" href={new URL(record.file, config.apiBaseUrl).href} rel="noreferrer">
            {text}
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: "External URL",
      dataIndex: "externalUrl",
      key: "externalUrl",
      width: 200,
      render: (externalUrl) => {
        return externalUrl ? (
          <a target="_blank" href={externalUrl} rel="noreferrer">
            {externalUrl}
          </a>
        ) : null;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      hidden: !isDebtorDocumentDeleteAuthorized,
      render: (text, record) => {
        return (
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this document?"
            onConfirm={() => handleDelete({ documentId: record.id, creditorId })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        );
      },
    },
  ].filter((column) => !column.hidden);

  return isDebtorDocumentGetAuthorized ? (
    <>
      <StyledCard>
        <StyledRow>
          <StyledHeader>Documents</StyledHeader>
          <StyledDivider />
        </StyledRow>
        {(isDebtorDocumentCreateAuthorized || isClientProfileCanUploadDocumentsAuthorized) && (
          <StyledDocumentButton
            type="link"
            icon={<PlusOutlined />}
            onClick={() => setUploadDocumentModalVisible(true)}
          >
            Add Document
          </StyledDocumentButton>
        )}
        <Table
          // @ts-ignore
          columns={columns}
          loading={isLoading}
          bordered
          scroll={{ x: "max-content" }}
          dataSource={documents}
        />
      </StyledCard>
      {isUploadDocumentModalVisible && (
        <UploadCreditorDocumentModal
          creditorId={creditorId}
          title="Add Document"
          open={isUploadDocumentModalVisible}
          onOk={onUploadDocumentOk}
          onCancel={onUploadDocumentCancel}
        />
      )}
    </>
  ) : null;
}

export default CreditorDocumentsTable;
